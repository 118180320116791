import { ColumnDefinition, OverlayBubbles, RestProps, Row } from '@clinintell/components/agGrid/tablesTypes';
import { ColumnDataType } from '@clinintell/components/StyledTableCell';
import { ColumnName, ColumnView, getNodeTypeLabelForNode } from '@clinintell/containers/metrics/typings/tableSchemas';
import { Metrics, ValueType } from '@clinintell/modules/metricsNavigation';
import { NodeTypeIds, TreeNode } from '@clinintell/modules/orgTree';
import { formatNumberWithCommas, formatToDecimalPlaces, parseDateIntoPeriod } from '@clinintell/utils/formatting';
import { useEffect, useState } from 'react';
import { MetricRecordJSON, MetricSchema } from '../typings/metricTypes';

export enum Ranges {
  gapVsCe = 'gapVsCeInErrorRange',
  change = 'changeInErrorRange',
  otoEGapVsCe = 'otoEGapVsCeInErrorRange',
  otoEChange = 'otoEChangeInErrorRange'
}

export function getOverlay(
  key: 'gapVsCe' | 'change' | 'otoEGapVsCe' | 'otoEChange',
  value: number
): OverlayBubbles | undefined {
  // severityCMI, LOS, RAF have no bubble data, all calling with value = NaN
  if (value === 0 || isNaN(value)) {
    return undefined;
  }

  if (key === 'gapVsCe' || key === 'otoEGapVsCe') {
    return value < 0 ? 'red' : 'yellow';
  }
  if (key === 'change' || key === 'otoEChange') {
    return value > 0 ? 'green' : 'red';
  }

  return undefined;
}

type RowMenu = {
  title: string;
  onClick: (entity: number) => void;
};

type UseMetricsDataConversionForTableProps = {
  dataset: MetricRecordJSON[] | null;
  schema: MetricSchema | null;
  tableColumnView: ColumnView | null;
  isLoading: boolean;
  periodStart?: Date | string;
  periodEnd?: Date | string;
  comparisonPeriodStart?: Date | string;
  comparisonPeriodEnd?: Date | string;
  metric: keyof typeof Metrics;
  valueType: ValueType;
  onNameClick?: (orgId: number) => void;
  onConditionClick?: (orgId: number, conditionId: number) => void;
  isProvider?: boolean;
  navigateToGraphFunc?: (entity: number) => void;
  navigateToComparisonTableFunc?: (entity: number) => void;
  conditionCodeFunc?: (conditionId: number) => void;
  rowCountLimit?: number;
  selectedEntity: TreeNode | null;
  forOCE?: boolean;
};

type MetricsDataConversionForTable = {
  rows?: Row[];
  footerRow?: Row;
  columns: Record<string, ColumnDefinition>;
  defaultSortColumn: string;
};

const useMetricsDataConversionForTable = ({
  dataset,
  schema,
  tableColumnView,
  isLoading,
  periodEnd,
  periodStart,
  comparisonPeriodEnd,
  comparisonPeriodStart,
  metric,
  valueType,
  onNameClick,
  isProvider,
  navigateToComparisonTableFunc,
  navigateToGraphFunc,
  rowCountLimit,
  onConditionClick,
  conditionCodeFunc,
  selectedEntity,
  forOCE = false
}: UseMetricsDataConversionForTableProps): MetricsDataConversionForTable => {
  const [metricsDataConversionForTable, setMetricsDataConversionForTable] = useState<MetricsDataConversionForTable>({
    rows: undefined,
    footerRow: undefined,
    columns: {},
    defaultSortColumn: ''
  });

  useEffect(() => {
    if (!dataset || !schema || !tableColumnView || isLoading) {
      return;
    }

    const metricRecords = dataset.length > 0 && dataset.some(rec => rec.name !== null) ? dataset : [];
    let footerRow: Row | undefined = undefined;

    // Certain columns are toggleable by value type (relative weight or dollars). These columns are determined by their suffix - they end
    // with either RW or Dollars. Based on the given valueType, iterate through the tableColumnView array and replace any of these columns
    // with their correct suffix (if any AND if needed)
    const newValueType = valueType === 'rwpv' ? 'RW' : 'Dollar';
    const oldValueType = valueType === 'rwpv' ? 'Dollar' : 'RW';

    const updatedColumnDefs = tableColumnView.columns.map(column => {
      if (!column.endsWith(oldValueType)) {
        return column;
      }

      return (`${column.split(oldValueType)[0]}${newValueType}` as unknown) as ColumnDataType;
    });

    // Update default sorting column if need be
    let defaultSortColumn = tableColumnView.defaultSort;
    if (defaultSortColumn.endsWith(oldValueType)) {
      defaultSortColumn = `${defaultSortColumn.split(oldValueType)[0]}${newValueType}` as ColumnName;
    }

    const rowMenu: RowMenu[] = [];
    if (navigateToGraphFunc) {
      rowMenu.push({ title: 'Graph', onClick: navigateToGraphFunc });
    }

    if (
      (!isProvider || (metric !== 'allConditions' && metric !== 'targetedConditions')) &&
      navigateToComparisonTableFunc
    ) {
      rowMenu.push({
        title: 'Comparison Table',
        onClick: navigateToComparisonTableFunc
      });
    }

    if ((metric === 'allConditions' || metric === 'targetedConditions') && conditionCodeFunc) {
      rowMenu.push({
        title: 'Condition Codes',
        onClick: conditionCodeFunc
      });
    }

    const menuNotForConditions =
      ((selectedEntity && selectedEntity.nodeTypeId === NodeTypeIds['Provider']) ||
        (metric !== 'allConditions' && metric !== 'targetedConditions')) &&
      rowMenu;

    // The name field is a special case where the column header is dynamic based on the dataset. The column header is given to us
    // in the returned JSON through the NodeType field. If there is a footer, that is the group and thus has the column header we want.
    // Otherwise it's a single record, so grab it from the single record.
    let nameColumnHeader = '';

    // The backend gives us all column definitions for every view, filter that out based on the given table columns for the specified view we want
    const columnDefinitions: Record<string, ColumnDefinition> = updatedColumnDefs.reduce((obj, column) => {
      // The backend returns column property names in various format, would be nice if it was consistently camel case
      const jsonColName = column.charAt(0).toUpperCase() + column.slice(1);

      const periodStartDate = typeof periodStart === 'string' ? new Date(periodStart) : periodStart;
      const periodEndDate = typeof periodEnd === 'string' ? new Date(periodEnd) : periodEnd;
      const comparisonPeriodStartDate =
        typeof comparisonPeriodStart === 'string' ? new Date(comparisonPeriodStart) : comparisonPeriodStart;
      const comparisonPeriodEndDate =
        typeof comparisonPeriodEnd === 'string' ? new Date(comparisonPeriodEnd) : comparisonPeriodEnd;

      // Modify column titles based on dynamic JSON data for certain columns
      let title;
      if ((column === 'current' || column === 'otoE') && periodStart && periodEnd) {
        title =
          periodStartDate && periodEndDate
            ? `${parseDateIntoPeriod(periodStartDate)}-${parseDateIntoPeriod(periodEndDate)}`
            : '';
      } else if (
        (column === 'historical' || column === 'historicalOtoE') &&
        comparisonPeriodStart &&
        comparisonPeriodEnd
      ) {
        title =
          comparisonPeriodStartDate && comparisonPeriodEndDate
            ? `${parseDateIntoPeriod(comparisonPeriodStartDate)}-${parseDateIntoPeriod(comparisonPeriodEndDate)}`
            : '';
      } else if (column === 'mdcName') {
        title = 'MDC';
      } else if (column === 'conditionTypeId') {
        title = 'Type';
      } else if (column === 'gapVsCe' || column === 'otoEGapVsCe') {
        title = 'Gap';
      } else if (column === 'action') {
        title = 'Action';
      } else {
        if (schema.properties[jsonColName] !== undefined) {
          title = schema.properties[jsonColName].title;
        } else {
          title = '';
        }
      }

      return {
        ...obj,
        [column]: {
          ...schema.properties[jsonColName],
          title,
          type: schema.properties[jsonColName] !== undefined ? schema.properties[jsonColName].type : 'string',
          maxCharacters: 0
        }
      };
    }, {});

    // Must do this separately to prevent errors
    metricRecords.forEach(record => {
      Object.keys(record)
        .filter(key => Object.prototype.hasOwnProperty.call(columnDefinitions, key))
        .forEach(property => {
          const type = columnDefinitions[property].type;
          const maxCharactersDef = columnDefinitions[property].maxCharacters;
          const maxCharacters = maxCharactersDef ? maxCharactersDef : 0;
          let formattedValue = record[property] ? record[property].toString() : '-';
          if (type === 'integer') {
            formattedValue = formatNumberWithCommas(formattedValue);
          } else if (type === 'number') {
            formattedValue = formatToDecimalPlaces(formattedValue, 2);
          }
          if (formattedValue.length > maxCharacters) {
            columnDefinitions[property].maxCharacters = formattedValue.length;
          }
        });
    });

    // Filter out footer row
    let tableRows: Row[] = metricRecords
      .filter(record => {
        if (!record.isFooter) {
          return true;
        }

        nameColumnHeader = getNodeTypeLabelForNode(record.nodeType);

        // Found the footer row, filter out properties that aren't part of this view
        footerRow = Object.keys(record)
          .filter(rec => Object.hasOwnProperty.call(columnDefinitions, rec))
          .reduce(
            (obj, col) => {
              const { type, prefix, postfix } = columnDefinitions[col];
              const restProps: RestProps = {};
              restProps.type = type;
              restProps.prefix = prefix;
              restProps.postfix = postfix;
              restProps.key = col;

              if (col === 'gapVsCe' || col === 'change') {
                const overlayValue = getOverlay(col, Number(record[Ranges[col]]));
                if (overlayValue) {
                  restProps.overlayStyle = overlayValue;
                }
              } else if (col === 'name') {
                restProps.entity = record.orgId;
                restProps.condition = record.conditionId;
                if (metric === 'allConditions' || metric === 'targetedConditions') {
                  restProps.conditionName = record['name'];
                }

                if (rowMenu) {
                  restProps.rowMenu = rowMenu.filter(menu => menu.title === 'Graph');
                }

                restProps.isFooter = true;
              }

              // Only show other children on the footer row IF the footer is the only row in the entire dataset
              if (metricRecords.length === 1) {
                if (
                  col === 'name' &&
                  record.otherChildren &&
                  Array.isArray(record.otherChildren) &&
                  record.otherChildren.length > 0
                ) {
                  restProps.otherChildren = [...record.otherChildren];
                }
                restProps.isFooter = false;
              }

              return {
                ...obj,
                [col]: {
                  rawValue: record[col],
                  uId: record.orgId,
                  condition: record.conditionId,
                  maxCharacters: columnDefinitions[col].maxCharacters,
                  restProps
                }
              };
            },
            {
              action: {
                rawValue: record.name,
                uId: record.orgId,
                overlayStyle: '',
                restProps: {
                  key: 'action',
                  entity: record.orgId,
                  condition: record.conditionId,
                  conditionTypeId: record.conditionTypeId,
                  rowMenu: rowMenu.filter(menu => menu.title === 'Graph'),
                  type: 'string',
                  maxCharacters: 0,
                  isFooter: false
                }
              }
            }
          );

        return false;
      })
      // Filter out columns we don't need for this view, much like what we did with the footer
      .map(row => {
        return Object.keys(row)
          .filter(rec => Object.hasOwnProperty.call(columnDefinitions, rec))
          .reduce(
            (obj, col, index) => {
              // Node type for rows should supercede footer node for dynamic column header
              nameColumnHeader = getNodeTypeLabelForNode(row.nodeType);

              const { type } = columnDefinitions[col];
              const restProps: RestProps = {};
              restProps.type = type;
              restProps.key = col;

              if (
                col === 'name' &&
                row.otherChildren &&
                Array.isArray(row.otherChildren) &&
                row.otherChildren.length > 0
              ) {
                restProps.otherChildren = [...row.otherChildren];
              }

              if (col === 'gapVsCe' || col === 'change' || col === 'otoEGapVsCe' || col === 'otoEChange') {
                const overlayValue = getOverlay(col, Number(row[Ranges[col]]));
                if (overlayValue) {
                  restProps.overlayStyle = overlayValue;
                }
                if (col === 'otoEChange') {
                  restProps.tooltipTitle = 'Error, Change';
                  restProps.tooltipContent = `+${row['otoEErrorChangePositive']}% / -${row['otoEErrorChangeNegative']}%`;
                } else if (col === 'otoEGapVsCe') {
                  restProps.tooltipTitle = 'Error, Gap';
                  restProps.tooltipContent = `+${row['otoEErrorGapPositive']}% / -${row['otoEErrorGapNegative']}%`;
                } // Condition records are not 'clickable'
              } else if (col === 'name') {
                restProps.entity = row.orgId;
                restProps.condition = row.conditionId;
                if (metric !== 'allConditions' && metric !== 'targetedConditions' && onNameClick) {
                  restProps.onClick = (): void => {
                    onNameClick(row.orgId);
                  };
                } else if ((metric === 'allConditions' || metric === 'targetedConditions') && onConditionClick) {
                  restProps.onClick = (): void => {
                    onConditionClick && onConditionClick(row.orgId, row.conditionId);
                  };
                  restProps.showTrainingTooltip =
                    row.hasApprovedContent !== undefined && row.hasApprovedContent === false;
                }

                if (metric === 'allConditions' || metric === 'targetedConditions') {
                  restProps.conditionName = row['name'];
                  restProps.rowMenu = rowMenu;
                }

                if (menuNotForConditions) {
                  restProps.rowMenu = rowMenu.filter(menu => menu.title === 'Graph');
                }

                restProps.isFooter = row.isFooter;
              }

              if (col === 'isTargetCondition') {
                return {
                  ...obj,
                  [col]: {
                    rawValue: row.isTargetCondition,
                    uId: row.orgId,
                    condition: row.conditionId,
                    maxCharacters: columnDefinitions[col].maxCharacters
                  }
                };
              }

              return {
                ...obj,
                [col]: {
                  rawValue: row[col],
                  uId: row.orgId,
                  condition: row.conditionId,
                  overlayStyle:
                    col === 'gapVsCe' || col === 'change' || col === 'otoEGapVsCe' || col === 'otoEChange'
                      ? getOverlay(col, Number(row[Ranges[col]]))
                      : '',
                  maxCharacters: columnDefinitions[col].maxCharacters,
                  restProps
                }
              };
            },
            {
              action: {
                rawValue: row.name,
                uId: row.orgId,
                overlayStyle: '',
                restProps: {
                  key: 'action',
                  entity: row.orgId,
                  condition: row.conditionId,
                  conditionTypeId: row.conditionTypeId,
                  rowMenu: menuNotForConditions ? rowMenu.filter(menu => menu.title === 'Graph') : rowMenu,
                  type: 'string',
                  maxCharacters: 0,
                  isFooter: false
                }
              }
            }
          );
      });

    if (rowCountLimit !== undefined) {
      tableRows = tableRows.slice(0, rowCountLimit);
    }

    columnDefinitions.name.title = nameColumnHeader;

    // IF there is a footer row and no other records from the backend JSON, the assumption is that the user is at the very bottom level possible
    // which is there is a single provider and nothing else. In that case, show the footer row as a regular row in the table and don't display any footer data
    let finalTableRows = tableRows;
    let finalFooterRow = footerRow;
    if (tableRows.length === 0 && footerRow) {
      finalTableRows = [footerRow as Row];
      finalFooterRow = undefined;
    }

    setMetricsDataConversionForTable({
      rows: finalTableRows,
      footerRow: finalFooterRow,
      columns: columnDefinitions,
      defaultSortColumn
    });
  }, [
    comparisonPeriodEnd,
    comparisonPeriodStart,
    dataset,
    isLoading,
    isProvider,
    metric,
    navigateToComparisonTableFunc,
    navigateToGraphFunc,
    onConditionClick,
    conditionCodeFunc,
    onNameClick,
    periodEnd,
    periodStart,
    rowCountLimit,
    schema,
    tableColumnView,
    valueType,
    selectedEntity,
    forOCE
  ]);

  return metricsDataConversionForTable;
};

export default useMetricsDataConversionForTable;
