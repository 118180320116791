/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import { Box, List, ListItem, ListItemText, useMediaQuery, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Resources } from '@clinintell/utils/resources';
import { add, Duration, endOfYear, startOfMonth, startOfYear, sub } from 'date-fns';
import Button from '@clinintell/components/button/Button';

//#region Styling
const useStyles = makeStyles(theme => ({
  root: {
    padding: 5,
    border: `1px solid ${theme.palette.grey[300]}`,
    [theme.breakpoints.down('md')]: {
      borderRadius: 0
    }
  },
  mainContainer: {
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: 10,
    [theme.breakpoints.down('md')]: {
      borderBottom: 'none'
    },
    [theme.breakpoints.down('sm')]: {
      border: 'none',
      borderRadius: 0
    },
    '& .calendarContainer': {
      minWidth: useSingleCalendar => (useSingleCalendar === true ? 200 : 435)
    },
    '& .calendars': {
      width: '100%',
      [theme.breakpoints.down('md')]: {
        width: '320px',
        '& .drpCalendarLeft .react-datepicker .react-datepicker__month': {
          borderRight: `solid 1px ${theme.palette.grey[300]}`
        }
      },
      '& > div': {
        width: useSingleCalendar => (useSingleCalendar === true ? 'auto' : '50%')
      }
    },
    '& .calendarHeader': {
      textAlign: 'center',
      fontWeight: 600,
      padding: '12px 10px'
    },
    '& .calendarHeaderLabel': {
      color: theme.palette.grey[800],
      borderBottom: `1px solid ${theme.palette.grey[200]}`
    },
    '& .react-datepicker': {
      width: '100%',
      marginTop: 12,
      border: 0,
      borderBottom: useSingleCalendar => (useSingleCalendar === true ? 'none' : `solid 1px ${theme.palette.grey[200]}`),
      borderRadius: 0,
      backgroundColor: theme.palette.common.white,
      [theme.breakpoints.down('md')]: {
        padding: '15px 0'
      },
      '& .react-datepicker__navigation--previous': {
        left: '35px',
        borderRightColor: theme.palette.common.black,
        '&:hover': {
          backgroundColor: theme.palette.grey[100],
          borderRadius: 10
        },
        [theme.breakpoints.down('md')]: {
          left: 30,
          marginTop: 15
        }
      },
      '& .react-datepicker__navigation--next': {
        right: '35px',
        borderLeftColor: theme.palette.common.black,
        '&:hover': {
          backgroundColor: theme.palette.grey[100],
          borderRadius: 10
        },
        [theme.breakpoints.down('md')]: {
          right: 30,
          marginTop: 15
        }
      },
      '& .react-datepicker__month-container': {
        width: '100%'
      },
      '& .react-datepicker__header': {
        backgroundColor: theme.palette.common.white,
        border: 0,
        padding: '10px 0',
        fontWeight: theme.typography.fontWeightRegular
      },
      '& .react-datepicker__header .react-datepicker-year-header': {
        marginTop: 5
      },
      '& .react-datepicker__month': {
        [theme.breakpoints.down('md')]: {
          margin: 0
        }
      },
      '& .react-datepicker__month-text--keyboard-selected': {
        backgroundColor: 'transparent',
        color: theme.palette.grey[900],
        borderRadius: 25
      },
      '& .react-datepicker__month .react-datepicker__month-text': {
        margin: '2px 14px',
        padding: '13px 4px',
        width: '2.25rem',
        fontWeight: theme.typography.fontWeightMedium,
        textTransform: 'capitalize',
        fontFamily: theme.typography.fontFamily,
        [theme.breakpoints.down('lg')]: {
          margin: '2px 8px'
        },
        [theme.breakpoints.down('md')]: {
          margin: '0 5px',
          width: '2.25rem'
        },
        [theme.breakpoints.down('sm')]: {
          width: '2rem'
        }
      },
      '& .react-datepicker__month .react-datepicker__month--selected, & .react-datepicker__day--selected': {
        backgroundColor: `${theme.palette.teal.main} !important`,
        color: 'white !important',
        borderRadius: 25
      },
      '& .react-datepicker__month .react-datepicker__month-text:hover': {
        backgroundColor: theme.palette.grey[50],
        color: theme.palette.teal.main,
        borderRadius: 25,
        padding: '13px 4px'
      },
      '& .react-datepicker__day:hover': {
        backgroundColor: theme.palette.grey[50],
        color: theme.palette.teal.main,
        borderRadius: 25
      },
      '& .react-datepicker__month .react-datepicker__month--disabled, .react-datepicker__quarter--disabled': {
        color: theme.palette.grey[300]
      }
    }
  },
  actionContainer: {
    padding: 8,
    '& button': {
      width: 130
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      padding: theme.spacing(2),
      textAlign: 'center',
      '& button': {
        width: `calc(100% - ${theme.spacing(2)})`,
        margin: `${theme.spacing(1)} 0`
      }
    }
  },
  preselectBox: {
    paddingTop: '20px',
    borderRight: `1px solid ${theme.palette.grey[200]}`,
    [theme.breakpoints.down('md')]: {
      padding: `${theme.spacing(2)} 0`,
      textAlign: 'center',
      '& button': {
        margin: '5px',
        width: '175px',
        fontWeight: 600,
        color: theme.palette.teal.main,
        '&:hover': {
          fontWeight: theme.typography.fontWeightBold,
          borderColor: theme.palette.teal.main,
          backgroundColor: theme.palette.teal.light5
        }
      }
    },
    '& ul.desktopList a': {
      width: '180px',
      '& span': {
        fontSize: '0.9rem'
      },
      '&:hover': {
        backgroundColor: theme.palette.grey[50],
        '& span': {
          fontWeight: theme.typography.fontWeightBold,
          color: theme.palette.teal.main
        }
      }
    }
  },
  preselectList: {
    listStyle: 'none',
    margin: 0,
    textAlign: 'center',
    position: 'relative',
    left: '-15px'
  },
  preselectItem: {
    padding: '5px',
    '& button': {
      fontSize: '.9rem',
      backgroundColor: theme.palette.common.white,
      color: theme.palette.blue.main,
      '&:hover': {
        backgroundColor: theme.palette.grey[50],
        color: theme.palette.teal.main,
        border: `1px solid ${theme.palette.blue.main}`,
        fontWeight: theme.typography.fontWeightBold
      },
      border: `1px solid ${theme.palette.blue.main}`
    }
  }
}));
//#endregion

//#region Props & Resources
interface TransformOrigin {
  horizontal: 'left' | 'center' | 'right';
  vertical: 'top' | 'center' | 'bottom';
}

export type DateRangeProps = {
  id: string;
  title: string;
  isDisabled: boolean;
  isHistorical?: boolean;
  isPreselectVisible?: boolean;
  isInterventionAvailable: boolean;
  absoluteMinDate: string;
  absoluteMaxDate: string;
  selectionMinDate?: string | Date | null;
  selectionMaxDate: string | Date;
  handlePreselect?: any;
  handleDateRangeApply: any;
  handlePrePostIntervention?: any;
  size?: 'small' | 'large';
  positionLeft?: string;
  transformOrigin?: TransformOrigin;
  useIntervalRange?: Duration;
  isLoading?: boolean;
  style?: React.CSSProperties;
  useSimpleLayout?: boolean;
  useSingleCalendar?: boolean;
  closeOnSelect?: boolean;
  // Only works with single calendars right now
  useDaySelection?: boolean;
};

export interface DateRangeContainerProps extends DateRangeProps {
  onCancel: any;
  onApply: any;
  onPreselect: any;
  onPrePostClick: any;
  size?: 'small' | 'large';
}
//#endregion

const DateRangePickerContainer: React.FC<DateRangeContainerProps> = (props: DateRangeContainerProps) => {
  const classes = useStyles(props.useSingleCalendar);
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('md'));
  const { isPreselectVisible, useSingleCalendar, closeOnSelect = false } = props;
  const absoluteMinDate = new Date(props.absoluteMinDate);
  const absoluteMaxDate = new Date(props.absoluteMaxDate);
  const [originalMinDate] = useState<Date | undefined>(
    !!props.selectionMinDate ? new Date(props.selectionMinDate) : undefined
  );
  const [originalMaxDate] = useState(new Date(props.selectionMaxDate));
  const [tempMinDate, setTempMinDate] = useState<Date | undefined>(originalMinDate);
  const [tempMaxDate, setTempMaxDate] = useState<Date>(originalMaxDate);
  const [tempSingleDate, setTempSingleDate] = useState<Date | undefined>(originalMinDate);

  const handleMinDateChange = (date: Date): void => {
    setTempMinDate(date);
    if (date > tempMaxDate) {
      let newMaxDate = endOfYear(date);
      if (props.useIntervalRange) {
        const adjustedMaxDate = startOfMonth(add(date, props.useIntervalRange));
        newMaxDate = adjustedMaxDate > absoluteMaxDate ? absoluteMaxDate : adjustedMaxDate;
      } else if (newMaxDate > absoluteMaxDate) {
        newMaxDate = absoluteMaxDate;
      }
      setTempMaxDate(newMaxDate);
    }
    if (props.useIntervalRange) {
      const adjustedMaxDate = startOfMonth(add(date, props.useIntervalRange));
      setTempMaxDate(adjustedMaxDate > absoluteMaxDate ? absoluteMaxDate : adjustedMaxDate);
    }
    if (closeOnSelect) {
      props.onApply(date, tempMaxDate);
    }
  };
  const handleMaxDateChange = (date: Date): void => {
    if (!tempMinDate) return;
    setTempMaxDate(date);
    if (date < tempMinDate) {
      let newMinDate = startOfYear(date);
      if (props.useIntervalRange) {
        const adjustedMinDate = startOfMonth(sub(date, props.useIntervalRange));
        newMinDate = adjustedMinDate < absoluteMinDate ? absoluteMinDate : adjustedMinDate;
      } else if (newMinDate < absoluteMinDate) {
        newMinDate = absoluteMinDate;
      }
      setTempMinDate(newMinDate);
    }
    if (props.useIntervalRange) {
      const adjustedMinDate = startOfMonth(sub(date, props.useIntervalRange));
      setTempMinDate(adjustedMinDate < absoluteMinDate ? absoluteMinDate : adjustedMinDate);
    }
    if (closeOnSelect) {
      props.onApply(tempMinDate, date);
    }
  };

  if (useSingleCalendar) {
    return (
      <Box display="flex" flexWrap="wrap" className={classes.mainContainer}>
        <Box className="calendarContainer">
          <Box className="calendars">
            <Box className="calendarHeader calendarHeaderLabel">Date</Box>
            <DatePicker
              id="drpSingleCalendar"
              selected={tempSingleDate}
              onChange={(date: Date): void => {
                setTempSingleDate(date);
                if (closeOnSelect) {
                  props.onApply(date);
                }
              }}
              onCalendarClose={(): void => props.onApply(tempSingleDate)}
              dateFormat="MMM yy"
              minDate={absoluteMinDate}
              maxDate={absoluteMaxDate}
              inline
              showMonthYearPicker={props.useDaySelection !== undefined ? !props.useDaySelection : true}
              shouldCloseOnSelect={true}
              data-testid="drpSingleDatePicker"
            />
          </Box>
          {!closeOnSelect && (
            <Box display="flex" flexWrap="nowrap" justifyContent="flex-end" className={classes.actionContainer}>
              <Box order={2} margin={1}>
                <Button
                  onClick={(): void => props.onApply(tempSingleDate)}
                  label={Resources.Apply}
                  testId="applyCurrentDate"
                />
              </Box>
              <Box order={1} margin={1}>
                <Button variant="outlined" type="secondary" onClick={props.onCancel} label={Resources.Cancel} />
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    );
  }

  return (
    <>
      {isMobileView ? (
        <Box display="flex" flexWrap="wrap" className={classes.mainContainer}>
          {isPreselectVisible ? (
            <Box
              display="flex"
              flexWrap="wrap"
              order={1}
              className={classes.preselectBox}
              justifyContent="center"
              alignContent="center"
            >
              <Box flexWrap="nowrap">
                <Button
                  type="secondary"
                  label={Resources.ThreeMonths}
                  variant="outlined"
                  onClick={(): void => props.onPreselect(3)}
                />
                <Button
                  type="secondary"
                  label={Resources.SixMonths}
                  variant="outlined"
                  onClick={(): void => props.onPreselect(6)}
                />
              </Box>
              <Box flexWrap="nowrap">
                <Button
                  type="secondary"
                  label={Resources.TwelveMonths}
                  variant="outlined"
                  onClick={(): void => props.onPreselect(12)}
                />
                <Button
                  type="secondary"
                  label={Resources.TwentyFourMonths}
                  variant="outlined"
                  onClick={(): void => props.onPreselect(24)}
                />
              </Box>
              {props.isInterventionAvailable && (
                <Box>
                  <Button
                    type="secondary"
                    label={Resources.PrePostIntervention}
                    variant="outlined"
                    onClick={(): void => props.onPrePostClick()}
                  />
                </Box>
              )}
            </Box>
          ) : null}
          <Box display="flex" flexGrow={1} flexWrap="nowrap" className="calendars" order={2}>
            <Box width="50%">
              <Box className="drpCalendarLeft">
                <Box className="calendarHeader calendarHeaderLabel">From</Box>
                <DatePicker
                  id="drpMinCalendar"
                  selected={tempMinDate}
                  onChange={handleMinDateChange}
                  dateFormat="MMM yy"
                  minDate={absoluteMinDate}
                  maxDate={absoluteMaxDate}
                  inline
                  selectsStart
                  showMonthYearPicker
                  shouldCloseOnSelect={true}
                  data-testid="drpMinDatePicker"
                />
              </Box>
            </Box>
            {props.useSingleCalendar ? null : (
              <Box width="50%">
                <Box className="calendarHeader calendarHeaderLabel">To</Box>
                <DatePicker
                  id="drpMaxCalendar"
                  selected={tempMaxDate}
                  onChange={handleMaxDateChange}
                  dateFormat="MMM yy"
                  minDate={absoluteMinDate}
                  maxDate={absoluteMaxDate}
                  inline
                  selectsEnd
                  showMonthYearPicker
                  shouldCloseOnSelect={false}
                  data-testid="drpMaxDatePicker"
                />
              </Box>
            )}
          </Box>
          {!closeOnSelect && (
            <Box
              display="flex"
              flexWrap="wrap"
              justifyContent="center"
              alignContent="center"
              className={classes.actionContainer}
              order={3}
            >
              <Box width="100%">
                <Button
                  type="secondary"
                  onClick={(): void => props.onApply(tempMinDate, tempMaxDate)}
                  label={Resources.Apply}
                />
                <Button variant="outlined" type="secondary" onClick={props.onCancel} label={Resources.Cancel} />
              </Box>
            </Box>
          )}
        </Box>
      ) : (
        <Box display="flex" className={classes.mainContainer}>
          {isPreselectVisible ? (
            <Box order={1} className={classes.preselectBox}>
              <List className="desktopList">
                <ListItem
                  button
                  component="a"
                  onClick={(): void => props.onPreselect(3)}
                  onTouchEnd={(): void => props.onPreselect(3)}
                >
                  <ListItemText>{Resources.ThreeMonths}</ListItemText>
                </ListItem>
                <ListItem
                  button
                  component="a"
                  onClick={(): void => props.onPreselect(6)}
                  onTouchEnd={(): void => props.onPreselect(6)}
                >
                  <ListItemText>{Resources.SixMonths}</ListItemText>
                </ListItem>
                <ListItem
                  button
                  component="a"
                  onClick={(): void => props.onPreselect(12)}
                  onTouchEnd={(): void => props.onPreselect(12)}
                >
                  <ListItemText>{Resources.TwelveMonths}</ListItemText>
                </ListItem>
                <ListItem
                  button
                  component="a"
                  onClick={(): void => props.onPreselect(24)}
                  onTouchEnd={(): void => props.onPreselect(24)}
                >
                  <ListItemText>{Resources.TwentyFourMonths}</ListItemText>
                </ListItem>
                {props.isInterventionAvailable && (
                  <ListItem
                    button
                    component="a"
                    onClick={(): void => props.onPrePostClick()}
                    onTouchEnd={(): void => props.onPrePostClick()}
                  >
                    <ListItemText>{Resources.PrePostIntervention}</ListItemText>
                  </ListItem>
                )}
              </List>
            </Box>
          ) : null}
          <Box className="calendarContainer" order={1}>
            <Box display="flex" flexWrap="nowrap" className="calendars">
              <Box>
                <Box className="calendarHeader calendarHeaderLabel">From</Box>
                <Box className="drpCalendarLeft" data-cy="drpMinCalendar">
                  <DatePicker
                    id="drpMinCalendar"
                    selected={tempMinDate}
                    onChange={handleMinDateChange}
                    dateFormat="MMM yy"
                    minDate={absoluteMinDate}
                    maxDate={absoluteMaxDate}
                    inline
                    selectsStart
                    showMonthYearPicker
                    shouldCloseOnSelect={false}
                    data-testid="drpMinDatePicker"
                  />
                </Box>
              </Box>
              <Box>
                <Box className="calendarHeader calendarHeaderLabel">To</Box>
                <Box className="drpCalendarRight" data-cy="drpMaxCalendar">
                  <DatePicker
                    id="drpMaxCalendar"
                    selected={tempMaxDate}
                    onChange={handleMaxDateChange}
                    dateFormat="MMM yy"
                    minDate={absoluteMinDate}
                    maxDate={absoluteMaxDate}
                    inline
                    selectsEnd
                    showMonthYearPicker
                    shouldCloseOnSelect={false}
                    data-testid="drpMaxDatePicker"
                  />
                </Box>
              </Box>
            </Box>
            {!closeOnSelect && (
              <Box display="flex" flexWrap="nowrap" justifyContent="flex-end" className={classes.actionContainer}>
                <Box order={2} margin={1}>
                  <Button
                    onClick={(): void => props.onApply(tempMinDate, tempMaxDate)}
                    label={Resources.Apply}
                    type="secondary"
                    testId="applyCurrentDate"
                    sx={{ minWidth: 130 }}
                  />
                </Box>
                <Box order={1} margin={1}>
                  <Button
                    variant="outlined"
                    type="secondary"
                    onClick={props.onCancel}
                    label={Resources.Cancel}
                    sx={{ minWidth: 130 }}
                  />
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      )}
    </>
  );
};

export default DateRangePickerContainer;
