import React, { useCallback, useEffect, useState } from 'react';
import { useConditions, useConditionsDispatch } from '@clinintell/modules/store';
import { ConditionTypes, fetchConditions, IndexedCondition } from '@clinintell/modules/conditions';
import { Select, SelectOptionType } from '@clinintell/components/index';

interface ConditionSelectOptionType extends SelectOptionType {
  conditionType?: string;
}

interface SelectConditionProps {
  id?: string;
  orgId?: number;
  onChange: (id: number, name: string) => void;
  selectedValue?: ConditionSelectOptionType | undefined;
  loadFirstOption?: boolean;
  filterConditionTypes?: ConditionTypes[];
  listWidth?: number;
}

const SelectCondition: React.FC<SelectConditionProps> = ({
  id = 'selectCondition',
  orgId,
  onChange,
  selectedValue,
  loadFirstOption = false,
  filterConditionTypes,
  listWidth
}) => {
  const conditionDispatch = useConditionsDispatch();
  const { byId: conditions, isLoading, isInitialized: isConditionsInitialized } = useConditions();

  const [conditionSelectList, setConditionSelectList] = useState<ConditionSelectOptionType[]>([]);

  const UpdateConditionSelect = useCallback((selectList: ConditionSelectOptionType[]) => {
    if (selectList.length === 0) return;
    setConditionSelectList(selectList);
  }, []);

  useEffect(() => {
    if (isLoading) return;

    if (!isConditionsInitialized) {
      conditionDispatch(fetchConditions({ orgId }));
    }
  }, [conditionDispatch, isConditionsInitialized, isLoading, orgId]);

  useEffect(() => {
    if (isLoading || !conditions) return;

    const selectList: ConditionSelectOptionType[] = [];

    const filteredConditions = filterConditionTypes
      ? Object.entries(conditions as IndexedCondition).filter(condition =>
          filterConditionTypes.includes(condition[1].conditionType as ConditionTypes)
        )
      : Object.entries(conditions as IndexedCondition);
    filteredConditions
      .sort((a, b) => {
        const aDTO = a[1];
        const bDTO = b[1];
        if (aDTO.conditionDescription.toLowerCase() > bDTO.conditionDescription.toLowerCase()) return 1;
        if (aDTO.conditionDescription.toLowerCase() < bDTO.conditionDescription.toLowerCase()) return -1;
        return 0;
      })
      .forEach(condition => {
        const { id, conditionDescription, conditionType } = condition[1];
        selectList.push({ value: Number(id), label: `${conditionDescription}, ${conditionType}` });
      });

    // This is for the Metrics > Graph > Conditions
    if (isConditionsInitialized && !isLoading && loadFirstOption && selectList.length) {
      const { value, label } = selectList[0];
      onChange(value as number, label);
    }

    UpdateConditionSelect(selectList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conditions]);

  let selectedOption: ConditionSelectOptionType | undefined = undefined;
  if (isConditionsInitialized && !isLoading && loadFirstOption) {
    const condition = Object.values(conditions as IndexedCondition)[0];
    selectedOption = {
      label: condition?.conditionDescription,
      value: condition?.id,
      conditionType: condition?.conditionType
    } as ConditionSelectOptionType;
  } else if (
    conditions &&
    !isLoading &&
    selectedValue &&
    (selectedValue?.label === undefined || selectedValue?.label === '')
  ) {
    const condition = Object.values(conditions as IndexedCondition).find(c => c.id === Number(selectedValue?.value));
    selectedOption = {
      label: condition?.conditionDescription,
      value: condition?.id,
      conditionType: condition?.conditionType
    } as ConditionSelectOptionType;
  } else if (selectedValue && selectedValue.label !== undefined && selectedValue.label !== '') {
    selectedOption = selectedValue;
  }

  return (
    <Select
      id={id}
      value={selectedOption ? Number(selectedOption.value) : -1}
      options={conditionSelectList}
      isSearchable={true}
      onChange={(value, label): void => {
        onChange(Number(value), label);
      }}
      listWidth={listWidth}
    />
  );
};

export default React.memo(SelectCondition);
