import React, { lazy, Suspense } from 'react';
import { Switch, useRouteMatch, Route } from 'react-router-dom';
import Can from '@clinintell/components/Can';
import RouteErrorPage from '@clinintell/containers/errorHandlers/RouteErrorPage';
import LoadingScreen from '@clinintell/components/LoadingScreen';

const MedicareComparison = lazy(() =>
  import(/* webpackChunkName: 'medicareComparison' */ '@clinintell/containers/medicareComparison/MedicareComparison')
);

const CMIAnalysisLanding = lazy(() =>
  import(/* webpackChunkName: 'cmiAnalysis' */ '@clinintell/containers/cmiAnalysisLanding/CMIAnalysisLanding')
);

const AnalysisRoutes = (): JSX.Element => {
  const { path } = useRouteMatch();

  return (
    <Suspense fallback={<LoadingScreen loadingIndicatorSize={250} />}>
      <Switch>
        <Route
          path={`${path}/medicarehistograms`}
          render={(): JSX.Element => {
            return (
              <Can
                permissions={['safHistogramView']}
                yes={(): JSX.Element => {
                  return <MedicareComparison />;
                }}
                no={(): JSX.Element => <RouteErrorPage error={new Error('401')} />}
              />
            );
          }}
        />
        <Route
          path={`${path}/cmianalysis`}
          render={() => {
            return (
              <Can
                permissions={['reportCmiAnalysisView', 'reportCmiComparisonView']}
                yes={(): JSX.Element => {
                  return <CMIAnalysisLanding />;
                }}
                no={(): JSX.Element => <RouteErrorPage error={new Error('401')} />}
              />
            );
          }}
        />
        <Route render={(): JSX.Element => <RouteErrorPage error={new Error('404')} />} />
      </Switch>
    </Suspense>
  );
};

export default AnalysisRoutes;
