import React, { lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import Can from '@clinintell/components/Can';
import RouteErrorPage from '@clinintell/containers/errorHandlers/RouteErrorPage';

const DocScoreGoalController = lazy(() =>
  import(/* webpackChunkName: 'DocScoreGoalController' */ '@clinintell/containers/autogenStrategy/DocScoreGoalStrategy')
);
const TrackPerformance = lazy(() =>
  import(/* webpackChunkName: 'TrackPerformance' */ '@clinintell/containers/autogenStrategy/TrackPerformance')
);

const GoalRoutes = (): JSX.Element => {
  return (
    <Switch>
      <Route path="/goals/build">
        <Can
          permissions={['docScoreGoalEdit', 'docScoreGoalView', 'docScoreGoalOverride']}
          yes={(): JSX.Element => <DocScoreGoalController />}
          no={(): JSX.Element => <RouteErrorPage error={new Error('401')} />}
        />
      </Route>
      <Route path="/goals/track">
        <Can
          permissions={['docScoreGoalEdit', 'docScoreGoalView', 'docScoreGoalOverride']}
          yes={(): JSX.Element => <TrackPerformance />}
          no={(): JSX.Element => <RouteErrorPage error={new Error('401')} />}
        />
      </Route>
      <Route render={(): JSX.Element => <RouteErrorPage error={new Error('404')} />} />
    </Switch>
  );
};

export default GoalRoutes;
