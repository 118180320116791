import { setBreadcrumbs } from '@clinintell/modules/breadcrumbNavigation';
import { useBreadcrumbNavigationDispatch } from '@clinintell/modules/store';
import { useEffect } from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';

const useClearBreadcrumbNavigationOnRouteChange = () => {
  const { pathname } = useLocation();
  const breadcrumbNavigationDispatch = useBreadcrumbNavigationDispatch();

  // These routes use breadcrumbs so in order to prevent a race condition, don't clear
  const isMetricsRoute = useRouteMatch('/metrics');

  useEffect(() => {
    if (isMetricsRoute) {
      return;
    }

    breadcrumbNavigationDispatch(setBreadcrumbs({ breadcrumbs: [] }));
  }, [breadcrumbNavigationDispatch, isMetricsRoute, pathname]);
};

export default useClearBreadcrumbNavigationOnRouteChange;
