import React from 'react';
import { useUser } from '@clinintell/modules/store';
import { metricLabels, metricPermissions, Metrics as MetricTypes } from '@clinintell/modules/metricsNavigation';
import { userCan } from '@clinintell/containers/authentication/rules';
import { Select, SelectOptionType } from '../../components/index';

interface SelectMetricProps {
  id?: string;
  onChange: (value: keyof typeof MetricTypes) => void;
  selectedValue?: string;
  excludeMetrics?: MetricTypes[];
  showConditionsOptions: boolean;
  isDisabled?: boolean;
  width?: number | string;
  testId?: string;
}

const SelectMetric: React.FC<SelectMetricProps> = ({
  id = 'selectMetric',
  onChange,
  selectedValue,
  excludeMetrics,
  showConditionsOptions,
  isDisabled = false,
  width,
  testId
}) => {
  const user = useUser();

  const lineDividerMetrics = [MetricTypes.severityCmi, MetricTypes.los, MetricTypes.psi, MetricTypes.raf];

  const buildMetricMenu = (): SelectOptionType[] => {
    let filteredMetrics = Object.values(MetricTypes);
    if (excludeMetrics) {
      filteredMetrics = Object.values(MetricTypes).filter(metric => !excludeMetrics.includes(metric));
    }
    const menu: SelectOptionType[] = filteredMetrics
      .map((metric: keyof typeof MetricTypes) => ({
        value: metric,
        label: metricLabels[metric],
        withLineDivider: lineDividerMetrics.includes(metric as MetricTypes)
      }))
      .filter(metric => {
        return userCan(user, [...metricPermissions[metric.value]]);
      });

    if (user.features.some(f => f.featureName === 'metricsConditionsViewTargetConditions') && showConditionsOptions) {
      const targetedConditionsOption = {
        value: 'targetedConditions',
        label: 'Targeted Conditions'
      };
      menu.push(targetedConditionsOption);
    }

    if (
      user.features.some(
        f => f.featureName === 'metricsConditionsViewAllConditions' || f.featureName === 'metricsAll'
      ) &&
      showConditionsOptions
    ) {
      const allConditionsOption = {
        value: 'allConditions',
        label: 'All Conditions'
      };
      menu.push(allConditionsOption);
    }

    return menu;
  };
  const metricSelectOptions = buildMetricMenu();

  return (
    <Select
      id={id}
      options={metricSelectOptions}
      value={selectedValue ?? ''}
      onChange={(value): void => {
        const metric = value as keyof typeof MetricTypes;
        onChange(metric);
      }}
      isDisabled={isDisabled}
      width={width}
      testId={testId}
    />
  );
};

export default SelectMetric;
