import React, { useEffect, useState } from 'react';
import { useGetAPICAll } from '@clinintell/utils/useGetAPICall';
import { QueryStringParam, createUrlWithQueryString } from '@clinintell/utils/querystring';
import { InputLabel, Select, SelectOptionType } from '../../components/index';

interface SystemGroupType {
  id: number;
  physicianGroupName: string;
}
type Options = SelectOptionType[];

type Props = {
  labelPosition?: 'top' | 'side' | 'none';
  defaultValue?: number;
  defaultLabel?: string;
  onSystemGroupChangeFn: (value: number | null, display?: string) => void;
  onSelectLoaded?: (value: boolean) => void;
  excludeGroups?: boolean;
  width?: number | string;
};

const SelectSystemGroup: React.FC<Props> = ({
  labelPosition = 'top',
  defaultValue,
  defaultLabel,
  onSystemGroupChangeFn,
  onSelectLoaded,
  excludeGroups = false,
  width = 400
}) => {
  const [systemGroupOptions, setSystemGroupOptions] = useState<Options | []>([]);
  const [systemGroupTotalCount, setSystemGroupTotalCount] = useState(0);

  const selectionChange = (option: SelectOptionType): void => {
    const { value, label } = option;
    if (systemGroupOptions.findIndex(group => group.value === value) === -1) {
      setSystemGroupOptions([...systemGroupOptions, option]);
      setSystemGroupTotalCount(systemGroupTotalCount + 1);
    }
    onSystemGroupChangeFn(value ? (value as number) : null, label);
  };

  const params: QueryStringParam[] = [];
  if (excludeGroups) {
    params.push(
      {
        key: 'excludeHiddenSpecialtyGroups',
        value: 'true'
      },
      {
        key: 'excludeOtherGroups',
        value: 'true'
      },
      {
        key: 'excludeOtherMDs',
        value: 'true'
      },
      {
        key: 'excludeHiddenProviders',
        value: 'true'
      }
    );
  }

  const { output: systemGroupOutput, isLoading } = useGetAPICAll<SystemGroupType[]>({
    endpoint: createUrlWithQueryString(`physiciangroups`, params),
    isWaiting: false
  });

  if (systemGroupOutput && systemGroupOutput.error) {
    throw new Error(systemGroupOutput.error);
  }

  useEffect(() => {
    if (!systemGroupOutput || !systemGroupOutput.data) {
      return;
    }
    if (systemGroupOutput && systemGroupOutput.error) {
      throw new Error(systemGroupOutput.error);
    }

    if (systemGroupOutput && systemGroupOutput.data && systemGroupOutput.data.length > 0) {
      const systemGroupHashMap = new Map<string, number>();
      systemGroupOutput.data
        .sort((a, b) => {
          return a.physicianGroupName.localeCompare(b.physicianGroupName, 'en', { ignorePunctuation: true });
        })
        .forEach(group => {
          if (!systemGroupHashMap.has(group.physicianGroupName)) {
            systemGroupHashMap.set(group.physicianGroupName, group.id);
          }
        });
      const dropDownList: Options = [];
      systemGroupHashMap.forEach((value, key) => {
        dropDownList.push({
          value: value,
          label: key
        });
      });

      if (
        systemGroupHashMap.size > 50 &&
        defaultValue &&
        defaultLabel &&
        dropDownList.findIndex(h => h.value === defaultValue) === -1
      ) {
        dropDownList.push({ value: defaultValue, label: defaultLabel });
      }

      setSystemGroupOptions(dropDownList);

      if (
        onSelectLoaded &&
        defaultValue &&
        dropDownList.length > 0 &&
        dropDownList.find(group => group.value === defaultValue)
      ) {
        onSelectLoaded(true);
      }
    }
  }, [defaultLabel, defaultValue, isLoading, onSelectLoaded, systemGroupOutput]);

  return (
    <InputLabel position={labelPosition} label="System Group">
      <Select
        width={width}
        isSearchable={true}
        options={systemGroupOptions}
        value={Number(defaultValue)}
        onChange={(value, label): void => {
          selectionChange({ value: Number(value), label });
        }}
      />
    </InputLabel>
  );
};

export default React.memo(SelectSystemGroup);
