import { Auth0Client } from '@auth0/auth0-spa-js';
import { decodeArrayBufferToString } from './arrayBuffers';

export type AsyncStatus = 'idle' | 'fetching' | 'fetched';

export enum HTTPMethods {
  GET = 'GET',
  POST = 'POST',
  PUT = 'PUT',
  DELETE = 'DELETE',
  PATCH = 'PATCH'
}

export type HttpResponseException = {
  status?: number;
  message?: string;
};

export interface Fetch<T> {
  url: string;
  method: keyof typeof HTTPMethods;
  accessToken?: string;
  data?: T;
  headers?: Record<string, string>;
  signal?: AbortSignal;
}

export interface FetchWithInjectableMessage<T> extends Fetch<T> {
  getMethod?: <T, U>({ url, method, data, headers, signal, accessToken }: Fetch<U>) => Promise<FetchResponse<T>>;
}

export interface FetchResponse<T> extends Response {
  parsedBody?: T;
}

const defaultHeaders = {
  'Content-Type': 'application/json',
  Pragma: 'no-cache',
  Expires: 'Sat, 01 Jan 2000 00:00:00 GMT'
};

const defaultCacheSetting = 'no-cache'; // IE 11 auto-caches async calls, which we don't want

/* Wrapper around fetch browser API for making async calls. Accepts the following parameters:
    url     -  The URL for the fetch call
    method  -  HTTP method/verb of the fetch call: GET, POST, PUT, ...
    data    -  JSON object representing any data being posted to the server
    headers -  JSON object representing any HTTP headers being sent through the request

  The method returns an object with the following properties:
    data -   The output data of the async call
    status - The HTTP response code of the async call: 404, 500, 200, ...
*/
export const performFetch = async <T, U>({
  url,
  method,
  data,
  headers,
  signal,
  accessToken
}: Fetch<U>): Promise<FetchResponse<T>> => {
  try {
    const options: RequestInit = {
      method,
      signal,
      cache: defaultCacheSetting,
      headers: {
        ...defaultHeaders,
        ...headers,
        ...(accessToken && { Authorization: `Bearer ${accessToken}` })
      }
    };

    if (data) {
      if (typeof data === 'string') {
        options.body = data;
      } else if (data instanceof FormData) {
        options.body = data;
        // If formdata, the server will automatically set the multipart/form-data content type in the
        // request header as well as the boundary.
        delete (options.headers as Record<string, string>)['Content-Type'];
      } else {
        options.body = JSON.stringify(data);
      }
    }

    const response: FetchResponse<T> = await fetch(url, options);
    if (!response.ok || response.status === 204) {
      // console.log('TODO: handle different error types, retry if timeout', response);
      // Error Boundary should pick up any bad responses
      return response;
    }

    const contentType = response.headers.get('Content-Type');
    // This will throw if the above response is not ok
    try {
      switch (contentType) {
        case 'application/json; charset=utf-8':
          response.parsedBody = await response.json();
          break;
        case 'text/plain; charset=utf-8':
          const resp = response.clone();
          // The backend API is incorrectly returning text content types for certain calls that are actually
          // returning JSON data. Try to parse JSON first, if that fails parse the text.
          try {
            response.parsedBody = await response.json();
          } catch {
            response.parsedBody = ((await resp.text()) as unknown) as T;
          }
          break;
        case 'application/vnd.openxmlformats':
        case 'application/pdf':
        case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        case 'application/zip':
          response.parsedBody = ((await response.arrayBuffer()) as unknown) as T;
          break;
      }
    } catch (exception) {
      return new Response('Response parsing exception caught.', {
        status: 500,
        statusText: (exception as HttpResponseException).message
      });
    }

    return response;
  } catch (exception) {
    // console.log('TODO filter out abort errors by error text', exception);
    // throw new Error(exception);
    return new Response('Unknown application exception caught', {
      status: 500,
      statusText: (exception as HttpResponseException).message
    });
  }
};

export interface AsyncOutput<T> {
  data?: T;
  error?: string;
  aborted?: boolean;
  status?: number;
  url?: string;
}

// Wrapper to make requests and return data in consistent format - either returning a data property or error property
export const makeRequest = async <T, U = void>({
  url,
  headers,
  signal,
  accessToken,
  getMethod = performFetch,
  method,
  data
}: FetchWithInjectableMessage<U>): Promise<AsyncOutput<T>> => {
  const result = await getMethod<T, U>({ url, method, headers, signal, accessToken, data });

  if (result.status === 200 || result.status === 201 || result.status === 202) {
    return {
      data: result.parsedBody,
      status: result.status,
      url
    };
  } else if (result.status === 204) {
    return {
      status: result.status,
      url
    };
  } else if (result.status === 400 || result.status === 409) {
    try {
      const errorMessage = decodeArrayBufferToString(await result.arrayBuffer());

      return {
        error: errorMessage
      };
    } catch (exception) {
      let errorMessage = '';
      if (typeof exception === 'string') {
        errorMessage = exception;
      } else if (exception instanceof Error) {
        errorMessage = exception.message;
      }

      return {
        error: errorMessage
      };
    }
  }

  return {
    error: `${result.status} error: ${result.statusText}`
  };
};

interface API {
  _url: string;
  _isSecureApi: boolean;
  _auth0WebClient: Auth0Client | null;
}

interface GetProps {
  endpoint: string;
  headers?: Record<string, string>;
  signal?: AbortSignal;
  useSAF?: boolean;
}

type FormProps<T> = {
  endpoint: string;
  data: T;
  fileType: string;
  headers?: Record<string, string>;
  method: 'POST' | 'PUT';
};

type PostProps<T> = {
  endpoint: string;
  data?: T;
  headers?: Record<string, string>;
};

export type DuplicateRequestHandler = 'takeFirst' | 'takeEvery' | 'takeLatest';

function getContentType(fileType: string): string {
  let contentType = 'application/json';
  switch (fileType) {
    case 'csv':
      contentType = 'text/csv';
      break;
    case 'doc':
      contentType = 'application/msword';
      break;
    case 'docx':
      contentType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
      break;
    case 'json':
      contentType = 'application/json';
      break;
    case 'pdf':
      contentType = 'application/pdf';
      break;
    case 'ppt':
      contentType = 'application/vnd.ms-powerpoint';
      break;
    case 'xls':
      contentType = 'application/vnd.ms-excel';
      break;
    case 'xlsx':
      contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      break;
    default:
      contentType = 'application/x-www-form-urlencoded';
  }
  return contentType;
}

// API classes which formulate the correct URL based on the needed API
export class APIClass implements API {
  _url = '';
  _isSecureApi: boolean;
  static instance: APIClass;
  _auth0WebClient: Auth0Client | null = null;
  _invalidatedAuthStatus = false;
  _environment = '';
  constructor(isSecureApi: boolean) {
    this._isSecureApi = isSecureApi;
  }

  setUrl = (url: string): void => {
    this._url = url;
  };

  // API could make silent authentication calls to Auth0 in order to refresh the access token
  setAuth0WebClient = (client: Auth0Client): void => {
    this._auth0WebClient = client;
  };

  setInvalidatedAuthStatus = (value: boolean): void => {
    this._invalidatedAuthStatus = value;
  };

  setEnvironment = (value: string): void => {
    this._environment = value;
  };

  getInvalidatedAuthStatus = (): boolean => {
    return this._invalidatedAuthStatus;
  };

  // Authenticates user by checking if browser cookie has a valid token. If not, performs a slient auth against Auth0's
  // servers. If that fails, the user is unathenticated and should be redirected to sign in. Returns access token if valid.
  _authenticateAndRetrieveCookie = async (): Promise<string | undefined> => {
    if (!this._url) {
      throw new Error(
        'API url is not set, this must be explicitly set from the injected webpack configuration values before use.'
      );
    }

    if (!this._auth0WebClient) {
      throw new Error('Auth0 Web Client is not set, this must be set through the setAuth0WebClient class method.');
    }

    if (!this._environment) {
      throw new Error(
        'Environment is not set, this must be set through the setEnvironment class method in the root App component.'
      );
    }

    try {
      const accessToken = await this._auth0WebClient.getTokenSilently();
      return accessToken;
    } catch (exception) {
      this._auth0WebClient.logout({
        returnTo: this._environment
      });
    }
  };

  get = async <T>({ endpoint, headers, signal, useSAF }: GetProps): Promise<AsyncOutput<T>> => {
    let accessToken = undefined;
    if (this._isSecureApi) {
      accessToken = await this._authenticateAndRetrieveCookie();
    }

    return await makeRequest<T>({
      url: useSAF ? `${this._url}/saf/${endpoint}` : `${this._url}/${endpoint}`,
      headers,
      signal,
      method: 'GET',
      ...(this._isSecureApi && { accessToken: accessToken as string })
    });
  };

  download = async (endpoint: string, fileType: string): Promise<AsyncOutput<null>> => {
    let accessToken = undefined;
    if (this._isSecureApi) {
      accessToken = await this._authenticateAndRetrieveCookie();
    }

    const contentType = getContentType(fileType);

    return await makeRequest<null>({
      url: `${this._url}/${endpoint}`,
      headers: {
        'Content-Type': contentType,
        'Content-Disposition': 'attachment',
        'Accept-Encoding': 'gzip, deflate'
      },
      method: 'GET',
      ...(this._isSecureApi && { accessToken: accessToken as string })
    });
  };

  submit = async <T>({ endpoint, data, fileType, method, headers }: FormProps<T>): Promise<AsyncOutput<null>> => {
    if (!this._isSecureApi) {
      throw new Error('Cannot perform form submission with an unsecured API.');
    }

    const accessToken = await this._authenticateAndRetrieveCookie();

    const contentType = getContentType(fileType);

    return await makeRequest<null, T>({
      url: `${this._url}/${endpoint}`,
      headers: {
        // Browser applies Content-Type for form data
        'Content-Type': contentType,
        'Accept-Encoding': 'gzip, deflate',
        ...headers
      },
      method,
      data,
      ...(this._isSecureApi && { accessToken: accessToken as string })
    });
  };

  post = async <T, U>({ endpoint, data, headers }: PostProps<U>): Promise<AsyncOutput<T>> => {
    let accessToken = undefined;
    if (this._isSecureApi) {
      accessToken = await this._authenticateAndRetrieveCookie();
    }

    return await makeRequest<T, U>({
      url: `${this._url}/${endpoint}`,
      data,
      headers,
      method: 'POST',
      ...(this._isSecureApi && { accessToken: accessToken as string })
    });
  };

  put = async <T, U>({ endpoint, data, headers }: PostProps<U>): Promise<AsyncOutput<T>> => {
    let accessToken = undefined;
    if (this._isSecureApi) {
      accessToken = await this._authenticateAndRetrieveCookie();
    } else {
      throw new Error('Cannot perform POST requests with an unsecured API.');
    }

    return await makeRequest<T, U>({
      url: `${this._url}/${endpoint}`,
      data,
      headers,
      method: 'PUT',
      ...(this._isSecureApi && { accessToken: accessToken as string })
    });
  };

  patch = async <T, U>({ endpoint, data, headers }: PostProps<U>): Promise<AsyncOutput<T>> => {
    let accessToken = undefined;
    if (this._isSecureApi) {
      accessToken = await this._authenticateAndRetrieveCookie();
    } else {
      throw new Error('Cannot perform POST requests with an unsecured API.');
    }

    return await makeRequest<T, U>({
      url: `${this._url}/${endpoint}`,
      data,
      headers,
      method: 'PATCH',
      ...(this._isSecureApi && { accessToken: accessToken as string })
    });
  };

  delete = async <T, U>({ endpoint, headers, data }: PostProps<U>): Promise<AsyncOutput<T>> => {
    let accessToken = undefined;
    if (this._isSecureApi) {
      accessToken = await this._authenticateAndRetrieveCookie();
    } else {
      throw new Error('Cannot perform DELETE requests with an unsecured API.');
    }

    return await makeRequest<T, U>({
      url: `${this._url}/${endpoint}`,
      headers,
      method: 'DELETE',
      data,
      ...(this._isSecureApi && { accessToken: accessToken as string })
    });
  };
}

export const ApplicationAPI = new APIClass(true);
export const LoginAPI = new APIClass(false);
