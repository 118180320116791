import React, { Suspense, lazy } from 'react';
import Layout from '@clinintell/containers/layout/Layout';
import ErrorBoundary from '@clinintell/containers/errorHandlers/ErrorBoundary';
import LoadingScreen from '@clinintell/components/LoadingScreen';
import { Switch, Route, Redirect } from 'react-router-dom';
import RouteErrorPage from '@clinintell/containers/errorHandlers/RouteErrorPage';
import ReportRoutes from '@clinintell/routes/reports/ReportRoutes';
import AnalysisRoutes from '@clinintell/routes/analysis/AnalysisRoutes';
import GoalRoutes from '@clinintell/routes/goals/GoalRoutes';
import UserRoutes from '@clinintell/routes/users/UserRoutes';
import TrainingRoutes from '@clinintell/routes/training/TrainingRoutes';
import { SnackbarProvider } from 'notistack';
import { TargetedConditionsProvider } from '@clinintell/containers/metrics/logic/TargetedConditionsContext';
import useClearBreadcrumbNavigationOnRouteChange from './logic/useClearBreadcrumbNavigationOnRouteChange';

const Metrics = lazy(() => import(/* webpackChunkName: 'metrics' */ '@clinintell/containers/metrics/Metrics'));

const DashboardController = lazy(() =>
  import(/* webpackChunkName: 'dashboardController' */ '@clinintell/containers/dashboard/Dashboard')
);

const SupportPage = lazy(() => import(/* webpackChunkName: 'support' */ '@clinintell/containers/support/Support'));

const AuthenticatedRoutes: React.FC = () => {
  useClearBreadcrumbNavigationOnRouteChange();

  return (
    <SnackbarProvider maxSnack={4} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
      <Layout>
        <ErrorBoundary>
          <Suspense fallback={<LoadingScreen loadingIndicatorSize={250} />}>
            <Switch>
              <Route
                path="/metrics"
                render={(): JSX.Element => (
                  <TargetedConditionsProvider>
                    <Metrics />
                  </TargetedConditionsProvider>
                )}
              />
              <Route path="/reports" render={(): JSX.Element => <ReportRoutes />} />
              <Route path="/goals" render={(): JSX.Element => <GoalRoutes />} />
              <Route path="/training" render={(): JSX.Element => <TrainingRoutes />} />
              <Route path="/signin" render={(): JSX.Element => <Redirect to="/" />} />
              <Route path="/signup" render={(): JSX.Element => <Redirect to="/" />} />
              <Route path="/callback" render={(): JSX.Element => <Redirect to="/" />} />
              <Route path="/index.html">
                <Redirect to="/" />
              </Route>
              <Route path="/usermanagement" render={(): JSX.Element => <UserRoutes />} />
              <Route
                path="/analytics"
                render={(): JSX.Element => {
                  return <AnalysisRoutes />;
                }}
              />
              <Route path="/support" render={(): JSX.Element => <SupportPage />} />
              <Route exact path="/" render={(): JSX.Element => <DashboardController />} />
              <Route render={(): JSX.Element => <RouteErrorPage error={new Error('404')} />} />
            </Switch>
          </Suspense>
        </ErrorBoundary>
      </Layout>
    </SnackbarProvider>
  );
};

export default AuthenticatedRoutes;
