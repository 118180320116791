import React from 'react';
import { Box, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  chip: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    borderRadius: 5,
    textAlign: 'left',
    textTransform: 'uppercase',
    marginBottom: theme.spacing(2)
  },
  blueChip: {
    backgroundColor: '#E9F1F8',
    border: `2px solid #588FBA`,
    color: '#588FBA',
    '& h4, h5': {
      color: '#588FBA'
    }
  },
  greenChip: {
    backgroundColor: '#E0F3F5',
    border: `2px solid #369EA8`,
    color: '#369EA8',
    '& h4, h5': {
      color: '#369EA8'
    }
  },
  disabledChip: {
    border: `2px solid #94A3B8`,
    color: '#94A3B8',
    backgroundColor: '#F1F5F9'
  }
}));

type Props = {
  color: 'blue' | 'green';
  title: string;
  height?: number;
  disabled?: boolean;
  children: JSX.Element;
};

const StyledLabelChip: React.FC<Props> = ({ color, title, height = 56, disabled = false, children }) => {
  const { chip, blueChip, greenChip, disabledChip } = useStyles();
  return (
    <Box height={height} className={clsx(chip, disabled ? disabledChip : color === 'blue' ? blueChip : greenChip)}>
      <Typography variant="p2" style={{ fontSize: '0.8rem', paddingTop: height > 56 ? 8 : 0 }}>
        {title}
      </Typography>
      {children}
    </Box>
  );
};

export default StyledLabelChip;
