import ClinIntellSkeleton from '@clinintell/components/ClinIntellSkeleton';
import { useCMIAnalysisState } from '@clinintell/modules/store';
import { formatToDecimalPlaces } from '@clinintell/utils/formatting';
import { Box } from '@mui/material';
import React from 'react';
import useTimeSeriesData from '../logic/useTimeSeriesData';
import AnalysisChart from './AnalysisChart';

const SeverityCMIAnalysisDetails: React.FC = () => {
  const { chartMinDate, chartMaxDate, severityCMISummary, cmiSummary, entityId } = useCMIAnalysisState();

  const { data: cmiChartData, isLoading: cmiIsLoading } = useTimeSeriesData({
    minChartPeriod: chartMinDate,
    maxChartPeriod: chartMaxDate,
    orgId: entityId,
    metric: 'cmi'
  });

  const { data: severityCmiChartData, isLoading: severityCmiIsLoading } = useTimeSeriesData({
    minChartPeriod: chartMinDate,
    maxChartPeriod: chartMaxDate,
    orgId: entityId,
    metric: 'severityCmi'
  });

  // Filter out discharges and gap columns from chart datasets so we can re-use existing hooks to generate the chart
  let filteredCmiChartData;
  if (cmiChartData) {
    filteredCmiChartData = cmiChartData.data.filter(
      set => set.dataSetType !== 'Gap' && set.dataSetType !== 'Discharges'
    );
  }

  let filteredSeverityCmiChartData;
  if (severityCmiChartData) {
    filteredSeverityCmiChartData = severityCmiChartData.data.filter(set => set.dataSetType === 'Actual');
  }

  const cmiDifference =
    cmiSummary && cmiSummary.current && cmiSummary.historical
      ? Number(formatToDecimalPlaces(cmiSummary.current, 2)) - Number(formatToDecimalPlaces(cmiSummary.historical, 2))
      : 0;

  const severityCmiDifference =
    severityCMISummary && severityCMISummary.current && severityCMISummary.historical
      ? Number(formatToDecimalPlaces(severityCMISummary.current, 2)) -
        Number(formatToDecimalPlaces(severityCMISummary.historical, 2))
      : 0;

  const cmiChart =
    cmiChartData && filteredCmiChartData && !cmiIsLoading ? (
      <AnalysisChart
        metric="cmi"
        dataset={cmiChartData}
        chartDataSets={filteredCmiChartData}
        chartPeriodType="Month"
        currentDateIsBetterTimes={!cmiSummary || cmiDifference > 0}
        chartTitle="Severity Capture"
      />
    ) : (
      <ClinIntellSkeleton variant="rectangular" height="26rem" width="100%" />
    );

  const severityCmiChart =
    severityCmiChartData && filteredSeverityCmiChartData && !severityCmiIsLoading ? (
      <AnalysisChart
        metric="severityCmi"
        dataset={severityCmiChartData}
        chartDataSets={filteredSeverityCmiChartData}
        chartPeriodType="Month"
        currentDateIsBetterTimes={!severityCMISummary || severityCmiDifference > 0}
        chartTitle="Severity CMI"
      />
    ) : (
      <ClinIntellSkeleton variant="rectangular" height="26rem" width="100%" />
    );

  return (
    <>
      <Box marginBottom={2}>{cmiChart}</Box>
      {severityCmiChart}
    </>
  );
};

export default SeverityCMIAnalysisDetails;
