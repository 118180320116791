import { put, takeLatest, call } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { ApplicationAPI } from '@clinintell/utils/api';
import { Feature } from '../containers/authentication/rules';

export enum Roles {
  ClassicDashboardMetricsAll = 'ClassicDashboard_Metrics_All',
  ClassicDashboardPrintView = 'ClassicDashboard_Print_View'
}

interface BaseRoleFeature {
  id: number;
  displayName: string;
  description: string;
}

export interface UserFeature extends BaseRoleFeature {
  featureName: Feature;
}

type Role = {
  id: number;
  roleName: string;
  displayName: string;
  description: string;
};

export interface User {
  id: number;
  email: string;
  name: string;
  auth0TokenId: string;
  rootId: number;
  homeOrgId: number;
  dashboardOrgId: number;
  tenant: string;
  authAccountVerified: boolean;
  auth0Id: string;
  features: Array<UserFeature>;
  role: Role;
  isLoaded: boolean;
  invalid: boolean;
  blocked: boolean;
}

// Actions
enum UserActions {
  LOAD_USER = 'LOAD_USER',
  LOAD_USER_SUCCESSFUL = 'LOAD_USER_SUCCESSFUL',
  LOAD_USER_FAILED = 'LOAD_USER_FAILED'
}

export interface UserAction {
  type?: keyof typeof UserActions;
}

export interface UserActionWithPayload extends UserAction {
  payload?: User;
}

export const initialUser: User = {
  id: -1,
  email: '',
  name: '',
  auth0TokenId: '',
  rootId: -1,
  homeOrgId: 0,
  dashboardOrgId: 0,
  features: [],
  isLoaded: false,
  tenant: '',
  role: {
    id: -1,
    roleName: 'metricsDocScore',
    displayName: '',
    description: ''
  },
  invalid: false,
  authAccountVerified: false,
  auth0Id: '',
  blocked: false
};

// Reducer
const reducer = (state: User = initialUser, action: UserActionWithPayload): User => {
  switch (action.type) {
    case 'LOAD_USER_SUCCESSFUL': {
      return {
        ...state,
        ...action.payload,
        isLoaded: true
      };
    }
    case 'LOAD_USER_FAILED': {
      return {
        ...state,
        invalid: true,
        isLoaded: true
      };
    }
    default: {
      return state;
    }
  }
};

// Helper
export function cleanData(user: User): User {
  const cleanUser = { ...user };
  Object.entries(cleanUser.role).forEach(entry => {
    // roleName should be lower case like any variable
    if (entry[0] === 'roleName') {
      cleanUser.role[entry[0]] = cleanUser.role[entry[0]].slice(0, 1).toLowerCase() + cleanUser.role[entry[0]].slice(1);
    }
    // sometimes the API puts nulls here, but string display is easier with ''
    if (entry[0] === 'displayName' || entry[0] === 'description') {
      if (entry[1] === null) {
        cleanUser.role[entry[0]] = '';
      }
    }
  });

  cleanUser.features.forEach((feature, index) => {
    Object.entries(feature).forEach(entry => {
      // lower case first letter in variable name
      if (entry[0] === 'featureName') {
        // API returning invalid PascalCase feature names, typescript wants camelCased featureName
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        cleanUser.features[index].featureName =
          cleanUser.features[index].featureName.slice(0, 1).toLowerCase() +
          cleanUser.features[index].featureName.slice(1);
      }
      // prefer empty string to null for display names
      if (entry[0] === 'displayName' || entry[0] === 'description') {
        if (entry[1] === null) {
          entry[1] = '';
        }
      }
    });
  });
  return cleanUser;
}

// Sagas
export function* fetchUser(): SagaIterator {
  try {
    const result = yield call(ApplicationAPI.get, {
      endpoint: 'auth/user'
    });

    if (result.error) {
      yield put({ type: 'LOAD_USER_FAILED' });
    }

    const data = cleanData(result.data);
    yield put({ type: 'LOAD_USER_SUCCESSFUL', payload: { ...data } });
  } catch (exception) {
    yield put({ type: 'LOAD_USER_FAILED' });
  }
}

export function* userSaga(): SagaIterator {
  yield takeLatest('LOAD_USER', fetchUser);
}

export default reducer;
