import makeStyles from '@mui/styles/makeStyles';

// Used to heavily override the Auth0 widgets to conform to ClinIntell style standards. This must be used within the base site theme
// provider, as it references colors and such.
const useAuth0WidgetStyles = makeStyles(theme => ({
  auth0WidgetStyles: {
    // Remove certain Auth0 widget elements
    '& .auth0-lock-overlay, .auth0-lock-close-button, .auth0-lock-tabs-container, .auth0-lock-terms, .auth0-lock-header-logo, .auth0-lock.auth0-lock .auth0-lock-header-bg.auth0-lock-blur-support .auth0-lock-header-bg-blur, .auth0-label-submit > span': {
      display: 'none !important'
    },
    // Set fonts
    '& .auth0-lock.auth0-lock': {
      fontFamily: theme.typography.fontFamily
    },
    // Height/width overrides
    '& .auth0-lock.auth0-lock .auth0-lock-widget': {
      width: '100% !important'
    },
    '& .auth0-lock-widget': {
      [theme.breakpoints.up('md')]: {
        maxWidth: '25rem'
      }
    },
    '& .auth0-lock.auth0-lock.auth0-lock-opened-in-frame': {
      marginLeft: '0 !important',
      marginRight: '0 !important',
      width: '100% !important'
    },
    // Get rid of all header backgrounds
    '& .auth0-lock.auth0-lock .auth0-lock-header-bg': {
      background: '#fff !important',
      backgroundColor: '#fff !important',
      opacity: 0
    },
    // Status message color overrides
    '& .auth0-lock.auth0-lock .auth0-lock-error .auth0-lock-input-wrap': {
      borderColor: theme.palette.red[500]
    },
    '& .auth0-lock-error-msg': {
      color: theme.palette.red[500],
      [theme.breakpoints.down('md')]: {
        marginTop: 0,
        background: theme.palette.red[50],
        padding: theme.spacing(1)
      }
    },
    '& .auth0-global-message': {
      [theme.breakpoints.down('md')]: {
        width: 'calc(90vw - 60px)',
        marginLeft: '20px',
        borderRadius: '2px'
      }
    },
    '& .auth0-lock.auth0-lock .auth0-global-message.auth0-global-message-error': {
      background: theme.palette.red[50],
      marginTop: theme.spacing(1),
      '& > span > span': {
        color: theme.palette.red[500]
      }
    },
    '& .auth0-lock.auth0-lock .auth0-global-message.auth0-global-message-success': {
      background: theme.palette.green[50],
      marginTop: theme.spacing(1),
      '& > span > span': {
        color: theme.palette.green[500]
      }
    },
    // Custom styling
    '& .auth0-lock-header': {
      backgroundColor: '#fff !important',
      color: `${theme.palette.blue.main} !important`,
      padding: `${theme.spacing(7)} 0 0 ${theme.spacing(2)} !important`,
      textAlign: 'left !important',
      fontSize: `${theme.typography.h4.fontSize} !important`,
      [theme.breakpoints.down('md')]: {
        background: `${theme.palette.blue.main} !important`
      }
    },
    '& .auth0-lock-alternative-link, .auth0-lock.auth0-lock .auth0-lock-form .auth0-lock-alternative .auth0-lock-alternative-link': {
      fontSize: '15px',
      [theme.breakpoints.down('md')]: {
        color: `${theme.palette.common.white} !important`
      }
    },
    '& .auth0-lock.auth0-lock .auth0-lock-submit': {
      [theme.breakpoints.down('md')]: {
        color: theme.palette.blue.main,
        backgroundColor: `${theme.palette.grey[200]} !important`,
        borderRadius: '3px',
        width: 'calc(90vw - 40px)',
        marginLeft: '20px',
        padding: 0
      }
    },
    '& .auth0-lock-cred-pane-internal-wrapper': {
      [theme.breakpoints.down('md')]: {
        height: 'inherit !important'
      }
    },
    '& .auth0-lock-name': {
      fontWeight: 700,
      [theme.breakpoints.down('md')]: {
        color: theme.palette.common.white,
        padding: '0 !important'
      }
    },
    '& .auth0-lock-form p:not(.auth0-lock-alternative)': {
      textAlign: 'left !important',
      '& > span': {
        [theme.breakpoints.down('md')]: {
          color: theme.palette.common.white
        }
      }
    },
    '& .auth0-lock.auth0-lock .auth0-lock-close-button': {
      boxShadow: '0 6px 3px rgba(0, 0, 0, 0.1)'
    },
    '& .auth0-lock.auth0-lock .auth0-lock-back-button': {
      height: '12px',
      width: '12px',
      [theme.breakpoints.up('md')]: {
        border: `2px solid ${theme.palette.blue.main}`,
        padding: '3px'
      },
      '&:hover': {
        boxShadow: '0 6px 3px rgba(0, 0, 0, 0.1)'
      }
    },
    '& .auth0-label-submit': {
      fontWeight: 700,
      textTransform: 'capitalize',
      fontSize: theme.typography.p1.fontSize,
      '& > svg': {}
    },
    // The mobile view uses a gradient background, so disable all internal background values by making them opaque
    '& .auth0-lock.auth0-lock .auth0-lock-header, .auth0-lock-cred-pane-internal-wrapper, .auth0-lock.auth0-lock .auth0-lock-cred-pane, .auth0-lock-content-wrapper': {
      [theme.breakpoints.down('md')]: {
        background: 'rgba(0,0,0,0) !important'
      }
    },
    '& .auth0-lock': {
      paddingBottom: '2rem'
    },
    '& .auth0-lock-password-strength': {
      position: 'fixed',
      bottom: '140px',
      [theme.breakpoints.down('md')]: {
        bottom: '110px',
        background: 'rgb(0, 0, 0)',
        boxShadow: '0 0 20px 0 rgba(0,0,0,0.5)',
        borderRadius: '3px',
        left: 0
      }
    },
    '& .auth0-lock-password-strength ul li ul li': {
      color: 'white',
      '&.auth0-lock-checked': {
        color: '#7ed321'
      }
    },
    '& .auth0-lock .auth0-sso-notice-container': {
      margin: '-10px -20px 20px',
      [theme.breakpoints.down('md')]: {
        margin: '-10px 0 20px',
        background: '#f1f1f1',
        borderRadius: '2px'
      }
    }
  }
}));

export default useAuth0WidgetStyles;
