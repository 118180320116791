import PageTitle from '@clinintell/components/PageTitle';
import { Box } from '@mui/material';
import React, { useContext } from 'react';
import { CardSelectorLayout } from '../cmiAnalysisLanding/CMIAnalysisLanding';
import CIReportsModal from '@clinintell/containers/reports/sections/reportModal/CIReportsModal';
import HospitalAndGroupFilter from '@clinintell/containers/reports/sections/hospitalAndGroupFilter/HospitalAndGroupFilter';
import { ReportType } from '@clinintell/containers/reports/types/actionTypes';
import SystemReport, { ExecutiveReport } from './sections/systemReport/SystemReport';
import TrainingProgressFilter from './sections/trainingProgressFilter/TrainingProgressFilter';
import ReportCard from '@clinintell/containers/reports/components/ReportCard';
import { ReportsContext } from './sections/context/ReportContext';
import QuarterlySummaryModal from './sections/quarterlySummary/QuarterlySummaryModal';
import { TrainingProgressRouteProps } from './types/routeProps';

const Reports = () => {
  const {
    hospitalId,
    groupId,
    setReportHospitalId,
    setReportGrouplId,
    setReportDateHandler,
    setReportTrainingGroupHandler,
    setReportBtnDisabled
  } = useContext(ReportsContext);

  return (
    <Box sx={{ my: 3, mx: 3 }}>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <PageTitle>Reports</PageTitle>
      </Box>
      <Box component="p" sx={{ mb: '50px' }}>
        Choose the report you would like to view or download.
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '25px' }}>
        <CardSelectorLayout>
          <ReportCard typeReport={ReportType.Provider}>
            <CIReportsModal
              reportLabel="View Report"
              reportModalTitle="Provider Report"
              reportType={ReportType.Provider}
            >
              <HospitalAndGroupFilter
                hospitalId={hospitalId}
                groupId={groupId}
                reportType={ReportType.Provider}
                onHospitalChange={(id: number | null) => {
                  setReportHospitalId(id);
                  setReportBtnDisabled(true);
                }}
                onPhysicianGroupChange={(id: number | null) => {
                  setReportGrouplId(id);
                  setReportBtnDisabled(false);
                }}
              />
            </CIReportsModal>
          </ReportCard>
          <ReportCard typeReport={ReportType.Group}>
            <CIReportsModal reportLabel="View Report" reportModalTitle="Group Report" reportType={ReportType.Group}>
              <HospitalAndGroupFilter
                hospitalId={hospitalId}
                groupId={groupId}
                onHospitalChange={(id: number | null) => {
                  setReportHospitalId(id);
                  setReportBtnDisabled(true);
                }}
                onPhysicianGroupChange={(id: number | null) => {
                  setReportGrouplId(id);
                  setReportBtnDisabled(false);
                }}
              />
            </CIReportsModal>
          </ReportCard>
        </CardSelectorLayout>
        <CardSelectorLayout>
          <ReportCard typeReport={ReportType.Quarterly}>
            <QuarterlySummaryModal />
          </ReportCard>
          <ReportCard typeReport={ReportType.System}>
            <CIReportsModal
              reportLabel="Download Report"
              modalBtnLabel="Download Report"
              reportModalTitle="System Report"
              reportType={ReportType.System}
              // need to delay disabling the button
              closeCallback={() => setTimeout(() => setReportBtnDisabled(true), 500)}
            >
              <SystemReport dateChngTracker={setReportDateHandler} />
            </CIReportsModal>
          </ReportCard>
        </CardSelectorLayout>
        <CardSelectorLayout>
          <ReportCard typeReport={ReportType.Executive}>
            <CIReportsModal
              reportLabel="Download Report"
              modalBtnLabel="Download Report"
              reportModalTitle="Executive Summary Report"
              reportType={ReportType.Executive}
              closeCallback={() => setTimeout(() => setReportBtnDisabled(true), 500)}
            >
              <HospitalAndGroupFilter
                hospitalId={hospitalId}
                groupId={groupId}
                onHospitalChange={setReportHospitalId}
                onPhysicianGroupChange={setReportGrouplId}
              />
              <ExecutiveReport dateChngTracker={setReportDateHandler} />
            </CIReportsModal>
          </ReportCard>
          <ReportCard typeReport={ReportType.Training}>
            <CIReportsModal
              reportLabel="View Report"
              reportModalTitle="Training Progress Report"
              reportType={ReportType.Training}
            >
              <TrainingProgressFilter
                onFilterChanged={(filters: TrainingProgressRouteProps) => {
                  setReportTrainingGroupHandler(filters);
                  setReportBtnDisabled(false);
                }}
              />
            </CIReportsModal>
          </ReportCard>
        </CardSelectorLayout>
      </Box>
    </Box>
  );
};

export default Reports;
