import { MenuItem } from '@clinintell/modules/menuNavigation';
import { Box, Fade, SxProps, Theme } from '@mui/material';
import React from 'react';
import { useMenuNavigationState } from '@clinintell/modules/store';
import Tooltip from '@clinintell/components/tooltip/Tooltip';
import useIsMobileView from '@clinintell/utils/useIsMobileView';
import GetHyperlinkType from './GetHyperlinkType';

export type MenuNavigationLinkProps = {
  menuItem: MenuItem;
  sx?: SxProps<Theme>;
  onClick: (menuItem: MenuItem) => void;
};

const MenuNavigationLink: React.FC<MenuNavigationLinkProps> = ({ menuItem, sx, onClick }) => {
  const { menuIsTemporarilyExpanded, menuIsExpanded } = useMenuNavigationState();

  const isMobileView = useIsMobileView();
  const expandMenu = menuIsExpanded || menuIsTemporarilyExpanded || isMobileView;

  const iconComponent = !expandMenu ? (
    <Tooltip content={menuItem.label} position="right">
      <span>{<menuItem.IconComponent />}</span>
    </Tooltip>
  ) : (
    <menuItem.IconComponent sx={{ mr: 1 }} />
  );

  const handleMenuItemClick = () => {
    onClick(menuItem);
  };

  return (
    <Box sx={{ ...sx }}>
      <GetHyperlinkType
        isExternalHyperlink={menuItem.isExternalLink || false}
        onClick={handleMenuItemClick}
        url={menuItem.link}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            textDecoration: 'none',
            '&:hover': {
              cursor: 'pointer'
            },
            '& > *': {
              color: menuItem.isActive ? 'teal.main' : 'grey.500',
              stroke: theme => (menuItem.isActive ? theme.palette.teal.main : theme.palette.grey[500]),
              fill: theme => (menuItem.isActive ? theme.palette.teal.main : theme.palette.grey[500]),
              '&:hover': {
                color: 'teal.main',
                stroke: theme => theme.palette.teal.main
              }
            }
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            {iconComponent}
            <Fade timeout={{ enter: 800 }} appear={false} in={expandMenu}>
              <Box component="span" sx={{ fontWeight: 600, pointerEvents: 'none' }}>
                {menuItem.label}
              </Box>
            </Fade>
          </Box>
        </Box>
      </GetHyperlinkType>
    </Box>
  );
};

export default MenuNavigationLink;
