import { Collapse, alpha, Theme } from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import React, { ReactNode } from 'react';
import { animated, useSpring } from 'react-spring';
import { TransitionProps } from '@mui/material/transitions';
import TreeItem from '@mui/lab/TreeItem';
import { NodeTypeIds, TreeNode } from '@clinintell/modules/orgTree';
import { makeStyles } from '@mui/styles';

function TransitionComponent(props: TransitionProps): JSX.Element {
  const style = useSpring({
    from: { opacity: 0, transform: 'translate3d(20px,0,0)' },
    to: { opacity: props.in ? 1 : 0, transform: `translate3d(${props.in ? 0 : 20}px,0,0)` }
  });

  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
}

type NodeLabelProps = {
  label: ReactNode;
};

const StyledNode = withStyles((theme: Theme) =>
  createStyles({
    iconContainer: {
      '& .close': {
        opacity: 0.3
      },
      '&:hover': {
        cursor: 'pointer'
      }
    },
    group: {
      marginLeft: 15,
      paddingLeft: 18,
      borderLeft: `1px dashed ${alpha(theme.palette.dark[500], 0.4)}`
    },
    content: {
      background: 'none !important',
      '&:hover': {
        cursor: 'default' // do not have a pointer over the whitespace near a tree item
      }
    }
  })
)((props: Omit<NodeProps, 'renderNode'> & NodeLabelProps) => (
  <TreeItem
    {...props}
    onKeyDown={(event): void => props.onKeyDown(event, props.node)}
    TransitionComponent={TransitionComponent}
  />
));

type UseStylesProps = {
  hasUnloadedChildren: boolean;
};

const useStyles = makeStyles<Theme, UseStylesProps>(theme => ({
  iconContainer: {
    display: ({ hasUnloadedChildren }): string => (hasUnloadedChildren ? 'none !important' : 'flex'),
    '& + div': {
      marginLeft: ({ hasUnloadedChildren }): string => (hasUnloadedChildren ? '-8px' : '0')
    }
  }
}));

export type NodeProps = {
  node: TreeNode;
  onKeyDown: (event: React.KeyboardEvent<HTMLLIElement>, item: TreeNode) => void;
  renderNode: (node: TreeNode) => React.ReactNode;
  nodeId: string;
  maxNodeType: keyof typeof NodeTypeIds;
};

const Node: React.FC<NodeProps> = ({ node, onKeyDown, renderNode, children, maxNodeType }): JSX.Element => {
  const { iconContainer } = useStyles({
    hasUnloadedChildren:
      (node.children.length === 0 && !node.isLeaf) || node.isLeaf || node.nodeTypeId >= NodeTypeIds[maxNodeType]
  });
  const labelElement = renderNode(node);

  return (
    <div key={node.id} id={node.id.toString()}>
      <StyledNode
        onKeyDown={(event: React.KeyboardEvent<HTMLLIElement>): void => onKeyDown(event, node)}
        key={node.id}
        nodeId={node.id.toString()}
        classes={{
          iconContainer
        }}
        label={labelElement}
        node={node}
      >
        {children}
      </StyledNode>
    </div>
  );
};

export default Node;
