/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { all, call, put, takeLatest } from 'redux-saga/effects';
import { SagaIterator } from 'redux-saga';
import { ApplicationAPI, AsyncOutput } from '@clinintell/utils/api';
import { QueryStringParam, createUrlWithQueryString } from '@clinintell/utils/querystring';
import { TreeJSON } from '@clinintell/modules/orgTree';
import { ColumnDefinition } from '@clinintell/components/Table';
import {
  MetricRecordJSON,
  MetricConditionRecordJSON,
  SmartStrategyMetricRecordJSON,
  SmartStrategyConditionRecordJSON,
  MetricSchema,
  NodeType
} from '@clinintell/containers/metrics/typings/metricTypes';
import { ChartDataSetJSON } from '@clinintell/containers/metricsTimeSeries/typings/metricChartTypes';
import { formatDateForAPI } from '@clinintell/utils/formatting';

export enum DocScoreGoalActions {
  INITIALIZE_GOALS = 'INITIALIZE_GOALS',
  INITIALIZE_GOALS_BEGIN = 'INITIALIZE_GOALS_BEGIN',
  INITIALIZE_GOALS_FAILED = 'INITIALIZE_GOALS_FAILED',
  INITIALIZE_GOALS_SUCCESS = 'INITIALIZE_GOALS_SUCCESS',
  INIT_GROUP_STRATEGY = 'INIT_GROUP_STRATEGY',
  INIT_GROUP_HISTORY_STRATEGY = 'INIT_GROUP_HISTORY_STRATEGY',
  GET_HOSPITAL_STRATEGY = 'GET_HOSPITAL_STRATEGY',
  GET_HOSPITAL_STRATEGY_BEGIN = 'GET_HOSPITAL_STRATEGY_BEGIN',
  GET_HOSPITAL_STRATEGY_FAILED = 'GET_HOSPITAL_STRATEGY_FAILED',
  GET_HOSPITAL_STRATEGY_SUCCESS = 'GET_HOSPITAL_STRATEGY_SUCCESS',
  GET_GROUP_STRATEGY = 'GET_GROUP_STRATEGY',
  GET_GROUP_STRATEGY_BEGIN = 'GET_GROUP_STRATEGY_BEGIN',
  GET_GROUP_STRATEGY_FAILED = 'GET_GROUP_STRATEGY_FAILED',
  GET_GROUP_STRATEGY_SUCCESS = 'GET_GROUP_STRATEGY_SUCCESS',
  GET_GROUP_HISTORY = 'GET_GROUP_HISTORY',
  GET_GROUP_HISTORY_BEGIN = 'GET_GROUP_HISTORY_BEGIN',
  GET_GROUP_HISTORY_FAILED = 'GET_GROUP_HISTORY_FAILED',
  GET_GROUP_HISTORY_SUCCESS = 'GET_GROUP_HISTORY_SUCCESS',
  GET_GROUP_ALL_CONDITIONS = 'GET_GROUP_ALL_CONDITIONS',
  GET_GROUP_ALL_CONDITIONS_BEGIN = 'GET_GROUP_ALL_CONDITIONS_BEGIN',
  GET_GROUP_ALL_CONDITIONS_FAILED = 'GET_GROUP_ALL_CONDITIONS_FAILED',
  GET_GROUP_ALL_CONDITIONS_SUCCESS = 'GET_GROUP_ALL_CONDITIONS_SUCCESS',
  GET_ENTITY_DATES = 'GET_ENTITY_DATES',
  GET_ENTITY_DATES_BEGIN = 'GET_ENTITY_DATES_BEGIN',
  GET_ENTITY_DATES_SUCCESSFUL = 'GET_ENTITY_DATES_SUCCESSFUL',
  GET_ENTITY_DATES_FAILED = 'GET_ENTITY_DATES_FAILED',
  SET_DEFALT_DATES = 'SET_DEFALT_DATES',
  SET_VALUE_TYPE = 'SET_VALUE_TYPE',
  SET_AUTOGEN_ENTITY = 'SET_AUTOGEN_ENTITY',
  SET_GROUP_STRATEGY_CONDITIONS = 'SET_GROUP_STRATEGY_CONDITIONS',
  SET_GROUP_STRATEGY_CHANGED = 'SET_GROUP_STRATEGY_CHANGED',
  SET_GROUP_STRATEGY_PERIOD = 'SET_GROUP_STRATEGY_PERIOD',
  SET_RESTORE_GROUP_STRATEGY = 'SET_RESTORE_GROUP_STRATEGY',
  GET_SYSTEM_INFO = 'GET_SYSTEM_INFO',
  GET_SYSTEM_INFO_BEGIN = 'GET_SYSTEM_INFO_BEGIN',
  GET_SYSTEM_INFO_FAILED = 'GET_SYSTEM_INFO_FAILED',
  GET_SYSTEM_INFO_SUCCESS = 'GET_SYSTEM_INFO_SUCCESS',
  SET_STRATEGY_LEVEL = 'SET_STRATEGY_LEVEL',
  GET_PERFORMANCE_DATA = 'GET_PERFORMANCE_DATA',
  GET_PERFORMANCE_DATA_BEGIN = 'GET_PERFORMANCE_DATA_BEGIN',
  GET_PERFORMANCE_DATA_FAILED = 'GET_PERFORMANCE_DATA_FAILED',
  GET_PERFORMANCE_DATA_SUCCESS = 'GET_PERFORMANCE_DATA_SUCCESS',
  GET_PERFORMANCE_GRAPH = 'GET_PERFORMANCE_GRAPH',
  GET_PERFORMANCE_GRAPH_BEGIN = 'GET_PERFORMANCE_GRAPH_BEGIN',
  GET_PERFORMANCE_GRAPH_FAILED = 'GET_PERFORMANCE_GRAPH_FAILED',
  GET_PERFORMANCE_GRAPH_SUCCESS = 'GET_PERFORMANCE_GRAPH_SUCCESS',
  GET_CONDITION_PERFORMANCE_DATA = 'GET_CONDITION_PERFORMANCE_DATA',
  SET_PERFORMANCE_ENTITY = 'SET_PERFORMANCE_ENTITY',
  START_BULK_PROCESS = 'START_BULK_PROCESS'
}

export type DocScoreGoalAction<T> = {
  type?: keyof typeof DocScoreGoalActions;
  payload?: T;
};

export type ValueType = 'rwpv' | 'dollars';

export enum ConditionType {
  DRG = 1,
  HCC,
  Elixhauser
}
export interface ConditionJSON {
  id: number;
  conditionName: string;
  isLegalCondition?: boolean;
  isTargetCondition?: boolean;
  conditionTypeId: number;
  opportunityRW?: string | number | boolean;
}

export interface OverallMetricRecordJSON {
  csvOrgIds: string;
  name: string;
  nodeTypeId: number;
  nodeType: string;
  cases: number;
  isFooter: boolean;
  cmi: MetricRecordJSON | null;
  docScore: MetricRecordJSON | null;
  elixhauserMortality: MetricRecordJSON | null;
  elixhauserReadmission: MetricRecordJSON | null;
  raf: MetricRecordJSON | null;
  otherChildren: [];
}

interface OverallDataSchema {
  type: object;
  properties: Record<string, ColumnDefinition>;
}

export interface OverallMetricSchema {
  definitions: {
    CmiDto: OverallDataSchema;
    DocScoreDto: OverallDataSchema;
    ElixhauserMortalityDto: OverallDataSchema;
    ElixhauserReadmissionDto: OverallDataSchema;
    RafDto: OverallDataSchema;
  };
  type: object;
  properties: {
    CsvOrgIds: OverallDataSchema;
    Name: OverallDataSchema;
    NodeTypeId: OverallDataSchema;
    NodeType: OverallDataSchema;
    Cases: OverallDataSchema;
    IsFooter: OverallDataSchema;
    Cmi: OverallDataSchema;
    DocScore: OverallDataSchema;
    ElixhauserMortality: OverallDataSchema;
    ElixhauserReadmission: OverallDataSchema;
    Raf: OverallDataSchema;
    OtherChildren: OverallDataSchema;
  };
}
interface PerformanceData {
  entity: number | string;
  entityName: string;
  title?: string;
  conditionId?: number;
  conditionName?: string;
  startDate: string | Date;
  endDate: string | Date;
  graphData: ChartDataSetJSON | null;
  conditionMetricData: SmartStrategyMetricRecordJSON[] | null;
  conditionMetricSchema: MetricSchema | null;
  overallMetricData: OverallMetricRecordJSON[] | null;
  overallMetricSchema: OverallMetricSchema | null;
  isLoading: boolean;
  isGraphLoading: boolean;
  hasError: boolean;
  errorMessage?: string;
}

export type GroupSavedCondition = {
  id: number;
  conditionName: string;
  conditionDescription: string;
};

export type GroupDocScoreHistoryRecord = {
  id: number;
  insertBy: string;
  insertDate: string;
  physicianGroupOrgHierarchyId: number;
  suggestedTargetDocScore: number;
  targetDocScore: number;
  implementationDate: string;
  isActive: boolean;
  savedConditions: GroupSavedCondition[];
  baselineStartPeriod: string;
  baselineEndPeriod: string;
  updateDate: string | null;
  updateBy: string | null;
};

export type GroupDocScoreGoal = {
  orgId: number;
  csvOrgIds: string;
  name: string;
  nodeTypeId: number;
  nodeType: NodeType;
  currentDocScore: number;
  clinicallyExpectedDocScore: number;
  cases: number;
  currentCmi: number;
  clinicallyExpectedCmi: number;
  cmiGapVsCE: number;
  opportunityDollar: number;
  opportunityRW: number;
  opportunityRWPieSlice: number;
  providerCount: number;
  addressableOpportunityRW: number;
  addressableOpportunityRWPieSlice: number;
  addressableOpportunityDollar: number;
  drgPiePercent: number;
  groupTotalAddressableOpportunityRW: number;
  targetDocScore: number;
  docScoreChangeByTarget: number;
  adjustedTargetDocScore: number;
  isSavedTarget: boolean;
  targetSavedDate?: string;
  targetSavedBy: string;
  targetSavedDocScore?: number;
  drgConditions: SmartStrategyConditionRecordJSON | null;
  reportedProviderOpportunityRW: number;
  otherMDOpportunityRW: number;
  proposedConditions: ConditionJSON[];
  periodStart: Date;
  periodEnd: Date;
  implemenationDate: Date;
  rwPointValue: number;
  preTargetDocScore: number;
  hasTargetDocScoreHistory: boolean;
  history: {
    historyDataset: GroupDocScoreHistoryRecord[] | null;
    historyIsLoading: boolean;
    historyHasError: boolean;
    historyErrorMessage?: string;
  };
  isFooter: boolean;
};

export type DefaultDates = {
  currentMinMonth: Date | null;
  currentMaxMonth: Date | null;
  historicalMinMonth: Date | null;
  historicalMaxMonth: Date | null;
  absoluteMinMonth: Date | null;
  absoluteMaxMonth: Date | null;
  isDatesLoading: boolean;
};

export interface TrackerData extends Pick<DefaultDates, 'absoluteMinMonth' | 'absoluteMaxMonth' | 'isDatesLoading'> {
  entityName: string;
  baselineMinMonth: Date;
  baselineMaxMonth: Date;
  implementationMinMonth: Date;
  implementationMaxMonth: Date;
  saveFormulas: boolean;
  useDefaultDates: boolean;
}
const HOSPITAL_LEVEL = 'hospital';
const GROUP_LEVEL = 'group';
export type StrategyLevel = typeof HOSPITAL_LEVEL | typeof GROUP_LEVEL;

export interface HospitalDocScoreGoalDataset extends DefaultDates {
  dataset: GroupDocScoreGoal[] | null;
  groupStrategy: GroupDocScoreGoal;
  groupStrategyChanged: boolean;
  restoreGroupStrategy: boolean;
  groupAllConditions?: ConditionJSON[];
  entity: number;
  entityName: string;
  isRootSaf: boolean;
  systemRootId: number;
  systemAvailable: boolean;
  isLevelSelectionLoading: boolean;
  isStrategyInitialized: boolean;
  valueType: ValueType;
  error: string;
  isLoading: boolean;
  isGroupLoading: boolean;
  isGroupConditionsLoading: boolean;
  trackerData: TrackerData;
  strategyLevel: StrategyLevel;
  performanceData: PerformanceData;
}

export const initialGroupDocScoreGoal: GroupDocScoreGoal = {
  orgId: 0,
  csvOrgIds: '',
  name: '',
  nodeTypeId: 1,
  nodeType: 'Entity',
  currentDocScore: 0,
  clinicallyExpectedDocScore: 0,
  cases: 0,
  currentCmi: 0,
  clinicallyExpectedCmi: 0,
  cmiGapVsCE: 0,
  opportunityDollar: 0,
  opportunityRW: 0,
  opportunityRWPieSlice: 0,
  providerCount: 0,
  addressableOpportunityRW: 0,
  addressableOpportunityRWPieSlice: 0,
  addressableOpportunityDollar: 0,
  drgPiePercent: 0,
  targetDocScore: 0,
  docScoreChangeByTarget: 0,
  adjustedTargetDocScore: 0,
  isSavedTarget: false,
  targetSavedDate: '',
  targetSavedBy: '',
  drgConditions: null,
  reportedProviderOpportunityRW: 0,
  otherMDOpportunityRW: 0,
  proposedConditions: [],
  groupTotalAddressableOpportunityRW: 0,
  periodStart: new Date(),
  periodEnd: new Date(),
  implemenationDate: new Date(),
  hasTargetDocScoreHistory: false,
  rwPointValue: 0,
  preTargetDocScore: 0,
  history: {
    historyDataset: null,
    historyIsLoading: false,
    historyHasError: false
  },
  isFooter: false
};

export const initialState: HospitalDocScoreGoalDataset = {
  dataset: [],
  groupStrategy: initialGroupDocScoreGoal,
  groupStrategyChanged: false,
  restoreGroupStrategy: false,
  entity: -1,
  entityName: '',
  isRootSaf: false,
  systemRootId: -1,
  systemAvailable: true,
  isLevelSelectionLoading: false,
  isStrategyInitialized: false,
  valueType: 'rwpv',
  error: '',
  isLoading: false,
  isGroupLoading: false,
  isGroupConditionsLoading: false,
  currentMinMonth: null,
  currentMaxMonth: null,
  historicalMinMonth: null,
  historicalMaxMonth: null,
  absoluteMinMonth: null,
  absoluteMaxMonth: null,
  isDatesLoading: false,
  trackerData: {
    entityName: '',
    absoluteMinMonth: null,
    absoluteMaxMonth: null,
    baselineMinMonth: new Date(),
    baselineMaxMonth: new Date(),
    implementationMinMonth: new Date(),
    implementationMaxMonth: new Date(),
    isDatesLoading: false,
    saveFormulas: false,
    useDefaultDates: true
  },
  strategyLevel: 'hospital',
  performanceData: {
    entity: -1,
    entityName: '',
    conditionId: -1,
    conditionName: '',
    startDate: new Date(),
    endDate: new Date(),
    isLoading: false,
    isGraphLoading: false,
    graphData: null,
    conditionMetricData: null,
    conditionMetricSchema: null,
    overallMetricData: null,
    overallMetricSchema: null,
    hasError: false
  }
};

interface DocScoreGoalInitPayload {
  entity?: number;
  rootId: number;
  isSaf?: boolean;
  systemAvailable?: boolean;
}

export const initializeDocScoreGoals = ({
  entity,
  rootId
}: DocScoreGoalInitPayload): DocScoreGoalAction<DocScoreGoalInitPayload> => ({
  type: DocScoreGoalActions.INITIALIZE_GOALS,
  payload: {
    entity,
    rootId
  }
});

export interface DocScoreDatePayload {
  absoluteMinMonth?: Date;
  absoluteMaxMonth?: Date;
  currentMinMonth: Date | null;
  currentMaxMonth: Date | null;
  historicalMinMonth?: Date | null;
  historicalMaxMonth?: Date | null;
  csvOrgIds?: string;
}

interface TrackPerformanceInfo extends DocScoreDatePayload {
  entityName: string;
  entity?: number | string;
  title?: string;
  conditionId?: number;
  conditionName?: string;
}

export interface ViewPerformanceInfo extends DocScoreDatePayload {
  entity: number | string;
  entityName?: string;
  isCondition?: boolean;
  conditionId?: number;
  aggregate?: boolean;
}

export interface DocScoreRequestPayload extends DocScoreDatePayload {
  hospitalOrgId: number;
  entityName?: string;
  physGroupOrgId?: number | string;
  targetConditions?: number[];
  adjustedTargetDocScore?: number;
  useSavedTargetConditions?: boolean;
  groupMaxCases?: number;
  groupMaxCMIGapVsCE?: number;
  groupMaxDrgPiePercent?: number;
  groupMaxProviderCount?: number;
  groupTotalAddressableOpportunityRW?: number;
  strategyLevel: StrategyLevel;
}

interface SetStrategyLevelPayload {
  strategyLevel: StrategyLevel;
  entity: number;
  entityName: string;
}

export interface GroupConditionsRequestPayload extends DocScoreDatePayload {
  physGroupOrgId: number | string;
}

export const getHospitalStrategy = (payload: DocScoreRequestPayload): DocScoreGoalAction<DocScoreRequestPayload> => ({
  type: DocScoreGoalActions.GET_HOSPITAL_STRATEGY,
  payload
});

export const getGroupStrategy = (payload: DocScoreRequestPayload): DocScoreGoalAction<DocScoreRequestPayload> => ({
  type: DocScoreGoalActions.GET_GROUP_STRATEGY,
  payload
});

export const getGroupAllConditions = (
  payload: GroupConditionsRequestPayload
): DocScoreGoalAction<GroupConditionsRequestPayload> => ({
  type: DocScoreGoalActions.GET_GROUP_ALL_CONDITIONS,
  payload
});

export const initGroupStrategy = (payload: GroupDocScoreGoal): DocScoreGoalAction<GroupDocScoreGoal> => ({
  type: DocScoreGoalActions.INIT_GROUP_STRATEGY,
  payload
});

export const initGroupHistoryStrategy = (payload: GroupDocScoreGoal): DocScoreGoalAction<GroupDocScoreGoal> => ({
  type: DocScoreGoalActions.INIT_GROUP_HISTORY_STRATEGY,
  payload
});

export const setValueType = (valueType: ValueType): DocScoreGoalAction<ValueType> => ({
  type: DocScoreGoalActions.SET_VALUE_TYPE,
  payload: valueType
});

export const setDocScoreDates = (payload: DocScoreDatePayload): DocScoreGoalAction<DocScoreDatePayload> => ({
  type: DocScoreGoalActions.SET_DEFALT_DATES,
  payload
});

export const getEntityDefaultDates = (physGroupOrgId: number): DocScoreGoalAction<number> => ({
  type: DocScoreGoalActions.GET_ENTITY_DATES,
  payload: physGroupOrgId
});

export const setGroupConditions = (conditions: ConditionJSON[]): DocScoreGoalAction<ConditionJSON[]> => ({
  type: DocScoreGoalActions.SET_GROUP_STRATEGY_CONDITIONS,
  payload: conditions
});

export const setGroupPeriods = (payload: DocScoreDatePayload): DocScoreGoalAction<DocScoreDatePayload> => ({
  type: DocScoreGoalActions.SET_GROUP_STRATEGY_PERIOD,
  payload
});

export const setStrategyChanged = (payload: boolean): DocScoreGoalAction<boolean> => ({
  type: DocScoreGoalActions.SET_GROUP_STRATEGY_CHANGED,
  payload
});

export const setRestoreGroupStrategy = (payload: boolean): DocScoreGoalAction<boolean> => ({
  type: DocScoreGoalActions.SET_RESTORE_GROUP_STRATEGY,
  payload
});

export const getSystemInfo = (userId: number): DocScoreGoalAction<number> => ({
  type: DocScoreGoalActions.GET_SYSTEM_INFO,
  payload: userId
});

export const setPerformanceEntity = (payload: TrackPerformanceInfo): DocScoreGoalAction<TrackPerformanceInfo> => ({
  type: DocScoreGoalActions.SET_PERFORMANCE_ENTITY,
  payload
});

export const getPerformanceData = (payload: ViewPerformanceInfo): DocScoreGoalAction<ViewPerformanceInfo> => ({
  type: DocScoreGoalActions.GET_PERFORMANCE_DATA,
  payload: payload
});

export const getPerformanceGraph = (payload: ViewPerformanceInfo): DocScoreGoalAction<ViewPerformanceInfo> => ({
  type: DocScoreGoalActions.GET_PERFORMANCE_GRAPH,
  payload
});

export const getConditionPerformanceData = (payload: ViewPerformanceInfo): DocScoreGoalAction<ViewPerformanceInfo> => ({
  type: DocScoreGoalActions.GET_CONDITION_PERFORMANCE_DATA,
  payload
});

export const getGroupHistory = (payload: DocScoreGoalInitPayload): DocScoreGoalAction<DocScoreGoalInitPayload> => ({
  type: DocScoreGoalActions.GET_GROUP_HISTORY,
  payload
});

export const setStrategyLevel = (payload: SetStrategyLevelPayload): DocScoreGoalAction<SetStrategyLevelPayload> => ({
  type: DocScoreGoalActions.SET_STRATEGY_LEVEL,
  payload
});

function DocScoreGoalReducer(
  state: HospitalDocScoreGoalDataset = initialState,
  action: DocScoreGoalAction<
    | HospitalDocScoreGoalDataset
    | DocScoreRequestPayload
    | DocScoreDatePayload
    | DocScoreGoalInitPayload
    | TrackPerformanceInfo
    | ViewPerformanceInfo
    | ChartDataSetJSON
    | GroupConditionsRequestPayload
    | GroupDocScoreHistoryRecord[]
    | GroupDocScoreGoal
    | MetricConditionRecordJSON
    | PerformanceData
    | ConditionJSON[]
    | ValueType
    | string
    | number
    | boolean
  >
): HospitalDocScoreGoalDataset {
  switch (action.type) {
    case DocScoreGoalActions.INITIALIZE_GOALS_BEGIN: {
      return {
        ...state,
        isLevelSelectionLoading: true,
        isStrategyInitialized: true,
        entity: action.payload as number,
        groupStrategy: initialGroupDocScoreGoal,
        groupAllConditions: []
      };
    }
    case DocScoreGoalActions.INITIALIZE_GOALS_FAILED:
      return {
        ...state,
        isLevelSelectionLoading: false,
        error: action.payload as string
      };
    case DocScoreGoalActions.INITIALIZE_GOALS_SUCCESS: {
      const { rootId, isSaf, systemAvailable } = action.payload as DocScoreGoalInitPayload;
      return {
        ...state,
        isRootSaf: isSaf as boolean,
        systemRootId: rootId,
        systemAvailable: systemAvailable as boolean,
        isLevelSelectionLoading: false,
        strategyLevel: (systemAvailable as boolean) ? 'group' : 'hospital'
      };
    }
    case DocScoreGoalActions.SET_DEFALT_DATES: {
      const dates = action.payload as DocScoreDatePayload;

      return {
        ...state,
        currentMinMonth: dates.currentMinMonth ?? null,
        currentMaxMonth: dates.currentMaxMonth ?? null,
        historicalMinMonth: dates.historicalMinMonth ?? null,
        historicalMaxMonth: dates.historicalMaxMonth ?? null,
        performanceData: {
          ...state.performanceData,
          startDate: dates.currentMinMonth ?? '',
          endDate: dates.currentMaxMonth ?? ''
        }
      };
    }
    case DocScoreGoalActions.GET_HOSPITAL_STRATEGY_BEGIN:
      return {
        ...state,
        dataset: [],
        groupStrategy: initialGroupDocScoreGoal,
        groupStrategyChanged: false,
        restoreGroupStrategy: false,
        groupAllConditions: [],
        error: '',
        isLoading: true
      };
    case DocScoreGoalActions.GET_HOSPITAL_STRATEGY_FAILED:
      return {
        ...state,
        dataset: [],
        groupStrategy: initialGroupDocScoreGoal,
        groupAllConditions: [],
        error: action.payload as string,
        isLoading: false
      };
    case DocScoreGoalActions.GET_HOSPITAL_STRATEGY_SUCCESS:
      const { dataset, entity, entityName } = action.payload as HospitalDocScoreGoalDataset;
      return {
        ...state,
        dataset,
        entity,
        entityName,
        groupStrategy: initialGroupDocScoreGoal,
        groupAllConditions: [],
        error: '',
        isLoading: false
      };
    case DocScoreGoalActions.INIT_GROUP_STRATEGY: {
      const { drgConditions } = action.payload as GroupDocScoreGoal;
      return {
        ...state,
        groupStrategyChanged: false,
        restoreGroupStrategy: false,
        groupStrategy: {
          ...(action.payload as GroupDocScoreGoal),
          periodStart: state.currentMinMonth as Date,
          periodEnd: state.currentMaxMonth as Date,
          proposedConditions: drgConditions
            ? drgConditions.metrics.map((condition: MetricRecordJSON) => {
                return {
                  id: condition.conditionId,
                  conditionName: condition.name,
                  conditionTypeId: condition.conditionTypeId,
                  isTargetCondition: condition.isTargetCondition,
                  isLegalCondition: condition.isLegalCondition as boolean,
                  opportunityRW: condition.opportunityRW
                };
              })
            : [],
          history: {
            historyDataset: null,
            historyIsLoading: false,
            historyHasError: false
          }
        },
        groupAllConditions: state.restoreGroupStrategy ? state.groupAllConditions : []
      };
    }
    case DocScoreGoalActions.INIT_GROUP_HISTORY_STRATEGY: {
      return {
        ...state,
        groupStrategyChanged: false,
        restoreGroupStrategy: false,
        groupStrategy: {
          ...(action.payload as GroupDocScoreGoal)
        }
      };
    }
    case DocScoreGoalActions.GET_GROUP_STRATEGY_BEGIN: {
      const { currentMinMonth, currentMaxMonth, csvOrgIds } = action.payload as DocScoreDatePayload;
      const { orgId, history, name } = state.groupStrategy;
      return {
        ...state,
        groupStrategy: {
          ...initialGroupDocScoreGoal,
          orgId: history ? orgId : 0,
          name: history ? name : '',
          csvOrgIds: csvOrgIds && csvOrgIds.length > 0 ? csvOrgIds : state.groupStrategy.csvOrgIds,
          periodStart: currentMinMonth as Date,
          periodEnd: currentMaxMonth as Date,
          proposedConditions: state.groupStrategy.proposedConditions,
          history
        },
        error: '',
        isGroupLoading: true
      };
    }
    case DocScoreGoalActions.GET_GROUP_STRATEGY_FAILED:
      return {
        ...state,
        groupStrategy: initialGroupDocScoreGoal,
        error: action.payload as string,
        isGroupLoading: false
      };
    case DocScoreGoalActions.GET_GROUP_STRATEGY_SUCCESS: {
      const { name } = action.payload as GroupDocScoreGoal;
      return {
        ...state,
        groupStrategy: {
          ...state.groupStrategy,
          ...(action.payload as GroupDocScoreGoal),
          name: state.groupStrategy.history && state.groupStrategy.name === '' ? name : state.groupStrategy.name,
          proposedConditions: state.groupStrategy.proposedConditions
        },
        error: '',
        isGroupLoading: false
      };
    }
    case DocScoreGoalActions.GET_GROUP_ALL_CONDITIONS_BEGIN:
      return {
        ...state,
        groupAllConditions: [],
        error: '',
        isGroupConditionsLoading: true
      };
    case DocScoreGoalActions.GET_GROUP_ALL_CONDITIONS_FAILED:
      return {
        ...state,
        groupAllConditions: [],
        error: action.payload as string,
        isGroupConditionsLoading: false
      };
    case DocScoreGoalActions.GET_GROUP_ALL_CONDITIONS_SUCCESS:
      const metricConditions = (action.payload as MetricConditionRecordJSON).metrics;
      return {
        ...state,
        groupAllConditions: metricConditions.map(condition => {
          return {
            id: condition.conditionId,
            conditionName: condition.name,
            isLegalCondition: condition.isLegalCondition as boolean,
            isTargetCondition: condition.isTargetCondition,
            conditionTypeId: condition.conditionTypeId,
            opportunityRW: condition.opportunityRW
          };
        }),
        error: '',
        isGroupConditionsLoading: false
      };
    case DocScoreGoalActions.SET_VALUE_TYPE:
      return { ...state, valueType: action.payload as ValueType };
    case DocScoreGoalActions.GET_ENTITY_DATES_BEGIN:
      return {
        ...state,
        absoluteMinMonth: null,
        currentMinMonth: null,
        currentMaxMonth: null,
        historicalMinMonth: null,
        historicalMaxMonth: null,
        isDatesLoading: true
      };
    case DocScoreGoalActions.GET_ENTITY_DATES_FAILED:
      return {
        ...state,
        absoluteMinMonth: null,
        currentMinMonth: null,
        currentMaxMonth: null,
        historicalMinMonth: null,
        historicalMaxMonth: null,
        isDatesLoading: false
      };
    case DocScoreGoalActions.GET_ENTITY_DATES_SUCCESSFUL:
      const {
        absoluteMinMonth,
        currentMinMonth,
        currentMaxMonth,
        historicalMinMonth,
        historicalMaxMonth
      } = action.payload as DocScoreDatePayload;

      return {
        ...state,
        absoluteMinMonth: absoluteMinMonth ?? null,
        absoluteMaxMonth: currentMaxMonth, // _absMaxMonth,
        currentMinMonth: currentMinMonth,
        currentMaxMonth: currentMaxMonth,
        historicalMinMonth: historicalMinMonth ?? null,
        historicalMaxMonth: historicalMaxMonth ?? null,
        isDatesLoading: false,
        performanceData: {
          ...state.performanceData,
          startDate: currentMinMonth ?? new Date(),
          endDate: currentMaxMonth ?? new Date()
        }
      };
    case DocScoreGoalActions.SET_GROUP_STRATEGY_CONDITIONS:
      return {
        ...state,
        groupStrategy: {
          ...state.groupStrategy,
          proposedConditions: [...(action.payload as ConditionJSON[])]
        }
      };
    case DocScoreGoalActions.SET_GROUP_STRATEGY_CHANGED:
      return {
        ...state,
        groupStrategyChanged: action.payload as boolean
      };
    case DocScoreGoalActions.SET_GROUP_STRATEGY_PERIOD: {
      const { currentMinMonth, currentMaxMonth } = action.payload as DocScoreDatePayload;
      return {
        ...state,
        groupStrategy: {
          ...state.groupStrategy,
          periodStart: currentMinMonth as Date,
          periodEnd: currentMaxMonth as Date
        }
      };
    }
    case DocScoreGoalActions.SET_RESTORE_GROUP_STRATEGY:
      return {
        ...state,
        restoreGroupStrategy: action.payload as boolean
      };
    case DocScoreGoalActions.GET_SYSTEM_INFO_BEGIN:
      return {
        ...state,
        trackerData: {
          entityName: '',
          absoluteMinMonth: null,
          absoluteMaxMonth: null,
          baselineMinMonth: new Date(),
          baselineMaxMonth: new Date(),
          implementationMinMonth: new Date(),
          implementationMaxMonth: new Date(),
          saveFormulas: false,
          useDefaultDates: true,
          isDatesLoading: true
        }
      };
    case DocScoreGoalActions.GET_SYSTEM_INFO_FAILED:
      return {
        ...state,
        trackerData: {
          entityName: '',
          absoluteMinMonth: null,
          absoluteMaxMonth: null,
          baselineMinMonth: new Date(),
          baselineMaxMonth: new Date(),
          implementationMinMonth: new Date(),
          implementationMaxMonth: new Date(),
          saveFormulas: false,
          useDefaultDates: true,
          isDatesLoading: false
        }
      };
    case DocScoreGoalActions.GET_SYSTEM_INFO_SUCCESS: {
      const { entityName, absoluteMinMonth, currentMinMonth, currentMaxMonth } = action.payload as TrackPerformanceInfo;
      const _currentDate = new Date();

      let currentMinDate = currentMinMonth;
      if (currentMinDate === null) {
        currentMinDate = new Date(_currentDate);
        currentMinDate.setFullYear(_currentDate.getFullYear() - 1);
      }
      let currentMaxDate = currentMaxMonth;
      if (currentMaxDate === null) {
        currentMaxDate = new Date(_currentDate);
        currentMaxDate.setMonth(_currentDate.getMonth() + 1);
        currentMaxDate.setDate(_currentDate.getDate() - 1);
      }

      const baselineMinDate = new Date(currentMinDate);
      const baselineMaxDate = new Date(currentMaxDate);
      if (currentMinDate && currentMaxDate) {
        baselineMinDate.setFullYear(baselineMinDate.getFullYear() - 1);
        baselineMaxDate.setFullYear(baselineMaxDate.getFullYear() - 1);
      }

      return {
        ...state,
        trackerData: {
          entityName,
          absoluteMinMonth: absoluteMinMonth ?? null,
          absoluteMaxMonth: currentMaxDate, // _absMaxMonth,
          baselineMinMonth: baselineMinDate,
          baselineMaxMonth: baselineMaxDate,
          implementationMinMonth: currentMinDate,
          implementationMaxMonth: currentMaxDate,
          saveFormulas: false,
          useDefaultDates: true,
          isDatesLoading: false
        }
      };
    }
    case DocScoreGoalActions.SET_AUTOGEN_ENTITY:
      return { ...state, entity: Number(action.payload) };
    case DocScoreGoalActions.SET_STRATEGY_LEVEL:
      const {
        strategyLevel,
        entity: strategyEntity,
        entityName: strategyEntityName
      } = action.payload as SetStrategyLevelPayload;

      return { ...state, strategyLevel, entity: strategyEntity, entityName: strategyEntityName };
    case DocScoreGoalActions.SET_PERFORMANCE_ENTITY: {
      const {
        entity,
        entityName,
        title,
        conditionId,
        conditionName,
        currentMinMonth,
        currentMaxMonth
      } = action.payload as TrackPerformanceInfo;
      return {
        ...state,
        performanceData: {
          entity: entity!,
          entityName,
          title,
          conditionId,
          conditionName,
          startDate: currentMinMonth!,
          endDate: currentMaxMonth!,
          graphData: null,
          conditionMetricData: null,
          conditionMetricSchema: null,
          overallMetricData: null,
          overallMetricSchema: null,
          hasError: false,
          isLoading: false,
          isGraphLoading: false
        }
      };
    }
    case DocScoreGoalActions.GET_PERFORMANCE_DATA_BEGIN:
      return { ...state, performanceData: { ...state.performanceData, isLoading: true, isGraphLoading: true } };
    case DocScoreGoalActions.GET_PERFORMANCE_DATA_FAILED:
      return {
        ...state,
        performanceData: {
          ...state.performanceData,
          graphData: null,
          conditionMetricData: null,
          conditionMetricSchema: null,
          hasError: true,
          isLoading: false,
          isGraphLoading: false,
          errorMessage: action.payload as string
        }
      };
    case DocScoreGoalActions.GET_PERFORMANCE_DATA_SUCCESS: {
      const {
        graphData,
        conditionMetricData,
        conditionMetricSchema,
        overallMetricData,
        overallMetricSchema
      } = action.payload as PerformanceData;
      return {
        ...state,
        performanceData: {
          ...state.performanceData,
          graphData,
          conditionMetricData,
          conditionMetricSchema,
          overallMetricData,
          overallMetricSchema,
          hasError: false,
          errorMessage: '',
          isLoading: false,
          isGraphLoading: false
        }
      };
    }
    case DocScoreGoalActions.GET_PERFORMANCE_GRAPH_BEGIN:
      return {
        ...state,
        performanceData: {
          ...state.performanceData,
          entityName: action.payload as string,
          graphData: null,
          isGraphLoading: true
        }
      };
    case DocScoreGoalActions.GET_PERFORMANCE_GRAPH_FAILED:
      return {
        ...state,
        performanceData: {
          ...state.performanceData,
          graphData: null,
          isGraphLoading: false,
          hasError: true,
          errorMessage: action.payload as string
        }
      };
    case DocScoreGoalActions.GET_PERFORMANCE_GRAPH_SUCCESS:
      return {
        ...state,
        performanceData: {
          ...state.performanceData,
          graphData: action.payload as ChartDataSetJSON,
          isGraphLoading: false,
          hasError: false,
          errorMessage: ''
        }
      };
    case DocScoreGoalActions.GET_GROUP_HISTORY_BEGIN:
      return {
        ...state,
        groupStrategy: {
          ...state.groupStrategy,
          history: {
            historyDataset: null,
            historyIsLoading: true,
            historyHasError: false,
            historyErrorMessage: ''
          }
        }
      };
    case DocScoreGoalActions.GET_GROUP_HISTORY_FAILED:
      return {
        ...state,
        groupStrategy: {
          ...state.groupStrategy,
          history: {
            historyDataset: null,
            historyIsLoading: false,
            historyHasError: true,
            historyErrorMessage: action.payload as string
          }
        }
      };
    case DocScoreGoalActions.GET_GROUP_HISTORY_SUCCESS:
      return {
        ...state,
        groupStrategy: {
          ...state.groupStrategy,
          history: {
            historyDataset: action.payload as GroupDocScoreHistoryRecord[],
            historyIsLoading: false,
            historyHasError: false,
            historyErrorMessage: ''
          }
        }
      };
    case DocScoreGoalActions.START_BULK_PROCESS:
      return {
        ...state,
        isLoading: true
      };
    default:
      return { ...state };
  }
}

export function* initializeStrategySaga({ payload }: DocScoreGoalAction<DocScoreGoalInitPayload>): SagaIterator {
  if (!payload) throw new Error('Entity and RootId are required');

  const { entity, rootId } = payload;

  yield put({ type: DocScoreGoalActions.INITIALIZE_GOALS_BEGIN, payload: entity ?? -1 });

  const orgOutput: AsyncOutput<TreeJSON> = yield call(ApplicationAPI.get, { endpoint: `org/${rootId}` });

  if (orgOutput.error) {
    yield put({ type: DocScoreGoalActions.INITIALIZE_GOALS_FAILED, payload: orgOutput.error });
  } else if (orgOutput.data) {
    const systemAvailable =
      (orgOutput.data.nodeTypeId === 2 && orgOutput.data.childrenCount > 0) ||
      (orgOutput.data.children && orgOutput.data.children.filter(node => node.nodeTypeId === 2).length > 0);
    yield put({
      type: DocScoreGoalActions.INITIALIZE_GOALS_SUCCESS,
      payload: {
        rootId,
        isSaf: orgOutput.data.name.toLowerCase().includes('saf'),
        systemAvailable
      }
    });
  }
}

export function* fetchEntityDocScoreGoalSaga({ payload }: DocScoreGoalAction<DocScoreRequestPayload>): SagaIterator {
  if (!payload) {
    throw new Error(
      'hospitalOrgId, currentMinMonth, currentMaxMonth must be set with the GET_HOSPITAL_STRATEGY action'
    );
  }

  yield put({ type: DocScoreGoalActions.GET_HOSPITAL_STRATEGY_BEGIN });

  const { hospitalOrgId, entityName, currentMinMonth, currentMaxMonth, strategyLevel } = payload;

  if (currentMinMonth === null || currentMaxMonth === null) {
    throw new Error('currentMinMonth and currentMaxMonth must be set');
  }

  const queryStringValues: QueryStringParam[] = [
    {
      key: 'currentStart',
      value: formatDateForAPI(new Date(currentMinMonth))
    },
    {
      key: 'currentEnd',
      value: formatDateForAPI(new Date(currentMaxMonth))
    }
  ];
  if (strategyLevel === 'group') {
    queryStringValues.push({
      key: 'systemsSpecialtyGroupName',
      value: encodeURIComponent(entityName as string)
    });
  }

  const hospitalEndpoint = createUrlWithQueryString(`docscoregoal/${hospitalOrgId}`, queryStringValues);
  const systemEndpoint = createUrlWithQueryString(`docscoregoal/systemsspecialtygroup/`, queryStringValues);
  const strategyEndpoint = strategyLevel === 'hospital' ? hospitalEndpoint : systemEndpoint;

  const strategyOutput: AsyncOutput<GroupDocScoreGoal[]> = yield call(ApplicationAPI.get, {
    endpoint: strategyEndpoint
  });

  if (strategyOutput.error) {
    yield put({
      type: DocScoreGoalActions.GET_HOSPITAL_STRATEGY_FAILED,
      payload: strategyOutput.error
    });
  } else {
    yield put({
      type: DocScoreGoalActions.GET_HOSPITAL_STRATEGY_SUCCESS,
      payload: {
        dataset: strategyOutput.data,
        entity: hospitalOrgId,
        entityName: entityName
      }
    });
  }
}

export function* fetchGroupDocScoreGoalSaga({ payload }: DocScoreGoalAction<DocScoreRequestPayload>): SagaIterator {
  if (!payload) {
    throw new Error(
      'hospitalOrgId, physGroupOrgId, currentMinMonth, currentMaxMonth must be set with the GET_HOSPITAL_STRATEGY action'
    );
  }

  const {
    hospitalOrgId,
    physGroupOrgId,
    adjustedTargetDocScore,
    targetConditions,
    useSavedTargetConditions,
    currentMinMonth,
    currentMaxMonth,
    groupTotalAddressableOpportunityRW,
    historicalMinMonth,
    historicalMaxMonth
  } = payload;

  yield put({
    type: DocScoreGoalActions.GET_GROUP_STRATEGY_BEGIN,
    payload: { currentMinMonth, currentMaxMonth, csvOrgIds: physGroupOrgId ?? '' }
  });

  const queryStringValues: QueryStringParam[] = [];

  if (historicalMinMonth) {
    queryStringValues.push({
      key: 'historicalStart',
      value: formatDateForAPI(new Date(historicalMinMonth))
    });
  }
  if (historicalMaxMonth) {
    queryStringValues.push({
      key: 'historicalEnd',
      value: formatDateForAPI(new Date(historicalMaxMonth))
    });
  }
  if (currentMinMonth) {
    queryStringValues.push({
      key: 'currentStart',
      value: formatDateForAPI(new Date(currentMinMonth))
    });
  }
  if (currentMaxMonth) {
    queryStringValues.push({
      key: 'currentEnd',
      value: formatDateForAPI(new Date(currentMaxMonth))
    });
  }
  if (adjustedTargetDocScore) {
    queryStringValues.push({
      key: 'adjustedTargetDocScore',
      value: String(adjustedTargetDocScore)
    });
  }
  if (targetConditions) {
    queryStringValues.push({
      key: 'targetCondIds',
      value: targetConditions.toString()
    });
  }
  if (useSavedTargetConditions) {
    queryStringValues.push({
      key: 'useSavedTargetConditions',
      value: 'true'
    });
  }
  if (groupTotalAddressableOpportunityRW) {
    queryStringValues.push({
      key: 'groupTotalAddressableOpportunityRW',
      value: `${groupTotalAddressableOpportunityRW}`
    });
  }

  let strategyEndpoint = createUrlWithQueryString(`docscoregoal/${hospitalOrgId}/${physGroupOrgId}`, queryStringValues);

  if (typeof physGroupOrgId === 'string' && physGroupOrgId.split(',').length > 0) {
    queryStringValues.push({
      key: 'csvPhysicianGroupOrgHierarchyIds',
      value: physGroupOrgId
    });
    strategyEndpoint = createUrlWithQueryString(`docscoregoal/`, queryStringValues);
  }

  const strategyOutput: AsyncOutput<GroupDocScoreGoal> = yield call(ApplicationAPI.get, {
    endpoint: strategyEndpoint
  });

  if (strategyOutput.error) {
    yield put({
      type: DocScoreGoalActions.GET_GROUP_STRATEGY_FAILED,
      payload: strategyOutput.error
    });
  } else {
    yield put({
      type: DocScoreGoalActions.GET_GROUP_STRATEGY_SUCCESS,
      payload: strategyOutput.data
    });
  }
}

export function* fetchGroupAllConditionsSaga({
  payload
}: DocScoreGoalAction<GroupConditionsRequestPayload>): SagaIterator {
  if (!payload) {
    throw new Error('physGroupOrgId must be provided');
  }

  yield put({ type: DocScoreGoalActions.GET_GROUP_ALL_CONDITIONS_BEGIN });

  const { physGroupOrgId, currentMinMonth, currentMaxMonth, historicalMinMonth, historicalMaxMonth } = payload;

  let endpoint = `metrics/conditions/${physGroupOrgId}`;
  const queryStringValues: QueryStringParam[] = [
    {
      key: 'includeAllConditions',
      value: '1'
    },
    {
      key: 'shareDrg',
      value: '5'
    },
    {
      key: 'shareHcc',
      value: '5'
    },
    {
      key: 'shareMortality',
      value: '5'
    },
    {
      key: 'shareReadmission',
      value: '5'
    },
    {
      key: 'useSavedTargetConds',
      value: '1'
    },
    {
      key: 'conditionTypeIds',
      value: '1'
    }
  ];

  if (typeof physGroupOrgId === 'string' && physGroupOrgId.split(',').length > 0) {
    endpoint = `metrics/conditions`;
    queryStringValues.push({
      key: 'csvOrgIds',
      value: physGroupOrgId
    });
  }

  if (currentMinMonth) {
    queryStringValues.push({
      key: 'currentStart',
      value: formatDateForAPI(new Date(currentMinMonth))
    });
  }
  if (currentMaxMonth) {
    queryStringValues.push({
      key: 'currentEnd',
      value: formatDateForAPI(new Date(currentMaxMonth))
    });
  }
  if (historicalMinMonth) {
    queryStringValues.push({
      key: 'historicalStart',
      value: formatDateForAPI(new Date(historicalMinMonth))
    });
  }
  if (historicalMaxMonth) {
    queryStringValues.push({
      key: 'historicalEnd',
      value: formatDateForAPI(new Date(historicalMaxMonth))
    });
  }

  const groupConditionOutput: AsyncOutput<MetricConditionRecordJSON> = yield call(ApplicationAPI.get, {
    endpoint: createUrlWithQueryString(endpoint, queryStringValues)
  });

  if (groupConditionOutput.error) {
    yield put({ type: DocScoreGoalActions.GET_GROUP_ALL_CONDITIONS_FAILED, payload: groupConditionOutput.error });
  } else {
    yield put({ type: DocScoreGoalActions.GET_GROUP_ALL_CONDITIONS_SUCCESS, payload: groupConditionOutput.data });
  }
}

export function* fetchEntityDefaultDatesSaga({ payload }: DocScoreGoalAction<number>): SagaIterator {
  if (!payload) {
    throw new Error('physGroupOrgId must be provided');
  }

  yield put({ type: DocScoreGoalActions.GET_ENTITY_DATES_BEGIN });

  const entityDatesOutput: AsyncOutput<DocScoreDatePayload> = yield call(ApplicationAPI.get, {
    endpoint: `defaultdates/${payload}`
  });

  if (entityDatesOutput.error) {
    yield put({ type: DocScoreGoalActions.GET_ENTITY_DATES_FAILED, payload: entityDatesOutput.error });
  } else {
    yield put({ type: DocScoreGoalActions.GET_ENTITY_DATES_SUCCESSFUL, payload: entityDatesOutput.data });
  }
}

export function* fetchSystemInfoSaga({ payload }: DocScoreGoalAction<number>): SagaIterator {
  if (!payload) {
    throw new Error('userId must be provided');
  }

  yield put({ type: DocScoreGoalActions.GET_SYSTEM_INFO_BEGIN });

  const [entityDatesOutput, rootOrgOutput]: [AsyncOutput<DocScoreDatePayload>, AsyncOutput<TreeJSON>] = yield all([
    call(ApplicationAPI.get, { endpoint: `defaultdates/${payload}` }),
    call(ApplicationAPI.get, { endpoint: `org/${payload}` })
  ]);

  if (entityDatesOutput.error || rootOrgOutput.error) {
    const errors: string[] = [];

    if (entityDatesOutput.error) errors.push(entityDatesOutput.error);
    if (rootOrgOutput.error) errors.push(rootOrgOutput.error);

    yield put({ type: DocScoreGoalActions.GET_SYSTEM_INFO_FAILED, payload: errors.join(' / ') });
  } else {
    yield put({
      type: DocScoreGoalActions.GET_SYSTEM_INFO_SUCCESS,
      payload: {
        ...entityDatesOutput.data,
        entityName: rootOrgOutput.data ? rootOrgOutput.data.name : ''
      }
    });
  }
}

export function* fetchPerformanceDataSaga({ payload }: DocScoreGoalAction<ViewPerformanceInfo>): SagaIterator {
  if (!payload) throw new Error('Entity, startDate, and endDate is required');

  const { entity, currentMinMonth, currentMaxMonth, aggregate } = payload;
  if (currentMinMonth === null || currentMaxMonth === null)
    throw new Error('currentMinMonth and currentMaxMonth is required');

  const startDate = formatDateForAPI(new Date(currentMinMonth));
  const endDate = formatDateForAPI(new Date(currentMaxMonth));

  yield put({ type: DocScoreGoalActions.GET_PERFORMANCE_DATA_BEGIN });

  const queryStringValues: QueryStringParam[] = [
    {
      key: 'csvOrgIds',
      value: `${entity}`
    },
    {
      key: 'includeChildern',
      value: 'true'
    },
    {
      key: 'aggergateOrgIds',
      value: aggregate && aggregate === true ? 'true' : 'false'
    },
    {
      key: 'currentStart',
      value: formatDateForAPI(new Date(currentMinMonth))
    },
    {
      key: 'currentEnd',
      value: formatDateForAPI(new Date(currentMaxMonth))
    }
  ];

  const graphEndpoint = `graph/docScore/?csvOrgIds=${entity}&startDate=${startDate}&endDate=${endDate}`;
  const overallEndpoint = createUrlWithQueryString(`metrics/overallperformance`, queryStringValues);

  const [graphOutput, overallDataOutput, overallSchemaOutput]: [
    AsyncOutput<ChartDataSetJSON>,
    AsyncOutput<OverallMetricRecordJSON[]>,
    AsyncOutput<OverallDataSchema>
  ] = yield all([
    call(ApplicationAPI.get, { endpoint: graphEndpoint }),
    call(ApplicationAPI.get, { endpoint: overallEndpoint }),
    call(ApplicationAPI.get, { endpoint: `schema/overallperformance` })
  ]);

  if (graphOutput.error || overallDataOutput.error || overallSchemaOutput.error) {
    const errorArray: string[] = [];
    if (graphOutput.error) errorArray.push(graphOutput.error);
    if (overallDataOutput.error) errorArray.push(overallDataOutput.error);
    if (overallSchemaOutput.error) errorArray.push(overallSchemaOutput.error);

    yield put({ type: DocScoreGoalActions.GET_PERFORMANCE_DATA_FAILED, payload: errorArray.join(', ') });
  } else {
    yield put({
      type: DocScoreGoalActions.GET_PERFORMANCE_DATA_SUCCESS,
      payload: {
        graphData: graphOutput.data,
        overallMetricData: overallDataOutput.data,
        overallMetricSchema: overallSchemaOutput.data
      }
    });
  }
}

export function* fetchConditionPerformanceDataSaga({ payload }: DocScoreGoalAction<ViewPerformanceInfo>): SagaIterator {
  if (!payload) throw new Error('Entity, startDate, and endDate is required');

  const { entity, currentMinMonth, currentMaxMonth, conditionId } = payload;
  if (currentMinMonth === null || currentMaxMonth === null)
    throw new Error('currentMinMonth and currentMaxMonth is required');

  const startDate = formatDateForAPI(new Date(currentMinMonth));
  const endDate = formatDateForAPI(new Date(currentMaxMonth));

  yield put({ type: DocScoreGoalActions.GET_PERFORMANCE_DATA_BEGIN });

  const queryStringValues: QueryStringParam[] = [
    {
      key: 'currentStart',
      value: formatDateForAPI(new Date(currentMinMonth))
    },
    {
      key: 'currentEnd',
      value: formatDateForAPI(new Date(currentMaxMonth))
    },
    {
      key: 'conditionId',
      value: `${conditionId}`
    },
    {
      key: 'shareDRG',
      value: '5'
    },
    {
      key: 'shareMortality',
      value: '5'
    },
    {
      key: 'shareReadmission',
      value: '5'
    },
    {
      key: 'shareHcc',
      value: '5'
    }
  ];

  // Get the graph, CMI metric, docScore metric, CMI metric schema, and docScore metric schema
  let graphEndpoint = `Graph/conditions/${entity}?StartDate=${startDate}&EndDate=${endDate}&conditionId=${conditionId}`;
  let conditionEndpoint = createUrlWithQueryString(`metrics/conditions/${entity}`, queryStringValues);

  if (typeof entity === 'string' && entity.split(',').length > 0) {
    queryStringValues.push({
      key: 'csvOrgIds',
      value: entity
    });
    graphEndpoint = `graph/conditions/?csvOrgIds=${entity}&StartDate=${startDate}&EndDate=${endDate}&conditionId=${conditionId}`;
    conditionEndpoint = createUrlWithQueryString(`metrics/conditions/`, queryStringValues);
  }
  const [graphOutput, conditionOutput, conditionSchemaOutput]: [
    AsyncOutput<ChartDataSetJSON>,
    AsyncOutput<MetricConditionRecordJSON>,
    AsyncOutput<MetricSchema>
  ] = yield all([
    call(ApplicationAPI.get, { endpoint: graphEndpoint }),
    call(ApplicationAPI.get, { endpoint: conditionEndpoint }),
    call(ApplicationAPI.get, { endpoint: `schema/condition` })
  ]);

  if (graphOutput.error || conditionOutput.error || conditionSchemaOutput.error) {
    const errorArray: string[] = [];
    if (graphOutput.error) errorArray.push(graphOutput.error);
    if (conditionOutput.error) errorArray.push(conditionOutput.error);
    if (conditionSchemaOutput.error) errorArray.push(conditionSchemaOutput.error);

    yield put({ type: DocScoreGoalActions.GET_PERFORMANCE_DATA_FAILED, payload: errorArray.join(' / ') });
  } else {
    yield put({
      type: DocScoreGoalActions.GET_PERFORMANCE_DATA_SUCCESS,
      payload: {
        graphData: graphOutput.data as ChartDataSetJSON,
        cmiMetricData: null,
        cmiMetricSchema: null,
        docScoreMetricData: null,
        docScoreMetricSchema: null,
        conditionMetricData: (conditionOutput.data ? conditionOutput.data.metrics : []) as MetricRecordJSON[],
        conditionMetricSchema: conditionSchemaOutput.data as MetricSchema
      }
    });
  }
}

export function* fetchPerformanceGraphSaga({ payload }: DocScoreGoalAction<ViewPerformanceInfo>): SagaIterator {
  if (!payload) throw new Error('Entity, startDate, and endDate is required');

  const { entity, entityName, currentMinMonth, currentMaxMonth, isCondition, conditionId } = payload;
  if (currentMinMonth === null || currentMaxMonth === null)
    throw new Error('currentMinMonth and currentMaxMonth is required');

  const startDate = formatDateForAPI(new Date(currentMinMonth));
  const endDate = formatDateForAPI(new Date(currentMaxMonth));

  yield put({ type: DocScoreGoalActions.GET_PERFORMANCE_GRAPH_BEGIN, payload: entityName });

  let graphEndpoint = `Graph/${isCondition ? 'conditions' : 'docScore'}/${
    typeof entity === 'string' ? '?csvOrgIds=' + entity + '&' : entity + '?'
  }startDate=${startDate}&endDate=${endDate}`;
  if (isCondition) {
    graphEndpoint += `&conditionId=${conditionId}`;
  }
  const output: AsyncOutput<ChartDataSetJSON> = yield call(ApplicationAPI.get, {
    endpoint: graphEndpoint
  });

  if (output.error) {
    yield put({ type: DocScoreGoalActions.GET_PERFORMANCE_GRAPH_FAILED, payload: output.error });
  } else {
    yield put({ type: DocScoreGoalActions.GET_PERFORMANCE_GRAPH_SUCCESS, payload: output.data });
  }
}

export function* fetchGroupHistorySaga({ payload }: DocScoreGoalAction<DocScoreGoalInitPayload>): SagaIterator {
  if (!payload) throw new Error('payload is required');

  // reusing params
  const { entity, rootId } = payload;

  yield put({ type: DocScoreGoalActions.GET_GROUP_HISTORY_BEGIN });

  const output: AsyncOutput<GroupDocScoreHistoryRecord[]> = yield call(ApplicationAPI.get, {
    endpoint: `docscoregoal/${entity}/${rootId}/history`
  });

  if (output.error) {
    yield put({ type: DocScoreGoalActions.GET_GROUP_HISTORY_FAILED, payload: output.error });
  } else {
    yield put({ type: DocScoreGoalActions.GET_GROUP_HISTORY_SUCCESS, payload: output.data });
  }
}

export function* docScoreGoalDataSaga(): SagaIterator {
  yield takeLatest(DocScoreGoalActions.INITIALIZE_GOALS, initializeStrategySaga);
  yield takeLatest(DocScoreGoalActions.GET_HOSPITAL_STRATEGY, fetchEntityDocScoreGoalSaga);
  yield takeLatest(DocScoreGoalActions.GET_GROUP_STRATEGY, fetchGroupDocScoreGoalSaga);
  yield takeLatest(DocScoreGoalActions.GET_GROUP_ALL_CONDITIONS, fetchGroupAllConditionsSaga);
  yield takeLatest(DocScoreGoalActions.GET_ENTITY_DATES, fetchEntityDefaultDatesSaga);
  yield takeLatest(DocScoreGoalActions.GET_SYSTEM_INFO, fetchSystemInfoSaga);
  yield takeLatest(DocScoreGoalActions.GET_PERFORMANCE_DATA, fetchPerformanceDataSaga);
  yield takeLatest(DocScoreGoalActions.GET_PERFORMANCE_GRAPH, fetchPerformanceGraphSaga);
  yield takeLatest(DocScoreGoalActions.GET_CONDITION_PERFORMANCE_DATA, fetchConditionPerformanceDataSaga);
  yield takeLatest(DocScoreGoalActions.GET_GROUP_HISTORY, fetchGroupHistorySaga);
}

export default DocScoreGoalReducer;
