import { TextField, TextFieldProps } from '@mui/material';
import React from 'react';

type TextInputProps = Omit<TextFieldProps, 'size' | 'variant' | 'className'> & {
  hasValidationErrors?: boolean;
};

const TextInput: React.FC<TextInputProps> = props => {
  const baseBorderColor = !props.hasValidationErrors ? 'grey.300' : 'red.500';

  return (
    <TextField
      {...props}
      size="small"
      type="text"
      variant="outlined"
      sx={{
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: baseBorderColor
        },
        '&&:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: 'blue.light2'
        },
        '&&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: 'blue.light2'
        },
        '& .Mui-disabled': {
          backgroundColor: 'grey.100',
          '& + .MuiOutlinedInput-notchedOutline': {
            borderColor: baseBorderColor,
            '&:hover': {
              borderColor: baseBorderColor
            }
          }
        },
        '& .MuiInputBase-input': {
          fontFamily: theme => theme.typography.fontFamily,
          '&::placeholder': {
            color: 'grey.400',
            fontFamily: theme => theme.typography.fontFamily
          }
        },
        ...props.sx
      }}
    />
  );
};

export default TextInput;
