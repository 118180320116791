import React from 'react';
import Can from '@clinintell/components/Can';
import { ReportType } from '@clinintell/containers/reports/types/actionTypes';
import { Feature } from '@clinintell/containers/authentication/rules';
import { CARD_WIDTH } from '../../cmiAnalysisLanding/CMIAnalysisLanding';
import { Box } from '@mui/material';
import CardSelector from '@clinintell/components/cardSelector/CardSelector';

type ReportTypeProps = {
  typeReport: ReportType;
  children?: JSX.Element | JSX.Element[];
};

const reportCardsList: { report: ReportType; permissions: Feature[]; title: string; explanation: string }[] = [
  {
    report: ReportType.Provider,
    permissions: ['reportProviderReportView'],
    title: 'Provider Report',
    explanation: "Summarizes a provider's CMI Doc Score and Target Condition performance relative to their group"
  },
  {
    report: ReportType.Group,
    permissions: ['reportGroupReportAdminView', 'reportGroupReportProviderView'],
    title: 'Group Report',
    explanation: 'Assesses a physician group’s monthly severity reporting performance.'
  },
  {
    report: ReportType.Quarterly,
    permissions: ['reportSystemReport'],
    title: 'Quarterly Summary Report',
    explanation: "Summarizes a physician group's quarterly severity reporting performance."
  },
  {
    report: ReportType.Training,
    permissions: ['reportTrainingReportProgressView'],
    title: 'Training Progress Report',
    explanation: "Highlights the hospital, physician group and provider's progress on the ClinIntell training."
  },
  {
    report: ReportType.System,
    permissions: ['reportSystemReport'],
    title: 'System Report',
    explanation: "Summarizes a health system's severity reporting performance."
  },
  {
    report: ReportType.Executive,
    permissions: ['reportSystemReport'],
    title: 'Executive Summary Report',
    explanation:
      "Summarizes a facility's 12-month severity reporting performance compared to the previous 12 months and identifies areas of addressable opportunity."
  }
];

const ReportCard: React.FC<ReportTypeProps> = ({ typeReport = '', children }) => {
  return (
    <>
      <Can
        permissions={reportCardsList.find(({ report }) => report === typeReport)?.permissions as Feature[]}
        yes={() => (
          <Box sx={{ width: CARD_WIDTH }}>
            <CardSelector>
              <CardSelector.Details
                title={reportCardsList.find(({ report }) => report === typeReport)?.title}
                explanation={reportCardsList.find(({ report }) => report === typeReport)?.explanation}
              />
              <CardSelector.Actions>{children}</CardSelector.Actions>
            </CardSelector>
          </Box>
        )}
        no={() => null}
      ></Can>
    </>
  );
};

export default ReportCard;
