import React, { lazy } from 'react';
import { Switch, useRouteMatch, Route } from 'react-router-dom';
import Can from '@clinintell/components/Can';
import RouteErrorPage from '@clinintell/containers/errorHandlers/RouteErrorPage';

const ManageUsersController = lazy(() =>
  import(
    /* webpackChunkName: 'usermanagement/ManageUsersController' */ '@clinintell/containers/usermanagement/ManageUsers'
  )
);
// const ManageRolesController = lazy(() =>
//   import(
//     /* webpackChunkName: 'usermanagement/ManageRolesController' */ '@clinintell/containers/usermanagement/ManageRoles'
//   )
// );
// const ManageFeatures = lazy(() =>
//   import(/* webpackChunkName: 'usermanagement/ManageFeatures' */ '@clinintell/containers/usermanagement/ManageFeatures')
// );

const UserRoutes = (): JSX.Element => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/manageusers`}>
        <Can
          permissions={['usersEdit', 'usersView']}
          yes={(): JSX.Element => <ManageUsersController />}
          no={(): JSX.Element => <RouteErrorPage error={new Error('401')} />}
        />
      </Route>
      {/* <Route path={`${path}/manageroles`}>
        <Can
          permissions={['usersEdit', 'usersView']}
          yes={(): JSX.Element => <ManageRolesController />}
          no={(): JSX.Element => <RouteErrorPage />}
        />
      </Route>
      <Route path={`${path}/managefeatures`}>
        <Can
          permissions={['usersEdit', 'usersView']}
          yes={(): JSX.Element => <ManageFeatures />}
          no={(): JSX.Element => <RouteErrorPage />}
        />
      </Route> */}
      <Route render={(): JSX.Element => <RouteErrorPage error={new Error('404')} />} />
    </Switch>
  );
};

export default UserRoutes;
