import { Box, SxProps, Theme } from '@mui/material';
import React from 'react';

type PageTitleProps = {
  sx?: SxProps<Theme>;
};

const PageTitle: React.FC<PageTitleProps> = ({ children, sx }) => (
  <Box sx={{ typography: 'h4', color: 'grey.700', fontWeight: 600, ...sx }}>{children}</Box>
);

export default PageTitle;
