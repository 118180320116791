import { eachMonthOfInterval, isBefore, parseISO, formatISO, addYears } from 'date-fns';
import { parseDateIntoPeriod } from './formatting';
import { format } from 'date-fns';
import usLocale from 'date-fns/locale/en-US';
import { SelectOptionType } from '@clinintell/types/common';

// export type DateOption = {
//   display: string; // like July20 - utils/formatting/parseDateintoPeriod
//   value: string; // like 2020-07-01T00:00:00-05:00 (ISO8601) - date-fns/formatISO
// };

export const makeMonthYearList = (start: string, end: string): SelectOptionType[] => {
  try {
    const startDate = parseISO(start);
    const endDate = parseISO(end);
    return eachMonthOfInterval({ start: startDate, end: endDate })
      .sort((a, b) => {
        return isBefore(a, b) ? 0 : -1;
      })
      .map(v => {
        return {
          label: parseDateIntoPeriod(v),
          value: formatISO(v)
        };
      });
  } catch (e) {
    console.log('error', start, end, 'did not make date range');
    return [{ label: 'dates error', value: 'error' }];
  }
};

export const getNewDate = (): string => {
  return format(new Date(), 'MM/dd/yyyy hh:mm:ss', { locale: usLocale });
};

export const getCurrentDateInUTC = (): string => {
  return format(new Date(), "yyyy-MM-dd'T'HH:mm:ss");
};

export const addYearsToDate = (date: Date, numberOfYears: number): string => {
  const result = addYears(date, numberOfYears);
  return format(result, 'MM/dd/yyyy hh:mm:ss', { locale: usLocale });
};
