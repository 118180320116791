import React from 'react';
import { AppBar, Box } from '@mui/material';
import { appBarHeight } from '@clinintell/theme/theme';

type HeaderProps = {
  children: React.ReactNode;
};

const Header = React.forwardRef<HTMLDivElement | null, HeaderProps>(({ children }, ref) => {
  return (
    <AppBar
      ref={ref}
      sx={{
        backgroundColor: 'shade.white',
        boxShadow: 'none',
        color: 'grey.500',
        width: '100%',
        zIndex: 'header'
      }}
      position="sticky"
    >
      <Box
        sx={{
          height: appBarHeight,
          display: 'flex',
          alignItems: 'center',
          paddingX: 2
        }}
      >
        {children}
      </Box>
    </AppBar>
  );
});

export default Header;
