import HideOnScrollDown from '@clinintell/components/transitions/HideOnScrollDown';
import ActionPanelContextProvider from '@clinintell/modules/actionPanelContext';
import { useMenuNavigationState } from '@clinintell/modules/store';
import { Box } from '@mui/material';
import React, { useRef, useState } from 'react';
import BreadcrumbNavigation from '@clinintell/containers/layout/sections/breadcrumbNavigation/BreadcrumbNavigation';
import Header from '@clinintell/containers/layout/sections/header/Header';
import useDefaultActiveMenuItemFromRoute from '@clinintell/containers/layout/sections/menuNavigation/logic/useDefaultActiveMenuItemFromRoute';
import Sidebar, {
  COLLAPSED_SIDEBAR_WIDTH,
  EXPANDED_SIDEBAR_WIDTH
} from '@clinintell/containers/layout/sections/sidebar/Sidebar';
import { appBarHeight } from '@clinintell/theme/theme';
import useIsMobileView from '@clinintell/utils/useIsMobileView';
import DesktopView from '@clinintell/utils/DesktopView';
import MobileView from '@clinintell/utils/MobileView';
import MobileHeader, { MOBILE_HEADER_HEIGHT } from '@clinintell/containers/layout/sections/mobileHeader/MobileHeader';
import ClinIntellLogoAlt from '@clinintell/components/icons/ClinIntellLogoAlt';
import ThreeLinesButton from '@clinintell/components/threeLinesButton/ThreeLinesButton';
import Button from '@clinintell/components/button/Button';
import useSignOut from '@clinintell/containers/authentication/useSignOut';
import useMainContentPadding from './logic/useMainContentPadding';
import MenuNavigationContainer from './sections/menuNavigation/sections/MenuNavigationContainer';
import HeaderActions from './sections/headerActions/HeaderActions';

const Layout: React.FC = ({ children }) => {
  const [isActionPanelOpen, setIsActionPanelOpen] = useState(false);
  const [mobileMenuIsOpen, setMobileMenuIsOpen] = useState(false);

  const { menuIsExpanded, menuIsTemporarilyExpanded } = useMenuNavigationState();
  useDefaultActiveMenuItemFromRoute();
  const { px, py } = useMainContentPadding();
  const isMobileView = useIsMobileView();
  const { signOut } = useSignOut();

  const mainContentRef = useRef<HTMLDivElement>(null);

  const expandMenu = menuIsExpanded || menuIsTemporarilyExpanded;

  const handleThreeLinesButtonClick = () => {
    setMobileMenuIsOpen(!mobileMenuIsOpen);
  };

  const handleMenuItemClick = () => {
    setMobileMenuIsOpen(false);
  };

  return (
    <>
      <Box display="flex" flexDirection="row">
        <DesktopView>
          <Sidebar />
        </DesktopView>
        <Box
          ref={mainContentRef}
          sx={{
            ...(!isMobileView
              ? {
                  width: `calc(100% - ${expandMenu ? EXPANDED_SIDEBAR_WIDTH : COLLAPSED_SIDEBAR_WIDTH}px)`,
                  ml: `${expandMenu ? EXPANDED_SIDEBAR_WIDTH : COLLAPSED_SIDEBAR_WIDTH}px`,
                  transition: 'margin-left 200ms ease-in'
                }
              : { width: '100%' })
          }}
        >
          <HideOnScrollDown shouldAppear>
            <>
              <DesktopView>
                <Header>
                  <>
                    <BreadcrumbNavigation />
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                        flexGrow: 1
                      }}
                    >
                      <HeaderActions />
                    </Box>
                  </>
                </Header>
              </DesktopView>
              <MobileView>
                <MobileHeader>
                  <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <ClinIntellLogoAlt sx={{ height: '20px', width: '200px' }} />
                  </Box>
                  <ThreeLinesButton
                    sx={{ position: 'absolute', top: '50%', right: '2rem', transform: 'translateY(-50%)' }}
                    isOpen={mobileMenuIsOpen}
                    onClick={handleThreeLinesButtonClick}
                  />
                </MobileHeader>
              </MobileView>
            </>
          </HideOnScrollDown>
          <MobileView>
            <HideOnScrollDown shouldAppear={mobileMenuIsOpen}>
              <Box
                sx={{
                  position: 'fixed',
                  top: '80px',
                  backgroundColor: 'grey.50',
                  width: '100%',
                  boxSizing: 'border-box',
                  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.08)',
                  px: 2,
                  py: 4,
                  zIndex: theme => theme.zIndex.header - 1
                }}
              >
                <MenuNavigationContainer onMenuItemClick={handleMenuItemClick} />
                <Box sx={{ px: 2 }}>
                  <Button label="Sign Out" sx={{ width: '100%', mt: 2 }} onClick={signOut} />
                </Box>
              </Box>
            </HideOnScrollDown>
          </MobileView>
          <ActionPanelContextProvider isActionPanelOpen={isActionPanelOpen} setIsActionPanelOpen={setIsActionPanelOpen}>
            <Box
              component="main"
              sx={{
                boxSizing: 'border-box',
                display: 'flex',
                flexDirection: 'column',
                transition: 'margin-left .3s',
                minHeight: `calc(100vh - ${isMobileView ? `${MOBILE_HEADER_HEIGHT}px` : appBarHeight})`,
                width: '100%',
                backgroundColor: 'grey.100',
                flexGrow: 1,
                px,
                py
              }}
            >
              {children}
            </Box>
          </ActionPanelContextProvider>
        </Box>
      </Box>
    </>
  );
};

export default Layout;
