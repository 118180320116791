import React, { Component, Fragment } from 'react';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { ai } from './TelemetryService';
import { withRouter, RouteComponentProps, BrowserRouter } from 'react-router-dom';

/**
 * This Component provides telemetry with Azure App Insights
 *
 * NOTE: the package '@microsoft/applicationinsights-react-js' has a HOC withAITracking that requires this to be a Class Component rather than a Functional Component
 */

interface TelemetryProviderProps {
  after: () => void;
  history: RouteComponentProps;
  instrumentationKey: string;
}
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// ts-ignore
type WithRouterProps = TelemetryProviderProps & RouteComponentProps;
class TelemetryProvider extends Component<WithRouterProps> {
  state = {
    initialized: false
  };

  componentDidMount(): void {
    const { history } = this.props;
    const { initialized } = this.state;
    const AppInsightsInstrumentationKey = this.props.instrumentationKey; // PUT YOUR KEY HERE
    if (!Boolean(initialized) && Boolean(AppInsightsInstrumentationKey) && Boolean(history)) {
      ai.initialize(AppInsightsInstrumentationKey, (history as unknown) as BrowserRouter);
      this.setState({ initialized: true });
    }

    this.props.after();
  }

  render(): JSX.Element {
    const { children } = this.props;
    return <Fragment>{children}</Fragment>;
  }
}

export default withRouter(withAITracking(ai.reactPlugin, TelemetryProvider));
