import React, { useState } from 'react';
import { Box, Card, IconButton, TextField, useTheme, Typography } from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import CheckMarkIcon from '@clinintell/components/icons/CheckMarkIcon';
import EditIcon from '@clinintell/components/icons/EditIcon';
import SettingIcon from '@clinintell/components/icons/SettingIcon';
import DeleteWidgetIcon from '@clinintell/components/icons/DeleteWidgetIcon';
import {
  ColumnView,
  getDefaultMetricTableColumns,
  getDefaultViewForMetric
} from '@clinintell/containers/metrics/typings/tableSchemas';
import {
  GraphInfo,
  QueryParams,
  TableInfo,
  WidgetType,
  WidgetSize
} from '@clinintell/containers/dashboard/widgetTypes';
import { useUser } from '@clinintell/modules/store';
import { ExtendedWidgetDefinition } from '@clinintell/modules/dashboard';
import TableWidget from '@clinintell/components/Widgets/TableWidget';
import GraphWidget from '@clinintell/components/Widgets/GraphWidget';
import ProgressWidget from '@clinintell/components/Widgets/ProgressWidget';
import SpeedometerWidget from '@clinintell/components/Widgets/SpeedometerWidget';
import { Draggable } from 'react-beautiful-dnd';
import { ChartDataSetAverageTypes } from '@clinintell/containers/metricsTimeSeries/typings/metricChartTypes';
import { Metrics as MetricTypes } from '@clinintell/modules/metricsNavigation';
import useAlert from '@clinintell/components/alert/logic/useAlert';
import PopupMenu from '@clinintell/components/popupMenu/PopupMenu';
import PopupMenuItem from '@clinintell/components/popupMenu/PopupMenuItem';
import PageTitle from '@clinintell/components/PageTitle';

interface Props {
  index: number;
  hashId: string;
  name: string;
  endpoint: string;
  title?: string;
  entity?: number;
  entityName?: string;
  division?: number;
  divisionName?: string;
  hospital?: number;
  hospitalName?: string;
  group?: number;
  groupName?: string;
  metric?: MetricTypes;
  widgetType: WidgetType;
  size: WidgetSize;
  queryString?: QueryParams;
  graphInfo?: GraphInfo;
  tableInfo?: TableInfo;
  customSettings?: QueryParams;
  deleteFn?: (name: string) => void;
  openSettings?: (name: string) => void;
  actionPanelOpen: boolean;
  isDragDropDisabled: boolean;
  editTitleFn?: (name: string, title: string) => void;
  updateWidgetFn: (updatedWidget: ExtendedWidgetDefinition) => void;
}

const EXCLUDE_WIDGETS = ['progress', 'd3speedometer', 'trainingconditions'];

const Widget: React.FC<Props> = props => {
  const theme = useTheme();
  const { role } = useUser();
  const { roleName } = role;

  const { pushAlert } = useAlert();
  const {
    index,
    hashId,
    name,
    title,
    widgetType,
    metric,
    graphInfo,
    tableInfo,
    deleteFn,
    openSettings,
    editTitleFn,
    actionPanelOpen = false,
    isDragDropDisabled
  } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [editTitle, setEditTitle] = useState(false);
  const [titleValue, setTitleValue] = useState(title ?? '');

  const defaultColumns: ColumnView = getDefaultMetricTableColumns(MetricTypes.cmi);

  const handleMenuClose = (): void => {
    setAnchorEl(null);
  };

  const triggerSystemMessage = (): void => {
    pushAlert({ message: 'System Widgets Cannot Be Modified', variant: 'error' });
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement> | React.TouchEvent<HTMLButtonElement>): void => {
    if (EXCLUDE_WIDGETS.includes(name)) {
      triggerSystemMessage();
      return;
    }
    setAnchorEl(event.currentTarget);
  };

  const toggleEditWidgetTitle = (): void => {
    if (roleName === 'provider') return;
    if (EXCLUDE_WIDGETS.includes(name)) {
      triggerSystemMessage();
      return;
    }
    setEditTitle(!editTitle);
    handleMenuClose();
  };

  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setTitleValue(event.currentTarget.value);
  };

  const handleKeyUp = (event: React.KeyboardEvent): void => {
    if (event.key === 'Enter' && titleValue.length > 0) {
      toggleEditWidgetTitle();
      editTitleFn && editTitleFn(name, titleValue);
    } else if (event.key === 'Escape') {
      toggleEditWidgetTitle();
    }
  };

  if (name === 'progress' || name === 'd3speedometer') {
    return (
      <Card className={name}>
        {title ? (
          <Box component="div" padding={1} display="flex" alignItems="center" ml={1} style={{ minHeight: 45 }}>
            <Typography variant="h5" component="span">
              {title}
            </Typography>
          </Box>
        ) : null}
        <Box>
          {widgetType.toLowerCase() === 'progress' ? <ProgressWidget {...props} /> : <SpeedometerWidget {...props} />}
        </Box>
      </Card>
    );
  }

  return (
    <Draggable draggableId={`drag-${hashId}`} index={index} isDragDisabled={isDragDropDisabled}>
      {(provided): JSX.Element => (
        <Card
          className={name}
          key={`card-${hashId}`}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          {title && (
            <Box
              key={`content-title-${hashId}`}
              component="div"
              padding={1}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                minHeight: 45
              }}
              onDoubleClick={toggleEditWidgetTitle}
            >
              {editTitle && editTitleFn ? (
                <>
                  <TextField
                    id={`edit${name}`}
                    defaultValue={title}
                    variant="standard"
                    placeholder={titleValue.length === 0 ? 'Widget Title Required' : 'Widget Title'}
                    onChange={handleTitleChange}
                    onKeyUp={handleKeyUp}
                    error={titleValue.length === 0}
                    fullWidth
                    required
                  />
                  <IconButton
                    aria-label="save"
                    onClick={(): void => {
                      toggleEditWidgetTitle();
                      editTitleFn(name, titleValue);
                    }}
                    onTouchEnd={(): void => {
                      toggleEditWidgetTitle();
                      editTitleFn(name, titleValue);
                    }}
                    disabled={titleValue.length === 0}
                    size="large"
                  >
                    <CheckMarkIcon
                      sx={
                        titleValue.length > 0 ? { color: theme.palette.green[500] } : { color: theme.palette.red[500] }
                      }
                    />
                  </IconButton>
                </>
              ) : (
                <Box sx={{ pl: 1 }}>
                  <PageTitle sx={{ typography: 'h5', color: 'primary.main' }}>{title}</PageTitle>
                </Box>
              )}
              {roleName !== 'provider' && !EXCLUDE_WIDGETS.includes(name) && (
                <IconButton
                  aria-label="settings"
                  onClick={(e): void => {
                    if (!actionPanelOpen) handleMenuOpen(e);
                  }}
                  onTouchEnd={(e): void => {
                    if (!actionPanelOpen) handleMenuOpen(e);
                  }}
                  aria-haspopup={true}
                  size="large"
                >
                  <MoreVert />
                </IconButton>
              )}
            </Box>
          )}
          <Box key={`content-${hashId}`}>
            {widgetType.toLowerCase() === 'table' ? (
              <TableWidget
                tableInfo={
                  tableInfo
                    ? tableInfo
                    : {
                        columns: defaultColumns.columns,
                        defaultSort: defaultColumns.defaultSort,
                        sortDirection: 'desc',
                        limit: 10,
                        view: getDefaultViewForMetric(metric ?? MetricTypes.cmi)
                      }
                }
                metric={metric ?? MetricTypes.cmi}
                {...props}
              />
            ) : widgetType.toLowerCase() === 'progress' ? (
              <ProgressWidget {...props} />
            ) : graphInfo && graphInfo.graphType === 'speedometer' ? (
              <SpeedometerWidget {...props} />
            ) : (
              <GraphWidget
                metric={props.metric ?? MetricTypes.docScore}
                graphInfo={
                  graphInfo
                    ? graphInfo
                    : {
                        graphType: 'line',
                        dataSetAverageTypes: ChartDataSetAverageTypes.ThreeMonth,
                        filteredDatasets: []
                      }
                }
                {...props}
              />
            )}
          </Box>
          <PopupMenu anchorEl={anchorEl} isOpen={Boolean(anchorEl)} onClose={handleMenuClose}>
            <PopupMenuItem
              label="Settings"
              onClick={() => {
                openSettings && openSettings(name);
                handleMenuClose();
              }}
              icon={<SettingIcon sx={{ marginRight: 1, fontSize: '1.1rem' }} />}
            />
            {!actionPanelOpen && (
              <PopupMenuItem
                onClick={toggleEditWidgetTitle}
                label="Rename"
                icon={<EditIcon sx={{ marginRight: 1, fontSize: '1.1rem' }} />}
              />
            )}
            {!actionPanelOpen && deleteFn && (
              <PopupMenuItem
                onClick={(): void => {
                  handleMenuClose();
                  deleteFn(name);
                }}
                label="Remove"
                icon={<DeleteWidgetIcon sx={{ marginRight: '4px', fontSize: '1.1rem' }} />}
                sx={{ pl: '12px' }}
              />
            )}
          </PopupMenu>
        </Card>
      )}
    </Draggable>
  );
};

export default Widget;
