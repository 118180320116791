// Need to improve typescript checking on AG Grid table for this
import React from 'react';
import AgGrid from '@clinintell/components/agGrid';
import { ColDef, ICellRendererParams } from 'ag-grid-community/dist/lib/main';
import { DRGMixTableRow } from '@clinintell/containers/cmiComparison/typings/cmiComparisonDtos';
import { Row } from '../agGrid/tablesTypes';
import { Box, useTheme } from '@mui/material';
import * as Cells from '@clinintell/components/agGrid/cells';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { formatToDecimalPlaces } from '@clinintell/utils/formatting';
import { sortingComparator } from '@clinintell/containers/metrics/sections/metricsAgGridTable';

type DRGImpactTableProps = {
  tableRows: DRGMixTableRow[];
  footerRow?: DRGMixTableRow;
  defaultSortDirection: 'asc' | 'desc';
  referenceLabel: string;
  comparerLabel: string;
  changeLabel: string;
};

const DRGImpactTable: React.FC<DRGImpactTableProps> = ({
  tableRows,
  footerRow,
  defaultSortDirection,
  referenceLabel,
  comparerLabel,
  changeLabel
}) => {
  const theme = useTheme();

  const commonFieldProps = {
    suppressMenu: true,
    sort: null
  };
  const baseHeaderClass = 'resizable-header column-header-style';
  const footerStyle = {
    color: theme.palette.common.black,
    background: theme.palette.blue.light5,
    fontWeight: 700,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  };
  const columnDefs: Array<ColDef> = [
    {
      ...commonFieldProps,
      field: 'drgCode',
      headerName: 'DRG Code',
      headerClass: `drgCode ${baseHeaderClass} right-header`,
      cellStyle: {
        textAlign: 'right'
      },
      headerComponentParams: {
        align: 'flex-end'
      },
      width: 150
    },
    {
      ...commonFieldProps,
      field: 'drgValue',
      headerName: 'Relative Value',
      headerClass: `drgValue ${baseHeaderClass} centred-header`,
      cellRenderer: 'cellRender',
      width: 150,
      comparator: sortingComparator
    },
    {
      ...commonFieldProps,
      field: 'description',
      headerName: 'Description',
      headerClass: `description ${baseHeaderClass}`,
      width: 450,
      cellRenderer: 'cellRender',
      comparator: sortingComparator
    },
    {
      ...commonFieldProps,
      field: 'referenceShare',
      headerName: `${referenceLabel} Share`,
      headerClass: `referenceShare ${baseHeaderClass} centred-header`,
      cellRenderer: 'cellRender',
      comparator: sortingComparator
    },
    {
      ...commonFieldProps,
      field: 'comparerShare',
      headerName: `${comparerLabel} Share`,
      headerClass: `comparerShare ${baseHeaderClass} centred-header`,
      cellRenderer: 'cellRender',
      comparator: sortingComparator
    },
    {
      ...commonFieldProps,
      field: 'changeShare',
      headerName: changeLabel,
      headerClass: `changeShare ${baseHeaderClass} centred-header`,
      cellRenderer: 'cellRender',
      comparator: sortingComparator
    },
    {
      ...commonFieldProps,
      field: 'cmiImpact',
      headerName: 'CMI Impact',
      headerClass: `cmiImpact ${baseHeaderClass} centred-header`,
      cellRenderer: 'cellRender',
      comparator: sortingComparator,
      sort: defaultSortDirection
    }
  ];

  let totalCmiImpact = 0;
  const agGridRows = tableRows.map(
    (row): Row => {
      totalCmiImpact += Number(formatToDecimalPlaces(row.cmiImpact, 3));

      return {
        drgCode: row.drgCode,
        drgValue: {
          rawValue: row.drgValue,
          component: (
            <Box
              component="span"
              fontWeight="bold"
              fontSize={theme.typography.p2.fontSize}
              fontFamily={theme.typography.fontFamily}
              color={row.drgValue === 'high' ? theme.palette.green[700] : theme.palette.red[500]}
              width="100%"
              textAlign="center"
            >
              {row.drgValue.toUpperCase()}
            </Box>
          )
        },
        description: {
          rawValue: row.description,
          component: (
            <Box
              fontSize={theme.typography.p2.fontSize}
              fontFamily={theme.typography.fontFamily}
              width="100%"
              textAlign="left"
              component="span"
            >
              {row.description}
            </Box>
          )
        },
        referenceShare: {
          rawValue: row.entity1.share,
          component: (
            <Box width="100%" textAlign="center" component="span">{`${formatToDecimalPlaces(
              row.entity1.share,
              2
            )}%`}</Box>
          )
        },
        comparerShare: {
          rawValue: row.entity2.share,
          component: (
            <Box width="100%" textAlign="center" component="span">{`${formatToDecimalPlaces(
              row.entity2.share,
              2
            )}%`}</Box>
          )
        },
        changeShare: {
          rawValue: row.shareChange,
          component: (
            <Box width="100%" justifyContent="center" display="flex" alignItems="center">
              <ArrowRightAltIcon
                style={{
                  transform: row.shareChange < 0 ? 'rotate(90deg)' : 'rotate(270deg)'
                }}
              />
              {`${formatToDecimalPlaces(Math.abs(row.shareChange), 2)}%`}
            </Box>
          )
        },
        cmiImpact: {
          rawValue: row.cmiImpact,
          component: (
            <Box width="100%" textAlign="center" component="span">
              {formatToDecimalPlaces(row.cmiImpact, 3)}
            </Box>
          )
        }
      };
    }
  );

  const getRenderCells = (params: ICellRendererParams): unknown => {
    const { key, rawValue, rowId, component } = params.value;

    return {
      [key]: Cells['DefaultCell'],
      restProps: { rawValue },
      value: rawValue,
      key,
      rowId,
      component
    };
  };

  return (
    <AgGrid
      getRenderCells={getRenderCells}
      rowData={agGridRows}
      columnDefs={columnDefs}
      rowsPerPage={10}
      tableFinalHeight={10 * 38 + (!!footerRow ? 128 : 128 - 39)}
      defaultColDef={{
        sortable: true,
        resizable: true,
        flex: 1,
        sortingOrder: ['asc', 'desc']
      }}
      footer={
        ({
          drgCode: '',
          drgValue: {
            component: <Box component="span" />
          },
          description: {
            component: <Box component="span" />
          },
          referenceShare: {
            component: <Box component="span" />
          },
          comparerShare: {
            component: <Box component="span" />
          },
          changeShare: {
            component: <Box component="span" />
          },
          cmiImpact: {
            component: (
              <Box width="100%" textAlign="center" component="span">
                {formatToDecimalPlaces(totalCmiImpact, 3)}
              </Box>
            )
          },
          rowId: 0
        } as unknown) as Row
      }
      footerStyle={footerStyle}
    />
  );
};

export default DRGImpactTable;
