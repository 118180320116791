import ClinIntellSkeleton from '@clinintell/components/ClinIntellSkeleton';
import AnalysisNavigation from '@clinintell/components/analysisSharedComponents/AnalysisNavigation';
import AnalysisSummaryRow from '@clinintell/components/analysisSharedComponents/AnalysisSummaryRow';
import ResultSummaryBox from '@clinintell/components/analysisSharedComponents/ResultSummaryBox';
import { useCMIAnalysisState } from '@clinintell/modules/store';
import { Box, useTheme } from '@mui/material';
import React from 'react';
import CMIResultSummary from './CMIResultSummary';
import DRGResultSummary from './DRGResultSummary';
import SeverityCMIResultSummary from './SeverityCMIResultSummary';
import { CMIAnalysisViews } from '@clinintell/modules/cmiAnalysisSelections';

type AnalysisSummaryProps = {
  analysisType: CMIAnalysisViews;
  onAnalysisChange: (type: CMIAnalysisViews) => void;
};

const AnalysisSummary: React.FC<AnalysisSummaryProps> = ({ analysisType, onAnalysisChange }) => {
  const theme = useTheme();

  const {
    isLoadingSummaryRecords,
    noSummaryData,
    noBestFit,
    cmiSummary,
    drgMixSummary,
    severityCMISummary,
    ceCMISummary,
    drgAnalysis
  } = useCMIAnalysisState();

  const noDataComponent = (
    <Box textAlign="center" component="p" color={theme.palette.red[500]}>
      No data to analyze based on the time period selected
    </Box>
  );
  let resultSummaryComponent;
  if (isLoadingSummaryRecords) {
    resultSummaryComponent = <ClinIntellSkeleton width="100%" height="8.75rem" variant="rectangular" />;
  } else if (noSummaryData || (cmiSummary && (!cmiSummary.current || !cmiSummary.historical)) || noBestFit) {
    resultSummaryComponent = noDataComponent;
  } else {
    switch (analysisType) {
      case 'cmi':
        resultSummaryComponent =
          cmiSummary && drgMixSummary && severityCMISummary ? (
            <CMIResultSummary
              cmiSummary={cmiSummary}
              drgMixSummary={drgMixSummary}
              severityCMISummary={severityCMISummary}
            />
          ) : null;

        break;
      case 'severity':
        resultSummaryComponent =
          cmiSummary && ceCMISummary && severityCMISummary ? (
            <SeverityCMIResultSummary
              cmiSummary={cmiSummary}
              ceCMISummary={ceCMISummary}
              severityCMISummary={severityCMISummary}
            />
          ) : null;

        break;
      case 'drgmix':
        resultSummaryComponent =
          drgMixSummary && drgAnalysis ? (
            <DRGResultSummary drgAnalysis={drgAnalysis} drgMixSummary={drgMixSummary} />
          ) : null;
        break;
      default:
        throw new Error('Invalid analysis type.');
    }
  }

  return (
    <AnalysisSummaryRow
      leftComponent={<AnalysisNavigation analysisType={analysisType} onAnalysisChange={onAnalysisChange} />}
      rightComponent={<ResultSummaryBox>{resultSummaryComponent}</ResultSummaryBox>}
    />
  );
};

export default AnalysisSummary;
