import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Box, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import useAuth0WidgetStyles from '@clinintell/theme/auth0WidgetTheme';
import { useClinIntellAuthWidget } from '@clinintell/containers/authentication/useClinIntellAuthWidget';
import { useAppConfig } from '@clinintell/modules/store';
import { LoginAPI } from '@clinintell/utils/api';
import { Redirect } from 'react-router-dom';
import useAppendClinIntellLogo from './useAppendClinIntellLogo';

const useStyles = makeStyles(theme => ({
  widgetContainer: {
    width: '25rem',
    [theme.breakpoints.down('md')]: {
      width: '90vw'
    }
  }
}));

interface Props {
  guid: string;
}

type SignUpPostData = {
  email: string;
};

const createAuth0LockConstructorOptions = (
  email: string | undefined,
  environment: string,
  color: string
): Auth0LockConstructorOptions => ({
  container: 'signInContainer',
  initialScreen: 'signUp',
  loginAfterSignUp: true,
  avatar: null,
  prefill: {
    email
  },
  allowLogin: false,
  theme: {
    primaryColor: color
  },
  languageDictionary: {
    signUpTitle: 'Please set your password below',
    signUpSubmitLabel: 'Submit'
  },
  auth: {
    redirectUrl: environment
  }
});

const SignUp: React.FC<Props> = ({ guid }) => {
  const [userCanSignUp, setUserCanSignUp] = useState<boolean>();
  const [email, setEmail] = useState<string>();

  const { widgetContainer } = useStyles();
  const { auth0WidgetStyles } = useAuth0WidgetStyles();
  const { environment } = useAppConfig();

  const theme = useTheme();

  const options = useMemo(() => createAuth0LockConstructorOptions(email, environment, theme.palette.blue.main), [
    email,
    environment,
    theme.palette.blue.main
  ]);

  const clinintellAuthWidget = useClinIntellAuthWidget(options, userCanSignUp === true);
  const containerRef = useRef<Element>(null);
  useAppendClinIntellLogo(containerRef.current, clinintellAuthWidget);

  useEffect(() => {
    // Fetch user information baesd on their GUID. Determine if they have signed up already or not
    if (userCanSignUp === undefined) {
      const fetchUserSignUpStatus = async (): Promise<void> => {
        const { data, status } = await LoginAPI.post<SignUpPostData, { id: string }>({
          endpoint: `signup`,
          data: {
            id: guid
          }
        });

        if (status === 200 && data) {
          setEmail(data.email);
          setUserCanSignUp(true);
        } else {
          setUserCanSignUp(false);
        }
      };

      fetchUserSignUpStatus();
    }
  }, [guid, userCanSignUp]);

  useEffect(() => {
    if (clinintellAuthWidget && userCanSignUp) {
      clinintellAuthWidget.on('signup ready', () => {
        const nodes = document.getElementsByClassName('auth0-lock-input-email');
        nodes[0].setAttribute('style', 'display: none');
      });

      clinintellAuthWidget.show();
    }
  }, [clinintellAuthWidget, userCanSignUp]);

  // If the user cannot sign up (either they already have or the GUID is invalid) then redirect to the login page
  if (userCanSignUp === false) {
    return <Redirect to="/" />;
  }

  // Box does in fact accept a ref, but Typescript check fails. This was fixed in Material-UI v5.
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return <Box ref={containerRef} className={`${widgetContainer} ${auth0WidgetStyles}`} id="signInContainer" />;
};

export default SignUp;
