import React, { useState } from 'react';
import { Box, Checkbox, FormControlLabel, useTheme } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import {
  ColumnName,
  TableSelectableColumn,
  TableSelectableColumnConfig,
  getTableSelectableColumns,
  getMetricViews,
  getDefaultViewForMetric
} from '@clinintell/containers/metrics/typings/tableSchemas';
import WidgetJSON, { WidgetSize } from '@clinintell/containers/dashboard/widgetTypes';
import { Metrics, Views } from '@clinintell/modules/metricsNavigation';
import { ChartDataSetType } from '@clinintell/containers/metricsTimeSeries/typings/metricChartTypes';
import { Metrics as MetricTypes } from '@clinintell/modules/metricsNavigation';
import { InputLabel, Select, SelectOptionType } from '@clinintell/components/index';
import { SelectMetric, SelectCondition } from '@clinintell/containers/common/index';
import useChartStyles from '@clinintell/containers/metricsTimeSeries/logic/useChartStyles';
import { ConditionTypes } from '@clinintell/modules/conditions';
const hash = require('object-hash');

const CustomFormControlLabel = withStyles(theme => ({
  root: {
    '& span.MuiTypography-body1': {
      fontSize: '1.1rem'
    }
  }
}))(FormControlLabel);

const SizeOptions = [
  { label: 'Small', value: 1, code: 's' },
  { label: 'Medium', value: 2, code: 'm' },
  { label: 'Large', value: 3, code: 'xl' }
];

const getSizeOptionByLabel = (label: string): SelectOptionType => {
  const modifiedLabel = label === 'xlarge' || label === 'l' || label === 'large' ? 'xl' : label;
  const ix = SizeOptions.findIndex(s => s.label === modifiedLabel || s.code === modifiedLabel);
  return ix !== -1 ? SizeOptions[ix] : SizeOptions[2];
};

interface Props {
  widget: WidgetJSON;
  onUpdateWidget: (widget: WidgetJSON) => void;
}

const EditWidget: React.FC<Props> = props => {
  const { widget, onUpdateWidget } = props;
  const theme = useTheme();

  const { widgetType, metric, queryString, graphInfo, tableInfo, size } = widget;
  const hashId = hash(widget);

  const [selectedMetric, setSelectedMetric] = useState(metric ?? MetricTypes.cmi);
  const [conditionValue, setConditionValue] = useState<number | undefined | null>(
    Number(queryString?.conditionId ?? -1)
  );
  const [conditionLabel, setConditionLabel] = useState(queryString?.conditionName);
  const [selectedSizeOption, setSelectedSizeOption] = useState(size);
  const [selectedView, setSelectedView] = useState<Views | null>(tableInfo ? (tableInfo.view as Views) : null);
  let viewSelectOptions = getMetricViews(selectedMetric).map(view => ({
    value: view.id,
    label: view.label
  }));

  const [selectedViewOption, setSelectedViewOption] = useState(selectedView);
  const [selectedGraphFilters, setSelectedGraphFilters] = useState<Set<ChartDataSetType>>(
    graphInfo && graphInfo.filteredDatasets ? new Set(graphInfo.filteredDatasets) : new Set([])
  );

  const [selectedTableColumns, setSelectedTableColumns] = useState<TableSelectableColumnConfig | null>(
    tableInfo ? getTableSelectableColumns(selectedMetric, selectedView, tableInfo.columns as ColumnName[]) : null
  );
  const [selectedTableSort, setSelectedTableSort] = useState<ColumnName>(
    tableInfo ? (tableInfo.defaultSort as ColumnName) : 'opportunityRW'
  );

  const baseGraphDataPoints: ChartDataSetType[] = [
    'AvgImpactDual',
    'AvgImpactSingle',
    'CESecondary',
    'Secondary',
    'SecondaryDual',
    'SecondarySingle',
    'Total'
  ];
  const baseGraphDataPointSet: Set<ChartDataSetType> = new Set(baseGraphDataPoints);

  const defaultGraphDataPoints: ChartDataSetType[] = baseGraphDataPoints.filter(
    data => data !== 'AvgImpactDual' && data !== 'SecondaryDual'
  );

  const handleTableColumnUpdate = (column: TableSelectableColumn): void => {
    if (selectedTableColumns === null) return;
    const cloneData = { ...selectedTableColumns };
    const ix = cloneData.columns.findIndex((col: TableSelectableColumn) => col.column === column.column);
    if (ix !== -1) {
      const cloneColumn = cloneData.columns[ix];
      cloneColumn.isVisible = !cloneColumn.isVisible;
      if (selectedTableSort === cloneColumn.column && !cloneColumn.isVisible) {
        setSelectedTableSort(selectedTableColumns.last().column);
        if (widget.tableInfo) {
          widget.tableInfo.defaultSort = selectedTableColumns.last().column;
        }
      }
    }
    setSelectedTableColumns(cloneData);
    if (widget.tableInfo) {
      widget.tableInfo.columns = cloneData.columns.filter(column => column.isVisible).map(column => column.column);
    }
    onUpdateWidget(widget);
  };

  const handleGraphDataPointUpdate = (index: number, datapoint: ChartDataSetType): void => {
    const cloneData = new Set(selectedGraphFilters);
    if (cloneData.has(datapoint)) {
      cloneData.delete(datapoint);
    } else {
      if (baseGraphDataPointSet.size - selectedGraphFilters.size === 1) return;
      cloneData.add(datapoint);
    }
    setSelectedGraphFilters(new Set(cloneData));
    if (widget.graphInfo && widget.graphInfo.filteredDatasets) {
      widget.graphInfo.filteredDatasets = [...cloneData];
    }
    onUpdateWidget(widget);
  };

  const handleMetricChange = (metric: keyof typeof MetricTypes): void => {
    const tableConfig = getTableSelectableColumns(metric, null);
    const tableView = getDefaultViewForMetric(metric);
    viewSelectOptions = getMetricViews(metric).map(view => ({
      value: view.id,
      label: view.label
    }));
    // setSelectedViewOption(viewSelectOptions.find((option: SelectOptionType) => option.value === tableView));
    setSelectedViewOption(tableView as Views);
    setSelectedView(tableView as Views);
    setSelectedTableColumns(tableConfig);
    setSelectedTableSort(tableConfig.defaultSort);
    setSelectedMetric(
      metric === MetricTypes.allConditions
        ? widget.name === 'top10UnderDocumentedClinicalConditionTable'
          ? MetricTypes.targetedConditions
          : MetricTypes.condition
        : (metric as MetricTypes)
    );
    widget.metric = metric as MetricTypes;
    if (widget.tableInfo && widget.widgetType === 'table') {
      widget.tableInfo = {
        ...widget.tableInfo,
        columns: tableConfig.columns.map(column => column.column),
        defaultSort: tableConfig.defaultSort,
        view: tableView as Views
      };
    }
    if (widget.graphInfo && widget.widgetType === 'graph' && metric === 'condition') {
      setSelectedGraphFilters(
        new Set(['AvgImpactDual', 'AvgImpactSingle', 'SecondaryDual', 'SecondarySingle', 'Total'])
      );
      widget.graphInfo.filteredDatasets = [
        'AvgImpactDual',
        'AvgImpactSingle',
        'SecondaryDual',
        'SecondarySingle',
        'Total'
      ];
    }
    if (metric !== 'condition') {
      setConditionValue(null);
      if (widget.queryString) {
        widget.queryString.conditionId = null;
        widget.queryString.conditionName = '';
      }
      if (widget.graphInfo) {
        widget.graphInfo.filteredDatasets = [];
      }
    }
    onUpdateWidget(widget);
  };

  const widgetMetricValue =
    selectedMetric === MetricTypes.allConditions
      ? widget.name === 'top10UnderDocumentedClinicalConditionTable'
        ? MetricTypes.targetedConditions
        : MetricTypes.condition
      : selectedMetric;

  const excludeMetrics = [MetricTypes.allConditions, MetricTypes.hcupcmi];
  if (widgetType !== 'table') {
    excludeMetrics.push(MetricTypes.targetedConditions);
  }

  const metricDropDown = (
    <InputLabel position="top" label="Metric">
      <SelectMetric
        id={`${hashId}-selectMetric`}
        onChange={handleMetricChange}
        selectedValue={widgetMetricValue}
        excludeMetrics={excludeMetrics}
        showConditionsOptions={false}
      />
    </InputLabel>
  );

  const viewDropDown = (
    <InputLabel position="top" label="View">
      <Select
        id="viewSelect"
        value={selectedViewOption as string}
        options={viewSelectOptions}
        onChange={(value): void => {
          setSelectedViewOption(value as Views);
          const tableConfig = getTableSelectableColumns(selectedMetric, value as Views);
          setSelectedTableColumns(tableConfig);
          setSelectedTableSort(tableConfig.defaultSort);
          setSelectedView(value as Views);
          if (widget.tableInfo) {
            widget.tableInfo = {
              ...widget.tableInfo,
              columns: tableConfig.columns
                .filter(
                  c => c.column !== 'mdcName' && c.column !== 'conditionTypeId' && c.column !== 'isTargetCondition'
                )
                .map(column => column.column),
              defaultSort: tableConfig.defaultSort,
              view: value as string
            };
          }
          onUpdateWidget(widget);
        }}
        listWidth={220}
      />
    </InputLabel>
  );

  const conditionDropDown = (
    <InputLabel label="Select Condition" position="top">
      <SelectCondition
        id={`${hashId}-selectCondition`}
        onChange={(id: number, name: string): void => {
          setConditionValue(id);
          setConditionLabel(name);
          if (widget.queryString) {
            widget.queryString.conditionId = id;
            widget.queryString.conditionName = name;
          }
          onUpdateWidget(widget);
        }}
        selectedValue={conditionValue ? { value: conditionValue, label: conditionLabel ?? '' } : undefined}
        filterConditionTypes={[ConditionTypes.DRG]}
      />
    </InputLabel>
  );

  const tableSortDropDown = (
    <InputLabel label="Table Sort" position="top">
      <Select
        options={
          selectedTableColumns
            ? selectedTableColumns.columns.map((column: TableSelectableColumn) => {
                return { value: column.column, label: column.title };
              })
            : []
        }
        value={selectedTableSort as string}
        onChange={(value): void => {
          setSelectedTableSort(value as ColumnName);
          if (widget.tableInfo) {
            widget.tableInfo.defaultSort = value as string;
          }
          onUpdateWidget(widget);
        }}
      />
    </InputLabel>
  );

  const chartStyles = useChartStyles(metric as keyof typeof Metrics);

  return (
    <Box
      key={`filter-${hashId}`}
      sx={{
        padding: theme.spacing(2),
        textAlign: 'left',
        '& button.dateRangePicker': {
          height: 38
        }
      }}
      height={metric === MetricTypes.condition || metric === MetricTypes.allConditions ? 475 : 400}
    >
      <Box display="flex" flexDirection="column" justifyContent="flex-start" alignItems="flex-start">
        <Box my={1} width="100%">
          {metricDropDown}
        </Box>
        {(metric === MetricTypes.condition ||
          (metric === MetricTypes.allConditions && widget.name !== 'top10UnderDocumentedClinicalConditionTable')) && (
          <Box my={1} width="100%">
            {conditionDropDown}
          </Box>
        )}
        <Box mt={1} display="flex" flexDirection="row" flexWrap="wrap" width="100%">
          <Box my={1} mr={1}>
            <InputLabel label="Widget Size" position="top">
              <Select
                id={`${hashId}-widgetSizeDropdown`}
                options={SizeOptions}
                value={Number(getSizeOptionByLabel(selectedSizeOption).value)}
                onChange={(value): void => {
                  setSelectedSizeOption(value as WidgetSize);
                  const selectedOption = SizeOptions.find(option => option.value === value);
                  if (selectedOption) {
                    widget.size = selectedOption.code as WidgetSize;
                  } else {
                    widget.size = value as WidgetSize;
                  }
                  onUpdateWidget(widget);
                }}
              />
            </InputLabel>
          </Box>
          {widgetType.toLowerCase() === 'table' ? (
            <Box display="flex" flexDirection="row">
              <Box
                my={1}
                ml={theme.breakpoints.down('sm') ? 0 : theme.spacing(1)}
                mr={theme.breakpoints.down('sm') ? 0 : theme.spacing(1)}
              >
                {viewDropDown}
              </Box>
              <Box my={1} mx={1}>
                {tableSortDropDown}
              </Box>
            </Box>
          ) : null}
        </Box>
        <Box my={1} width="100%" textAlign="left">
          {widgetType === 'graph' &&
          selectedMetric === 'condition' &&
          (conditionValue !== null || conditionValue !== undefined) ? (
            <InputLabel position="top" label="Legends">
              <Box>
                {defaultGraphDataPoints.map((dataPoint: ChartDataSetType, ix: number) => (
                  <CustomFormControlLabel
                    key={ix}
                    control={
                      <Checkbox
                        value={dataPoint}
                        color="primary"
                        checked={!selectedGraphFilters.has(dataPoint)}
                        onChange={(): void => handleGraphDataPointUpdate(ix, dataPoint)}
                      />
                    }
                    label={chartStyles[dataPoint].label}
                  />
                ))}
              </Box>
            </InputLabel>
          ) : widgetType === 'table' ? (
            <>
              <InputLabel position="top" label="Columns (hover over * column for more info)">
                <Box display="flex" flexWrap="wrap">
                  {selectedTableColumns &&
                    selectedTableColumns.columns
                      .filter(
                        col =>
                          col.column !== 'name' &&
                          col.column !== 'isTargetCondition' &&
                          col.column !== 'mdcName' &&
                          col.column !== 'conditionTypeId'
                      )
                      .map((col: TableSelectableColumn, ix: number) => (
                        <CustomFormControlLabel
                          key={ix}
                          control={
                            <Checkbox
                              value={col.column}
                              color="primary"
                              checked={col.isVisible}
                              onChange={(): void => handleTableColumnUpdate(col)}
                              disabled={col.column === 'name'}
                            />
                          }
                          label={col.title}
                          title={
                            col.column === 'name'
                              ? 'System | Hospital | Specialty Group | Physician | Condition'
                              : col.column === 'current'
                              ? 'Current Period Date Range'
                              : col.column === 'historical'
                              ? 'Historical Period Date Range'
                              : undefined
                          }
                        />
                      ))}
                </Box>
              </InputLabel>
            </>
          ) : null}
        </Box>
      </Box>
    </Box>
  );
};

export default EditWidget;
