import { calculateImpactOnCMI } from '@clinintell/containers/cmiAnalysis/logic/calculateImpactOnCMI';
import ResultSummaryTable from '@clinintell/components/analysisSharedComponents/ResultSummaryTable';
import { AnalysisRecord } from '@clinintell/modules/cmiComparison';
import { formatToDecimalPlaces } from '@clinintell/utils/formatting';
import React from 'react';

type CMIComparisonTableProps = {
  cmiSummary: AnalysisRecord;
  drgMixSummary: AnalysisRecord;
  pooledSeverityCMI: AnalysisRecord;
  baseLabel: string;
  comparisonLabel: string;
};

const CMIComparisonTable: React.FC<CMIComparisonTableProps> = ({
  cmiSummary,
  drgMixSummary,
  pooledSeverityCMI,
  baseLabel,
  comparisonLabel
}) => {
  const cmiSummaryDifference =
    cmiSummary.base && cmiSummary.compare
      ? Number(formatToDecimalPlaces(cmiSummary.base, 2)) - Number(formatToDecimalPlaces(cmiSummary.compare, 2))
      : '-';

  const drgMixSummaryDifference =
    drgMixSummary.base && drgMixSummary.compare
      ? Number(formatToDecimalPlaces(drgMixSummary.base, 2)) - Number(formatToDecimalPlaces(drgMixSummary.compare, 2))
      : '-';

  const pooledSeverityCMISummaryDifference =
    pooledSeverityCMI.base && pooledSeverityCMI.compare
      ? Number(formatToDecimalPlaces(pooledSeverityCMI.base, 2)) -
        Number(formatToDecimalPlaces(pooledSeverityCMI.compare, 2))
      : '-';

  const drgMixImpactOnCMI =
    cmiSummaryDifference !== '-' && drgMixSummaryDifference !== '-' && pooledSeverityCMISummaryDifference !== '-'
      ? calculateImpactOnCMI(cmiSummaryDifference, drgMixSummaryDifference, pooledSeverityCMISummaryDifference)
      : 0;

  const pooledSeverityCMIImpactOnCMI =
    cmiSummaryDifference !== '-' && pooledSeverityCMISummaryDifference !== '-' && drgMixSummaryDifference !== '-'
      ? calculateImpactOnCMI(cmiSummaryDifference, pooledSeverityCMISummaryDifference, drgMixSummaryDifference)
      : 0;

  return (
    <ResultSummaryTable
      rows={[
        {
          columns: [
            {
              value: '',
              width: 2,
              align: 'center',
              bold: true,
              key: 'header1'
            },
            {
              value: baseLabel,
              width: 4,
              align: 'center',
              bold: true,
              key: 'headerHistorical'
            },
            {
              value: comparisonLabel,
              width: 4,
              align: 'center',
              bold: true,
              key: 'headerCurrent'
            },
            {
              value: 'Difference',
              width: 2,
              align: 'center',
              bold: true,
              key: 'headerChange'
            }
          ],
          key: 'headerRow'
        },
        {
          divider: 'solid',
          key: 'headerDivider'
        },
        {
          columns: [
            {
              value: 'CMI',
              width: 2,
              align: 'left',
              bold: true,
              key: 'cmiLabel'
            },
            {
              value: cmiSummary.base ? formatToDecimalPlaces(cmiSummary.base, 2) : '-',
              width: 4,
              align: 'center',
              type: 'number',
              key: 'cmiHist'
            },
            {
              value: cmiSummary.compare ? formatToDecimalPlaces(cmiSummary.compare, 2) : '-',
              width: 4,
              align: 'center',
              type: 'number',
              key: 'cmiCurr'
            },
            {
              value: cmiSummaryDifference !== '-' ? formatToDecimalPlaces(cmiSummaryDifference, 2) : '-',
              width: 2,
              align: 'left',
              offsetCenter: true,
              type: 'number',
              key: 'cmiDiff'
            }
          ],
          key: 'cmiRow'
        },
        {
          divider: 'solid',
          key: 'cmiDivider'
        },
        {
          columns: [
            {
              value: 'DRG Mix',
              width: 2,
              align: 'left',
              bold: true,
              key: 'drgLabel'
            },
            {
              value: drgMixSummary.base ? formatToDecimalPlaces(drgMixSummary.base, 2) : '-',
              width: 4,
              align: 'center',
              type: 'number',
              key: 'drgHist'
            },
            {
              value: drgMixSummary.compare ? formatToDecimalPlaces(drgMixSummary.compare, 2) : '-',
              width: 4,
              align: 'center',
              type: 'number',
              key: 'drgCurr'
            },
            {
              value:
                drgMixSummaryDifference !== '-'
                  ? `${formatToDecimalPlaces(drgMixSummaryDifference, 2)} ${
                      drgMixImpactOnCMI !== 0 ? `   (${formatToDecimalPlaces(drgMixImpactOnCMI * 100, 0)}%)` : ''
                    }`
                  : '-',
              width: 2,
              align: 'left',
              offsetCenter: true,
              type: 'number',
              key: 'drgDiff'
            }
          ],
          key: 'drgRow'
        },
        {
          columns: [
            {
              value: 'Severity CMI',
              width: 2,
              align: 'left',
              bold: true,
              key: 'sevCmiLabel'
            },
            {
              value: pooledSeverityCMI.base !== null ? formatToDecimalPlaces(pooledSeverityCMI.base, 2) : '-',
              width: 4,
              align: 'center',
              type: 'number',
              key: 'sevCmiHist'
            },
            {
              value: pooledSeverityCMI.compare !== null ? formatToDecimalPlaces(pooledSeverityCMI.compare, 2) : '-',
              width: 4,
              align: 'center',
              type: 'number',
              key: 'sevCmiCurr'
            },
            {
              value:
                pooledSeverityCMISummaryDifference !== '-'
                  ? `${formatToDecimalPlaces(pooledSeverityCMISummaryDifference, 2)} ${
                      pooledSeverityCMIImpactOnCMI !== 0
                        ? `   (${formatToDecimalPlaces(pooledSeverityCMIImpactOnCMI * 100, 0)}%)`
                        : ''
                    }`
                  : '-',
              width: 2,
              align: 'left',
              type: 'number',
              offsetCenter: true,
              key: 'sevCmiDiff'
            }
          ],
          key: 'sevCmiRow'
        }
      ]}
    />
  );
};

export default CMIComparisonTable;
