import { userCan } from '@clinintell/containers/authentication/rules';
import EntityNavigation from '@clinintell/containers/entityNavigation/EntityNavigation';
import { NodeDetails } from '@clinintell/containers/treeSelector/logic/TreeContext';
import { useHospitals, useUser } from '@clinintell/modules/store';
import { Box } from '@mui/material';
import React from 'react';

type EntitySelectorProps = {
  myEntityName: string;
  myEntity: number | undefined;
  handleEntitySelection: (entity: number, nodeDetails: NodeDetails) => void;
  treeHeight?: number;
};

const EntitySelector: React.FC<EntitySelectorProps> = ({
  myEntityName,
  myEntity,
  handleEntitySelection,
  treeHeight
}) => {
  const user = useUser();
  const hospitals = useHospitals();
  const userCanSelectProvidersInTree = userCan(user, ['reportCmiAnalysisViewProviders']);

  return (
    <>
      <Box sx={{ typography: 'p1', fontWeight: 500 }} component="span">
        My Entity:
      </Box>
      {myEntityName ? ` ${myEntityName}` : ''}
      <Box mt={1} mb={4}>
        <EntityNavigation
          defaultExpandedNodes={[user.rootId.toString()]}
          isTreeVisible={true}
          entity={myEntity || user.rootId}
          withSearch={hospitals.totalCount > 1}
          maxNodeType={userCanSelectProvidersInTree ? 'Provider' : 'SpecialtyGroup'}
          minCharactersToSearch={3}
          maxCharactersToSearch={50}
          hideButtonColor="primary"
          onEntitySelected={handleEntitySelection}
          onEntitySearchComplete={handleEntitySelection}
          id="myEntitySelector"
          cannotHideTree
          height={treeHeight}
          type="client"
        />
      </Box>
    </>
  );
};

export default EntitySelector;
