import { userCan } from '@clinintell/containers/authentication/rules';
import { MenuItem } from '@clinintell/modules/menuNavigation';
import { useMenuNavigationState, useUser } from '@clinintell/modules/store';
import useIsMobileView from '@clinintell/utils/useIsMobileView';
import { Box } from '@mui/material';
import React from 'react';

type MenuNavigationProps = {
  renderMenuNavigationLink: (props: MenuItem) => React.ReactElement | null;
};

const MenuNavigation: React.FC<MenuNavigationProps> = ({ renderMenuNavigationLink }) => {
  const { menuItems } = useMenuNavigationState();
  const user = useUser();

  const isMobileView = useIsMobileView();
  const allowedMenuItems = menuItems.filter(menuItem => {
    if (!userCan(user, menuItem.permissions)) {
      return false;
    }

    return menuItem.hideIfDesktopView && !isMobileView ? false : true;
  });

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        pl: 3,
        pr: 2,
        '& > div:not(:last-child)': {
          transition: 'margin-bottom 400ms ease-in-out'
        }
      }}
    >
      {allowedMenuItems.map(menuItem => renderMenuNavigationLink(menuItem))}
    </Box>
  );
};

export default MenuNavigation;
