import React from 'react';
import { NodeTypeIds, OrgTreeTypes, TreeNode } from '@clinintell/modules/orgTree';
import NodeLabel from './sections/NodeLabel';
import Tree, { TreeProps } from './sections/Tree';
import { TreeProviderProps, useTreeDispatch, useTreeState, TreeProvider } from './logic/TreeContext';
import { makeStyles } from '@mui/styles';
import { TreeView } from '@mui/lab';
import { SxProps, Theme } from '@mui/material';
import { ChevronRight, ExpandMore } from '@mui/icons-material';
import ClinIntellSkeleton from '@clinintell/components/ClinIntellSkeleton';

const useStyles = makeStyles(theme => ({
  root: {
    overflow: 'auto',
    flexGrow: 1,
    marginTop: theme.spacing(1),
    marginBottom: 0,
    '& ul': {
      paddingLeft: theme.spacing(3),
      marginBottom: theme.spacing(2)
    }
  }
}));

export type AdditionalTreeSelectorProps = {
  type: OrgTreeTypes;
};

type TreeSelectorExtraProps = {
  nodeIdLoading: number | null;
  treeStyles?: React.CSSProperties;
  sx?: SxProps<Theme>;
};

export type TreeSelectorProps = Omit<TreeProps, 'renderTreeNode'> & TreeSelectorExtraProps;

export const TreeSelector = (props: TreeSelectorProps): JSX.Element => {
  const { selectNode, expandNode, toggleNodes } = useTreeDispatch();
  const { expandedNodes, selectedNode, rootNode } = useTreeState();

  const { root } = useStyles();

  const renderTreeNode = (item: TreeNode): React.ReactNode => {
    const childrenAreViewable = item.nodeTypeId < NodeTypeIds[props.maxNodeType];

    return (
      <NodeLabel
        isActive={selectedNode === item.id}
        isLoading={props.nodeIdLoading === item.id}
        onClick={selectNode}
        onLoadClick={expandNode}
        item={item}
        overrideNoIndentation={
          item.isLeaf && item.children.filter(node => !node.isLeaf).length === 0 && childrenAreViewable
        }
        overrideNoChildren={!childrenAreViewable}
      />
    );
  };

  if (!rootNode) {
    return <ClinIntellSkeleton variant="rectangular" height="20rem" width="100%" />;
  }

  return (
    <TreeView
      className={`${root} id-${props.domId}`}
      defaultCollapseIcon={<ExpandMore sx={{ stroke: theme => theme.palette.grey[500], strokeWidth: '0.25px' }} />}
      defaultExpandIcon={<ChevronRight sx={{ stroke: theme => theme.palette.grey[500], strokeWidth: '0.25px' }} />}
      onNodeToggle={(event, nodeIds): void => toggleNodes(event, nodeIds)}
      expanded={expandedNodes}
      style={props.treeStyles}
      {...props}
    >
      <Tree {...props} renderTreeNode={renderTreeNode} />
    </TreeView>
  );
};

export type TreeSelectorWithContextProps = TreeSelectorProps & TreeProviderProps & { sx?: SxProps<Theme> };

const TreeSelectorWithContext: React.FC<TreeSelectorWithContextProps> = ({
  onNodeSelected,
  onNodeToggled,
  rootNode,
  defaultExpandedNodes,
  selectedNode,
  nodeIdLoading,
  maxNodeType,
  domId,
  sx
}) => {
  return (
    <TreeProvider
      onNodeSelected={onNodeSelected}
      onNodeToggled={onNodeToggled}
      rootNode={rootNode}
      defaultExpandedNodes={defaultExpandedNodes}
      selectedNode={selectedNode}
      maxNodeType={maxNodeType}
    >
      <TreeSelector
        domId={domId}
        selectedNode={selectedNode}
        maxNodeType={maxNodeType}
        nodeIdLoading={nodeIdLoading}
        sx={sx}
      />
    </TreeProvider>
  );
};

export default TreeSelectorWithContext;
