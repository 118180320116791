import React from 'react';
import { Box, Link, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useHistory } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  root: {
    '&': {
      width: '100%',
      height: '90vh',
      display: 'flex',
      alignItems: 'center',
      [theme.breakpoints.down('md')]: {
        height: 'fit-content'
      }
    }
  },
  container: {
    width: 'fit-content',
    maxWidth: '40%',
    display: 'grid',
    margin: '0 auto',
    gridTemplateRows: '.5fr .15fr .25fr .10fr',
    rowGap: '1rem',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      maxWidth: '100%',
      padding: '1rem'
    }
  },
  imgContainer: {
    width: '100%',
    '& img': {
      display: 'flex',
      margin: '0 auto',
      [theme.breakpoints.down('md')]: {
        width: '50%'
      }
    }
  },
  errorHeader: {
    display: 'flex',
    margin: '0 auto',
    color: theme.palette.grey[700],
    fontWeight: 'bold',
    lineHeight: '40px'
  },
  errorMsg: {
    display: 'flex',
    textAlign: 'center',
    color: theme.palette.grey[500]
  },
  btnsContainer: {
    display: 'flex',
    justifyContent: 'center',
    padding: '1rem',
    columnGap: '1rem'
  },
  supportBtn: {
    width: 'fit-content',
    display: 'flex',
    fontWeight: 'bold',
    padding: '.5rem 1rem .5rem 1rem',
    background: 'transparent !important',
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '8px',
    textDecoration: 'none',
    '& span': {
      color: theme.palette.primary.main,
      margin: 0
    }
  },
  secordaryBtn: {
    width: 'fit-content',
    display: 'flex',
    fontWeight: 'bold',
    padding: '.5rem 1rem .5rem 1rem',
    background: theme.palette.primary.main,
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: '8px',
    textDecoration: 'none',
    '& span': {
      color: theme.palette.shade.white,
      margin: 0
    }
  }
}));

interface BtnProps {
  stylingClass: string;
  label: string;
  hrefLink?: string;
  target?: string;
  eventHandler?: () => void;
}

const ErrorBtn: React.FC<BtnProps> = ({ stylingClass, label, hrefLink = '#', target, eventHandler }) => {
  return (
    <Link className={stylingClass} href={hrefLink} onClick={eventHandler} onTouchEnd={eventHandler} target={target}>
      <Typography variant="p1">{label}</Typography>
    </Link>
  );
};

interface Props {
  imgSrc: string;
  errorCode?: string;
  errorTitle: string;
  errorTxtMsg?: string;
  showBackBtn?: boolean;
  showRefresh?: boolean;
  resetError?: () => void;
}

const RouteErrorPage: React.FC<Props> = ({
  imgSrc,
  errorCode,
  errorTitle,
  errorTxtMsg,
  showBackBtn = false,
  showRefresh = false,
  resetError
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const { root, container, imgContainer, errorHeader, errorMsg, btnsContainer, supportBtn, secordaryBtn } = useStyles(
    isMobile
  );
  const history = useHistory();

  const routeChange = () => {
    history.push(`/`);
    history.go(0);
  };

  return (
    <Box className={root}>
      <Box className={container}>
        <Box className={imgContainer}>
          <img src={imgSrc} alt={errorCode} />
        </Box>
        <Typography className={errorHeader} variant="h4">
          {errorTitle}
        </Typography>
        <Typography className={errorMsg} variant="p1">
          {errorTxtMsg}
        </Typography>
        <Box className={btnsContainer}>
          {showBackBtn || showRefresh ? (
            <ErrorBtn
              stylingClass={secordaryBtn}
              label={showBackBtn ? 'Back' : 'Refresh'}
              eventHandler={(): void => (showBackBtn ? window.history.back() : routeChange())}
            />
          ) : (
            ''
          )}
          <ErrorBtn
            stylingClass={supportBtn}
            label="Contact Support"
            hrefLink="https://help.clinintell.com/hc/en-us/requests/new"
            eventHandler={resetError}
            target="_blank"
          />
        </Box>
      </Box>
    </Box>
  );
};

export default RouteErrorPage;
