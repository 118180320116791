import { createBlob } from '@clinintell/containers/metrics/sections/Download';
import { OrgTreeTypes } from '@clinintell/modules/orgTree';
import { ApplicationAPI } from '@clinintell/utils/api';
import { formatDateForAPI } from '@clinintell/utils/formatting';
import { useState } from 'react';

type UseExcelDownloadProps = {
  entityId: number;
  historicalMinDate?: Date;
  historicalMaxDate?: Date;
  currentMinDate: Date;
  currentMaxDate: Date;
  analysisType: 'analysis' | 'comparison';
  compareToEntityId?: number;
  filename: string;
  orgTreeType?: OrgTreeTypes;
};

type UseExcelDownloadOutput = {
  isDownloading: boolean;
  downloadExcelOutput: (args: UseExcelDownloadProps) => Promise<void>;
};

const useExcelDownload = (): UseExcelDownloadOutput => {
  const [isDownloading, setIsDownloading] = useState(false);

  const fetchExcel = async ({
    entityId,
    historicalMinDate,
    historicalMaxDate,
    currentMaxDate,
    currentMinDate,
    analysisType,
    compareToEntityId,
    filename,
    orgTreeType = 'client'
  }: UseExcelDownloadProps): Promise<void> => {
    setIsDownloading(true);

    const metricEndpoint = analysisType === 'analysis' ? 'patientmixanalysis' : 'drgmixcomparison';
    const compareToQuery = compareToEntityId !== undefined ? `compareTo=${compareToEntityId}` : '';
    const dateQuery =
      historicalMinDate && historicalMaxDate
        ? `historicalStart=${formatDateForAPI(historicalMinDate)}&historicalEnd=${formatDateForAPI(
            historicalMaxDate
          )}&currentStart=${formatDateForAPI(currentMinDate)}&currentEnd=${formatDateForAPI(currentMaxDate)}`
        : `startDate=${formatDateForAPI(currentMinDate)}&endDate=${formatDateForAPI(currentMaxDate)}`;

    try {
      const endpoint =
        `${
          orgTreeType === 'saf' ? 'saf/' : ''
        }download/metrics/${metricEndpoint}/${entityId}?${compareToQuery}&${dateQuery}&csvcolumns=DrgCode,` +
        `DrgValue,Description,HistoricalDischarges,CurrentDischarges,HistoricalShare,CurrentShare,ShareChange,` +
        `CmiImpact&sortPositiveColumn=CmiImpact&sortPositiveAscending=true&sortNegativeColumn=CmiImpact&` +
        `sortNegativeAscending=false&SortInsignificantAscending=true&SortInsignifcantColumn=CmiImpact`;

      const result = await ApplicationAPI.download(endpoint, 'xlsx');
      createBlob(result.data, `${filename}.xlsx`);
    } catch (error) {
      throw new Error(`Error downloading to Excel - ${error}`);
    } finally {
      setIsDownloading(false);
    }
  };

  return {
    isDownloading,
    downloadExcelOutput: fetchExcel
  };
};

export default useExcelDownload;
