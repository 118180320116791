import React, { useEffect, useRef, useState } from 'react';
import { HospitalsDTO } from '@clinintell/modules/hospitals';
import { useGetAPICAll } from '@clinintell/utils/useGetAPICall';
import { ApplicationAPI } from '@clinintell/utils/api';
import { Box, Typography, useTheme } from '@mui/material';
import InputLabel from '@clinintell/components/InputLabel';
import AsyncSelect from 'react-select/async';
import { Select, SelectOptionType } from '../../components/index';
import useDropdownPositioning from '@clinintell/components/dropdown/logic/useDropdownPositioning';

export type HospitalReturnType = {
  itemCount: number;
  pageNumber: number;
  totalCount: number;
  hospitals: {
    id: number;
    hospitalName: string;
    trainingUsers: boolean;
  }[];
};

type SelectOptionTrainingType = SelectOptionType & { trainingUsers?: boolean };
type Options = SelectOptionTrainingType[];

type Props = {
  labelPosition?: 'top' | 'side' | 'none';
  title?: string;
  defaultValue?: number;
  defaultLabel?: string;
  onHospitalChangeFn: (value: number | null, display?: string) => void;
  onSelectLoaded?: (value: boolean) => void;
  width?: number | string;
  allHospitalsOptionLabel?: string;
  allHospitalsOptionValue?: number;
  trainingOnly?: boolean;
};

const SelectHospital: React.FC<Props> = ({
  labelPosition = 'top',
  title,
  defaultValue,
  defaultLabel,
  onHospitalChangeFn,
  onSelectLoaded,
  width = 400,
  allHospitalsOptionLabel,
  allHospitalsOptionValue,
  trainingOnly = false
}) => {
  const theme = useTheme();
  const [hospitalOptions, setHospitalOptions] = useState<Options | []>([]);
  const [hospitalTotalCount, setHospitalTotalCount] = useState(0);
  const [optionSelected, setOptionSelected] = useState(false);
  const [selectedHospitalOption, setSelectedHospitalOption] = useState<SelectOptionType | null>(null);

  const selectionChange = (option: SelectOptionType): void => {
    const { value, label } = option;
    if (hospitalOptions.findIndex(hospital => Number(hospital.value) === Number(value)) === -1) {
      setHospitalOptions([...hospitalOptions, option]);
      setHospitalTotalCount(hospitalTotalCount + 1);
    }
    setSelectedHospitalOption(option);
    onHospitalChangeFn(value ? (value as number) : null, label);
  };

  const { output: hospitalOutput, isLoading } = useGetAPICAll<HospitalReturnType>({
    endpoint: `hospitals?itemCount=50`,
    isWaiting: false
  });

  if (hospitalOutput && hospitalOutput.error) {
    throw new Error(hospitalOutput.error);
  }

  useEffect(() => {
    if (hospitalOptions.length === 1 && !optionSelected) {
      onHospitalChangeFn(hospitalOptions[0].value as number, hospitalOptions[0].label);
      setSelectedHospitalOption(hospitalOptions[0]);
      setOptionSelected(true);
    }
  }, [hospitalOptions, onHospitalChangeFn, optionSelected]);

  useEffect(() => {
    if (!hospitalOutput || !hospitalOutput.data) {
      return;
    }
    if (hospitalOutput && hospitalOutput.data && hospitalOutput.data.hospitals) {
      let dropDownList: Options | [] = hospitalOutput.data.hospitals
        .map(hospital => {
          return {
            value: hospital.id,
            label: hospital.hospitalName,
            trainingUsers: hospital.trainingUsers
          };
        })
        .sort((a, b) => {
          return a.label.localeCompare(b.label, 'en', { ignorePunctuation: true });
        });

      if (trainingOnly) {
        dropDownList = dropDownList.filter(hospital => hospital.trainingUsers);
      }

      let maxOptionsFit = 50;
      if (allHospitalsOptionValue !== undefined) {
        maxOptionsFit -= 1;
        dropDownList.unshift({
          value: allHospitalsOptionValue,
          label: allHospitalsOptionLabel || '',
          trainingUsers: trainingOnly
        });
      }

      if (
        hospitalOutput.data.totalCount > maxOptionsFit &&
        defaultValue &&
        defaultLabel &&
        dropDownList.findIndex(h => h.value === defaultValue) === -1
      ) {
        dropDownList.push({ value: defaultValue, label: defaultLabel });
      }

      setHospitalOptions(dropDownList);
      setHospitalTotalCount(hospitalOutput.data.totalCount);

      if (
        onSelectLoaded &&
        defaultValue &&
        dropDownList.length > 0 &&
        dropDownList.find(hospital => hospital.value === defaultValue)
      ) {
        onSelectLoaded(true);
      }
    }
  }, [
    hospitalOutput,
    isLoading,
    onSelectLoaded,
    defaultValue,
    onHospitalChangeFn,
    defaultLabel,
    allHospitalsOptionValue,
    allHospitalsOptionLabel,
    trainingOnly
  ]);

  const promiseHospitalOptions = (searchTerm: string): Promise<SelectOptionType[]> => {
    if (searchTerm.length < 3) return new Promise(resolve => resolve(hospitalOptions));
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(
          ApplicationAPI.get<HospitalsDTO>({ endpoint: `hospitals?search=${searchTerm}` })
            .then(response => response.data)
            .then(data => {
              if (!data) return [];
              return data.hospitals.map(hospital => {
                return { value: hospital.id, label: hospital.hospitalName };
              });
            })
        );
      }, 1000);
    });
  };

  const ref = useRef<HTMLDivElement>();
  const { placement, setPlacementHandler, maxHeight } = useDropdownPositioning(ref);
  const handleMenuOpen = () => {
    setPlacementHandler();
  };

  const fontStyles = {
    fontFamily: theme.typography.fontFamily,
    fontSize: 13,
    color: theme.palette.grey[900]
  };
  const optionStyles = {
    paddingX: 2,
    paddingY: 1.5,
    color: theme.palette.grey[900],
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: theme.palette.grey[50]
    }
  };

  const defaultOption =
    defaultValue && defaultValue > 1
      ? hospitalOptions.find(hospital => hospital.value === defaultValue)
      : selectedHospitalOption;

  if (hospitalTotalCount > 50) {
    return (
      <InputLabel position={labelPosition} label="Hospital">
        <AsyncSelect
          cacheOptions
          isSearchable={true}
          closeMenuOnSelect={true}
          value={defaultOption}
          defaultOptions={hospitalOptions}
          loadOptions={promiseHospitalOptions}
          onChange={(value): void => {
            selectionChange(value as SelectOptionTrainingType);
          }}
          placeholder="Select"
          onMenuOpen={handleMenuOpen}
          components={{
            IndicatorSeparator: null
          }}
          menuPortalTarget={document.body}
          menuPlacement={placement}
          styles={{
            container: (provided, _state) => ({
              ...provided,
              minWidth: width
            }),
            indicatorsContainer: (provided, _state) => ({
              ...provided,
              '&:hover': {
                backgroundColor: theme.palette.grey[50],
                borderRadius: 25,
                width: 32,
                height: 32,
                margin: 2,
                '& div': {
                  padding: 6
                }
              }
            }),
            dropdownIndicator: (provided, state) => ({
              ...provided,
              '& svg': {
                width: '1em',
                height: '1em',
                fontSize: '1.5em',
                transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                transition: 'transform 250ms',
                strokeWidth: '0.25px'
              }
            }),
            control: (provided, _state) => ({
              ...provided,
              ...fontStyles,
              borderRadius: 10,
              textAlign: 'left',
              borderColor: theme.palette.grey[200],
              boxShadow: `0 0 0 0 ${theme.palette.grey[200]}`,
              '&:hover': {
                borderColor: theme.palette.grey[200]
              }
            }),
            menu: (provided, _state) => ({
              ...provided,
              ...fontStyles,
              width: '100%',
              borderRadius: 10,
              overflow: 'hidden',
              zIndex: theme.zIndex.modal + 3
            }),
            menuList: (provided, _state) => ({
              ...provided,
              maxHeight
            }),
            menuPortal: (provided, _state) => ({
              ...provided,
              zIndex: theme.zIndex.modal + 3
            }),
            option: (provided, state) => ({
              ...provided,
              ...optionStyles,
              fontWeight: state.isSelected ? 600 : 'normal',
              borderBottom: `${state.data.withLineDivider ? '1px' : 0} solid ${theme.palette.grey[200]}`
            })
          }}
        />
      </InputLabel>
    );
  }

  return (
    <>
      <Box
        display="flex"
        flexDirection={labelPosition === 'top' ? 'column' : 'row'}
        alignItems={labelPosition === 'top' ? 'flex-start' : 'center'}
        width={width}
      >
        {labelPosition !== 'none' && <Typography variant="p2">{title ?? 'Hospital'}</Typography>}
        <Box
          id="SelectBoxId"
          width="100%"
          style={labelPosition === 'none' ? undefined : labelPosition === 'top' ? { marginTop: 8 } : { marginLeft: 8 }}
        >
          <Select
            width={width}
            isSearchable={true}
            options={hospitalOptions}
            value={defaultOption ? defaultOption.value : Number(defaultValue)}
            onChange={(value, label): void => {
              selectionChange({ value: Number(value), label });
            }}
          />
        </Box>
      </Box>
    </>
  );
};

export default React.memo(SelectHospital);
