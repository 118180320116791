import { useAppConfig, useAuthenticationStatus } from '@clinintell/modules/store';
import useIsZendeskTenant from '@clinintell/utils/useIsZendeskTenant';

interface SignOut {
  signOut: () => void;
}
const useSignOut = (): SignOut => {
  const { environment } = useAppConfig();
  const { auth0Client } = useAuthenticationStatus();
  const isZendeskTenant = useIsZendeskTenant();

  return {
    signOut: (): void => {
      if (isZendeskTenant) {
        // Logout of Zendesk help center
        zE('webWidget', 'logout');
      }

      // Method found in auth0 community to manually delete certain cookies that keep an auth0 session alive even after signout
      const auth0Cookies = document.cookie.split('; ');
      auth0Cookies.forEach((cookie: string) => {
        if (
          cookie.startsWith('auth0.ssodata') ||
          cookie.startsWith('com.auth0.auth') ||
          cookie.startsWith('co%2Fverifier')
        ) {
          const hostnames = window.location.hostname.split('.');
          while (hostnames.length > 0) {
            const cookieBase =
              cookie.split(';')[0].split('=')[0] +
              '=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=' +
              hostnames.join('.') +
              ' ;path=';

            const pathnames = window.location.pathname.split('/');
            document.cookie = cookieBase + '/';
            while (pathnames.length > 0) {
              document.cookie = cookieBase + pathnames.join('/');
              pathnames.pop();
            }

            hostnames.shift();
          }
        }
      });

      auth0Client?.logout({
        returnTo: environment
      });
    }
  };
};

export default useSignOut;
