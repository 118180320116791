import React, { lazy, Suspense } from 'react';
import { Switch, useRouteMatch, Route, Redirect } from 'react-router-dom';
import Can from '@clinintell/components/Can';
import RouteErrorPage from '@clinintell/containers/errorHandlers/RouteErrorPage';
import LoadingScreen from '@clinintell/components/LoadingScreen';
import Reports from '@clinintell/containers/reports/Reports';
import { EntityReportRouteProps, TrainingProgressRouteProps } from '@clinintell/containers/reports/types/routeProps';
import { reportGeneralPermissions } from '@clinintell/modules/menuNavigation';
import ReportContainer from '@clinintell/containers/reports/sections/reportContainer/ReportContainer';
import HospitalAndGroupFilter from '@clinintell/containers/reports/sections/hospitalAndGroupFilter/HospitalAndGroupFilter';
import TrainingProgressFilterRoute from '@clinintell/containers/reports/sections/trainingProgressFilter/sections/TrainingProgressFilterRoute';
import ReportDownloadProvider from '@clinintell/containers/trainingStatusReport/context/ReportDownloadContext';
import { ReportsContextProvider } from '@clinintell/containers/reports/sections/context/ReportContext';

const GroupReport = lazy(() =>
  import(/* webpackChunkName: 'reports/group' */ '@clinintell/containers/groupReport/GroupReport')
);

const ProviderReport = lazy(() =>
  import(/* webpackChunkName: 'reports/provider' */ '@clinintell/containers/providerReport/ProviderReport')
);

const TrainingStatusReport = lazy(() =>
  import(
    /* webpackChunkName: 'reports/trainingprogress' */ '@clinintell/containers/trainingStatusReport/TrainingStatusReport'
  )
);

const ReportRoutes = (): JSX.Element => {
  const { path } = useRouteMatch();

  return (
    <Suspense fallback={<LoadingScreen loadingIndicatorSize={250} />}>
      <Switch>
        <Route
          path={`${path}/group`}
          render={({ location }) => {
            if (!location.state) {
              return <Redirect to={path} />;
            }

            const { groupId, hospitalId } = location.state as EntityReportRouteProps;

            return (
              <Can
                permissions={['reportGroupReportAdminView', 'reportGroupReportProviderView']}
                yes={(): JSX.Element => (
                  <ReportContainer<EntityReportRouteProps> title="Group Report" defaultState={{ hospitalId, groupId }}>
                    <ReportContainer.Filters<EntityReportRouteProps>>
                      {({ reportFilterState, setReportFilterState }) => {
                        return (
                          <HospitalAndGroupFilter
                            {...reportFilterState}
                            onHospitalChange={hospitalId =>
                              setReportFilterState(state => ({
                                ...state,
                                hospitalId: hospitalId as number,
                                groupId: state.hospitalId !== hospitalId ? -1 : state.groupId
                              }))
                            }
                            onPhysicianGroupChange={physicianGroupId =>
                              setReportFilterState(state => ({ ...state, groupId: physicianGroupId as number }))
                            }
                            width={300}
                          />
                        );
                      }}
                    </ReportContainer.Filters>
                    <ReportContainer.Content<EntityReportRouteProps>>
                      {({ reportFilterState }) => {
                        const { groupId } = reportFilterState as EntityReportRouteProps;
                        if (groupId === -1) return <></>;
                        return <GroupReport {...reportFilterState} />;
                      }}
                    </ReportContainer.Content>
                  </ReportContainer>
                )}
                no={(): JSX.Element => <RouteErrorPage error={new Error('403')} />}
              />
            );
          }}
        />
        <Route
          path={`${path}/provider`}
          render={({ location }) => {
            if (!location.state) {
              return <Redirect to={path} />;
            }

            const { groupId, hospitalId } = location.state as EntityReportRouteProps;

            return (
              <Can
                permissions={['reportProviderReportView']}
                yes={(): JSX.Element => (
                  <ReportContainer<EntityReportRouteProps>
                    title="Provider Report"
                    defaultState={{ hospitalId, groupId }}
                  >
                    <ReportContainer.Filters<EntityReportRouteProps>>
                      {({ reportFilterState, setReportFilterState }) => {
                        return (
                          <HospitalAndGroupFilter
                            {...reportFilterState}
                            onHospitalChange={hospitalId =>
                              setReportFilterState(state => ({
                                ...state,
                                hospitalId: hospitalId as number,
                                groupId: state.hospitalId !== hospitalId ? -1 : state.groupId
                              }))
                            }
                            onPhysicianGroupChange={physicianGroupId =>
                              setReportFilterState(state => ({ ...state, groupId: physicianGroupId as number }))
                            }
                            width={300}
                          />
                        );
                      }}
                    </ReportContainer.Filters>
                    <ReportContainer.Content<EntityReportRouteProps>>
                      {({ reportFilterState }) => {
                        const { groupId } = reportFilterState as EntityReportRouteProps;
                        if (groupId === -1) return <></>;
                        return <ProviderReport {...reportFilterState} />;
                      }}
                    </ReportContainer.Content>
                  </ReportContainer>
                )}
                no={(): JSX.Element => <RouteErrorPage error={new Error('403')} />}
              />
            );
          }}
        />
        <Route
          path={`${path}/trainingprogress`}
          render={({ location }): JSX.Element => (
            <Can
              permissions={['reportTrainingReportProgressView']}
              yes={(): JSX.Element => {
                if (!location.state) {
                  return <Redirect to={path} />;
                }

                const { hospital, group, physician } = location.state as TrainingProgressRouteProps;

                return (
                  <ReportDownloadProvider>
                    <ReportContainer<TrainingProgressRouteProps>
                      title="Training Progress"
                      defaultState={{ hospital, group, physician }}
                    >
                      <ReportContainer.Filters<TrainingProgressRouteProps>>
                        {({ reportFilterState, setReportFilterState }) => {
                          return (
                            <TrainingProgressFilterRoute
                              reportFilterState={reportFilterState}
                              setReportFilterState={setReportFilterState}
                            />
                          );
                        }}
                      </ReportContainer.Filters>
                      <ReportContainer.Content<TrainingProgressRouteProps>>
                        {({ reportFilterState }) => {
                          return <TrainingStatusReport {...reportFilterState} />;
                        }}
                      </ReportContainer.Content>
                    </ReportContainer>
                  </ReportDownloadProvider>
                );
              }}
              no={(): JSX.Element => <RouteErrorPage error={new Error('403')} />}
            />
          )}
        />
        <Route
          path={path}
          render={(): JSX.Element => (
            <Can
              permissions={reportGeneralPermissions}
              yes={(): JSX.Element => {
                return (
                  <ReportsContextProvider>
                    <Reports />
                  </ReportsContextProvider>
                );
              }}
              no={(): JSX.Element => <RouteErrorPage error={new Error('401')} />}
            />
          )}
        />
        <Route render={(): JSX.Element => <RouteErrorPage error={new Error('404')} />} />
      </Switch>
    </Suspense>
  );
};

export default ReportRoutes;
