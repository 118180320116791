import React, { lazy, Suspense } from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import Can from '@clinintell/components/Can';
import LoadingScreen from '@clinintell/components/LoadingScreen';
import RouteErrorPage from '@clinintell/containers/errorHandlers/RouteErrorPage';

const TrainingPreview = lazy(() =>
  import(/* webpackChunkName: 'trainingPreview' */ '@clinintell/containers/trainingPreview/TrainingPreview')
);

const TrainingConfiguration = lazy(() =>
  import(
    /* webpackChunkName: 'trainingConfiguration' */ '@clinintell/containers/trainingConfiguration/TrainingConfiguration'
  )
);

const TrainingTest = lazy(() =>
  import(/* webpackChunkName: 'trainingTest' */ '@clinintell/containers/trainingTest/TrainingTest')
);

const ConditionTrainingContent = lazy(() =>
  import(
    /* webpackChunkName: 'trainingConditionContent' */ '@clinintell/containers/trainingContent/ConditionTrainingContent'
  )
);

const TrainingContentApproval = lazy(() =>
  import(
    /* webpackChunkName: 'trainingConditionContent' */ '@clinintell/containers/trainingContentApproval/contentApproval'
  )
);

const TrainingContentPocketCard = lazy(() =>
  import(
    /* webpackChunkName: 'trainingConditionContent' */ '@clinintell/containers/trainingContentApproval/trainingContentPocketCard'
  )
);

const TrainingRoutes = (): JSX.Element => {
  const { url } = useRouteMatch();

  return (
    <Suspense fallback={<LoadingScreen loadingIndicatorSize={250} />}>
      <Switch>
        <Route path={`${url}/preview`}>
          <Can
            permissions={['trainingPreview']}
            yes={(): JSX.Element => {
              return <TrainingPreview />;
            }}
            no={(): JSX.Element => <RouteErrorPage error={new Error('401')} />}
          />
        </Route>
        <Route path={`${url}/assign`}>
          <Can
            permissions={['trainingEdit', 'trainingContentView']}
            yes={(): JSX.Element => {
              return <TrainingConfiguration />;
            }}
            no={(): JSX.Element => <RouteErrorPage error={new Error('401')} />}
          />
        </Route>
        <Route path={`${url}/contentApproval`}>
          <Can
            permissions={['trainingContentApproval']}
            yes={(): JSX.Element => {
              return <TrainingContentApproval />;
            }}
            no={(): JSX.Element => <RouteErrorPage error={new Error('401')} />}
          />
        </Route>
        <Route
          path={`${url}/test/:id`}
          render={({ match }): JSX.Element => {
            return (
              <Can
                permissions={['trainingTake', 'trainingPreview']}
                yes={(): JSX.Element => {
                  return <TrainingTest orgHierarchyId={match.params.id} />;
                }}
                no={(): JSX.Element => <RouteErrorPage error={new Error('401')} />}
              />
            );
          }}
        />
        <Route
          path={`${url}/:groupOrgId/condition/:id`}
          render={({ match }): JSX.Element => (
            <Can
              permissions={['trainingTake', 'trainingPreview']}
              yes={(): JSX.Element => {
                return <ConditionTrainingContent groupOrgId={match.params.groupOrgId} conditionId={match.params.id} />;
              }}
              no={(): JSX.Element => <RouteErrorPage error={new Error('401')} />}
            />
          )}
        ></Route>
        <Route
          path={`${url}/conditionContent/:conditionId/:contentConditionId`}
          render={({ match }): JSX.Element => (
            <Can
              permissions={['trainingTake', 'trainingPreview']}
              yes={(): JSX.Element => {
                return (
                  <TrainingContentPocketCard
                    conditionId={match.params.conditionId}
                    contentConditionId={match.params.contentConditionId}
                  />
                );
              }}
              no={(): JSX.Element => <RouteErrorPage error={new Error('401')} />}
            />
          )}
        ></Route>
        <Route render={(): JSX.Element => <RouteErrorPage error={new Error('404')} />} />
      </Switch>
    </Suspense>
  );
};

export default TrainingRoutes;
