import React, { useEffect, useMemo, useRef } from 'react';
import { useTheme, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import useAuth0WidgetStyles from '@clinintell/theme/auth0WidgetTheme';
import { useClinIntellAuthWidget } from '@clinintell/containers/authentication/useClinIntellAuthWidget';
import { useAppConfig, useAuth0Config } from '@clinintell/modules/store';
import { validateEmail } from '@clinintell/utils/validators';
import { LoginAPI } from '@clinintell/utils/api';
import useAppendClinIntellLogo from './useAppendClinIntellLogo';

const useStyles = makeStyles(theme => ({
  widgetContainer: {
    [theme.breakpoints.down('md')]: {
      width: '90vw'
    }
  }
}));

const createAuth0LockConstructorOptions = (
  environment: string,
  audienceId: string,
  color: string
): Auth0LockConstructorOptions => ({
  allowAutocomplete: true,
  container: 'signInContainer',
  initialScreen: 'login',
  rememberLastLogin: true,
  avatar: null,
  allowSignUp: false,
  allowForgotPassword: true,
  usernameStyle: 'email',
  auth: {
    redirectUrl: `${environment}/callback`,
    redirect: true,
    responseType: 'token id_token',
    audience: audienceId,
    autoParseHash: true
  },
  theme: {
    primaryColor: color
  },
  languageDictionary: {
    error: {
      login: {
        'lock.invalid_email_password': 'The email or password you entered is incorrect.',
        'lock.invalid_username_password': 'The email or password you entered is incorrect.'
      }
    },
    loginSubmitLabel: 'Sign In',
    title: 'Sign In',
    passwordInputPlaceholder: 'Password',
    emailInputPlaceholder: 'Email',
    usernameInputPlaceholder: 'Corporate Username',
    usernameOrEmailInputPlaceholder: 'Corporate Username',
    enterpriseActiveLoginInstructions: 'Please enter your corporate credentials below.',
    forgotPasswordAction: 'Forgot your password?',
    forgotPasswordTitle: 'Forgot Your Password?',
    forgotPasswordInstructions: 'Please enter your email below to receive your reset password instructions',
    forgotPasswordSubmitLabel: 'Reset Password'
  }
});

const SignIn: React.FC = () => {
  const { widgetContainer } = useStyles();
  const theme = useTheme();
  const { auth0WidgetStyles } = useAuth0WidgetStyles();
  const { environment } = useAppConfig();
  const { audienceId } = useAuth0Config();

  const options = useMemo(() => createAuth0LockConstructorOptions(environment, audienceId, theme.palette.blue.main), [
    environment,
    audienceId,
    theme.palette.blue.main
  ]);

  const clinintellAuthWidget = useClinIntellAuthWidget(options, true);

  const containerRef = useRef<Element>(null);
  useAppendClinIntellLogo(containerRef.current, clinintellAuthWidget);

  useEffect(() => {
    if (clinintellAuthWidget) {
      clinintellAuthWidget.on('signin ready', () => {
        // Remove hijacked forgot password onclick event as this form is shared between signin and forgot password.
        const form = document.getElementsByClassName('auth0-lock-widget')[0] as HTMLFormElement;
        form.onclick = null;
      });

      clinintellAuthWidget.on('forgot_password ready', async () => {
        const globalMessage = document.getElementsByClassName('auth0-global-message')[0] as HTMLDivElement;
        if (globalMessage) {
          globalMessage.parentNode?.removeChild(globalMessage);
        }

        const form = document.getElementsByClassName('auth0-lock-widget')[0] as HTMLFormElement;
        // Prevent default form submission. Perform validation ourselves and then call forgot password API endpoint.
        form.onclick = async (event): Promise<void> => {
          // Don't want other input clicks to trigger this
          if (
            (event.target as Element).className !== 'auth0-label-submit' &&
            (event.target as Element).className !== 'auth0-lock-submit'
          ) {
            return;
          }

          event.preventDefault();

          // Get email field
          const email = document.getElementById('1-email') as HTMLInputElement;
          if (!email) {
            throw new Error('Cannot find input with id 1-email.');
          }

          // Validate email
          let errorMessage = '';
          if (!email.value) {
            errorMessage = `Email can't be blank`;
          } else if (!validateEmail(email.value)) {
            errorMessage = 'Email is invalid';
          }

          const parentElement = email.parentElement as HTMLElement;
          const containerElement = parentElement.parentElement as HTMLElement;

          if (errorMessage) {
            parentElement.style.borderColor = '#D12323';

            // Prevents duplicate error hints from being added to the email input
            if (containerElement.childElementCount < 2) {
              // Add hint div element
              const parentDiv = document.createElement('div');
              parentDiv.id = 'auth0-lock-error-msg-email';
              parentDiv.className = 'auth0-lock-error-msg';

              const hintDiv = document.createElement('div');
              hintDiv.className = 'auth0-lock-error-invalid-hint';
              hintDiv.innerText = errorMessage;

              parentDiv.appendChild(hintDiv);
              containerElement.appendChild(parentDiv);
              // Update hint element
            } else {
              (containerElement.children[1] as HTMLDivElement).innerText = errorMessage;
            }

            return;
          }

          // Show loading indicator
          const reactRoot = document.querySelectorAll('[data-reactroot]')[0];
          reactRoot.classList.add('auth0-lock-mode-loading');

          if (containerElement.childElementCount > 1) {
            containerElement.removeChild(containerElement.children[1]);
          }

          parentElement.style.borderColor = '#f1f1f1';

          // Perform API call now
          await LoginAPI.post({
            endpoint: 'users/passresreq',
            data: {
              Email: email.value
            }
          });

          reactRoot.classList.remove('auth0-lock-mode-loading');

          // Send user back to sign in by emulating the back button click
          const backButton = document.getElementsByClassName('auth0-lock-back-button')[0] as HTMLButtonElement;
          backButton.click();

          const messageWrapper = document.getElementsByClassName(
            'auth0-lock-content-body-wrapper'
          )[0] as HTMLDivElement;

          const messageBackground = document.createElement('div');
          messageBackground.className = 'auth0-global-message auth0-global-message-success';

          const messageAnimateSpan = document.createElement('span');
          messageAnimateSpan.className = 'animated fadeInUp';

          const message = document.createElement('span');
          message.innerText =
            'If you have a valid account with us, you should receive a password reset link shortly. Please contact us at support@clinintell.com if you need further assistance.';

          messageAnimateSpan.appendChild(message);
          messageBackground.appendChild(messageAnimateSpan);
          messageWrapper.insertBefore(messageBackground, messageWrapper.firstChild);
        };
      });

      clinintellAuthWidget.show();
    }
  }, [clinintellAuthWidget, theme.palette.blue.main, environment]);

  return (
    // Box does in fact accept a ref, but Typescript check fails. This was fixed in Material-UI v5.
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <Box ref={containerRef} width="25rem" className={`${widgetContainer} ${auth0WidgetStyles}`} id="signInContainer" />
  );
};

export default SignIn;
