import useIsMobileView from '@clinintell/utils/useIsMobileView';
import { useLocation } from 'react-router';

type UseMainPaddingContentOutput = {
  py: number;
  px: number;
};

const useMainPaddingContent = (): UseMainPaddingContentOutput => {
  const { pathname } = useLocation();
  const isMobileView = useIsMobileView();

  if (pathname === '/resources') {
    return { py: 0, px: 0 };
  }
  // TODO -- don't love doing this, maybe when we refresh resources we can look at following the same padding guidelines as the rest of the app
  return {
    py: 1.5,
    px: isMobileView ? 0.5 : 1.5
  };
};

export default useMainPaddingContent;
