import React, { FC, ReactElement } from 'react';
import { Box, useTheme, useMediaQuery } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ClinIntellLogo from '@clinintell/assets/images/logo.svg';
import LoginBackgroundSvg from '@clinintell/assets/images/loginbk_v2.svg';

const useStyles = makeStyles(theme => ({
  container: {
    background: `url(${LoginBackgroundSvg}) no-repeat center`,
    backgroundSize: 'cover',
    [theme.breakpoints.down('md')]: {
      backgroundImage: 'linear-gradient(-180deg, #385EAB 0%, #365DAB 30%, #729ACA 45%, #95B3D7 80%)',
      justifyContent: 'flex-start',
      minHeight: '100vh'
    },
    [theme.breakpoints.up('md')]: {
      height: '100vh'
    }
  },
  logo: {
    height: '3.2rem',
    width: '10rem',
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(4)
    }
  }
}));

interface Props {
  children: ReactElement;
}

const AuthenticationTemplate: FC<Props> = ({ children }) => {
  const { container, logo } = useStyles();
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box
      width="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      className={container}
    >
      {isMobileView ? <img className={logo} alt="ClinIntell logo" src={ClinIntellLogo} /> : null}
      <Box position="relative">{children}</Box>
    </Box>
  );
};

export default AuthenticationTemplate;
