import { SeverityLevel } from '@microsoft/applicationinsights-web';
import React, { ErrorInfo } from 'react';
import RouteErrorPage from './RouteErrorPage';
import { appInsights } from '../../App';

type ErrorBoundaryProps = {
  children: JSX.Element;
};

type ErrorBoundaryState = {
  hasError: boolean;
  error: Error | null;
};

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  state: ErrorBoundaryState = {
    hasError: false,
    error: null
  };

  monitorLocationChange: number | null = null;

  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.resetErrorState = this.resetErrorState.bind(this);
  }

  resetErrorState(): void {
    if (this.state.hasError) {
      this.setState({
        hasError: false,
        error: null
      });
    }
  }

  componentDidUpdate(): void {
    if (this.state.hasError) {
      let pathname = window.location.pathname;
      this.monitorLocationChange = window.setInterval(() => {
        if (window.location.pathname !== pathname) {
          this.resetErrorState();
          pathname = window.location.pathname;
        }
      }, 100);
    } else if (this.monitorLocationChange !== null) {
      window.clearInterval(this.monitorLocationChange);
    }
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return { hasError: true, error };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    console.error('Error Boundary - Component Stack: ', `${errorInfo}`);
    appInsights.trackException({ error: error, severityLevel: SeverityLevel.Error });
  }

  render(): JSX.Element {
    if (this.state.hasError && this.state.error) {
      return <RouteErrorPage error={this.state.error} resetError={this.resetErrorState} />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
