import { Box } from '@mui/material';
import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';

type UserSettingsNavLinkProps = {
  link: string;
  label: string;
  onClick: () => void;
};

const UserSettingsNavLink: React.FC<UserSettingsNavLinkProps> = ({ link, label, onClick }) => {
  const { pathname } = useLocation();

  const handleNavLinkClick = () => {
    onClick();
  };

  return (
    <NavLink onClick={handleNavLinkClick} to={link} style={{ textDecoration: 'none' }}>
      <Box
        sx={{
          py: 1.5,
          color: 'grey.900',
          textDecoration: 'none',
          fontWeight: pathname === link ? 600 : 400,
          transition: 'background-color 200ms linear',
          '&:hover': {
            cursor: 'pointer',
            backgroundColor: 'grey.50'
          }
        }}
      >
        <Box component="span" sx={{ typography: 'p1', px: 1 }}>
          {label}
        </Box>
      </Box>
    </NavLink>
  );
};

export default UserSettingsNavLink;
