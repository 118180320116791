import Tooltip from '@clinintell/components/tooltip/Tooltip';
import { Box } from '@mui/material';
import React, { useRef, useState } from 'react';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import IconButton from '@clinintell/components/iconButton/IconButton';
import Popup from '@clinintell/components/popup/Popup';
import UserSettings from '../userSettings/UserSettings';
import { useMenuNavigationDispatch, useMenuNavigationState } from '@clinintell/modules/store';
import { setActiveMenuItem } from '@clinintell/modules/menuNavigation';
import useIsZendeskTenant from '@clinintell/utils/useIsZendeskTenant';

const HeaderActions = () => {
  const [isUserSettingsOpen, setIsUserSettingsOpen] = useState(false);
  const connectingRef = useRef<HTMLDivElement>(null);

  const menuNavigationDispatch = useMenuNavigationDispatch();
  const { menuItems } = useMenuNavigationState();
  const resourcesMenuData = menuItems.find(menuItem => menuItem.name === 'resources');
  const isZendeskTenant = useIsZendeskTenant();

  const handleUserSettingsButtonClick = () => {
    setIsUserSettingsOpen(true);
  };

  const handleUserSettingsClickAway = () => {
    if (isUserSettingsOpen) {
      setIsUserSettingsOpen(false);
    }
  };

  const handleUserSettingsClose = () => {
    setIsUserSettingsOpen(false);
  };

  const handleResourcesClick = () => {
    menuNavigationDispatch(setActiveMenuItem({ name: 'resources' }));
  };

  return (
    <>
      {resourcesMenuData && isZendeskTenant ? (
        <Tooltip content="Help Center" position="bottom-start">
          <span>
            <Box component="a" onClick={handleResourcesClick} href={resourcesMenuData.link} target="_blank">
              <LiveHelpIcon
                sx={{
                  mt: '2px',
                  fontSize: '1.6rem',
                  color: 'grey.500',
                  transition: 'color 100ms linear',
                  '&:hover': { color: 'grey.400', cursor: 'pointer' }
                }}
              />
            </Box>
          </span>
        </Tooltip>
      ) : null}
      <Tooltip content="User Settings" position="bottom-start">
        <Box ref={connectingRef}>
          <IconButton
            onClick={handleUserSettingsButtonClick}
            sx={{
              color: 'grey.500',
              transition: 'color 100ms linear',
              '&:hover': { color: 'grey.400', cursor: 'pointer' }
            }}
          >
            <AccountCircleIcon sx={{ fontSize: '1.8rem' }} />
          </IconButton>
        </Box>
      </Tooltip>
      <Popup
        sx={{ marginTop: 3.5, marginRight: 1 }}
        paperSx={{ minWidth: '17rem' }}
        connectingElement={connectingRef.current}
        isOpen={isUserSettingsOpen}
        onClose={handleUserSettingsClickAway}
      >
        <UserSettings onClose={handleUserSettingsClose} />
      </Popup>
    </>
  );
};

export default HeaderActions;
