import React, { useState, useEffect, useLayoutEffect, useMemo } from 'react';
import 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import {
  ColDef,
  RowNode,
  SelectionChangedEvent,
  GridApi,
  ICellRendererParams,
  ColumnApi,
  IStatusPanelComp
} from 'ag-grid-community/dist/lib/main';
import CustomStatusBar from './components/customStatusBar';
import columnHeader from './components/columnHeader';
import { Row, RowsPerPageValues } from '@clinintell/components/agGrid/tablesTypes';
import { TableStateType } from '@clinintell/modules/metricsNavigation';
import { Box } from '@mui/material';

interface StatusBarType extends IStatusPanelComp {
  componentInstance: {
    setGridApi: (api: GridApi) => void;
  };
}

interface FooterStyle {
  color: string;
  background?: string;
  fontWeight: number;
  borderTop?: string;
  whiteSpace: string;
  overflow: string;
  textOverflow: string;
}

export interface AGGridProps {
  rowData: Row[];
  rowsPerPage: RowsPerPageValues;
  tableFinalHeight: number | string;
  tableLayout?: 'auto' | 'fixed';
  className?: string;
  footer?: Row;
  columnDefs?: ColDef[];
  defaultColDef?: ColDef;
  columnIdentifier?: string;
  selectRows?: (rowData: number[]) => void;
  initialSelectedRows?: number[];
  setTableHeight?: (height: number) => void;
  footerStyle?: FooterStyle;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getRenderCells?: (params: ICellRendererParams) => any;
  pagination?: boolean;
  checkboxSelection?: boolean;
  getGridApiOnReady?: (api: GridApi) => void;
  restoreTableInfo?: () => void;
  tableState?: TableStateType;
}

export type RowStyleType = {
  node: RowNode;
};

export type DataRenderType = {
  api: GridApi;
};

const AgGrid = ({
  rowsPerPage,
  className,
  selectRows,
  initialSelectedRows,
  rowData,
  columnDefs,
  footer,
  defaultColDef,
  columnIdentifier,
  tableFinalHeight,
  footerStyle,
  getRenderCells,
  setTableHeight,
  pagination = true,
  checkboxSelection = false,
  getGridApiOnReady,
  restoreTableInfo,
  tableState
}: AGGridProps): JSX.Element => {
  const [gridApi, setGridApi] = useState<GridApi>();
  const [agGridSeletedRows, setAgGridSeletedRows] = useState<number[]>(initialSelectedRows as number[]);
  const [statusBarInitialized, setStatusBarInitialized] = useState(false);
  useLayoutEffect(() => {
    function updateSize(): void {
      if (!!gridApi && gridApi !== null) {
        //eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        setTimeout(() => {
          gridApi.sizeColumnsToFit();
        }, 100);
      }
    }
    window.addEventListener('resize', updateSize);

    return (): void => {
      window.removeEventListener('resize', updateSize);
    };
  }, [gridApi]);

  useEffect(() => {
    if (!!gridApi && gridApi !== null && !!footer && !!footer.length) {
      //eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      gridApi.setPinnedBottomRowData(footer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [footer]);

  const initializeStatusBar = (api: GridApi): void => {
    setTimeout(
      () => {
        const statusBar = api.getStatusPanel('customStatusBarComponent') as StatusBarType;
        if (!statusBarInitialized && statusBar && Object.getOwnPropertyDescriptor(statusBar, 'componentInstance')) {
          statusBar.componentInstance.setGridApi(api);
          setStatusBarInitialized(true);
        }
      },
      // longer time to load for more rows
      rowData.length > rowsPerPage ? 250 : 100
    );
  };

  const onGridReady = (params: { api: GridApi; columnApi: ColumnApi }): void => {
    setGridApi(params.api);
    params.api.showLoadingOverlay();
    params.api.sizeColumnsToFit();
    params.api.setDomLayout('autoHeight');
    initializeStatusBar(params.api);

    if (getGridApiOnReady) getGridApiOnReady(params.api);
  };

  const getRowStyle = (params: RowStyleType): FooterStyle | undefined => {
    // footerStyle
    if (
      params.node &&
      params.node.data &&
      (params.node.data.rowId === 0 || (columnIdentifier && params.node.data[columnIdentifier]?.restProps?.isFooter))
    ) {
      return footerStyle;
    }
    return undefined;
  };

  const openOthersHeightCallBack = (
    height: number,
    tableHeightTmp: number,
    rowId: number,
    gridApiVar: GridApi
  ): void => {
    if (!!setTableHeight) setTableHeight(tableHeightTmp);
    if (!!gridApiVar) {
      gridApiVar.forEachNode(function(rowNode: RowNode) {
        if (rowNode.data && rowNode.data.rowId === rowId) {
          rowNode.setRowHeight(height);
        }
      });
      gridApiVar.onRowHeightChanged();
      gridApiVar.sizeColumnsToFit();
    }
  };

  const equals = (a: number[], b: number[]): boolean => JSON.stringify(a) === JSON.stringify(b);
  const selectionChanged = (event: SelectionChangedEvent): void => {
    const newSelected = event.api.getSelectedNodes().map((node: RowNode) => node.data.rowId);
    setAgGridSeletedRows(newSelected);
    if (!!selectRows && !equals(newSelected, initialSelectedRows as number[]) && checkboxSelection) {
      selectRows(newSelected);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const firstDataRendered = (params: any): void => {
    const api = params.api as GridApi;
    setTimeout(() => {
      try {
        if (!!footer) params.api.setPinnedBottomRowData(footer);
      } catch (e) {
        if (!!footer) params.api.setPinnedBottomRowData([footer]);
      }
      if (checkboxSelection) selectCheckBox(params.api, agGridSeletedRows);
      if (restoreTableInfo && tableState) {
        const { columnInfo, filters, pageNumber } = tableState;
        if (columnInfo && columnInfo.length > 0) {
          api.setColumnDefs(columnInfo);
        }
        if (filters) api.setFilterModel(filters);
        if (pageNumber) api.paginationGoToPage(pageNumber);
        restoreTableInfo();
      }
      api.hideOverlay();
      initializeStatusBar(params.api);
    }, 1000);
  };

  const selectCheckBox = (gridApiTmp: GridApi, selectedAgGridRowsTmp: number[] | undefined): void => {
    setAgGridSeletedRows([]);
    if (
      !!selectedAgGridRowsTmp &&
      !!rowData &&
      selectedAgGridRowsTmp.length > 0 &&
      selectedAgGridRowsTmp.length < rowData.length
    ) {
      gridApiTmp.forEachLeafNode((node: RowNode) => {
        if (selectedAgGridRowsTmp.indexOf(node.data.rowId) !== -1) {
          node.setSelected(true);
        }
      });
    } else if (!!selectedAgGridRowsTmp && !!rowData && selectedAgGridRowsTmp.length === rowData.length) {
      gridApiTmp.selectAll();
    }
  };

  const cellRender = (params: ICellRendererParams): JSX.Element => {
    if (!!params.value) {
      if (!!getRenderCells) {
        const renderCells = getRenderCells(params);
        const key = renderCells.key;
        const rowId = renderCells.rowId;
        const component = renderCells.component;

        if (!!component) return component;
        else if (!!!renderCells[key] && !!renderCells.value) return renderCells.value;
        else if (!!!renderCells[key]) return <>*</>;

        const ComponentRender = renderCells[key];
        const OthersCell = renderCells['OthersCell'];
        const openOthers = (height: number, tableHeightTmp: number): void => {
          openOthersHeightCallBack(height, tableHeightTmp, rowId, params.api);
        };

        return (
          <ComponentRender {...renderCells.restProps}>
            {!!renderCells.OthersCell && !!renderCells.othersProps ? (
              <OthersCell {...renderCells.othersProps} openOthers={openOthers}>
                {renderCells.styledValue}
              </OthersCell>
            ) : (
              renderCells.styledValue
            )}
          </ComponentRender>
        );
      } else return params.value;
    }

    return <>--</>;
  };

  const statusBar = useMemo(() => {
    return {
      statusPanels: [
        {
          statusPanel: 'CustomStatusBar',
          key: 'customStatusBarComponent',
          statusPanelParams: {
            api: gridApi
          }
        }
      ]
    };
  }, [gridApi]);

  return (
    <Box
      sx={{
        height: '100%',
        width: '100%',
        '& .ag-row': {
          borderColor: theme => `${theme.palette.grey[100]} !important`
        },
        '& .ag-row-odd': {
          backgroundColor: theme => `${theme.palette.shade.white} !important`
        },
        '& .ag-row-hover:not(.ag-row-pinned)': {
          backgroundColor: '#eff6fc !important'
        },
        '& .ag-react-container': {
          display: 'flex !important'
        },
        '& .column-header-style, .ag-header-icon': {
          backgroundColor: '#385eab !important',
          color: '#fff !important',
          fontSize: '1rem !important',
          fontWeight: '700 !important'
        },
        '& .ag-header-cell:hover, .ag-header-cell:focus, .ag-header-cell:active': {
          backgroundColor: '#385eab !important'
        },
        '& .resizable-header > .ag-header-cell-resize': {
          top: '0 !important',
          right: '0 !important',
          width: '5px !important',
          cursor: 'col-resize',
          height: '100% !important',
          position: 'absolute !important',
          minHeight: '38px !important',
          borderLeft: '1px solid #2e7cb1',
          userSelect: 'none !important',
          borderRight: '1px solid #2e7cb1',
          color: 'none !important',
          background: 'none !important'
        },
        '& .ag-header-cell-resize:after': {
          content: 'none !important'
        },
        '& .MuiTableCell-root': {
          borderBottom: '0px !important',
          padding: '0px !important',
          width: '100% !important'
        },
        '& .ag-overlay-wrapper': {
          /*backdrop-filter: blur(5px);*/
          zIndex: 9999999999999
        },
        '& .centred-header .ag-header-cell-label': {
          justifyContent: 'center !important'
        },
        '& .right-header .ag-header-cell-label': {
          justifyContent: 'flex-end !important'
        },
        '& .ag-theme-material .ag-icon-checkbox-checked': {
          color: '#0076c6 !important'
        },
        '& .ag-cell': {
          '-webkit-font-smoothing': 'antialiased !important',
          lineHeight: '34px !important',
          border: 'none !important'
        },
        '& .ag-checked::after': {
          color: 'rgb(56, 94, 171) !important'
        },
        '& .ag-checkbox-input-wrapper::after': {
          color: 'rgb(56, 94, 171) !important'
        },
        '& .ag-checkbox-input-wrapper.ag-indeterminate::after': {
          color: '#fff !important'
        },
        '& .ag-header-select-all > .ag-checkbox-input-wrapper.ag-indeterminate': {
          backgroundColor: '#000 !important'
        },
        '& .ag-paging-row-summary-panel': {
          display: 'none !important'
        },
        '& .ag-header-viewport': {
          background: '#385eab !important'
        },
        '& .ag-set-filter-list': {
          width: '400px !important'
        },
        '& .ag-sort-order': {
          display: 'none !important'
        },
        '& .ag-cell-value': {
          width: '100%'
        },
        '& .ag-cell-wrapper': {
          alignItems: 'flex-start !important'
        },
        '& .ag-row-selected': {
          backgroundColor: 'rgba(215, 234, 249, 0.08) !important'
        },
        '& .ag-root-wrapper': {
          border: '0px !important',
          borderBottomLeftRadius: '4px !important',
          borderBottomRightRadius: '4px !important',
          boxShadow:
            '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%) !important'
        },
        '& .ag-floating-bottom': {
          background: '#d3ddf1 !important',
          borderTop: '2px solid #385eab !important'
        },
        '& .ag-selection-checkbox-div .ag-cell-value': {
          display: 'none'
        },
        '& .ag-selection-checkbox-div-header .ag-header-select-all': {
          marginRight: '10px !important'
        },
        '& .ag-selection-checkbox-div-header .ag-icon-filter': {
          marginLeft: '0px !important'
        },
        '& .ag-selection-checkbox-div-header .ag-icon-asc, .ag-selection-checkbox-div-header .ag-icon-desc, .ag-selection-checkbox-div-header .ag-header-cell-text': {
          display: 'none !important'
        },
        '& .ag-header-cell > .ag-react-container': {
          width: '100%'
        },
        '& .conditionTypeId .ag-header-cell-resize, .isTargetCondition .ag-header-cell-resize': {
          cursor: 'none !important'
        },
        '& .ag-center-cols-clipper': {
          minHeight: 'unset !important'
        }
      }}
      className={'ag-theme-alpine ' + className}
    >
      <AgGridReact
        onGridReady={onGridReady}
        onFirstDataRendered={firstDataRendered}
        onSelectionChanged={selectionChanged}
        defaultColDef={defaultColDef}
        columnDefs={columnDefs}
        rowData={rowData}
        domLayout={'autoHeight'}
        frameworkComponents={{
          cellRender: cellRender,
          CustomStatusBar: CustomStatusBar,
          // customHeader causes an issue with the dnd event listener
          agColumnHeader: columnHeader
        }}
        statusBar={pagination ? statusBar : undefined}
        suppressMenuHide={true}
        applyColumnDefOrder={true}
        suppressContextMenu={true}
        suppressCellSelection={true}
        headerHeight={38}
        rowHeight={38}
        pagination={pagination}
        suppressPaginationPanel
        suppressRowClickSelection
        rowSelection={'multiple'}
        animateRows={true}
        paginationPageSize={rowsPerPage}
        getRowStyle={getRowStyle}
        enableCellTextSelection={true}
        popupParent={document.querySelector('body') as HTMLElement}
        suppressRowTransform
      />
    </Box>
  );
};

export default AgGrid;
