import { Metrics, Views } from './metricsNavigation';
import {
  MetricRecordJSON,
  MetricConditionRecordJSON,
  MetricSchema,
  MetricConditionSchema
} from '@clinintell/containers/metrics/typings/metricTypes';
import { UserFeature } from './user';
import { ChartDataSetJSON } from '@clinintell/containers/metricsTimeSeries/typings/metricChartTypes';
import { SagaIterator } from 'redux-saga';
import { call, all, put, takeLatest } from 'redux-saga/effects';
import { ApplicationAPI, AsyncOutput } from '@clinintell/utils/api';
import { formatDateForAPI } from '@clinintell/utils/formatting';
import { QueryStringParam, createUrlWithQueryString } from '@clinintell/utils/querystring';
import { ColumnView, getMetricTableColumns } from '@clinintell/containers/metrics/typings/tableSchemas';

export type MDCTypes = {
  id: number;
  mdcName: string;
};

export type TableColumnFilter = {
  column: string;
  value: string | string[] | number | number[];
};

export interface MetricOpportunityShares {
  hccShare?: number;
  mortalityShare?: number;
  readmissionShare?: number;
  psiShare?: number;
}

export type PSIIndicator = {
  title: string;
  tooltip: string;
  weight: number;
  color?: string;
  children?: PSIIndicator[];
};
export interface MetricDataset extends MetricOpportunityShares {
  dataset: MetricRecordJSON[] | null;
  drgPie: number;
  hccPie: number;
  mortalityPie: number;
  readmissionPie: number;
  psiPie: number;
  mdcTypes: MDCTypes[];
  schema: MetricSchema | null;
  tableColumnView: ColumnView | null;
  tableFilters: TableColumnFilter[];
  isLoading: boolean;
  hasError: boolean;
  errorMessage?: string;
  targetConditions: number[];
  psiPieShares?: PSIIndicator[];
}

export type TimeSeriesDataset = {
  id: keyof typeof Metrics;
  dataset: ChartDataSetJSON | null;
  hasError: boolean;
  errorMessage?: string;
};

export type TimeSeriesDatasets = {
  datasets: TimeSeriesDataset[] | null;
  isLoading: boolean;
};

// metric's dataset applies only to a single metric, time series datasets are stored as a batch of several metrics
export type MetricsData = {
  metrics: MetricDataset;
  timeSeries: TimeSeriesDatasets;
};

// Actions
enum MetricsDataActions {
  FETCH_METRIC_DATASET_BEGIN = 'FETCH_DATASET_BEGIN',
  FETCH_METRIC_DATASET_SUCCESSFUL = 'FETCH_DATASET_SUCCESSFUL',
  FETCH_METRIC_DATASET_FAIL = 'FETCH_METRIC_DATASET_FAIL',
  FETCH_METRIC_DATASET = 'SET_DATASET',
  FETCH_TIME_SERIES_DATASETS = 'SET_TIME_SERIES_DATASETS',
  FETCH_TIME_SERIES_DATASETS_BEGIN = 'FETCH_TIME_SERIES_DATASETS_BEGIN',
  FETCH_TIME_SERIES_DATASETS_SUCCESSFUL = 'FETCH_TIME_SERIES_DATASETS_SUCCESSFUL',
  FETCH_TIME_SERIES_DATASETS_FAIL = 'FETCH_TIME_SERIES_DATASETS_FAIL',
  SET_METRICS_TABLE_COLUMN_VIEW = 'SET_METRICS_TABLE_COLUMN_VIEW',
  SET_FILTERS = 'SET_FILTERS'
}

export interface MetricsDataAction<T> {
  type?: keyof typeof MetricsDataActions;
  payload?: T;
}

type FetchMetricsPayload = {
  entity: number;
  metric: keyof typeof Metrics;
  periodStart: Date;
  periodEnd: Date;
  comparisonPeriodStart: Date;
  comparisonPeriodEnd: Date;
  condition?: number;
  view: keyof typeof Views;
  shareHcc?: number;
  shareMortality?: number;
  shareReadmission?: number;
  sharePSI?: number;
  useSavedTargetConds?: boolean;
  targetConditions?: number[];
  mdcIds?: number[];
  userFeatures?: UserFeature[];
  forOCE?: boolean;
  adjPSI90Weight?: number;
  adjPSI04Weight?: number;
  adjPSI02Weight?: number;
  adjPSI07Weight?: number;
};

type FetchTimeSeriesPayload = {
  entity: number;
  periodStart: Date;
  periodEnd: Date;
  condition?: number;
};

export const fetchMetricDataset = ({
  entity,
  metric,
  condition,
  periodStart,
  periodEnd,
  comparisonPeriodStart,
  comparisonPeriodEnd,
  view,
  shareHcc,
  shareMortality,
  shareReadmission,
  sharePSI,
  useSavedTargetConds,
  targetConditions,
  mdcIds,
  userFeatures,
  forOCE = false,
  adjPSI90Weight,
  adjPSI04Weight,
  adjPSI02Weight,
  adjPSI07Weight
}: FetchMetricsPayload): MetricsDataAction<FetchMetricsPayload> => ({
  type: 'FETCH_METRIC_DATASET',
  payload: {
    entity,
    metric,
    periodStart,
    periodEnd,
    comparisonPeriodStart,
    comparisonPeriodEnd,
    condition,
    view,
    shareHcc,
    shareMortality,
    shareReadmission,
    sharePSI,
    useSavedTargetConds,
    targetConditions,
    mdcIds,
    userFeatures,
    forOCE,
    adjPSI90Weight,
    adjPSI04Weight,
    adjPSI02Weight,
    adjPSI07Weight
  }
});

export const fetchTimeSeriesDatasets = (
  entity: number,
  periodStart: Date,
  periodEnd: Date,
  condition?: number
): MetricsDataAction<FetchTimeSeriesPayload> => ({
  type: 'FETCH_TIME_SERIES_DATASETS',
  payload: { entity, periodStart, periodEnd, condition }
});

export const setMetricsTableColumnView = (
  metric: keyof typeof Metrics,
  view: keyof typeof Views,
  forOCE = false
): MetricsDataAction<ColumnView> => ({
  type: 'SET_METRICS_TABLE_COLUMN_VIEW',
  payload: getMetricTableColumns(metric, view, forOCE)
});

export type ColumnFilter = {
  column: string;
  value: string | string[] | number | number[];
};

export const searchMetrics = (filters: ColumnFilter[]): MetricsDataAction<ColumnFilter[]> => ({
  type: 'SET_FILTERS',
  payload: filters
});

export const initialState: MetricsData = {
  metrics: {
    isLoading: false,
    dataset: null,
    drgPie: 0,
    hccPie: 0,
    mortalityPie: 0,
    readmissionPie: 0,
    hccShare: 0,
    mortalityShare: 0,
    readmissionShare: 0,
    psiPie: 0,
    mdcTypes: [],
    schema: null,
    tableColumnView: null,
    tableFilters: [],
    hasError: false,
    targetConditions: []
  },
  timeSeries: {
    isLoading: false,
    datasets: null
  }
};

// Reducer
const reducer = (
  state: MetricsData = initialState,
  action: MetricsDataAction<
    TimeSeriesDataset[] | MetricDataset | ColumnView | ColumnFilter | ColumnFilter[] | string | MDCTypes[]
  >
): MetricsData => {
  switch (action.type) {
    case 'FETCH_METRIC_DATASET_BEGIN': {
      return {
        ...state,
        metrics: {
          dataset: null,
          drgPie: 0,
          hccPie: 0,
          mortalityPie: 0,
          readmissionPie: 0,
          hccShare: 0,
          mortalityShare: 0,
          readmissionShare: 0,
          psiPie: 0,
          mdcTypes: [],
          schema: null,
          tableColumnView: null,
          tableFilters: state.metrics.tableFilters,
          isLoading: true,
          hasError: false,
          targetConditions: [],
          psiPieShares: undefined
        }
      };
    }
    case 'FETCH_METRIC_DATASET_SUCCESSFUL': {
      const metricDataset = action.payload as MetricDataset;

      return {
        ...state,
        metrics: {
          dataset: [...(metricDataset.dataset as MetricRecordJSON[])],
          drgPie: metricDataset.drgPie,
          hccPie: metricDataset.hccPie,
          mortalityPie: metricDataset.mortalityPie,
          readmissionPie: metricDataset.readmissionPie,
          hccShare: metricDataset.hccShare,
          mortalityShare: metricDataset.mortalityShare,
          readmissionShare: metricDataset.readmissionShare,
          psiPie: metricDataset.psiPie,
          mdcTypes: metricDataset.mdcTypes,
          schema: { ...(metricDataset.schema as MetricSchema) },
          tableColumnView: { ...(metricDataset.tableColumnView as ColumnView) },
          tableFilters: metricDataset.tableFilters,
          isLoading: false,
          hasError: false,
          targetConditions: metricDataset.targetConditions,
          psiPieShares: metricDataset.psiPieShares
        }
      };
    }
    case 'FETCH_METRIC_DATASET_FAIL': {
      return {
        ...state,
        metrics: {
          dataset: null,
          drgPie: 0,
          hccPie: 0,
          mortalityPie: 0,
          readmissionPie: 0,
          hccShare: 0,
          mortalityShare: 0,
          readmissionShare: 0,
          psiPie: 0,
          mdcTypes: [],
          schema: null,
          tableColumnView: null,
          tableFilters: state.metrics.tableFilters,
          isLoading: false,
          hasError: true,
          errorMessage: action.payload as string,
          targetConditions: [],
          psiPieShares: undefined
        }
      };
    }
    case 'SET_FILTERS': {
      const filters: ColumnFilter[] = action.payload as ColumnFilter[];
      return {
        ...state,
        metrics: {
          ...state.metrics,
          tableFilters: filters
        }
      };
    }
    case 'FETCH_TIME_SERIES_DATASETS_BEGIN': {
      return {
        ...state,
        timeSeries: {
          ...state.timeSeries,
          isLoading: true
        }
      };
    }
    case 'FETCH_TIME_SERIES_DATASETS_SUCCESSFUL': {
      return {
        ...state,
        timeSeries: {
          datasets: [...(action.payload as TimeSeriesDataset[])],
          isLoading: false
        }
      };
    }
    case 'FETCH_TIME_SERIES_DATASETS_FAIL': {
      return {
        ...state,
        timeSeries: {
          datasets: null,
          isLoading: false
        }
      };
    }
    case 'SET_METRICS_TABLE_COLUMN_VIEW': {
      return {
        ...state,
        metrics: {
          ...state.metrics,
          tableColumnView: { ...(action.payload as ColumnView) }
        }
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;

// Sagas
export function* fetchMetricDatasetSaga({ payload }: MetricsDataAction<FetchMetricsPayload>): SagaIterator {
  if (!payload) {
    throw new Error(
      'entity, metric, periodStart, periodEnd, comparisonPeriodStart, and comparisonPeriodEnd values must be sent with the FETCH_METRIC_DATASET action'
    );
  }

  yield put({
    type: 'FETCH_METRIC_DATASET_BEGIN'
  });

  const {
    entity,
    metric,
    periodStart,
    periodEnd,
    comparisonPeriodStart,
    comparisonPeriodEnd,
    condition,
    view,
    useSavedTargetConds,
    targetConditions,
    shareHcc,
    shareMortality,
    shareReadmission,
    sharePSI,
    mdcIds,
    forOCE,
    adjPSI90Weight,
    adjPSI04Weight,
    adjPSI02Weight,
    adjPSI07Weight
  } = payload;

  let metricsEndpoint = '';
  let psiPiesEndpoint = '';
  const queryStringValues: QueryStringParam[] = [
    {
      key: 'currentStart',
      value: formatDateForAPI(periodStart)
    },
    {
      key: 'currentEnd',
      value: formatDateForAPI(periodEnd)
    }
  ];

  if (comparisonPeriodStart && comparisonPeriodEnd) {
    queryStringValues.push(
      {
        key: 'historicalStart',
        value: formatDateForAPI(comparisonPeriodStart)
      },
      {
        key: 'historicalEnd',
        value: formatDateForAPI(comparisonPeriodEnd)
      }
    );
  }

  if (metric !== 'condition' && metric !== 'allConditions' && metric !== 'targetedConditions') {
    metricsEndpoint = createUrlWithQueryString(`metrics/${metric}/${entity}`, queryStringValues);
  } else {
    if (metric === 'condition') {
      queryStringValues.push({
        key: 'conditionId',
        value: condition ? condition.toString() : '0'
      });
      queryStringValues.push({
        key: 'shareDrg',
        value: '5'
      });
      if (shareHcc !== undefined) {
        queryStringValues.push({
          key: 'shareHcc',
          value: shareHcc.toString()
        });
      }
      if (shareMortality !== undefined) {
        queryStringValues.push({
          key: 'shareMortality',
          value: shareMortality.toString()
        });
      }
      if (shareReadmission !== undefined) {
        queryStringValues.push({
          key: 'shareReadmission',
          value: shareReadmission.toString()
        });
      }
      if (
        adjPSI90Weight !== undefined ||
        adjPSI04Weight !== undefined ||
        adjPSI02Weight !== undefined ||
        adjPSI07Weight !== undefined
      ) {
        queryStringValues.push({
          key: 'adjPSI90Weight',
          value: adjPSI90Weight ? adjPSI90Weight.toString() : '-1'
        });
        queryStringValues.push({
          key: 'adjPSI4Weight',
          value: adjPSI04Weight ? adjPSI04Weight.toString() : '-1'
        });
        queryStringValues.push({
          key: 'adjPSI2Weight',
          value: adjPSI02Weight ? adjPSI02Weight.toString() : '-1'
        });
        queryStringValues.push({
          key: 'adjPSI7Weight',
          value: adjPSI07Weight ? adjPSI07Weight.toString() : '-1'
        });
      }
    } else if (metric === 'allConditions') {
      if (condition) {
        queryStringValues.push({
          key: 'conditionId',
          value: condition ? condition.toString() : '0'
        });
      }
      queryStringValues.push({
        key: 'includeAllConditions',
        value: '1'
      });
      queryStringValues.push({
        key: 'useSavedTargetConds',
        value: useSavedTargetConds ? '1' : '0'
      });
      queryStringValues.push({
        key: 'shareDrg',
        value: '5'
      });
      queryStringValues.push({
        key: 'shareHcc',
        value: shareHcc ? shareHcc.toString() : '0'
      });
      queryStringValues.push({
        key: 'shareMortality',
        value: shareMortality ? shareMortality.toString() : '0'
      });
      queryStringValues.push({
        key: 'shareReadmission',
        value: shareReadmission ? shareReadmission.toString() : '0'
      });
      queryStringValues.push({
        key: 'sharePSI',
        value: sharePSI ? sharePSI.toString() : '0'
      });
      if (
        adjPSI90Weight !== undefined ||
        adjPSI04Weight !== undefined ||
        adjPSI02Weight !== undefined ||
        adjPSI07Weight !== undefined
      ) {
        queryStringValues.push({
          key: 'adjPSI90Weight',
          value: adjPSI90Weight ? adjPSI90Weight.toString() : '0'
        });
        queryStringValues.push({
          key: 'adjPSI4Weight',
          value: adjPSI04Weight ? adjPSI04Weight.toString() : '0'
        });
        queryStringValues.push({
          key: 'adjPSI2Weight',
          value: adjPSI02Weight ? adjPSI02Weight.toString() : '0'
        });
        queryStringValues.push({
          key: 'adjPSI7Weight',
          value: adjPSI07Weight ? adjPSI07Weight.toString() : '0'
        });
      }
      if (targetConditions && targetConditions.length) {
        queryStringValues.push({
          key: 'targetConditionIds',
          value: targetConditions.join(',')
        });
      }
      if (mdcIds && mdcIds.length) {
        queryStringValues.push({
          key: 'mdcIds',
          value: mdcIds.join(',')
        });
      }
    } else if (metric === 'targetedConditions') {
      queryStringValues.push({
        key: 'includeAllConditions',
        value: '0'
      });
    }

    metricsEndpoint = createUrlWithQueryString(`metrics/conditions/${entity}`, queryStringValues);
    psiPiesEndpoint = createUrlWithQueryString(`metrics/psipies/${entity}`, queryStringValues);
  }

  let psiPiesOutput: AsyncOutput<PSIIndicator[]> = {};
  let mdcOutput: AsyncOutput<MDCTypes[]> = {};
  if (metric === 'allConditions') {
    mdcOutput = yield call(ApplicationAPI.get, { endpoint: 'mdc' });
    psiPiesOutput = yield call(ApplicationAPI.get, { endpoint: psiPiesEndpoint });
  }

  const [metricsOutput, schemaOutput]: [
    AsyncOutput<MetricConditionRecordJSON | MetricRecordJSON[]>,
    AsyncOutput<MetricConditionSchema | MetricSchema>
  ] = yield all([
    call(ApplicationAPI.get, {
      endpoint: metricsEndpoint
    }),
    call(ApplicationAPI.get, {
      endpoint: `schema/${
        metric === 'condition' || metric === 'allConditions' || metric === 'targetedConditions' ? 'condition' : metric
      }`
    })
  ]);

  if (metricsOutput.error || schemaOutput.error || mdcOutput.error || psiPiesOutput.error) {
    const errorArray: string[] = [];
    if (metricsOutput.error) {
      errorArray.push(metricsOutput.error);
    }

    if (schemaOutput.error) {
      errorArray.push(schemaOutput.error);
    }

    if (mdcOutput.error) {
      errorArray.push(mdcOutput.error);
    }

    if (psiPiesOutput.error) {
      errorArray.push(psiPiesOutput.error);
    }

    yield put({
      type: 'FETCH_METRIC_DATASET_FAIL',
      payload: errorArray.join(' / ')
    });
  } else {
    const data = metricsOutput.data as MetricConditionRecordJSON;
    const schema = schemaOutput.data as MetricConditionSchema;
    const psiPies = metric === 'allConditions' ? (psiPiesOutput.data as PSIIndicator[]) : {};
    yield put({
      type: 'FETCH_METRIC_DATASET_SUCCESSFUL',
      payload: {
        dataset: data.metrics ?? data,
        drgPie: data.drgShare ?? 0,
        hccPie: data.hccShare ?? 0,
        mortalityPie: data.mortalityShare ?? 0,
        readmissionPie: data.readmissionShare ?? 0,
        psiPie: data.psiShare ?? 0,
        mdcTypes: metric === 'allConditions' ? (mdcOutput.data as MDCTypes[]) : [],
        schema: schema.definitions ? schema.definitions.ConditionMetric : schema,
        tableColumnView: getMetricTableColumns(metric, view, forOCE),
        tableFilters: mdcIds ? [{ column: 'mdcName', value: mdcIds }] : [],
        targetConditions:
          metric === 'allConditions'
            ? (metricsOutput.data as MetricConditionRecordJSON).metrics
                .filter(rec => rec.isTargetCondition)
                .map(rec => rec.conditionId)
            : [],
        psiPieShares: psiPies
      }
    });
  }
}

export function* fetchTimeSeriesDatasetsSaga({ payload }: MetricsDataAction<FetchTimeSeriesPayload>): SagaIterator {
  if (!payload) {
    throw new Error(
      'entity, periodStart, and periodEnd values must be sent with the FETCH_TIME_SERIES_DATASETS action'
    );
  }

  yield put({
    type: 'FETCH_TIME_SERIES_DATASETS_BEGIN'
  });

  const { entity, periodStart, periodEnd, condition } = payload;
  const payloadOutput = [];

  if (condition) {
    const queryStringValues: QueryStringParam[] = [
      {
        key: 'StartDate',
        value: formatDateForAPI(periodStart)
      },
      {
        key: 'EndDate',
        value: formatDateForAPI(periodEnd)
      }
    ];

    queryStringValues.push({
      key: 'conditionId',
      value: condition.toString()
    });

    const output: AsyncOutput<ChartDataSetJSON> = yield call(ApplicationAPI.get, {
      endpoint: createUrlWithQueryString(`Graph/conditions/${entity}`, queryStringValues)
    });

    if (output.error) {
      yield put({
        type: 'FETCH_TIME_SERIES_DATASETS_FAIL',
        payload: output.error
      });
    } else {
      payloadOutput.push({
        id: 'condition',
        dataset: output.data || null,
        hasError: output.error ? true : false,
        errorMessage: output.error || ''
      });
    }
  } else {
    const nonConditionMetrics = Object.values(Metrics).filter(
      metric =>
        metric !== 'condition' && metric !== 'allConditions' && metric !== 'targetedConditions' && metric !== 'hcupcmi'
    );

    const outputDatasets: AsyncOutput<ChartDataSetJSON>[] = yield all(
      nonConditionMetrics.map(metric =>
        call(ApplicationAPI.get, {
          endpoint: `Graph/${metric}/${entity}?StartDate=${formatDateForAPI(periodStart)}&EndDate=${formatDateForAPI(
            periodEnd
          )}`
        })
      )
    );

    if (outputDatasets.findIndex(response => response.error && response.error.includes('500')) > -1) {
      yield put({
        type: 'FETCH_TIME_SERIES_DATASETS_FAIL',
        payload: '500 Error'
      });
    } else {
      payloadOutput.push(
        ...outputDatasets.map((dataset, index) => ({
          id: nonConditionMetrics[index],
          dataset: dataset.data || null,
          hasError: dataset.error ? true : false,
          errorMessage: dataset.error || ''
        }))
      );
    }
  }

  yield put({
    type: 'FETCH_TIME_SERIES_DATASETS_SUCCESSFUL',
    payload: payloadOutput
  });
}

export function* timeSeriesDataSaga(): SagaIterator {
  yield takeLatest('FETCH_TIME_SERIES_DATASETS', fetchTimeSeriesDatasetsSaga);
}

export function* metricsDataSaga(): SagaIterator {
  yield takeLatest('FETCH_METRIC_DATASET', fetchMetricDatasetSaga);
}
