import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { siteTheme } from '@clinintell/theme/theme';
import { ThemeProvider, Theme, StyledEngineProvider } from '@mui/material';
import { useUser, useAuthenticationStatus } from '@clinintell/modules/store';
import { getAppInsights } from './telemetry/TelemetryService';
import TelemetryProvider from './telemetry/telemetry-provider';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import ErrorBoundary from '@clinintell/containers/errorHandlers/ErrorBoundary';
import LoadingScreen from '@clinintell/components/LoadingScreen';
import RouteErrorPage from '@clinintell/containers/errorHandlers/RouteErrorPage';
import UnauthenticatedRoutes from '@clinintell/routes/authentication/UnauthenticatedRoutes';
import AuthenticatedRoutes from './routes/authentication/AuthenticatedRoutes';
import WindowSizeContextProvider from './modules/windowSizeContext';
import useCreateAuth0Client from './containers/app/useCreateAuth0Client';
import useSetAuthenticationStatus from './containers/app/useSetAuthenticationStatus';
import useFetchInitialData from './containers/app/useFetchInitialData';
import { ConfidentialityAgreementProvider } from './containers/authentication/ConfidentialityAgreementContext';
// TODO -- enable this later
//import useLoadingUserGuiding from '@clinintell/containers/app/useLoadUserGuiding';
import useAuthenticateWithZendesk from './containers/app/useAuthenticateWithZendesk';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

// Log to application insights
export let appInsights: ApplicationInsights;

const App: React.FC = () => {
  const user = useUser();
  const { isAuthenticated } = useAuthenticationStatus();

  const isCookiesEnabled = window.navigator.cookieEnabled;

  const auth0Client = useCreateAuth0Client();

  useSetAuthenticationStatus(auth0Client);
  useFetchInitialData(isAuthenticated === null ? false : isAuthenticated);

  // TODO -- enable this later
  //useLoadingUserGuiding(isAuthenticated === null ? false : isAuthenticated);
  useAuthenticateWithZendesk(isAuthenticated === null ? false : isAuthenticated);

  if (isAuthenticated === null || (isAuthenticated === true && !user.isLoaded)) {
    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={siteTheme}>
          <LoadingScreen backgroundHeight="100vh" loadingIndicatorSize={250} />
        </ThemeProvider>
      </StyledEngineProvider>
    );
  }

  return isCookiesEnabled && !user.blocked ? (
    <BrowserRouter>
      <TelemetryProvider
        instrumentationKey={APPLICATION_INSIGHTS_INSTRUMENTATION_KEY}
        after={(): void => {
          appInsights = getAppInsights();
        }}
      >
        <WindowSizeContextProvider>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={siteTheme}>
              <ErrorBoundary>
                {isAuthenticated ? (
                  <AuthenticatedRoutes />
                ) : (
                  <ConfidentialityAgreementProvider>
                    <UnauthenticatedRoutes />
                  </ConfidentialityAgreementProvider>
                )}
              </ErrorBoundary>
            </ThemeProvider>
          </StyledEngineProvider>
        </WindowSizeContextProvider>
      </TelemetryProvider>
    </BrowserRouter>
  ) : (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={siteTheme}>
        <RouteErrorPage
          error={{
            name: user.blocked ? 'blocked' : '403',
            message: 'We are unable to log you in. Please update your browser settings to enable cookies.'
          }}
        ></RouteErrorPage>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
