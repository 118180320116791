import { userCan } from '@clinintell/containers/authentication/rules';
import useSignOut from '@clinintell/containers/authentication/useSignOut';
import { MenuNames, setActiveMenuItem } from '@clinintell/modules/menuNavigation';
import { useMenuNavigationDispatch, useMenuNavigationState, useUser } from '@clinintell/modules/store';
import { Box } from '@mui/material';
import React from 'react';
import UserSettingsNavLink from './sections/UserSettingsNavLink';

type UserSettingsProps = {
  onClose: () => void;
};

const UserSettings: React.FC<UserSettingsProps> = ({ onClose }) => {
  const user = useUser();
  const { menuItems } = useMenuNavigationState();

  const userManagementItem = menuItems.find(menuItem => menuItem.name === 'userManagement');
  const userHasManageUsersPrivs = userManagementItem ? userCan(user, userManagementItem.permissions) : false;

  const { signOut } = useSignOut();
  const menuNavigationDispatch = useMenuNavigationDispatch();

  const handleNavLinkClick = (menuName: MenuNames) => {
    menuNavigationDispatch(setActiveMenuItem({ name: menuName }));
    onClose();
  };

  const handleSignOutClick = () => {
    signOut();
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column'
      }}
    >
      <Box
        component="span"
        sx={{
          typography: 'p1',
          paddingTop: 1,
          px: 1
        }}
      >
        {user.name}
      </Box>
      <Box
        component="span"
        sx={{
          typography: 'footer',
          px: 1,
          mb: 1
        }}
      >
        {user.email}
      </Box>
      {userHasManageUsersPrivs ? (
        <UserSettingsNavLink
          onClick={() => handleNavLinkClick('userManagement')}
          link="/usermanagement/manageusers"
          label="Manage Users"
        />
      ) : null}
      <Box
        role="button"
        onClick={handleSignOutClick}
        sx={{
          py: 1.5,
          borderTop: '0.5px solid',
          borderColor: 'grey.300',
          transition: 'background-color 200ms linear',
          '&:hover': {
            cursor: 'pointer',
            backgroundColor: 'grey.50'
          }
        }}
      >
        <Box component="span" sx={{ typography: 'p1', px: 1 }}>
          Sign Out
        </Box>
      </Box>
    </Box>
  );
};

export default UserSettings;
