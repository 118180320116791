import { Box, useMediaQuery, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react';
import AnalysisSummaryRow from '@clinintell/components/analysisSharedComponents/AnalysisSummaryRow';
import AnalysisNavigation from '@clinintell/components/analysisSharedComponents/AnalysisNavigation';
import ResultSummaryBox from '@clinintell/components/analysisSharedComponents/ResultSummaryBox';
import Button from '@clinintell/components/button/Button';
import CMIComparisonResults from './sections/CMIComparisonResults';
import CMIComparisonTable from './sections/CMIComparisonTable';
import ClinIntellSkeleton from '@clinintell/components/ClinIntellSkeleton';
import SeverityCaptureResults from './sections/SeverityCaptureResults';
import SeverityCaptureTable from './sections/SeverityCaptureTable';
import DRGMixTable from './sections/DRGMixTable';
import DRGMixResults from './sections/DRGMixResults';
import DatePeriodSelector from './sections/DatePeriodSelector';
import CreateIcon from '@mui/icons-material/Create';
import useExcelDownload from '@clinintell/components/analysisSharedComponents/useExcelDownload';
import usePDFDownload from '@clinintell/components/analysisSharedComponents/usePDFDownload';
import DownloadIcon from '@clinintell/components/icons/Download';
import EntitySelectionPanel from './sections/EntitySelectionPanel';
import { isIE11 } from '@clinintell/utils/browsers';
import Tooltip from '@clinintell/components/tooltip/Tooltip';
import { setAnalysisView } from '@clinintell/modules/cmiComparison';
import { CMIAnalysisViews } from '@clinintell/modules/cmiAnalysisSelections';
import {
  useCMIComparisonState,
  useCMIComparisonDispatch,
  useCMIAnalysisSelectionsState
} from '@clinintell/modules/store';
import PageTitle from '@clinintell/components/PageTitle';

const useStyles = makeStyles(theme => ({
  selectionContainer: {
    marginBottom: theme.spacing(3),
    display: 'flex',
    flexFlow: 'wrap',
    gap: theme.spacing(3),
    justifyContent: 'flex-start',
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1)
    },
    '& > *:not(last-child)': {
      marginRight: ({ isIE11 }: { isIE11: boolean }): string => (isIE11 ? theme.spacing(3) : '0')
    }
  }
}));

type CMIComparisonProps = {
  title: string;
};

const CMIComparison: React.FC<CMIComparisonProps> = ({ title }) => {
  const {
    orgId,
    comparisonOrgId,
    orgName,
    comparisonOrgName,
    cmiSummary,
    docScoreSummary,
    pooledSeverityCMISummary,
    drgMixSummary,
    drgAnalysis,
    isLoadingSummaryRecords,
    noSummaryData,
    comparisonMinDate,
    comparisonMaxDate,
    chartMinDate,
    chartMaxDate,
    analysisView,
    orgTreeType
  } = useCMIComparisonState();

  const [selectorsAreOpen, setSelectorsAreOpen] = useState(false);

  const cmiComparisonDispatch = useCMIComparisonDispatch();
  const { downloadExcelOutput, isDownloading } = useExcelDownload();
  const { downloadPDF, isDownloading: isPDFDownloading } = usePDFDownload();
  const { analysisType } = useCMIAnalysisSelectionsState();

  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('md'));

  const { selectionContainer } = useStyles({ isIE11 });

  const handleExcelDownload = (): void => {
    if (orgId === undefined || comparisonOrgId === undefined || !comparisonMinDate || !comparisonMaxDate) {
      return;
    }

    downloadExcelOutput({
      entityId: orgId,
      compareToEntityId: comparisonOrgId,
      currentMinDate: new Date(comparisonMinDate),
      currentMaxDate: new Date(comparisonMaxDate),
      analysisType: 'comparison',
      filename: analysisType === 'comparison' ? 'Entity Comparison_DRG Mix' : 'National Medicare Comparison_DRG Mix',
      orgTreeType
    });
  };

  const handlePDFDownload = (): void => {
    if (orgId === undefined || comparisonOrgId === undefined || !comparisonMinDate || !comparisonMaxDate) {
      return;
    }

    downloadPDF({
      entityId: orgId,
      compareToEntityId: comparisonOrgId,
      chartMinDate: new Date(chartMinDate),
      chartMaxDate: new Date(chartMaxDate),
      currentMinDate: new Date(comparisonMinDate),
      currentMaxDate: new Date(comparisonMaxDate),
      analysisType: 'comparison',
      filename: 'CMI Comparison',
      orgTreeType
    });
  };

  const handleAnalysisViewChange = (view: CMIAnalysisViews) => {
    cmiComparisonDispatch(setAnalysisView({ analysisView: view }));
  };

  let summaryComponent = null;
  if (isLoadingSummaryRecords) {
    summaryComponent = <ClinIntellSkeleton width="100%" height="8.75rem" variant="rectangular" />;
  } else if (
    cmiSummary &&
    docScoreSummary &&
    drgMixSummary &&
    orgId &&
    comparisonOrgId &&
    !isLoadingSummaryRecords &&
    drgAnalysis &&
    pooledSeverityCMISummary
  ) {
    switch (analysisView) {
      case 'cmi':
        summaryComponent = (
          <CMIComparisonTable
            cmiSummary={cmiSummary}
            pooledSeverityCMI={pooledSeverityCMISummary}
            drgMixSummary={drgMixSummary}
            baseLabel={orgName}
            comparisonLabel={comparisonOrgName}
          />
        );
        break;
      case 'severity':
        summaryComponent = (
          <SeverityCaptureTable
            baseLabel={orgName}
            comparisonLabel={comparisonOrgName}
            docScoreSummary={docScoreSummary}
            pooledSeverityCMISummary={pooledSeverityCMISummary}
          />
        );
        break;
      case 'drgmix':
        summaryComponent = (
          <DRGMixTable
            baseLabel={orgName}
            comparisonLabel={comparisonOrgName}
            drgMixSummary={drgMixSummary}
            drgAnalysis={drgAnalysis}
          />
        );
        break;
      default:
        throw new Error(`Invalid analysis type - ${analysisView}`);
    }
  } else if (noSummaryData) {
    summaryComponent = (
      <Box textAlign="center" component="p" color={theme.palette.red[500]}>
        No data to analyze based on the entities or time period selected
      </Box>
    );
  }

  let results;
  let downloadButton = null;
  switch (analysisView) {
    case 'cmi':
      results = <CMIComparisonResults />;
      downloadButton = (
        <Tooltip content={noSummaryData ? 'No data to download based on the entities or time period selected' : ''}>
          <Button
            isBusy={isPDFDownloading}
            label="Download PDF"
            onClick={handlePDFDownload}
            leftAdornment={
              <DownloadIcon
                sx={{ stroke: theme => (noSummaryData ? theme.palette.grey[400] : theme.palette.blue.main) }}
              />
            }
            variant="outlined"
            disabled={noSummaryData}
          />
        </Tooltip>
      );
      break;
    case 'severity':
      results = <SeverityCaptureResults />;
      break;
    case 'drgmix':
      results = <DRGMixResults />;
      downloadButton = (
        <Tooltip content={noSummaryData ? 'No data to download based on the entities or time period selected' : ''}>
          <Button
            isBusy={isDownloading}
            label="Download Excel"
            onClick={handleExcelDownload}
            leftAdornment={
              <DownloadIcon
                sx={{ stroke: theme => (noSummaryData ? theme.palette.grey[400] : theme.palette.blue.main) }}
              />
            }
            variant="outlined"
            disabled={noSummaryData}
          />
        </Tooltip>
      );
      break;
    default:
      throw new Error(`Invalid analysis type - ${analysisView}`);
  }

  return (
    <Box marginY={3} marginX={isMobileView ? 0 : 3}>
      <PageTitle>{title}</PageTitle>
      <Box mt={3}>
        <Box className={selectionContainer}>
          <DatePeriodSelector />
          <Button
            label="Change Comparison"
            onClick={(): void => setSelectorsAreOpen(true)}
            sx={{ width: '15rem' }}
            leftAdornment={<CreateIcon sx={{ mt: '4px' }} />}
          />
          {downloadButton}
        </Box>
        <AnalysisSummaryRow
          leftComponent={<AnalysisNavigation analysisType={analysisView} onAnalysisChange={handleAnalysisViewChange} />}
          rightComponent={<ResultSummaryBox>{summaryComponent}</ResultSummaryBox>}
        />
        <Box>{results}</Box>
      </Box>
      {orgId !== undefined && comparisonOrgId !== undefined ? (
        <EntitySelectionPanel
          myCurrentSelectedEntity={{
            id: orgId,
            name: orgName,
            type: undefined
          }}
          peerCurrentSelectedEntity={{
            id: comparisonOrgId,
            name: comparisonOrgName,
            type: undefined
          }}
          isOpen={selectorsAreOpen}
          toggleIsOpen={(): void => setSelectorsAreOpen(false)}
        />
      ) : null}
    </Box>
  );
};

export default CMIComparison;
