import { Box, SxProps, Theme } from '@mui/material';
import React from 'react';

type IconButtonProps = {
  onClick: () => void;
  sx?: SxProps<Theme>;
};

const IconButton: React.FC<IconButtonProps> = ({ onClick, children, sx }) => {
  return (
    <Box
      sx={{
        border: 'none',
        backgroundColor: 'shade.white',
        '&:hover': {
          cursor: 'pointer'
        },
        ...sx
      }}
      component="button"
      onClick={onClick}
      onTouchEnd={onClick}
    >
      {children}
    </Box>
  );
};

export default IconButton;
