import React, { useContext } from 'react';
import { ModalContext, ModalContextProvider } from './CIModalContext';
import Dialog from '@mui/material/Dialog';
import { Box, DialogTitle, DialogContent, DialogActions, useTheme, IconButton } from '@mui/material';
import Button from '@clinintell/components/button/Button';
import CloseIcon from '@mui/icons-material/Close';
import makeStyles from '@mui/styles/makeStyles';
import { containerBorderRadius } from '@clinintell/theme/theme';

const useStyles = makeStyles(theme => ({
  dialogPaperContainer: {
    height: 'auto',
    minWidth: '515px',
    width: '30vw',
    borderRadius: `${containerBorderRadius}px`
  },
  dialogHeader: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '16px 18px',
    '& .dialogChildContainer': {
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      textAlign: 'center'
    },
    '& .dialogExtBtn': {
      marginLeft: 'auto',
      cursor: 'pointer'
    }
  },
  dialogContent: {
    height: 'auto'
  },
  dialogFooter: {
    display: 'flex',
    backgroundColor: theme.palette.grey[50],
    justifyContent: 'flex-end',
    padding: 16
  }
}));

type DialogProps = {
  modalBtn: JSX.Element;
  onClose?: () => void;
};

// Define the component's composition properties
// Header - Content - Footer
type CIModalComposition = {
  Header: React.FC<ModalHeader>;
  Content: React.FC<ModalContent>;
  Footer: React.FC<ModalFooter>;
};

const CIModal: React.FC<DialogProps> & CIModalComposition = props => {
  return (
    <ModalContextProvider>
      <CIModalContainer {...props}>{props.children}</CIModalContainer>
    </ModalContextProvider>
  );
};

const CIModalContainer: React.FC<DialogProps> = ({ modalBtn, onClose, children }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { modalVisible, toggleVisible } = useContext(ModalContext);
  const handleModalToggle = () => {
    if (modalVisible && onClose !== undefined) onClose();
    if (toggleVisible !== undefined) toggleVisible();
  };

  return (
    <>
      <CIModalButtonWrapper disabled={modalBtn.props['disabled']}>{modalBtn}</CIModalButtonWrapper>
      <Dialog
        classes={{ paper: classes.dialogPaperContainer }}
        onClose={handleModalToggle}
        onBackdropClick={handleModalToggle}
        open={modalVisible}
        disableEscapeKeyDown={true}
      >
        {children}
      </Dialog>
    </>
  );
};

type ModalHeader = {
  id?: string;
  showCloseIcon?: boolean;
};

const CIDialogHeader: React.FC<ModalHeader> = ({ id = 'CIDialogHeaderID', showCloseIcon = false, children }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { toggleVisible } = useContext(ModalContext);
  return (
    <DialogTitle id={id} className={classes.dialogHeader}>
      <Box className="dialogChildContainer">{children}</Box>
      {showCloseIcon ? (
        <IconButton onClick={toggleVisible}>
          <CloseIcon className="dialogExtBtn" fontSize="small" color="primary" data-testid="cimodal-closeIcon" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

type ModalContent = {
  id?: string;
};

const CIDialogContent: React.FC<ModalContent> = ({ id = 'CIDialogContentID', children }) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  return (
    <DialogContent id={id} className={classes.dialogContent}>
      {children}
    </DialogContent>
  );
};

type ModalFooter = {
  id?: string;
  cancelBtn?: boolean;
  cancelBtnEventHandler?: () => void;
};

const CIDialogFooter: React.FC<ModalFooter> = ({
  id = 'CIDialogFooterID',
  cancelBtn = false,
  cancelBtnEventHandler = null,
  children
}) => {
  const theme = useTheme();
  const classes = useStyles(theme);
  const { toggleVisible } = useContext(ModalContext);
  const cancelBtnClickHanlder = () => {
    if (cancelBtnEventHandler != null) cancelBtnEventHandler();
    toggleVisible();
  };

  if (!children) return null;
  return (
    <DialogActions id={id} className={classes.dialogFooter}>
      {cancelBtn ? <Button label="Cancel" type="primary" variant="outlined" onClick={cancelBtnClickHanlder} /> : null}
      {children}
    </DialogActions>
  );
};

type CIModalButtonWrapperProps = {
  clickBtnHandler?: () => void;
  disabled?: boolean;
};

export const CIModalButtonWrapper: React.FC<CIModalButtonWrapperProps> = ({ clickBtnHandler, disabled, children }) => {
  const { toggleVisible } = useContext(ModalContext);
  const clickHandler = () => {
    if (disabled) return;
    if (clickBtnHandler != null) clickBtnHandler();
    toggleVisible();
  };

  if (!children) return null;
  return (
    <Box
      sx={{
        height: 'wrap-content',
        width: 'wrap-content',
        margin: 0,
        padding: 0
      }}
      onClick={clickHandler}
    >
      {children}
    </Box>
  );
};

CIModal.Header = CIDialogHeader;
CIModal.Content = CIDialogContent;
CIModal.Footer = CIDialogFooter;

export default CIModal;
