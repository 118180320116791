import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import SignIn from '@clinintell/containers/authentication/SignIn';
import { parseQueryString } from '@clinintell/utils/querystring';
import SignUp from '@clinintell/containers/authentication/SignUp';
import RouteErrorPage from '@clinintell/containers/errorHandlers/RouteErrorPage';
import { useAuthenticationStatusDispatch } from '@clinintell/modules/store';
import AuthenticationTemplate from '@clinintell/containers/authentication/AuthenticationTemplate';
import Support from '@clinintell/containers/support/Support';
import ConfidentialityAgreement from '@clinintell/containers/authentication/ConfidentialityAgreement';

const UnauthenticatedRoutes = (): JSX.Element => {
  const authDispatch = useAuthenticationStatusDispatch();

  return (
    <Switch>
      <Route
        path="/signin"
        render={(): JSX.Element => (
          <AuthenticationTemplate>
            <ConfidentialityAgreement>
              <SignIn />
            </ConfidentialityAgreement>
          </AuthenticationTemplate>
        )}
      />
      <Route
        path="/callback"
        render={(): JSX.Element => {
          // This route is configured through Auth0 to hit after logging in. It should contain a hash with the access token and other critical information
          authDispatch({ type: 'SET_AUTHENTICATED' });

          return <Redirect to="/" />;
        }}
      />
      <Route
        path="/signup"
        render={({ location }): JSX.Element => {
          const [guid] = parseQueryString(location.search);

          // Supports new guid parameter as well as legacy email parameter
          if (guid.key !== 'guid' && guid.key !== 'email') {
            return <Redirect to="/signin" />;
          }

          return (
            <AuthenticationTemplate>
              <ConfidentialityAgreement>
                <SignUp guid={guid.value} />
              </ConfidentialityAgreement>
            </AuthenticationTemplate>
          );
        }}
      />
      <Route path="/support" render={(): JSX.Element => <Support />} />
      <Route path="/" render={(): JSX.Element => <Redirect to="/signin" />} />
      <Route render={(): JSX.Element => <RouteErrorPage />} />
    </Switch>
  );
};

export default UnauthenticatedRoutes;
