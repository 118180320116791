import { ChartDataSets, ChartPoint } from 'chart.js';
import { SliderPluginClass } from './plugins/sliderPluginClass';

export enum ChartTypes {
  Line = 'Line',
  Bar = 'Bar'
}

export enum LineTypes {
  Solid = 'Solid',
  Dashed = 'Dashed',
  Bar = 'Bar',
  Slider = 'Slider'
}

export type ChartDataTypes = 'decimal' | 'percentage';

export interface YAxisSet {
  min: number;
  max: number;
  title: string;
  dataType: ChartDataTypes;
  precision: number;
}

export interface XAxisSet {
  labels: string[];
  title: string;
}

interface ErrorBar {
  plus: number;
  minus: number;
}

export type ErrorBars = {
  [key: string]: ErrorBar;
};

export interface ChartDatasetBase {
  data: (number | null)[];
  backgroundColor: string | string[];
}

export interface ChartDataset extends ChartDatasetBase {
  label: string;
  borderColor: string | string[];
  pointHoverBackgroundColor: string | string[];
  pointHoverBorderColor: string | string[];
  borderWidth: number;
  borderDash?: number[];
  fill: boolean;
  errorBars?: ErrorBars;
  verticalAnnotation: string;
  yAxisID?: number;
  type: string;
  dataPointsHoverContent: string[];
  visibleBarLabels?: string[];
  minValue: number;
  maxValue: number;
  precision: number;
  prefix?: string;
  postfix?: string;
  hiddenByDefault: boolean;
  barPercentage: number;
  categoryPercentage: number;
  barThickness: number;
  tagDataSet: (string | null)[];
  _meta?: object;
}

interface GridLines {
  display: boolean;
  drawOnChartArea: boolean;
  drawBorder: boolean;
  fontSize?: number;
  color: string | string[];
  tickMarkLength?: number;
  drawTicks?: boolean;
}

interface Ticks {
  display: boolean;
  drawTicks?: boolean;
  labelOffset?: number;
  fontSize: number;
  fontColor?: string;
  padding?: number;
  min?: number;
  max?: number;
  stepSize?: number;
  callback?: (value: number, index: number) => string | null;
  beginAtZero?: boolean;
  autoSkip?: boolean;
}

interface ScaleLabel {
  display: boolean;
  labelString: string;
  fontSize: number;
  fontColor?: string;
}

export interface XAxisOptions {
  gridLines: GridLines;
  ticks: Ticks;
  scaleLabel: ScaleLabel;
}

export interface YAxisOptions {
  position: string;
  id: number;
  gridLines: GridLines;
  ticks: Ticks;
  scaleLabel?: ScaleLabel;
  afterBuildTicks: () => number[];
}

export interface TooltipsCallback {
  label: (args: ChartDataset) => string;
}

export interface Tooltips {
  backgroundColor: string;
  callbacks: TooltipsCallback;
}

type ScaleType = 'y-axis-0' | 'x-axis-0';

type Scale = {
  [key in ScaleType]: {
    ticks: number[];
    getRightValue: (input: number | ChartPoint | null | undefined) => number;
    getPixelForValue: (input: number) => number;
  };
};

export interface ClinIntellChart extends Chart {
  renderedOnce?: boolean;
  scales?: Scale;
  sliderPlugin?: SliderPluginClass;
  sliderPluginConfigured?: boolean;
  initialEasingValue?: number;
}

export interface ClinIntellChartDataSets extends ChartDataSets {
  errorBars?: ErrorBars;
  visibleBarLabels?: string[];
}
