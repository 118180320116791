import React from 'react';
import { useGetAPICAll } from '@clinintell/utils/useGetAPICall';
import { ApplicationAPI } from '@clinintell/utils/api';
import { useHistory } from 'react-router-dom';
import ClinIntellSkeleton from '@clinintell/components/ClinIntellSkeleton';
import { NoDataMessage } from '@clinintell/containers/dashboard/Dashboard';
import { Box, Typography, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import TrainingStartIcon from '@clinintell/components/icons/TrainingStart';
import TrainingResumeIcon from '@clinintell/components/icons/TrainingResume';
import TrainingRetakeIcon from '@clinintell/components/icons/TrainingRetake';
import { QueryStringParam, createUrlWithQueryString } from '@clinintell/utils/querystring';
import { QueryParams } from '@clinintell/containers/dashboard/widgetTypes';

interface ProgressProps {
  endpoint: string;
  queryString?: QueryParams;
}

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    padding: theme.spacing(2),
    minHeight: 100,
    borderBottom: `solid 10px ${theme.palette.blue.main}`,
    cursor: 'pointer',
    '& .icon': {
      transform: 'translateY(0) scale(1)',
      transition: 'transform 100ms linear'
    },
    '&:hover': {
      '& .icon': {
        transform: 'translateY(-0.5rem) scale(1.1)'
      }
    },
    '&:focus': {
      '& .icon': {
        outline: 'none'
      }
    }
  },
  progressTitle: {
    fontWeight: 'bolder',
    textAlign: 'center'
  }
}));

const ProgressWidget: React.FC<ProgressProps> = ({ endpoint, queryString }) => {
  const history = useHistory();
  const theme = useTheme();
  const { container, progressTitle } = useStyles();

  const queryStringValues: QueryStringParam[] = [];

  if (queryString) {
    for (const [key, value] of Object.entries(queryString)) {
      if (value !== null && value !== undefined) {
        queryStringValues.push({ key, value });
      }
    }
  }

  const widgetEndpoint = createUrlWithQueryString(endpoint, queryStringValues);

  interface ProgressJSON {
    orgId: number;
    trainingStatus: string;
    percentComplete: number;
    percentScore: number;
  }

  /* Training Status
  1 - New,
  2 - InProgress,
  3 - Completed,
  4 - Removed,
  5 - Restarted,
  6 - Canceled,
  7 - NotAssigned
  */

  const { output, isLoading } = useGetAPICAll<ProgressJSON>({
    endpoint: widgetEndpoint,
    isWaiting: false,
    duplicateRequestHandler: 'takeLatest'
  });

  if (output && !isLoading && output.error && output.error.toLowerCase().indexOf('not found') > 0) {
    return <NoDataMessage />;
  }

  const isDataMissing = !output && !isLoading;

  let orgId = 0;
  let percentScore = 0;
  let trainingStatus = 'New';

  if (output && output.data && !isLoading) {
    orgId = output.data.orgId;
    percentScore = output.data.trainingStatus === 'InProgress' ? output.data.percentComplete : output.data.percentScore;
    trainingStatus = output.data.trainingStatus;
  }

  let title = 'Start Training';
  let subTitle = '';

  if (trainingStatus === 'InProgress') {
    title = 'Resume Training';
    subTitle = `${percentScore}% Progress`;
  } else if (trainingStatus === 'Completed') {
    title = 'Retake Training';
    subTitle = `Score: ${percentScore}%`;
  }

  const TRAINING_SVG_PROPS = {
    fontSize: '1.5rem',
    color: theme.palette.blue.main
  };

  const handleTrainingProgressClick = async (): Promise<void> => {
    if (trainingStatus === 'Completed') {
      await ApplicationAPI.put({
        endpoint: `training/test/retake?orgId=${orgId}`,
        data: ''
      });
    }

    history.push(`/training/test/${orgId}`);
  };

  return (
    <>
      {isDataMissing ? (
        <NoDataMessage />
      ) : output && output.data && !isLoading ? (
        <Box onClick={handleTrainingProgressClick} className={container}>
          {trainingStatus === 'New' ? (
            <Box
              paddingX={1}
              marginBottom={1}
              className="icon"
              color={theme.palette.blue.main}
              borderBottom={`6px solid rgba(56, 94, 171, 0.5)`}
            >
              <TrainingStartIcon style={TRAINING_SVG_PROPS} />
            </Box>
          ) : trainingStatus === 'InProgress' ? (
            <Box paddingX={1} marginBottom={1} className="icon" color={theme.palette.blue.main}>
              <TrainingResumeIcon style={TRAINING_SVG_PROPS} />
            </Box>
          ) : (
            <Box paddingX={1} marginBottom={1} className="icon" color={theme.palette.blue.main}>
              <TrainingRetakeIcon style={TRAINING_SVG_PROPS} />
            </Box>
          )}
          <Typography variant="h5" className={progressTitle}>
            {title}
          </Typography>
          {trainingStatus !== 'New' ? <Typography variant="p2">{subTitle}</Typography> : null}
        </Box>
      ) : (
        <ClinIntellSkeleton variant="rectangular" width="100%" height="140px" />
      )}
    </>
  );
};

export default ProgressWidget;
