import { Theme } from '@mui/material';
import React from 'react';
import CloseIcon from '../icons/CloseIcon';

const ModalCloseIcon = () => {
  return (
    <CloseIcon
      sx={{
        fill: (theme: Theme) => theme.palette.grey[200],
        transition: 'fill 100ms ease-in-out',
        '&:hover': {
          fill: theme => theme.palette.grey[300],
          cursor: 'pointer'
        },
        '& > path': {
          stroke: theme => theme.palette.grey[600],
          strokeWidth: 0
        }
      }}
    />
  );
};

export default ModalCloseIcon;
