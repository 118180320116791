import { SelectGroup, SelectHospital } from '@clinintell/containers/common';
import { useDefaultDates, useDefaultDatesDispatch } from '@clinintell/modules/store';
import { Typography, useTheme } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect } from 'react';
import AsyncSelect from 'react-select/async';
import { ReportType } from '@clinintell/containers/reports/types/actionTypes';

type HospitalAndGroupFilterProps = {
  groupId?: number;
  hospitalId?: number;
  reportType?: string;
  onPhysicianGroupChange: (groupId: number | null) => void;
  onHospitalChange: (hospitalId: number | null) => void;
  width?: number;
};

const HospitalAndGroupFilter: React.FC<HospitalAndGroupFilterProps> = ({
  groupId,
  hospitalId,
  reportType,
  onPhysicianGroupChange,
  onHospitalChange,
  width = '100%'
}) => {
  const defaultDatesDispatch = useDefaultDatesDispatch();
  const defaultDates = useDefaultDates();

  // Fetch default dates if needed for group
  useEffect(() => {
    if (groupId === undefined || groupId === -1 || defaultDates.byId[groupId]) {
      return;
    }
    defaultDatesDispatch({
      type: 'DEFAULT_DATES_FETCH_REQUESTED',
      payload: groupId
    });
  }, [defaultDates.byId, defaultDatesDispatch, groupId, reportType]);

  return (
    <>
      {reportType === ReportType.System ? (
        ''
      ) : (
        <>
          <Box p={1}>
            <SelectHospital defaultValue={hospitalId} width={width} onHospitalChangeFn={onHospitalChange} />
          </Box>
          <Box p={1}>
            {hospitalId === undefined || hospitalId < 0 ? (
              <EmptyDropdDown dropdownLabel="Physician Group" />
            ) : (
              <SelectGroup
                hospitalId={hospitalId}
                onGroupChangeFn={onPhysicianGroupChange}
                includeAllGroups={false}
                excludeOtherGroups
                excludeOtherMDs
                excludeHiddenProviders
                excludeHiddenSpecialtyGroups
                defaultValue={groupId}
                width={width}
              />
            )}
          </Box>
        </>
      )}
    </>
  );
};

type EmptyDropDownType = {
  dropdownLabel?: string;
};

const EmptyDropdDown: React.FC<EmptyDropDownType> = ({ dropdownLabel }) => {
  const theme = useTheme();
  const width = '400px';

  const fontStyles = {
    fontFamily: theme.typography.fontFamily,
    fontSize: 13,
    color: theme.palette.grey[900]
  };
  const optionStyles = {
    paddingX: 2,
    paddingY: 1.5,
    color: theme.palette.grey[900],
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: theme.palette.grey[50]
    }
  };

  return (
    <Box width="100%" display="flex" flexDirection="column" alignItems="flex-start">
      <Typography width="100%" variant="p2">
        {dropdownLabel}
      </Typography>
      <Box width="100%" style={{ marginTop: 8 }}>
        <AsyncSelect
          isDisabled={true}
          placeholder="Select"
          styles={{
            container: (provided, _state) => ({
              ...provided,
              minWidth: width
            }),
            indicatorsContainer: (provided, _state) => ({
              ...provided,
              '&:hover': {
                backgroundColor: theme.palette.grey[50],
                borderRadius: 25,
                width: 32,
                height: 32,
                margin: 2,
                '& div': {
                  padding: 6
                }
              },
              '& span': {
                display: 'none'
              }
            }),
            dropdownIndicator: (provided, state) => ({
              ...provided,
              '& svg': {
                width: '1em',
                height: '1em',
                fontSize: '1.5em',
                transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : 'rotate(0deg)',
                transition: 'transform 250ms',
                strokeWidth: '0.25px'
              }
            }),
            control: (provided, _state) => ({
              ...provided,
              ...fontStyles,
              borderRadius: 10,
              textAlign: 'left',
              borderColor: theme.palette.grey[200],
              boxShadow: `0 0 0 0 ${theme.palette.grey[200]}`,
              '&:hover': {
                borderColor: theme.palette.grey[200]
              }
            }),
            menu: (provided, _state) => ({
              ...provided,
              ...fontStyles,
              width: '100%',
              borderRadius: 10,
              overflow: 'hidden',
              zIndex: theme.zIndex.modal + 3
            }),
            menuList: (provided, _state) => ({
              MaxHeight: '400px',
              ...provided
            }),
            menuPortal: (provided, _state) => ({
              ...provided,
              zIndex: theme.zIndex.modal + 3
            }),
            option: (provided, state) => ({
              ...provided,
              ...optionStyles,
              fontWeight: state.isSelected ? 600 : 'normal',
              borderBottom: `0 solid ${theme.palette.grey[200]}`
            })
          }}
        />
      </Box>
    </Box>
  );
};

export default React.memo(HospitalAndGroupFilter);
