import React, { useState, useEffect } from 'react';
import AgGrid from '@clinintell/components/agGrid';
import * as Cells from '@clinintell/components/agGrid/cells';
import { Row, ColumnDefinition, RowsPerPageValues, RowValue } from '@clinintell/components/agGrid/tablesTypes';
import { ValueGetterParams, ColDef, ICellRendererParams, GridApi } from 'ag-grid-community/dist/lib/main';
import { formatNumberWithCommas } from '@clinintell/utils/formatting';
import { format } from 'date-fns';
import { useTheme } from '@mui/material';
import useMetricTooltips from '../logic/useMetricTooltips';
import { TableStateType } from '@clinintell/modules/metricsNavigation';

export interface MetricsTableProps {
  maxHeight?: string;
  tableLayout?: 'auto' | 'fixed';
  className?: string;
  rows?: Row[];
  footerRow?: Row;
  columns: Record<string, ColumnDefinition>;
  rowsPerPage: RowsPerPageValues;
  isDashboard?: boolean;
  metric?: string;
  columnIdentifier?: string;
  view?: string;
  selectedRows?: number[];
  selectRows?: (rows: number[]) => void;
  initialSelectedRows?: number[];
  tableId?: string;
  defaultSortColumn: string;
  display?: string;
  testID?: string;
  getGridApiOnReady?: (api: GridApi) => void;
  restoreTableInfo?: () => void;
  tableState?: TableStateType;
}

export const sortingComparator = (valueA: RowValue, valueB: RowValue): number => {
  if (!!valueA && !!valueB) {
    if (valueA.rawValue === valueB.rawValue) return 0;
    return valueA.rawValue > valueB.rawValue ? 1 : -1;
  }
  return 0;
};

const nameFilterParams = {
  applyMiniFilterWhileTyping: true,
  valueGetter: (params: ValueGetterParams): number | string => {
    return params.data.name.rawValue;
  },
  comparator: (a: string, b: string): number => {
    return a.localeCompare(b);
  }
};

const selectionFilterParams = {
  applyMiniFilterWhileTyping: true,
  valueGetter: (params: ValueGetterParams): number | string => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    if (params.selected === true) return 'Selected';
    else return 'Unselected';
  }
};

const typeFilterParams = {
  applyMiniFilterWhileTyping: true,
  valueGetter: (params: ValueGetterParams): number | string => {
    if (params.data.conditionTypeId.rawValue === 1) return 'DRG';
    else if (params.data.conditionTypeId.rawValue === 2) return 'HCC';
    else return 'EX';
  }
};

const MetricsTable = (props: MetricsTableProps): JSX.Element => {
  const [rowData, setRowData] = useState([]);
  const [footerData, setFooterData] = useState();
  const [tableHeight, setTableHeight] = useState(!!props.isDashboard ? 80 : 128);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getFooter();
    getRowsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.columns]);

  useEffect(() => {
    setIsLoading(true);
    const loadTimeout = setTimeout(() => {
      setIsLoading(false);
    }, 10);

    return (): void => {
      clearTimeout(loadTimeout);
    };
  }, [props.view]);

  const theme = useTheme();
  const { getTooltip } = useMetricTooltips();

  const getColumns = (): Array<ColDef> => {
    const columns = props.columns;
    const isDashboard = props.isDashboard;
    const agGridColumns: Array<ColDef> = [];

    Object.keys(props.columns).map((key: string): boolean => {
      if (key === 'action' && isDashboard) return false;
      const centredClass = key === 'name' || key === 'mdcName' ? '' : 'centred-header';
      const otherMinWith =
        key === 'conditionTypeId'
          ? 100
          : key === 'name' && isDashboard && columns[key].title !== 'Clinical Condition'
          ? 200
          : key === 'name' && !isDashboard && columns[key].title !== 'Clinical Condition'
          ? 390
          : key === 'name' && columns[key].title === 'Clinical Condition'
          ? 390
          : 80;

      const otherMaxWith = key === 'conditionTypeId' ? 100 : undefined;
      const communProps = {
        field: key,
        headerName:
          key === 'isTargetCondition'
            ? '...'
            : key === 'gapVsCe'
            ? 'Gap'
            : key === 'action'
            ? 'Action'
            : columns[key].title,
        sort:
          !!props.defaultSortColumn && props.defaultSortColumn === key && key !== 'conditionTypeId' ? 'desc' : false,
        headerComponentParams: {
          tooltipMessage: getTooltip(key, props.metric ?? 'cmi', props.view ?? 'opportunityRW')
        }
      };

      const agGridColumnTmp =
        key === 'isTargetCondition'
          ? {
              ...communProps,
              minWidth: 95,
              maxWidth: 95,
              headerClass: `${key} resizable-header column-header-style ag-selection-checkbox-div-header ${centredClass}`,
              cellClass: 'ag-selection-checkbox-div',
              checkboxSelection: true,
              headerCheckboxSelection: true,
              sort: 'asc',
              filter: true,
              filterParams: selectionFilterParams,
              menuTabs: ['filterMenuTab']
            }
          : key === 'mdcName'
          ? {
              ...communProps,
              minWidth: 450,
              headerClass: `${key} resizable-header column-header-style ${centredClass}`,
              filter: true,
              filterParams: { applyMiniFilterWhileTyping: true },
              cellStyle: { fontFamily: theme.typography.fontFamily, fontSize: theme.typography.p2.fontSize },
              menuTabs: ['filterMenuTab'],
              headerComponentParams: {
                align: 'flex-start'
              }
            }
          : key === 'name' || key === 'conditionTypeId'
          ? {
              ...communProps,
              minWidth: otherMinWith,
              maxWidth: otherMaxWith,
              headerClass: `${key} resizable-header column-header-style ${centredClass}`,
              cellRenderer: 'cellRender',
              comparator: sortingComparator,
              filter: true,
              filterParams: key === 'conditionTypeId' ? typeFilterParams : nameFilterParams,
              menuTabs: ['filterMenuTab'],
              headerComponentParams: {
                align: key === 'conditionTypeId' ? 'center' : 'flex-start'
              }
            }
          : key === 'action'
          ? {
              ...communProps,
              minWidth: 100,
              maxWidth: 100,
              headerClass: `${key} resizable-header column-header-style ${centredClass}`,
              cellRenderer: 'cellRender',
              checkboxSelection: false,
              headerCheckboxSelection: false,
              suppressMenu: true,
              sortable: false,
              sort: null
            }
          : {
              ...communProps,
              minWidth: otherMinWith,
              headerClass: `${key} resizable-header column-header-style ${centredClass}`,
              cellRenderer: 'cellRender',
              comparator: sortingComparator,
              suppressMenu: true
            };
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      agGridColumns.push(agGridColumnTmp);

      return true;
    });

    return agGridColumns;
  };

  const getRowsData = (): void => {
    const columnIdentifier = !!props.columnIdentifier ? props.columnIdentifier : 'name';
    const columns = props.columns;
    const rows = props.rows;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const rowsTmp: any = [];

    if (!!rows && rows.length > 0) {
      let rowObject = {};
      rows.map((row: Row): boolean => {
        const rowId =
          (row[columnIdentifier] as RowValue).condition && !!props.display && props.display !== 'comparison'
            ? Number((row[columnIdentifier] as RowValue).condition)
            : Number((row[columnIdentifier] as RowValue).uId);

        if (!!rows && !!columns) {
          Object.keys(columns).map((key: string): boolean => {
            const { prefix = '', postfix = '' } = columns[key];
            const rowHasValue = !!(row[key] as RowValue);
            if (key === 'isTargetCondition')
              rowObject = { ...rowObject, [key]: rowHasValue && (row[key] as RowValue).rawValue ? 1 : 2 };
            else
              rowObject = {
                ...rowObject,
                [key]: rowHasValue
                  ? key === 'mdcName'
                    ? (row[key] as RowValue).rawValue
                    : {
                        key,
                        rawValue: (row[key] as RowValue).rawValue,
                        prefix,
                        postfix,
                        restProps: (row[key] as RowValue).restProps,
                        rowId
                      }
                  : '',
                rowId,
                id: rowId
              };
            return true;
          });
          rowsTmp.push(rowObject);
        }

        return true;
      });
    }
    // return rowsTmp;
    setRowData(rowsTmp);
  };

  const getFooter = (): void => {
    const footerRow = props.footerRow;
    const columns = props.columns;

    const footerTmp = [];
    let rowObject = {};
    if (!!footerRow && !!columns) {
      Object.keys(columns).map((key: string): boolean => {
        const { prefix = '', postfix = '' } = columns[key];
        rowObject = {
          ...rowObject,
          [key]: !!footerRow[key]
            ? {
                key,
                rawValue: (footerRow[key] as RowValue).rawValue,
                prefix,
                postfix,
                restProps: (footerRow[key] as RowValue).restProps
              }
            : '',
          rowId: 0
        };
        return true;
      });

      footerTmp.push(rowObject);
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    setFooterData(footerTmp);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getRenderCells = (params: ICellRendererParams): any => {
    const { key, rawValue, prefix, postfix, restProps, rowId } = params.value;
    const otherChildren = !!restProps ? restProps.otherChildren : undefined;
    const showTrainingTooltip = !!restProps ? restProps.showTrainingTooltip : undefined;
    const type = !!restProps ? restProps.type : undefined;
    const styledValue = cellFormat(rawValue, prefix, postfix, type);
    const { isDashboard, columns } = props;

    const renderCells = {
      [key]:
        (key === 'name' && !isDashboard) ||
        (isDashboard && columns[key].title === 'Clinical Condition' && !showTrainingTooltip)
          ? Cells['NavigationCell']
          : key === 'action' && !isDashboard
          ? Cells['ActionCell']
          : key === 'name' && isDashboard && columns[key].title === 'Clinical Condition' && showTrainingTooltip
          ? Cells['ConditionCell']
          : key === 'gapVsCe' ||
            key === 'change' ||
            key === 'otoEGapVsCe' ||
            key === 'otoEChange' ||
            key === 'otoEErrorChangePositive' ||
            key === 'otoEErrorChangeNegative' ||
            key === 'otoEErrorChange' ||
            key === 'otoEErrorGapPositive' ||
            key === 'otoEErrorGapNegative' ||
            key === 'otoEErrorGap'
          ? Cells['BubbleCell']
          : key === 'conditionTypeId'
          ? Cells['ConditionTypeCell']
          : key !== 'mdcName'
          ? Cells['DefaultCell']
          : undefined,
      restProps: { ...restProps, showTrainingTooltip, rawValue },
      othersProps:
        !!otherChildren && otherChildren.length > 0
          ? {
              ...restProps,
              openOthers: true
            }
          : null,
      OthersCell: !!otherChildren && otherChildren.length > 0 ? Cells['OthersCell'] : null,
      styledValue: styledValue,
      value: rawValue,
      key,
      column: key,
      rowId
    };

    return renderCells;
  };

  const defaultColDef = {
    sortable: true,
    resizable: !props.isDashboard,
    flex: 1,
    headerCheckboxSelectionFilteredOnly: true,
    sortingOrder: ['asc', 'desc']
  };

  const footerStyle = {
    color: theme.palette.common.black,
    background: theme.palette.blue.light5,
    fontWeight: 700,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  };

  const isAllConditions =
    !!props.columns && !!Object.keys(props.columns).filter(key => key === 'mdcName').length && !props.isDashboard;

  return (
    <>
      {isLoading ? (
        ''
      ) : (
        <AgGrid
          {...props}
          columnDefs={getColumns()}
          rowData={rowData}
          getRenderCells={getRenderCells}
          footer={footerData}
          defaultColDef={defaultColDef}
          footerStyle={footerStyle}
          pagination={!props.isDashboard}
          tableFinalHeight={
            isAllConditions ? '65rem' : rowData.length * 38 + (!!props.footerRow ? tableHeight : tableHeight - 39)
          }
          setTableHeight={setTableHeight}
          checkboxSelection={props.metric === 'allConditions'}
          rowsPerPage={props.rowsPerPage}
        />
      )}
    </>
  );
};

export default MetricsTable;

export const cellFormat = (
  rawValue: string | number | number,
  prefix: string,
  postfix: string,
  type: string
): string => {
  let styledValue = '';
  if (type !== 'boolean') styledValue = `${prefix || ''}${styleCell(rawValue as string, type)}${postfix || ''}`;
  if (styledValue.startsWith('$-')) styledValue = styledValue.replace('$-', '-$');
  else if (rawValue === null && (type === 'number' || type === 'integer')) styledValue = '-';
  else if (rawValue === null && type === 'string') styledValue = '';
  else if (type === 'date') styledValue = rawValue ? format(new Date(rawValue as string), 'MM/dd/yyyy') : '-';

  if (styledValue === '-$') return '-';
  return styledValue;
};

const styleCell = (value: string | number | null, type: string): string => {
  if (value === null || value === '') return '-';

  switch (type) {
    case 'string':
      return value.toString();
    case 'number':
      if (value === 0) return '0.00';
      return formatNumberWithCommas(Number(value).toFixed(2));
    case 'integer':
      return formatNumberWithCommas(Number(value));
    default:
      return '-';
  }
};
