import React, { useCallback } from 'react';
import { useGetAPICAll } from '@clinintell/utils/useGetAPICall';
import {
  MetricRecordJSON,
  MetricSchema,
  MetricConditionRecordJSON
} from '@clinintell/containers/metrics/typings/metricTypes';
import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { ColumnView, getDefaultViewForMetric } from '@clinintell/containers/metrics/typings/tableSchemas';
import { NoDataMessage } from '@clinintell/containers/dashboard/Dashboard';
import { Metrics as MetricTypes } from '@clinintell/modules/metricsNavigation';
import ClinIntellSkeleton from '@clinintell/components/ClinIntellSkeleton';
import { QueryStringParam, createUrlWithQueryString } from '@clinintell/utils/querystring';
import { QueryParams, TableInfo } from '@clinintell/containers/dashboard/widgetTypes';
import MetricsAgGridTable from '@clinintell/containers/metrics/sections/metricsAgGridTable';
import useMetricsDataConversionForTable from '@clinintell/containers/metrics/logic/useMetricsDataConversionForTable';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  table: {
    [theme.breakpoints.down(1425)]: {
      '& .ag-theme-alpine .ag-root-wrapper .ag-root-wrapper-body .ag-layout-normal': {
        overflow: 'hidden'
      }
    }
  },
  training: {
    '& table td:not(:last-child)': {
      color: `${theme.palette.blue.main} !important`,
      cursor: 'pointer !important'
    },
    '& table td.notApproved': {
      color: `${theme.palette.common.black} !important`,
      cursor: 'default !important'
    }
  }
}));

type WidgetProps = {
  name: string;
  hashId: string;
  endpoint: string;
  metric?: MetricTypes;
  queryString?: QueryParams;
  tableInfo: TableInfo;
};

const TableWidget: React.FC<WidgetProps> = props => {
  if (
    (props.queryString && props.queryString.includeAllConditions && props.queryString.includeAllConditions.length) ||
    props.metric === 'allConditions' ||
    props.metric === 'targetedConditions' ||
    props.metric === 'condition'
  ) {
    return <TableConditionWidget {...props} />;
  }
  return <TableMetricWidget {...props} />;
};

const TableMetricWidget: React.FC<WidgetProps> = ({ name, hashId, endpoint, metric, queryString, tableInfo }) => {
  const { limit } = tableInfo;
  const { table } = useStyles();
  const queryStringParams: QueryStringParam[] = [];

  if (queryString) {
    for (const [key, value] of Object.entries(queryString)) {
      if (value !== null && value !== undefined) {
        queryStringParams.push({ key, value });
      }
    }
  }

  const metricsEndpoint = createUrlWithQueryString(endpoint, queryStringParams);

  const { output: metricOutput, isLoading: metricsAreLoading } = useGetAPICAll<MetricRecordJSON[]>({
    endpoint: metricsEndpoint,
    isWaiting: false,
    duplicateRequestHandler: 'takeEvery'
  });

  const { output: metricSchemaOutput, isLoading: schemaIsLoading } = useGetAPICAll<MetricSchema>({
    endpoint: `schema/${metric === 'condition' || metric === 'allConditions' ? 'condition' : metric}`,
    isWaiting: false,
    duplicateRequestHandler: 'takeEvery'
  });

  const { rows, footerRow, columns: tableColumns, defaultSortColumn } = useMetricsDataConversionForTable({
    dataset: metricOutput && metricOutput.data ? metricOutput.data : null,
    schema: metricSchemaOutput && metricSchemaOutput.data ? metricSchemaOutput.data : null,
    tableColumnView: tableInfo as ColumnView,
    valueType: 'rwpv',
    metric: metric ?? MetricTypes.cmi,
    periodStart: queryString && queryString['currentStart'] ? queryString['currentStart'] : undefined,
    periodEnd: queryString && queryString['currentEnd'] ? queryString['currentEnd'] : undefined,
    comparisonPeriodStart: queryString && queryString['historicalStart'] ? queryString['historicalStart'] : undefined,
    comparisonPeriodEnd: queryString && queryString['historicalEnd'] ? queryString['historicalEnd'] : undefined,
    isLoading: metricsAreLoading,
    rowCountLimit: name === 'top10UnderDocumentedClinicalConditionTable' ? limit : undefined,
    selectedEntity: null
  });

  if (metricOutput && metricOutput.data && metricOutput.data.length === 0) {
    return <NoDataMessage />;
  }

  const isTableDataMissing =
    metricOutput &&
    metricOutput.data &&
    metricOutput.data.filter(data => data.name === null && data.orgId === 0).length > 0;

  return (
    <>
      {isTableDataMissing ? (
        <NoDataMessage />
      ) : (
        <Box key={`widgetTable-${hashId}`} className={table} maxHeight={380} style={{ overflowY: 'auto' }}>
          {metricOutput && metricSchemaOutput && !schemaIsLoading && !metricsAreLoading ? (
            <MetricsAgGridTable
              rows={rows}
              footerRow={footerRow}
              defaultSortColumn={defaultSortColumn}
              key={`tbl-${name}-${hashId}`}
              tableId={hashId}
              maxHeight="100%"
              columns={tableColumns}
              rowsPerPage={100}
              isDashboard={true}
              metric={metric ?? MetricTypes.cmi}
              view={tableInfo.view ?? getDefaultViewForMetric(metric ?? MetricTypes.cmi)}
            />
          ) : (
            <ClinIntellSkeleton variant="rectangular" width="100%" height="20rem" />
          )}
        </Box>
      )}
    </>
  );
};

const TableConditionWidget: React.FC<WidgetProps> = ({ hashId, name, endpoint, metric, queryString, tableInfo }) => {
  const { training, table } = useStyles();
  // const { defaultPeriodEnd, defaultPeriodStart } = useMetricsNavigation();
  const { tableType } = tableInfo;
  const queryStringParams: QueryStringParam[] = [];

  if (queryString) {
    for (const [key, value] of Object.entries(queryString)) {
      if (value !== null && value !== undefined) {
        queryStringParams.push({ key, value });
      }
    }
  }

  const metricsEndpoint = createUrlWithQueryString(endpoint, queryStringParams);

  const { output: metricOutput, isLoading: metricsAreLoading } = useGetAPICAll<MetricConditionRecordJSON>({
    endpoint: metricsEndpoint,
    isWaiting: false,
    duplicateRequestHandler: 'takeEvery'
  });

  const { output: metricSchemaOutput, isLoading: schemaIsLoading } = useGetAPICAll<MetricSchema>({
    endpoint: `schema/${
      metric === 'condition' || metric === 'allConditions' || metric === 'targetedConditions' ? 'condition' : metric
    }`,
    isWaiting: false,
    duplicateRequestHandler: 'takeEvery'
  });

  const handleNameClick = useCallback((entity: number, condition?: number): void => {
    window.location.href = `training/${entity}/condition/${condition}?referrer=dashboard`;
  }, []);

  if (
    (metric === 'allConditions' || metric === 'condition' || metric === 'targetedConditions') &&
    !tableInfo.columns.some(col => col === 'conditionTypeId') &&
    !queryString?.conditionId
  ) {
    tableInfo.columns = [...tableInfo.columns.slice(0, 1), 'conditionTypeId', ...tableInfo.columns.slice(1)];
  }

  const { rows, footerRow, columns: tableColumns, defaultSortColumn } = useMetricsDataConversionForTable({
    dataset: metricOutput && metricOutput.data ? (metricOutput.data.metrics as MetricRecordJSON[]) : null,
    schema: metricSchemaOutput && metricSchemaOutput.data ? metricSchemaOutput.data : null,
    tableColumnView: tableInfo as ColumnView,
    valueType: 'rwpv',
    metric: metric ?? MetricTypes.cmi,
    periodStart: queryString && queryString['currentStart'] ? queryString['currentStart'] : undefined,
    periodEnd: queryString && queryString['currentEnd'] ? queryString['currentEnd'] : undefined,
    comparisonPeriodStart: queryString && queryString['historicalStart'] ? queryString['historicalStart'] : undefined,
    comparisonPeriodEnd: queryString && queryString['historicalEnd'] ? queryString['historicalEnd'] : undefined,
    isLoading: metricsAreLoading,
    onConditionClick: tableType && tableType === 'training' ? handleNameClick : undefined,
    selectedEntity: null
  });

  if (metricOutput && metricOutput.data && metricOutput.data.metrics.length === 0) {
    return <NoDataMessage />;
  }

  const isTableDataMissing =
    metricOutput &&
    metricOutput.data &&
    metricOutput.data.metrics.filter(data => data.name === null && data.orgId === 0).length > 0;

  return (
    <>
      {isTableDataMissing ? (
        <NoDataMessage />
      ) : (
        <Box
          key={`widget-${name}`}
          className={tableType && tableType === 'training' ? clsx(training, table) : table}
          maxHeight={380}
          style={{ overflowY: 'auto' }}
        >
          {metricOutput && metricSchemaOutput && !schemaIsLoading && !metricsAreLoading ? (
            <MetricsAgGridTable
              rows={rows}
              footerRow={footerRow}
              defaultSortColumn={defaultSortColumn}
              columns={tableColumns}
              key={`tbl-${name}`}
              tableId={hashId}
              maxHeight="100%"
              isDashboard={true}
              rowsPerPage={100}
              metric={metric ?? MetricTypes.cmi}
              view={tableInfo.view ?? getDefaultViewForMetric(metric ?? MetricTypes.cmi)}
            />
          ) : (
            <ClinIntellSkeleton variant="rectangular" width="100%" height="20rem" />
          )}
        </Box>
      )}
    </>
  );
};

export default TableWidget;
