import { ChildMenuItem, MenuItem } from '@clinintell/modules/menuNavigation';
import useIsZendeskTenant from '@clinintell/utils/useIsZendeskTenant';
import React from 'react';
import useMenuActions from '../logic/useMenuActions';
import MenuNavigation from '../MenuNavigation';
import ChildMenuNavigationLink from './ChildMenuNavigationLink';
import MenuNavigationLink from './MenuNavigationLink';
import MenuNavigationParent from './MenuNavigationParent';

type MenuNavigationContainerProps = {
  onMenuItemClick: () => void;
};

const MenuNavigationContainer: React.FC<MenuNavigationContainerProps> = ({ onMenuItemClick }) => {
  const { handleMenuParentClick, handleChildMenuLinkClick, handleMenuLinkClick } = useMenuActions();
  const isZendeskTenant = useIsZendeskTenant();

  const handleMobileMenuLinkClick = (menuItem: MenuItem) => {
    handleMenuLinkClick(menuItem);
    onMenuItemClick();
  };

  const handleMobileChildMenuLinkClick = (childMenuItem: ChildMenuItem) => {
    handleChildMenuLinkClick(childMenuItem);
    onMenuItemClick();
  };

  const renderMenuNavigationLink = (menuItem: MenuItem) => {
    if (menuItem.isZendeskTenant && !isZendeskTenant) {
      return null;
    }

    return menuItem.children.length > 0 ? (
      <MenuNavigationParent
        key={menuItem.name}
        renderChildMenuNavigationLink={childMenuItem => (
          <ChildMenuNavigationLink
            key={childMenuItem.name}
            onClick={handleMobileChildMenuLinkClick}
            childMenuItem={childMenuItem}
          />
        )}
        menuItem={menuItem}
        onClick={handleMenuParentClick}
        sx={{ borderBottom: '1px solid', borderColor: 'grey.200', pb: 2, mb: 2 }}
      />
    ) : (
      <MenuNavigationLink
        key={menuItem.name}
        onClick={handleMobileMenuLinkClick}
        menuItem={menuItem}
        sx={{ borderBottom: '1px solid', borderColor: 'grey.200', pb: 2, mb: 2 }}
      />
    );
  };

  return <MenuNavigation renderMenuNavigationLink={renderMenuNavigationLink} />;
};

export default MenuNavigationContainer;
