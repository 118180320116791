import { useCallback, useEffect, useRef } from 'react';
import * as uuid from 'uuid';
import { useUser } from '@clinintell/modules/store';
import { createJwtToken } from '@clinintell/utils/jwtTokens';
import { lazyLoadScript } from '@clinintell/utils/lazyLoad';
import useIsZendeskTenant from '@clinintell/utils/useIsZendeskTenant';

type ZendeskHelpCenterAuthenticationPayload = {
  iat: number;
  jti: string;
  email: string;
  name: string;
  external_id: string;
};

const useAuthenticateWithZendesk = (isAuthenticated: boolean) => {
  const user = useUser();
  const userRef = useRef(user);
  const isZendeskTenant = useIsZendeskTenant();

  const getJwtToken = useCallback((): string | null => {
    if (!userRef.current.isLoaded) {
      return null;
    }

    const date = new Date().getTime();
    const secondsSinceEpoch = Math.floor(date / 1000);
    const data = {
      iat: secondsSinceEpoch,
      jti: uuid.v4(),
      email: userRef.current.email,
      name: userRef.current.name,
      external_id: userRef.current.id.toString()
    };

    const token = createJwtToken<ZendeskHelpCenterAuthenticationPayload>(
      data,
      ZENDESK_WEB_WIDGET_AUTHENTICATION_SECRET
    );
    return token;
  }, []);

  useEffect(() => {
    if (user.isLoaded) {
      userRef.current = user;
    }
  }, [user]);

  useEffect(() => {
    if (!isZendeskTenant) {
      return;
    }

    window.zESettings = {
      webWidget: {
        authenticate: {
          jwtFn: function(callback: (token: string) => void) {
            const token = getJwtToken();
            if (!token) {
              return;
            }

            callback(token);
          }
        }
      }
    };

    const webWidgetSnippetUrl = `https://static.zdassets.com/ekr/snippet.js?key=${ZENDESK_WEB_WIDGET_SNIPPET_URL}`;
    lazyLoadScript('ze-snippet', webWidgetSnippetUrl);
  }, [getJwtToken, isZendeskTenant]);

  useEffect(() => {
    if (!isZendeskTenant) {
      return;
    }

    if (!isAuthenticated || !user.isLoaded) {
      return;
    }

    zE('webWidget', 'helpCenter:reauthenticate');
  }, [isAuthenticated, isZendeskTenant, user.email, user.id, user.isLoaded, user.name]);
};

export default useAuthenticateWithZendesk;
