import { Box, Paper, Slide, Theme, Typography, Backdrop } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useContext, useEffect } from 'react';
import { ActionPanelContext } from '@clinintell/modules/actionPanelContext';
import { WindowSizeContext } from '@clinintell/modules/windowSizeContext';
import ModalCloseIcon from '@clinintell/components/icons/ModalCloseIcon';
import clsx from 'clsx';

type StyleProps = {
  wide: boolean;
  direction: ActionPanelProps['direction'];
};

const useStyles = makeStyles<Theme, StyleProps>(theme => ({
  container: {
    position: 'fixed',
    [theme.breakpoints.up('md')]: {
      height: ({ wide }): string | number => (wide ? '50%' : '100vh')
    },
    height: '100vh',
    overflowY: 'auto',
    margin: 0,
    top: ({ direction }): string | number => (direction === 'up' ? '50%' : document.body.scrollTop),
    zIndex: theme.zIndex.actionPanel,
    borderRadius: 0,
    boxShadow: '-2px 0px 7px rgba(0, 0, 0, 0.3)',
    overflow: 'auto'
  },
  wide: {
    minWidth: 500,
    [theme.breakpoints.down('md')]: {
      minWidth: '100%'
    },
    width: ({ wide }): string | number => (wide ? '100vw' : '50%'),
    maxWidth: ({ wide }): string | number => (wide ? '100vw' : '50%')
  },
  sm: {
    width: '50%'
  },
  md: {
    width: '75%'
  },
  lg: {
    width: '100%'
  },
  header: {
    borderBottom: `1px solid #CBD5E1`,
    '& h4': {
      fontWeight: 'bold',
      fontSize: '1.4rem',
      color: theme.palette.common.black
    }
  },
  actionBackdrop: {
    zIndex: theme.zIndex.actionPanel
  }
}));

export type ValidationSummary = {
  isValid: boolean;
  summary: string[];
};

interface ActionPanelProps {
  open: boolean;
  closeFn: () => void;
  title: string | JSX.Element;
  direction?: 'left' | 'right' | 'up' | 'down';
  children: JSX.Element;
}

const ActionPanel: React.FC<ActionPanelProps> = ({ open, closeFn, title, direction = 'left', children }) => {
  const { container, wide, sm, md, lg, header, actionBackdrop } = useStyles({
    wide: direction === 'up' || direction === 'down',
    direction
  });
  const { width } = useContext(WindowSizeContext);
  const { setIsActionPanelOpen } = useContext(ActionPanelContext);

  useEffect(() => {
    setIsActionPanelOpen(open);

    if (open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [open, setIsActionPanelOpen]);

  return (
    <Backdrop
      open={open}
      className={actionBackdrop}
      onKeyPress={(e: React.KeyboardEvent): void => {
        if (e.key === 'escape') {
          closeFn();
        }
      }}
    >
      <Slide
        direction={direction}
        in={open}
        mountOnEnter
        unmountOnExit
        style={direction === 'left' ? { right: 0 } : { left: 0 }}
      >
        <Paper
          className={clsx(
            container,
            'actionpanel',
            direction === 'up' || direction === 'down' ? wide : width < 1200 ? lg : width < 1600 ? md : sm
          )}
        >
          <Box className={header} p={2}>
            <Typography variant="h4">
              <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                <Box display="flex" flexDirection="row" alignItems="center" justifyContent="flex-start" width="100%">
                  <Box onClick={closeFn} title="Cancel &amp; Close">
                    <ModalCloseIcon />
                  </Box>
                  <Box width="100%" ml={2} mb={0.5}>
                    {title}
                  </Box>
                </Box>
              </Box>
            </Typography>
          </Box>
          {children}
        </Paper>
      </Slide>
    </Backdrop>
  );
};

export default ActionPanel;
