import { TrainingProgressStatus } from '@clinintell/containers/trainingTest/types/trainingProgressTypes';
import { ApplicationAPI, AsyncOutput } from '@clinintell/utils/api';
import { SagaIterator } from 'redux-saga';
import { all, call, put, takeLatest, takeLeading } from 'redux-saga/effects';

export type ProviderTrainingAssignmentState = {
  orgId: number;
  orgName: string;
  trainingStatusId: number;
  trainingStatus: TrainingProgressStatus;
  percentComplete: number;
  percentScore: number;
  totalQuestions: number;
  questionAnswered: number;
  correctAnswers: number;
  totalVideos: number;
  totalVideosWatched: number;
  currentDocumentationScore: number | null;
  assignedDate: string;
  dueDate: string | null;
  completedDate: string | null;
  lastUpdated: string;
};

export type ProviderTrainingAssignmentPostProps = {
  orgId: number;
  providerIds: number[];
  conditionIds: number[];
  forceNewTraining: boolean;
  onEditCompleted?: (value: unknown) => void;
};

export type ProviderTrainingEditProps = {
  orgId: number;
  providerId: number;
  conditionsAdded?: number[];
  conditionsRemoved?: number[];
  forceNewTraining: boolean;
  sendNotification: boolean;
  onEditCompleted?: (value: unknown) => void;
};

// Actions
enum TrainingAssignmentActions {
  FETCH_GROUP_TRAINING_ASSIGNMENT = 'FETCH_GROUP_TRAINING_ASSIGNMENT',
  FETCH_GROUP_TRAINING_ASSIGNMENT_BEGIN = 'FETCH_GROUP_TRAINING_ASSIGNMENT_BEGIN',
  FETCH_GROUP_TRAINING_ASSIGNMENT_SUCCESSFUL = 'FETCH_GROUP_TRAINING_ASSIGNMENT_SUCCESSFUL',
  FETCH_GROUP_TRAINING_ASSIGNMENT_FAIL = 'FETCH_GROUP_TRAINING_ASSIGNMENT_FAIL',
  POST_GROUP_TRAINING_ASSIGNMENT = 'POST_GROUP_TRAINING_ASSIGNMENT',
  POST_GROUP_TRAINING_ASSIGNMENT_BEGIN = 'POST_GROUP_TRAINING_ASSIGNMENT_BEGIN',
  POST_GROUP_TRAINING_ASSIGNMENT_SUCCESSFUL = 'POST_GROUP_TRAINING_ASSIGNMENT_SUCCESSFUL',
  POST_GROUP_TRAINING_ASSIGNMENT_FAIL = 'POST_GROUP_TRAINING_ASSIGNMENT_FAIL',
  EDIT_TRAINING_ASSIGNMENT = 'EDIT_TRAINING_ASSIGNMENT',
  EDIT_TRAINING_ASSIGNMENT_BEGIN = 'EDIT_TRAINING_ASSIGNMENT_BEGIN',
  EDIT_TRAINING_ASSIGNMENT_SUCCESSFUL = 'EDIT_TRAINING_ASSIGNMENT_SUCCESSFUL',
  EDIT_TRAINING_ASSIGNMENT_FAIL = 'EDIT_TRAINING_ASSIGNMENT_FAIL'
}

type TrainingAssignmentPayload = ProviderTrainingAssignmentState[] | string;

export type TrainingAssignmentState = {
  providers: ProviderTrainingAssignmentState[];
  isLoading: boolean;
  errorMessage?: string;
};

export type TrainingAssignmentAction<T> = {
  type?: keyof typeof TrainingAssignmentActions;
  payload?: T;
};

export const fetchGroupTrainingAssignment = (orgId: number): TrainingAssignmentAction<number> => ({
  type: 'FETCH_GROUP_TRAINING_ASSIGNMENT',
  payload: orgId
});

export const postGroupTrainingAssignment = (
  props: ProviderTrainingAssignmentPostProps
): TrainingAssignmentAction<ProviderTrainingAssignmentPostProps> => ({
  type: 'POST_GROUP_TRAINING_ASSIGNMENT',
  payload: {
    ...props
  }
});

export const editTrainingAssignment = (
  props: ProviderTrainingEditProps
): TrainingAssignmentAction<ProviderTrainingEditProps> => ({
  type: 'EDIT_TRAINING_ASSIGNMENT',
  payload: {
    ...props
  }
});

export const initialState = {
  providers: [],
  isLoading: false,
  errorMessage: undefined
};

// Reducer
const reducer = (
  state: TrainingAssignmentState = initialState,
  action: TrainingAssignmentAction<TrainingAssignmentPayload>
): TrainingAssignmentState => {
  switch (action.type) {
    case 'FETCH_GROUP_TRAINING_ASSIGNMENT_BEGIN': {
      return {
        ...state,
        isLoading: true,
        errorMessage: undefined
      };
    }
    case 'FETCH_GROUP_TRAINING_ASSIGNMENT_SUCCESSFUL': {
      return {
        ...state,
        providers: [...(action.payload as ProviderTrainingAssignmentState[])],
        isLoading: false,
        errorMessage: undefined
      };
    }
    case 'FETCH_GROUP_TRAINING_ASSIGNMENT_FAIL': {
      return {
        ...state,
        providers: [],
        isLoading: false,
        errorMessage: action.payload as string
      };
    }
    case 'POST_GROUP_TRAINING_ASSIGNMENT_BEGIN': {
      return {
        ...state,
        isLoading: true,
        errorMessage: undefined
      };
    }
    case 'POST_GROUP_TRAINING_ASSIGNMENT_SUCCESSFUL': {
      return {
        ...state,
        providers: [...(action.payload as ProviderTrainingAssignmentState[])],
        isLoading: false,
        errorMessage: undefined
      };
    }
    case 'POST_GROUP_TRAINING_ASSIGNMENT_FAIL': {
      return {
        ...state,
        isLoading: false,
        errorMessage: action.payload as string
      };
    }
    case 'EDIT_TRAINING_ASSIGNMENT_BEGIN': {
      return {
        ...state,
        isLoading: true,
        errorMessage: undefined
      };
    }
    case 'EDIT_TRAINING_ASSIGNMENT_SUCCESSFUL': {
      return {
        ...state,
        providers: [...(action.payload as ProviderTrainingAssignmentState[])],
        isLoading: false,
        errorMessage: undefined
      };
    }
    case 'EDIT_TRAINING_ASSIGNMENT_FAIL': {
      return {
        ...state,
        isLoading: false,
        errorMessage: action.payload as string
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;

// Sagas
export function* fetchGroupTrainingAssignmentSaga({ payload }: TrainingAssignmentAction<number>): SagaIterator {
  yield put({
    type: 'FETCH_GROUP_TRAINING_ASSIGNMENT_BEGIN'
  });

  const groupTrainingAssignment: AsyncOutput<ProviderTrainingAssignmentState[]> = yield call(ApplicationAPI.get, {
    endpoint: `training/assignment/${payload}`
  });

  if (groupTrainingAssignment.status === 204) {
    yield put({
      type: 'FETCH_GROUP_TRAINING_ASSIGNMENT_FAIL',
      payload: `None of the providers in this group are enrolled in ClinIntell's online training.`
    });
  } else if (groupTrainingAssignment.error) {
    yield put({
      type: 'FETCH_GROUP_TRAINING_ASSIGNMENT_FAIL',
      payload: groupTrainingAssignment.error
    });
  } else {
    yield put({
      type: 'FETCH_GROUP_TRAINING_ASSIGNMENT_SUCCESSFUL',
      payload: groupTrainingAssignment.data
    });
  }
}

export function* postGroupTrainingAssignmentSaga({
  payload
}: TrainingAssignmentAction<ProviderTrainingAssignmentPostProps>): SagaIterator {
  yield put({
    type: 'POST_GROUP_TRAINING_ASSIGNMENT_BEGIN'
  });

  const { orgId, conditionIds, providerIds, onEditCompleted } = payload as ProviderTrainingAssignmentPostProps;

  const result: AsyncOutput<ProviderTrainingAssignmentState[]> = yield call(ApplicationAPI.put, {
    endpoint: `training/assignment/${orgId}`,
    data: JSON.stringify({
      forceNewTraining: true,
      conditionIds,
      providerIds
    })
  });

  if (result.error) {
    yield put({
      type: 'POST_GROUP_TRAINING_ASSIGNMENT_FAIL',
      payload: result.error
    });
  } else {
    yield put({
      type: 'POST_GROUP_TRAINING_ASSIGNMENT_SUCCESSFUL',
      payload: result.data
    });
  }

  if (onEditCompleted) {
    onEditCompleted(true);
  }
}

export function* editTrainingAssignmentSaga({
  payload
}: TrainingAssignmentAction<ProviderTrainingEditProps>): SagaIterator {
  yield put({
    type: 'EDIT_TRAINING_ASSIGNMENT_BEGIN'
  });

  // Multiple API calls are potentially made here. Fire them off all at once with a promise all.
  const apiCalls = [];
  const {
    conditionsAdded,
    conditionsRemoved,
    orgId,
    providerId,
    forceNewTraining,
    sendNotification,
    onEditCompleted
  } = payload as ProviderTrainingEditProps;
  if (conditionsAdded && conditionsAdded.length > 0) {
    apiCalls.push(
      call(ApplicationAPI.put, {
        endpoint: `training/assignment/${orgId}/${providerId}`,
        data: JSON.stringify({
          conditionIds: conditionsAdded,
          forceNewTraining,
          sendNotification
        })
      })
    );
  }

  if (conditionsRemoved && conditionsRemoved.length > 0) {
    apiCalls.push(
      ...conditionsRemoved.map(condition => {
        return call(ApplicationAPI.delete, {
          endpoint: `training/assignment/${orgId}/${providerId}/${condition}`
        });
      })
    );
  }

  if (apiCalls.length === 0) {
    yield put({
      type: 'EDIT_TRAINING_ASSIGNMENT_FAIL',
      payload: 'No conditions added or removed.'
    });
  }

  // TODO -- Need to handle result here and look for errors
  yield all(apiCalls);

  const updatedTrainingProgress = yield call(ApplicationAPI.get, {
    endpoint: `training/assignment/${orgId}`
  });

  if (updatedTrainingProgress.error) {
    yield put({
      type: 'EDIT_TRAINING_ASSIGNMENT_FAIL',
      payload: `Error fetching updated training progress - ${updatedTrainingProgress.error}. Training was successfully assigned.`
    });
  }

  if (updatedTrainingProgress.data) {
    yield put({
      type: 'EDIT_TRAINING_ASSIGNMENT_SUCCESSFUL',
      payload: updatedTrainingProgress.data
    });
  }

  if (onEditCompleted) {
    onEditCompleted(true);
  }
}

export function* groupTrainingAssignmentSaga(): SagaIterator {
  yield takeLatest('FETCH_GROUP_TRAINING_ASSIGNMENT', fetchGroupTrainingAssignmentSaga);
}

export function* groupTrainingAssignmentSaveSaga(): SagaIterator {
  yield takeLeading('POST_GROUP_TRAINING_ASSIGNMENT', postGroupTrainingAssignmentSaga);
}

export function* trainingAssignmentSaga(): SagaIterator {
  yield takeLeading('EDIT_TRAINING_ASSIGNMENT', editTrainingAssignmentSaga);
}
