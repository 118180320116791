import { SummaryRecord } from '@clinintell/modules/cmiAnalysis';
import { formatToDecimalPlaces } from '@clinintell/utils/formatting';
import React from 'react';
import ResultSummaryTable from '../../../components/analysisSharedComponents/ResultSummaryTable';

type SeverityCMIResultSummaryProps = {
  cmiSummary: SummaryRecord;
  ceCMISummary: SummaryRecord;
  severityCMISummary: SummaryRecord;
};

const SeverityCMIResultSummary: React.FC<SeverityCMIResultSummaryProps> = ({
  cmiSummary,
  ceCMISummary,
  severityCMISummary
}) => {
  return (
    <ResultSummaryTable
      rows={[
        {
          columns: [
            {
              value: '',
              width: 4,
              align: 'center',
              bold: true,
              key: 'header1'
            },
            {
              value: 'Historical',
              width: 2,
              align: 'center',
              bold: true,
              key: 'headerHistorical'
            },
            {
              value: 'Current',
              width: 2,
              align: 'center',
              bold: true,
              key: 'headerCurrent'
            },
            {
              value: '',
              width: 4,
              align: 'center',
              bold: true,
              key: 'headerChange'
            }
          ],
          key: 'headerRow'
        },
        {
          divider: 'solid',
          key: 'headerDivider'
        },
        {
          columns: [
            {
              value: 'CE CMI',
              width: 4,
              align: 'left',
              bold: true,
              key: 'ceLabel'
            },
            {
              value: ceCMISummary.historical ? formatToDecimalPlaces(ceCMISummary.historical, 2) : '-',
              width: 2,
              align: 'center',
              type: 'number',
              key: 'ceHist'
            },
            {
              value: ceCMISummary.current ? formatToDecimalPlaces(ceCMISummary.current, 2) : '-',
              width: 2,
              align: 'center',
              type: 'number',
              key: 'ceCurr'
            },
            {
              value: '',
              width: 4,
              align: 'left',
              key: 'ceChange'
            }
          ],
          key: 'ceRow'
        },
        {
          columns: [
            {
              value: 'CMI',
              width: 4,
              align: 'left',
              bold: true,
              key: 'cmiLabel'
            },
            {
              value: cmiSummary.historical ? formatToDecimalPlaces(cmiSummary.historical, 2) : '-',
              width: 2,
              align: 'center',
              type: 'number',
              key: 'cmiHist'
            },
            {
              value: cmiSummary.current ? formatToDecimalPlaces(cmiSummary.current, 2) : '-',
              width: 2,
              align: 'center',
              type: 'number',
              key: 'cmiCurr'
            },
            {
              value: '',
              width: 4,
              align: 'right',
              key: 'cmiDiff'
            }
          ],
          key: 'cmiRow'
        },
        {
          divider: 'solid',
          key: 'cmiGapDivider'
        },
        {
          columns: [
            {
              value: 'Gap',
              width: 4,
              align: 'left',
              bold: true,
              key: 'gapLabel'
            },
            {
              value:
                ceCMISummary.historical && cmiSummary.historical
                  ? formatToDecimalPlaces(
                      Number(formatToDecimalPlaces(ceCMISummary.historical, 2)) -
                        Number(formatToDecimalPlaces(cmiSummary.historical, 2))
                    )
                  : '-',
              width: 2,
              align: 'center',
              type: 'number',
              key: 'gapHist'
            },
            {
              value:
                ceCMISummary.current && cmiSummary.current
                  ? formatToDecimalPlaces(ceCMISummary.current - cmiSummary.current, 2)
                  : '-',
              width: 2,
              align: 'center',
              type: 'number',
              key: 'gapCurr'
            },
            {
              value:
                ceCMISummary.current && cmiSummary.current && ceCMISummary.historical && cmiSummary.historical
                  ? `${formatToDecimalPlaces(
                      ceCMISummary.current - cmiSummary.current - (ceCMISummary.historical - cmiSummary.historical),
                      2
                    )}`
                  : '-',
              width: 4,
              align: 'left',
              offsetCenter: true,
              type: 'number',
              key: 'gapDiff'
            }
          ],
          key: 'gapRow'
        },
        {
          columns: [
            {
              value: 'Severity CMI',
              width: 4,
              align: 'left',
              bold: true,
              key: 'sevCmiLabel'
            },
            {
              value: severityCMISummary.historical ? formatToDecimalPlaces(severityCMISummary.historical, 2) : '-',
              width: 2,
              align: 'center',
              type: 'number',
              key: 'sevCmiHist'
            },
            {
              value: severityCMISummary.current ? formatToDecimalPlaces(severityCMISummary.current, 2) : '-',
              width: 2,
              align: 'center',
              type: 'number',
              key: 'sevCmiCurr'
            },
            {
              value:
                severityCMISummary.current && severityCMISummary.historical
                  ? `${formatToDecimalPlaces(
                      Number(formatToDecimalPlaces(severityCMISummary.current, 2)) -
                        Number(formatToDecimalPlaces(severityCMISummary.historical, 2)),
                      2
                    )}`
                  : '-',
              width: 4,
              align: 'left',
              offsetCenter: true,
              type: 'number',
              key: 'sevCmiDiff'
            }
          ],
          key: 'sevCmiRow'
        }
      ]}
    />
  );
};

export default SeverityCMIResultSummary;
