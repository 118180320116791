import getChartAndTableConfiguration, { YAxesConfiguration } from './getChartAndTableConfiguration';
import useChartStyles from './useChartStyles';
import { Metrics } from '@clinintell/modules/metricsNavigation';
import { LegendItemProps } from '@clinintell/components/Chart/Legend';
import GenerateChartProps from '@clinintell/containers/metricsTimeSeries/logic/generateChartProps';
import {
  ChartDataSetJSON,
  ChartDataSetAverageTypes,
  ChartDataSet,
  ChartDataSetType
} from '../typings/metricChartTypes';
import { useTheme } from '@mui/material';
import generateGraphTableView from './generateGraphTableView';
import { XAxisSet, YAxisSet, ChartDataset } from '@clinintell/components/Chart/types';
import { ChartTableDefinition } from '@clinintell/components/index';

export type UseGraphContainerProps = {
  metric: keyof typeof Metrics;
  dataset: ChartDataSetJSON;
  chartPeriodType: keyof typeof ChartDataSetAverageTypes;
  chartDataSets: ChartDataSet[];
  forOCE?: boolean;
  hideCovid?: boolean;
};

export type UseGraphContainerPropsOutput = {
  xAxis: XAxisSet;
  yAxes: YAxisSet[];
  chartDatasets: ChartDataset[];
  legendItems: LegendItemProps[];
  tableData: ChartTableDefinition;
  tableFooter: string[];
  onToggle?: (id: ChartDataSetType) => void;
  disableToggling?: boolean;
  forOCE?: boolean;
};

const useGraphContainerProps = ({
  metric,
  dataset,
  chartPeriodType,
  chartDataSets,
  forOCE = false,
  hideCovid = false
}: UseGraphContainerProps): UseGraphContainerPropsOutput => {
  const theme = useTheme();
  const config = getChartAndTableConfiguration(metric, forOCE);
  const chartStyles = useChartStyles(metric);

  if (hideCovid) {
    config.chartDataSets = config.chartDataSets.filter(ds => ds.id !== 'COVID');
    config.dataTableDataSets = config.dataTableDataSets.filter(ds => ds.id !== 'COVID');
  }

  const sortedChartDataSets: ChartDataSet[] = [];
  // Keep the datasets in the order they are sorted by in the configuration
  config.chartDataSets.forEach(config => {
    sortedChartDataSets.push(...chartDataSets.filter(set => config.id === set.dataSetType));
  });

  let hasLineGraphDatasets = false;
  let hasBarChartDatasets = false;
  const chartDataSetStyles = sortedChartDataSets
    .filter(dataset => dataset.dataSetAverageType === chartPeriodType)
    .map(dataset => {
      const styles = chartStyles[dataset.dataSetType];
      if (styles.chartType === 'Bar') {
        hasBarChartDatasets = true;
      } else if (styles.chartType === 'Line') {
        hasLineGraphDatasets = true;
      }

      return chartStyles[dataset.dataSetType];
    });

  const legendItems: LegendItemProps[] = chartDataSetStyles.map(dataset => ({
    color: dataset.color,
    type: dataset.lineType,
    label: dataset.label
  }));

  const filteredYAxes: YAxesConfiguration[] = [];
  if (hasLineGraphDatasets) {
    filteredYAxes.push(config.yAxes[0]);
  }

  if (hasBarChartDatasets && config.yAxes.length === 2) {
    filteredYAxes.push(config.yAxes[1]);
  }

  const { xAxis, yAxes, chartDatasets, hasErrorBand } = GenerateChartProps({
    metric,
    chartDatasets: sortedChartDataSets,
    chartStyles: chartDataSetStyles,
    chartPeriodType,
    datasetConfigurations: config.chartDataSets,
    yAxes: filteredYAxes,
    defaultHiddenChartDatasets: config.chartDatasetsHiddenByDefault,
    forOCE
  });

  if (hasErrorBand) {
    legendItems.push({
      color: theme.palette.chart.grey.light,
      type: 'Solid',
      label: 'Error Band'
    });
  }

  const filteredTableData = dataset.data
    .filter(set => config.dataTableDataSets.find(dataset => dataset.id === set.dataSetType))
    .filter(set => set.dataSetAverageType === chartPeriodType);

  const tableDataSetStyles = config.dataTableDataSets.map(dataset => chartStyles[dataset.id]);
  const tableData = generateGraphTableView(filteredTableData, metric, tableDataSetStyles, config.dataTableDataSets);
  const tableFooter = dataset.footerNotes;

  return {
    xAxis,
    yAxes,
    chartDatasets,
    legendItems,
    tableData,
    tableFooter,
    forOCE
  };
};

export default useGraphContainerProps;
