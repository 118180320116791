import { useTheme } from '@mui/material';
import { GroupBase, StylesConfig } from 'react-select';
import { SelectOptionType } from './DropDown';

type UseDropdownProps = {
  width?: number | string;
  listWidth?: number;
  maxHeight: number;
};

const useDropdownStyles = ({
  width,
  listWidth,
  maxHeight
}: UseDropdownProps): StylesConfig<SelectOptionType, boolean, GroupBase<SelectOptionType>> => {
  const theme = useTheme();

  const fontStyles = {
    fontFamily: theme.typography.fontFamily,
    fontSize: 13,
    color: theme.palette.grey[900]
  };

  const optionStyles = {
    paddingX: 2,
    paddingY: 1.5,
    color: theme.palette.grey[900],
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: theme.palette.grey[50]
    }
  };

  return {
    container: (provided, _state) => ({
      ...provided,
      minWidth: width
    }),
    indicatorsContainer: (provided, _state) => ({
      ...provided,
      '&:hover': {
        backgroundColor: theme.palette.grey[50],
        borderRadius: 25,
        width: 32,
        height: 32,
        margin: 2,
        '& div': {
          padding: 6
        }
      }
    }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      '& svg': {
        width: '1em',
        height: '1em',
        fontSize: '1.5em',
        transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : 'rotate(0deg)',
        transition: 'transform 250ms',
        strokeWidth: '0.25px'
      }
    }),
    control: (provided, _state) => ({
      ...provided,
      ...fontStyles,
      borderRadius: 10,
      textAlign: 'left',
      borderColor: theme.palette.grey[200],
      boxShadow: `0 0 0 0 ${theme.palette.grey[200]}`,
      '&:hover': {
        borderColor: theme.palette.grey[200]
      }
    }),
    menu: (provided, _state) => ({
      ...provided,
      ...fontStyles,
      width: listWidth ?? width,
      borderRadius: 10,
      overflow: 'hidden',
      zIndex: theme.zIndex.modal + 3
    }),
    menuList: (provided, _state) => ({
      ...provided,
      maxHeight
    }),
    menuPortal: (provided, _state) => ({
      ...provided,
      zIndex: theme.zIndex.modal + 3
    }),
    option: (provided, state) => ({
      ...provided,
      ...optionStyles,
      fontWeight: state.isSelected ? 600 : 'normal',
      borderBottom: `${state.data.withLineDivider ? '1px' : 0} solid ${theme.palette.grey[200]}`
    })
  };
};

export default useDropdownStyles;
