import { ApplicationAPI } from '@clinintell/utils/api';
import { useState } from 'react';

type UseDownloadReportOutput = {
  isCreating: boolean;
  handleCreateReport: (reportDate: string) => Promise<void>;
};

const useDownloadReport = (): UseDownloadReportOutput => {
  const [isCreating, setIsCreating] = useState(false);

  const handleCreateReport = async (reportDate: string): Promise<void> => {
    if (!reportDate) {
      return;
    }

    setIsCreating(true);

    const endpoint = `reports/system/monthly`;

    const createReport = ApplicationAPI.post({
      endpoint,
      data: JSON.stringify({
        ReportDate: reportDate
      })
    });

    const result = await Promise.resolve(createReport);
    if (!result.data) return;

    const blob = new Blob([result.data as ArrayBuffer], {
      type: 'application/zip'
    });

    const filename = 'SystemMonthlyReport';
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, filename);
      return;
    }

    const link = document.createElement('a');
    link.download = filename;
    link.href = URL.createObjectURL(blob);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    setIsCreating(false);
  };

  return {
    isCreating,
    handleCreateReport
  };
};

export default useDownloadReport;
