// Simple method to comma separate whole numbers
export const formatNumberWithCommas = (value: number | string): string => {
  if (value === 0) {
    return '0';
  }

  if (!value) {
    return '';
  }

  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

// Formats date objects to a string version that is acceptable by the backend API (m-d-yyyy)
export const formatDateForAPI = (value: Date): string =>
  `${value.getMonth() + 1}-${value.getDate()}-${value.getFullYear()}`;

export const formatToDecimalPlaces = (value: string | number, numberOfDecimals = 2): string => {
  // In this application, - characters are legitimate values in decimal values
  if (value === '' || value === '-') {
    return '';
  }

  const shift = function(number: number, exponent: number): number {
    const numArray = ('' + number).split('e');
    return +(numArray[0] + 'e' + (numArray[1] ? +numArray[1] + exponent : exponent));
  };

  return shift(Math.round(shift(Number(value), +numberOfDecimals)), -numberOfDecimals).toFixed(numberOfDecimals);
};

// Takes a date object and parses into a string in format (Jan17)
export const parseDateIntoPeriod = (date: Date): string =>
  `${date.toLocaleDateString('en-US', { month: 'short' }).replace(/\u200E/g, '')}${date
    .getFullYear()
    .toString()
    .substring(2)}`;

export const months: string[] = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
export const parsePeriodIntoDate = (period: string): Date | null => {
  if (period.length !== 5) {
    return null;
  }

  const month: string = period.substring(0, 3);
  const year = Number(`20${period.substring(3, 5)}`);
  const monthId: number = months.findIndex(item => item === month);

  return new Date(`${monthId + 1}/1/${year}`);
};

export const capitalizeWord = (word: string): string => {
  if (word.trim() === '') {
    return word;
  }
  return word[0].toUpperCase() + word.substr(1);
};

export const truncateDecimals = (value: number, precision: number): string => {
  const expression = new RegExp('^-?\\d+(?:.\\d{0,' + (precision || -1) + '})?');
  const match = value.toString().match(expression);
  return match ? match[0] : '';
};
