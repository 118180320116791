import DateRangePicker from '@clinintell/components/DateRangePicker';
import { setCMIComparisonSettings } from '@clinintell/modules/cmiComparison';
import { useCMIComparisonDispatch, useCMIComparisonState } from '@clinintell/modules/store';
import { Box, Typography, useTheme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import useMyDefaultDates from '../../../components/analysisSharedComponents/useMyDefaultDates';

const useStyles = makeStyles(theme => ({
  datePickerContainer: {
    '& > span': {
      background: 'white',
      '& > button > span > div > span': {
        fontSize: theme.typography.p2.fontSize
      }
    }
  }
}));

const DatePeriodSelector: React.FC = () => {
  const cmiComparisonDispatch = useCMIComparisonDispatch();
  const { orgId, comparisonOrgId, chartMinDate, chartMaxDate, orgTreeType } = useCMIComparisonState();
  const defaultDates = useMyDefaultDates(orgId, orgTreeType);

  const theme = useTheme();
  const { datePickerContainer } = useStyles();
  const isMobileView = theme.breakpoints.down('md');

  const handleDateRangeChanged = (startDate: string, endDate: string): void => {
    if (orgId === undefined || comparisonOrgId === undefined) {
      return;
    }

    cmiComparisonDispatch(
      setCMIComparisonSettings({
        chartMinDate: startDate,
        chartMaxDate: endDate,
        orgTreeType
      })
    );
  };

  return defaultDates &&
    chartMinDate &&
    chartMaxDate &&
    defaultDates.absoluteMinMonth &&
    defaultDates.currentMaxMonth ? (
    <Box display="flex" flexDirection="row" alignItems="center">
      <Typography variant="p2">Period:</Typography>
      <Box className={datePickerContainer} ml={2}>
        <DateRangePicker
          id="cmiComparisonDateRange"
          title="Period"
          isDisabled={false}
          absoluteMinDate={defaultDates.absoluteMinMonth}
          absoluteMaxDate={defaultDates.currentMaxMonth}
          selectionMinDate={chartMinDate}
          selectionMaxDate={chartMaxDate}
          handleDateRangeApply={handleDateRangeChanged}
          isPreselectVisible={false}
          isInterventionAvailable={false}
          size={isMobileView ? 'small' : 'large'}
          transformOrigin={
            theme.breakpoints.down(1130)
              ? { vertical: 'top', horizontal: 'left' }
              : { vertical: 'top', horizontal: 'center' }
          }
        ></DateRangePicker>
      </Box>
    </Box>
  ) : null;
};

export default DatePeriodSelector;
