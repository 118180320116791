import { getUpdatedViewFromMetric, getMetricTableColumns } from '@clinintell/containers/metrics/typings/tableSchemas';
import {
  ChartDataSetAverageTypes,
  ChartDataSetType
} from '@clinintell/containers/metricsTimeSeries/typings/metricChartTypes';
import { metricHasValueTypeToggle } from '../containers/metrics/typings/tableSchemas';
import { RowsPerPageValues } from '@clinintell/components/Table';
import { Feature } from '@clinintell/containers/authentication/rules';
import { Labels } from '@clinintell/utils/resources';
import { ColDef } from 'ag-grid-community/dist/lib/main';
import { Configuration } from '@clinintell/containers/metricsTimeSeries/logic/getChartAndTableConfiguration';

export enum Metrics {
  cmi = 'cmi',
  docScore = 'docScore',
  severityCmi = 'severityCmi',
  los = 'los',
  elixhauserMortality = 'elixhauserMortality',
  elixhauserReadmission = 'elixhauserReadmission',
  psi = 'psi',
  raf = 'raf',
  targetedConditions = 'targetedConditions',
  allConditions = 'allConditions',
  condition = 'condition',
  hcupcmi = 'hcupcmi'
}

type MetricLabels = {
  [key in Metrics]: string;
};

type MetricPermissions = {
  [key in Metrics]: Feature[] | [];
};

export const metricPermissions: MetricPermissions = {
  cmi: ['metricsAll'],
  docScore: ['metricsAll', 'metricsDocScore'],
  severityCmi: ['metricsAll'],
  los: ['metricsAll'],
  elixhauserMortality: ['metricsAll'],
  elixhauserReadmission: ['metricsAll'],
  psi: ['metricsAll'],
  raf: ['metricsAll'],
  targetedConditions: ['metricsAll', 'metricsConditionsViewAllConditions', 'metricsConditionsViewTargetConditions'],
  allConditions: ['metricsAll', 'metricsDocScore'],
  condition: ['metricsAll', 'metricsDocScore'],
  hcupcmi: ['metricsAll', 'metricsDocScore']
};

export const metricLabels: MetricLabels = {
  cmi: Labels.cmi.title,
  docScore: Labels.docScore.title,
  severityCmi: Labels.severityCmi.title,
  los: Labels.los.title,
  elixhauserMortality: Labels.elixhauserMortality.title,
  elixhauserReadmission: Labels.elixhauserReadmission.title,
  psi: Labels.psi.title,
  raf: Labels.raf.title,
  targetedConditions: Labels.targetedConditions.title,
  allConditions: Labels.allConditions.title,
  condition: Labels.condition.title,
  hcupcmi: Labels.hcupcmi.title
};

export enum Views {
  improvement = 'improvement',
  improvementRange = 'improvementRange',
  opportunity = 'opportunity',
  opportunityRange = 'opportunityRange',
  improvementAndOpportunity = 'improvementAndOpportunity',
  change = 'change',
  gap = 'gap',
  changeAndGap = 'changeAndGap',
  opportunityRW = 'opportunityRW'
}

type ViewLabels = {
  [key in Views]: string;
};

export const viewLabels: ViewLabels = {
  improvement: 'Improvement',
  improvementRange: 'Improvement Range',
  opportunity: 'Opportunity',
  opportunityRange: 'Opportunity Range',
  improvementAndOpportunity: 'Improvement & Opportunity',
  change: 'Change',
  gap: 'Gap',
  changeAndGap: 'Change & Gap',
  opportunityRW: 'Opportunity (RW)'
};

export type Display = 'table' | 'graph' | 'comparison';
export type ValueType = 'rwpv' | 'dollars';
export type DataTrend = 'codedrate' | 'otoce';

type DataTrendLabels = {
  [key in DataTrend]: DataTrend;
};

export const dataTrendLabels: DataTrendLabels = {
  codedrate: 'codedrate',
  otoce: 'otoce'
};

export type TableStateType = {
  columnInfo?: ColDef[] | null;
  filters?: Record<string, unknown> | null;
  pageNumber?: number;
};

type TableStateData = {
  restoreTableInfo: boolean;
  restoreComparisonInfo: boolean;
  tableInfo: TableStateType | null;
  comparisonInfo: TableStateType | null;
};

export type GraphDataPointsByMetric = {
  [index: string]: {
    config: Configuration;
    datapoints: ChartDataSetType[];
  };
};

export type CoreNavigation = {
  entity: number;
  metric: keyof typeof Metrics;
  display: Display;
  view: keyof typeof Views;
  condition?: number;
  conditionName?: string;
  periodStart: Date;
  periodEnd: Date;
  comparisonPeriodStart: Date;
  comparisonPeriodEnd: Date;
  timeSeriesAveragePeriodType: keyof typeof ChartDataSetAverageTypes;
  timeSeriesPeriodStart: Date;
  timeSeriesPeriodEnd: Date;
  dataTrend: DataTrend;
};
export interface MetricsNavigation extends CoreNavigation {
  previousMetric?: keyof typeof Metrics;
  navigateToTargetConditions: boolean;
  navigatingFromFooter: boolean;
  navigatingFromComparison: boolean;
  valueType: ValueType;
  isInitialized: boolean;
  // default periods per client stored in the backend
  defaultPeriodStart: Date;
  defaultPeriodEnd: Date;
  defaultComparisonPeriodStart: Date;
  defaultComparisonPeriodEnd: Date;
  absoluteMinPeriod: Date;
  interventionPeriod?: Date;
  sortColumn: string;
  sortDesc: boolean;
  rowsPerPage: RowsPerPageValues;
  mdcId?: number;
  conditionTypeId?: number;
  conditionId?: number;
  tableDirty: boolean;
  hccWeight: number;
  exmWeight: number;
  exrWeight: number;
  psiWeight: number;
  psi90Weight: number;
  psi04Weight: number;
  psi02Weight: number;
  psi07Weight: number;
  coreStackNav: CoreNavigation[];
  useTargetConditions: boolean;
  dataTrend: DataTrend;
  tableStateData: TableStateData;
  graphDataPoints: GraphDataPointsByMetric;
}

// Actions
enum MetricsNavigationActions {
  INITIALIZE = 'INITIALIZE',
  SET_ENTITY = 'SET_ENTITY',
  SET_METRIC = 'SET_METRIC',
  SET_VIEW = 'SET_VIEW',
  SET_DISPLAY = 'SET_DISPLAY',
  SET_VALUE_TYPE = 'SET_VALUE_TYPE',
  SET_PERIODS = 'SET_PERIODS',
  SET_TIME_SERIES_PERIOD_TYPE = 'SET_TIME_SERIES_PERIOD_TYPE',
  SET_TIME_SERIES_PERIODS = 'SET_TIME_SERIES_PERIODS',
  SET_COLUMN_SORTING = 'SET_COLUMN_SORTING',
  SET_ROWS_PER_PAGE = 'SET_ROWS_PER_PAGE',
  SET_ENTITY_METRIC = 'SET_ENTITY_METRIC',
  SET_OPPRTUNITY_PREFERENCE = 'SET_OPPRTUNITY_PREFERENCE',
  SET_DEFAULT_DATES = 'SET_DEFAULT_DATES',
  SET_CORE_NAV = 'SET_CORE_NAV',
  POP_CORE_NAV = 'POP_CORE_NAV',
  SET_NAV_TO_TARGET_CONDITIONS = 'SET_NAV_TO_TARGET_CONDITIONS',
  SET_NAV_FROM_FOOTER = 'SET_NAV_FROM_FOOTER',
  SET_NAV_FROM_COMPARISON = 'SET_NAV_FROM_COMPARISON',
  CLEAR_TABLE_DIRTY = 'CLEAR_TABLE_DIRTY',
  SET_DATA_TREND = 'SET_DATA_TREND',
  SET_TABLE_INFO = 'SET_TABLE_INFO',
  SET_COMPARISON_INFO = 'SET_COMPARISON_INFO',
  SET_RESTORE_TABLE_INFO = 'SET_RESTORE_TABLE_INFO',
  SET_RESTORE_COMPARISON_INFO = 'SET_RESTORE_COMPARISON_INFO',
  CLEAR_TABLE_INFO = 'CLEAR_TABLE_INFO',
  CLEAR_COMPARISON_INFO = 'CLEAR_COMPARISON_INFO',
  SET_GRAPH_DATA_POINT = 'SET_GRAPH_DATA_POINT'
}

export interface MetricsNavigationAction {
  type?: keyof typeof MetricsNavigationActions;
  payload?: unknown;
}

type MetricPayload = {
  metric: keyof typeof Metrics;
  condition?: number;
  conditionName?: string;
};

type PeriodPayload = {
  periodStart: Date;
  periodEnd: Date;
  comparisonPeriodStart: Date;
  comparisonPeriodEnd: Date;
};

export const setEntity = (entity: number): MetricsNavigationAction => ({
  type: 'SET_ENTITY',
  payload: entity
});

export const setMetric = (
  metric: keyof typeof Metrics,
  condition?: number,
  conditionName?: string
): MetricsNavigationAction => ({
  type: 'SET_METRIC',
  payload: {
    metric,
    condition,
    conditionName
  }
});

export const setView = (view: keyof typeof Views): MetricsNavigationAction => ({
  type: 'SET_VIEW',
  payload: view
});

export const setDisplay = (display: Display): MetricsNavigationAction => ({
  type: 'SET_DISPLAY',
  payload: display
});

export const setValueType = (valueType: ValueType): MetricsNavigationAction => ({
  type: 'SET_VALUE_TYPE',
  payload: valueType
});

export const setRowsPerPage = (value: RowsPerPageValues): MetricsNavigationAction => ({
  type: 'SET_ROWS_PER_PAGE',
  payload: value
});

interface PeriodProps {
  periodStart: Date;
  periodEnd: Date;
  comparisonPeriodStart: Date;
  comparisonPeriodEnd: Date;
}

export const setPeriods = ({
  periodStart,
  periodEnd,
  comparisonPeriodStart,
  comparisonPeriodEnd
}: PeriodProps): MetricsNavigationAction => ({
  type: 'SET_PERIODS',
  payload: {
    periodStart,
    periodEnd,
    comparisonPeriodStart,
    comparisonPeriodEnd
  }
});

type TimeSeriesPeriodProps = {
  timeSeriesPeriodStart: Date;
  timeSeriesPeriodEnd: Date;
};

export const setTimeSeriesPeriods = ({
  timeSeriesPeriodStart,
  timeSeriesPeriodEnd
}: TimeSeriesPeriodProps): MetricsNavigationAction => ({
  type: 'SET_TIME_SERIES_PERIODS',
  payload: {
    timeSeriesPeriodStart,
    timeSeriesPeriodEnd
  }
});

export const setTimeSeriesPeriodType = (
  periodType: keyof typeof ChartDataSetAverageTypes
): MetricsNavigationAction => ({
  type: 'SET_TIME_SERIES_PERIOD_TYPE',
  payload: periodType
});

interface OpportunityPreferences {
  hccWeight: number;
  exmWeight: number;
  exrWeight: number;
  psiWeight: number;
  psi02Weight: number;
  psi04Weight: number;
  psi07Weight: number;
  psi90Weight: number;
}

export const setOpportunityPreferences = (payload: OpportunityPreferences): MetricsNavigationAction => ({
  type: 'SET_OPPRTUNITY_PREFERENCE',
  payload
});

export const clearTableDirty = (): MetricsNavigationAction => ({
  type: 'CLEAR_TABLE_DIRTY'
});

export const setCoreNavigation = ({
  entity,
  metric,
  display,
  view,
  condition,
  conditionName,
  dataTrend
}: CoreNavigation): MetricsNavigationAction => ({
  type: 'SET_CORE_NAV',
  payload: { entity, metric, display, view, condition, conditionName, dataTrend }
});

export const popCoreNavigation = (): MetricsNavigationAction => ({
  type: 'POP_CORE_NAV'
});

export const setNavigateToTargetConditions = (value: boolean): MetricsNavigationAction => ({
  type: 'SET_NAV_TO_TARGET_CONDITIONS',
  payload: value
});

export const setNavigatingFromFooter = (value: boolean): MetricsNavigationAction => ({
  type: 'SET_NAV_FROM_FOOTER',
  payload: value
});

export const setNavigatingFromComparison = (value: boolean): MetricsNavigationAction => ({
  type: 'SET_NAV_FROM_COMPARISON',
  payload: value
});

export const setDataTrend = (value: DataTrend): MetricsNavigationAction => ({
  type: 'SET_DATA_TREND',
  payload: value
});

interface ColumnSort {
  sortColumn: string;
  sortDesc: boolean;
}

export const setColumnSorting = ({ sortColumn, sortDesc }: ColumnSort): MetricsNavigationAction => {
  return {
    type: 'SET_COLUMN_SORTING',
    payload: {
      sortColumn,
      sortDesc
    }
  };
};

type TableInfoPayload = {
  columnInfo: ColDef[] | null;
  pageNumber: number;
  filters: Record<string, unknown> | null;
};

export const setTableInfo = (payload: TableInfoPayload): MetricsNavigationAction => ({
  type: 'SET_TABLE_INFO',
  payload
});

export const setComparisonInfo = (payload: TableInfoPayload): MetricsNavigationAction => ({
  type: 'SET_COMPARISON_INFO',
  payload
});

export const setRestoreTableInfo = (payload: boolean): MetricsNavigationAction => ({
  type: 'SET_RESTORE_TABLE_INFO',
  payload
});

export const setRestoreComparisonInfo = (payload: boolean): MetricsNavigationAction => ({
  type: 'SET_RESTORE_COMPARISON_INFO',
  payload
});

export const clearTableInfo = (): MetricsNavigationAction => ({
  type: 'CLEAR_TABLE_INFO'
});

export const clearComparisonInfo = (): MetricsNavigationAction => ({
  type: 'CLEAR_COMPARISON_INFO'
});

export const setGraphDataPoints = (payload: GraphDataPointsByMetric): MetricsNavigationAction => ({
  type: 'SET_GRAPH_DATA_POINT',
  payload
});

interface InitProps {
  metric: keyof typeof Metrics;
  defaultPeriodStart: string;
  defaultPeriodEnd: string;
  defaultComparisonPeriodStart: string;
  defaultComparisonPeriodEnd: string;
  entity: number;
  absoluteMinPeriod: string;
  interventionPeriod: string | null;
  hccWt?: number;
  exmWt?: number;
  exrWt?: number;
  psiWt?: number;
  dataTrend: DataTrend;
}

export const initializeMetricsNavigation = ({
  metric,
  defaultPeriodStart,
  defaultPeriodEnd,
  defaultComparisonPeriodStart,
  defaultComparisonPeriodEnd,
  entity,
  absoluteMinPeriod,
  interventionPeriod,
  hccWt,
  exmWt,
  exrWt,
  psiWt
}: InitProps): MetricsNavigationAction => ({
  type: 'INITIALIZE',
  payload: {
    metric,
    defaultPeriodStart: new Date(defaultPeriodStart),
    defaultPeriodEnd: new Date(defaultPeriodEnd),
    defaultComparisonPeriodStart: new Date(defaultComparisonPeriodStart),
    defaultComparisonPeriodEnd: new Date(defaultComparisonPeriodEnd),
    entity,
    absoluteMinPeriod: new Date(absoluteMinPeriod),
    interventionPeriod: interventionPeriod ? new Date(interventionPeriod) : null,
    hccWeight: hccWt,
    exmWeight: exmWt,
    exrWeight: exrWt,
    psiWeight: psiWt,
    dataTrend: dataTrendLabels.codedrate
  }
});

interface DefaultDateProps {
  defaultPeriodStart: string;
  defaultPeriodEnd: string;
  defaultComparisonPeriodStart: string;
  defaultComparisonPeriodEnd: string;
  absoluteMinPeriod: string;
  interventionPeriod: string | null;
}

export const setDefaultDates = ({
  defaultPeriodStart,
  defaultPeriodEnd,
  defaultComparisonPeriodStart,
  defaultComparisonPeriodEnd,
  absoluteMinPeriod,
  interventionPeriod
}: DefaultDateProps): MetricsNavigationAction => ({
  type: 'SET_DEFAULT_DATES',
  payload: {
    defaultPeriodStart: new Date(defaultPeriodStart),
    defaultPeriodEnd: new Date(defaultPeriodEnd),
    defaultComparisonPeriodStart: new Date(defaultComparisonPeriodStart),
    defaultComparisonPeriodEnd: new Date(defaultComparisonPeriodEnd),
    absoluteMinPeriod: new Date(absoluteMinPeriod),
    interventionPeriod: interventionPeriod ? new Date(interventionPeriod) : null
  }
});

export const viewHasComparisonPeriod = (view: keyof typeof Views): boolean =>
  view === 'improvement' ||
  view === 'improvementRange' ||
  view === 'improvementAndOpportunity' ||
  view === 'change' ||
  view === 'changeAndGap';

export const getAnalagousView = (view: keyof typeof Views): keyof typeof Views | null => {
  switch (view) {
    case 'change':
      return 'improvement';
    case 'improvement':
      return 'change';
    case 'opportunity':
      return 'gap';
    case 'gap':
      return 'opportunity';
    case 'improvementAndOpportunity':
      return 'changeAndGap';
    case 'changeAndGap':
      return 'improvementAndOpportunity';
    default:
      return null;
  }
};

const initialTableStateData = {
  restoreTableInfo: false,
  restoreComparisonInfo: false,
  tableInfo: null,
  comparisonInfo: null
};

// used for tests and in reducer
export const initialState: MetricsNavigation = {
  metric: 'cmi',
  view: 'opportunity',
  display: 'table',
  valueType: 'rwpv',
  entity: -1,
  periodStart: new Date(),
  periodEnd: new Date(),
  comparisonPeriodStart: new Date(),
  comparisonPeriodEnd: new Date(),
  isInitialized: false,
  defaultPeriodStart: new Date(),
  defaultPeriodEnd: new Date(),
  defaultComparisonPeriodStart: new Date(),
  defaultComparisonPeriodEnd: new Date(),
  absoluteMinPeriod: new Date(),
  interventionPeriod: undefined,
  // TODO -- eventually this will be determined by the underlying metrics JSON from the API
  timeSeriesAveragePeriodType: ChartDataSetAverageTypes.Month,
  timeSeriesPeriodStart: new Date(),
  timeSeriesPeriodEnd: new Date(),
  sortColumn: 'opportunityRW',
  sortDesc: true,
  rowsPerPage: 50,
  hccWeight: 0,
  exmWeight: 0,
  exrWeight: 0,
  psiWeight: 0,
  psi90Weight: 0,
  psi04Weight: 0,
  psi02Weight: 0,
  psi07Weight: 0,
  tableDirty: false,
  coreStackNav: [],
  navigateToTargetConditions: false,
  navigatingFromFooter: false,
  navigatingFromComparison: false,
  useTargetConditions: false,
  dataTrend: dataTrendLabels.codedrate,
  tableStateData: initialTableStateData,
  graphDataPoints: {}
};

// Reducer
const reducer = (state: MetricsNavigation = initialState, action: MetricsNavigationAction): MetricsNavigation => {
  switch (action.type) {
    case 'INITIALIZE': {
      // Get default view for this metric
      const defaultState = action.payload as MetricsNavigation;
      const defaultView = getUpdatedViewFromMetric(defaultState.metric, state.view, state.dataTrend === 'otoce');

      // Set periods to their default periods in the beginning
      const defaultStateWithPeriods = {
        ...defaultState,
        periodStart: defaultState.defaultPeriodStart,
        periodEnd: defaultState.defaultPeriodEnd,
        comparisonPeriodStart: defaultState.defaultComparisonPeriodStart,
        comparisonPeriodEnd: defaultState.defaultComparisonPeriodEnd,
        view: defaultView,
        absoluteMinPeriod: defaultState.absoluteMinPeriod,
        interventionPeriod: defaultState.interventionPeriod,
        timeSeriesPeriodStart: defaultState.defaultPeriodStart,
        timeSeriesPeriodEnd: defaultState.defaultPeriodEnd
      };

      return {
        ...state,
        ...defaultStateWithPeriods,
        view: defaultView,
        sortColumn: getMetricTableColumns(defaultState.metric, defaultView, defaultState.dataTrend === 'otoce')
          .defaultSort,
        display: 'table',
        isInitialized: true,
        useTargetConditions: state.metric === 'allConditions',
        coreStackNav: [],
        navigateToTargetConditions: false,
        navigatingFromFooter: false,
        navigatingFromComparison: false
      };
    }
    case 'SET_ENTITY': {
      const {
        metric,
        display,
        view,
        condition,
        conditionName,
        periodStart,
        periodEnd,
        comparisonPeriodStart,
        comparisonPeriodEnd,
        timeSeriesAveragePeriodType,
        timeSeriesPeriodStart,
        timeSeriesPeriodEnd,
        dataTrend
      } = state;
      const stackNav =
        display === 'comparison' || display === 'graph'
          ? [
              ...state.coreStackNav,
              {
                entity: Number(action.payload),
                metric,
                display,
                view,
                condition,
                conditionName,
                periodStart,
                periodEnd,
                comparisonPeriodStart,
                comparisonPeriodEnd,
                timeSeriesAveragePeriodType,
                timeSeriesPeriodStart,
                timeSeriesPeriodEnd,
                dataTrend
              }
            ]
          : [];

      return {
        ...state,
        entity: Number(action.payload),
        coreStackNav: stackNav,
        useTargetConditions: true
      };
    }
    case 'SET_DISPLAY': {
      const {
        entity,
        metric,
        view,
        condition,
        conditionName,
        periodStart,
        periodEnd,
        comparisonPeriodStart,
        comparisonPeriodEnd,
        timeSeriesAveragePeriodType,
        timeSeriesPeriodStart,
        timeSeriesPeriodEnd,
        dataTrend
      } = state;
      const display = action.payload as Display;
      const stackNav =
        display === 'table'
          ? []
          : [
              ...state.coreStackNav,
              {
                entity,
                metric,
                display,
                view,
                condition,
                conditionName,
                periodStart,
                periodEnd,
                comparisonPeriodStart,
                comparisonPeriodEnd,
                timeSeriesAveragePeriodType,
                timeSeriesPeriodStart,
                timeSeriesPeriodEnd,
                dataTrend
              }
            ];

      if (viewHasComparisonPeriod(state.view)) {
        state.timeSeriesPeriodStart = state.comparisonPeriodStart;
      } else {
        state.timeSeriesPeriodStart = state.periodStart;
      }
      state.timeSeriesPeriodEnd = state.periodEnd;

      return {
        ...state,
        display,
        coreStackNav: stackNav,
        graphDataPoints: display !== 'graph' ? {} : state.graphDataPoints
      };
    }
    case 'SET_VALUE_TYPE': {
      const { metric, view, dataTrend } = state;
      const sortColumn = getMetricTableColumns(metric, view, dataTrend === 'otoce').defaultSort;
      const hasValueToggle = metricHasValueTypeToggle(metric, dataTrend === 'otoce');

      if (hasValueToggle) {
        state.sortColumn = sortColumn;
      }

      return {
        ...state,
        valueType: action.payload as ValueType
      };
    }
    // updating the metric may also update the default view - the updated metric may not have
    // the current view in state. This in turn may also update the periods.
    case 'SET_METRIC': {
      const { metric, condition, conditionName } = action.payload as MetricPayload;
      let updatedValueType = state.valueType;
      // Update the sort column for Excel export
      const updatedView = getUpdatedViewFromMetric(metric, state.view, state.dataTrend === 'otoce');
      if ((metric === 'allConditions' || metric === 'targetedConditions') && state.metric !== 'condition') {
        updatedValueType = 'rwpv';
      }
      state.sortColumn = getMetricTableColumns(metric, updatedView, state.dataTrend === 'otoce').defaultSort;

      let stackNav: CoreNavigation[] = [...state.coreStackNav];
      let addToStackNav = false;
      let currentDataTrend = state.dataTrend;
      const navigatingFromComparison = state.navigatingFromComparison;

      if (state.display === 'comparison') addToStackNav = true;
      if (
        state.display === 'graph' &&
        metric !== 'allConditions' &&
        metric !== 'condition' &&
        metric !== 'targetedConditions'
      ) {
        addToStackNav = true;
        if (currentDataTrend === 'otoce') currentDataTrend = 'codedrate';
      }
      if (
        state.display === 'graph' &&
        condition &&
        (metric === 'allConditions' || metric === 'condition' || metric === 'targetedConditions')
      ) {
        addToStackNav = true;
      }
      if (
        metric !== 'allConditions' &&
        metric !== 'condition' &&
        metric !== 'targetedConditions' &&
        currentDataTrend === 'otoce'
      ) {
        currentDataTrend = 'codedrate';
      }

      if (addToStackNav) {
        stackNav = [
          ...state.coreStackNav,
          {
            entity: state.entity,
            metric,
            display: state.display,
            view: updatedView,
            condition,
            conditionName,
            periodStart: state.periodStart,
            periodEnd: state.periodEnd,
            comparisonPeriodStart: state.comparisonPeriodStart,
            comparisonPeriodEnd: state.comparisonPeriodEnd,
            timeSeriesAveragePeriodType: state.timeSeriesAveragePeriodType,
            timeSeriesPeriodStart: state.timeSeriesPeriodStart,
            timeSeriesPeriodEnd: state.timeSeriesPeriodEnd,
            dataTrend: state.dataTrend
          }
        ];
      }

      return {
        ...state,
        metric: metric as keyof typeof Metrics,
        previousMetric: state.metric === 'condition' ? state.previousMetric : state.metric,
        view: updatedView,
        valueType: updatedValueType,
        condition,
        conditionName,
        useTargetConditions: true,
        coreStackNav: stackNav,
        dataTrend: currentDataTrend,
        navigateToTargetConditions: stackNav.length === 0 ? false : state.navigateToTargetConditions,
        navigatingFromComparison: stackNav.length === 0 ? false : navigatingFromComparison,
        navigatingFromFooter: stackNav.length === 0 ? false : state.navigatingFromFooter
      };
    }
    // updating the view may also update the periods
    case 'SET_VIEW': {
      const {
        entity,
        metric,
        display,
        condition,
        conditionName,
        periodStart,
        periodEnd,
        comparisonPeriodStart,
        comparisonPeriodEnd,
        timeSeriesAveragePeriodType,
        timeSeriesPeriodStart,
        timeSeriesPeriodEnd,
        dataTrend,
        coreStackNav
      } = state;
      const view = action.payload as keyof typeof Views;

      let updateTimeSeriesDate = true;
      let lastCoreNavEntry = undefined;
      if (coreStackNav && coreStackNav.length) lastCoreNavEntry = coreStackNav[coreStackNav.length - 1];

      if (
        lastCoreNavEntry &&
        display === 'graph' &&
        dataTrend !== lastCoreNavEntry.dataTrend &&
        (metric === 'allConditions' || metric === 'condition' || metric === 'targetedConditions')
      ) {
        updateTimeSeriesDate = false;
      }

      if (updateTimeSeriesDate) {
        if (viewHasComparisonPeriod(view)) {
          state.timeSeriesPeriodStart = state.comparisonPeriodStart;
        } else {
          state.timeSeriesPeriodStart = state.periodStart;
        }
      }

      // Update the sort column for Excel export
      const updatedView = getUpdatedViewFromMetric(state.metric, view, state.dataTrend === 'otoce');
      state.sortColumn = getMetricTableColumns(state.metric, updatedView, state.dataTrend === 'otoce').defaultSort;

      return {
        ...state,
        view,
        coreStackNav: [
          ...state.coreStackNav,
          {
            entity,
            metric,
            display,
            view,
            condition,
            conditionName,
            periodStart,
            periodEnd,
            comparisonPeriodStart,
            comparisonPeriodEnd,
            timeSeriesAveragePeriodType,
            timeSeriesPeriodStart,
            timeSeriesPeriodEnd,
            dataTrend
          }
        ],
        tableStateData: { ...initialTableStateData }
      };
    }
    case 'SET_PERIODS': {
      const {
        entity,
        metric,
        display,
        view,
        condition,
        conditionName,
        timeSeriesAveragePeriodType,
        timeSeriesPeriodEnd,
        dataTrend
      } = state;

      const { periodStart, periodEnd, comparisonPeriodStart, comparisonPeriodEnd } = action.payload as PeriodPayload;

      return {
        ...state,
        periodStart,
        periodEnd,
        comparisonPeriodStart,
        comparisonPeriodEnd,
        // time series periods will update along with the updated periods
        timeSeriesPeriodStart: viewHasComparisonPeriod(state.view) ? comparisonPeriodStart : periodStart,
        timeSeriesPeriodEnd: periodEnd,
        useTargetConditions: false,
        coreStackNav: [
          ...state.coreStackNav,
          {
            entity,
            metric,
            display,
            view,
            condition,
            conditionName,
            periodStart,
            periodEnd,
            comparisonPeriodStart,
            comparisonPeriodEnd,
            timeSeriesAveragePeriodType,
            timeSeriesPeriodStart: viewHasComparisonPeriod(state.view) ? comparisonPeriodStart : periodStart,
            timeSeriesPeriodEnd,
            dataTrend
          }
        ]
      };
    }
    case 'SET_TIME_SERIES_PERIODS': {
      const {
        entity,
        metric,
        display,
        view,
        condition,
        conditionName,
        periodStart,
        periodEnd,
        comparisonPeriodStart,
        comparisonPeriodEnd,
        timeSeriesAveragePeriodType,
        dataTrend
      } = state;
      const { timeSeriesPeriodStart, timeSeriesPeriodEnd } = action.payload as TimeSeriesPeriodProps;
      return {
        ...state,
        timeSeriesPeriodStart,
        timeSeriesPeriodEnd,
        coreStackNav: [
          ...state.coreStackNav,
          {
            entity,
            metric,
            display,
            view,
            condition,
            conditionName,
            periodStart,
            periodEnd,
            comparisonPeriodStart,
            comparisonPeriodEnd,
            timeSeriesAveragePeriodType,
            timeSeriesPeriodStart,
            timeSeriesPeriodEnd,
            dataTrend
          }
        ]
      };
    }
    case 'SET_TIME_SERIES_PERIOD_TYPE': {
      const {
        entity,
        metric,
        display,
        view,
        condition,
        conditionName,
        periodStart,
        periodEnd,
        comparisonPeriodStart,
        comparisonPeriodEnd,
        timeSeriesPeriodStart,
        timeSeriesPeriodEnd,
        dataTrend
      } = state;
      const newTimeSeriesAveragePeriodType = action.payload as keyof typeof ChartDataSetAverageTypes;
      return {
        ...state,
        timeSeriesAveragePeriodType: newTimeSeriesAveragePeriodType,
        coreStackNav: [
          ...state.coreStackNav,
          {
            entity,
            metric,
            display,
            view,
            condition,
            conditionName,
            periodStart,
            periodEnd,
            comparisonPeriodStart,
            comparisonPeriodEnd,
            timeSeriesAveragePeriodType: newTimeSeriesAveragePeriodType,
            timeSeriesPeriodStart,
            timeSeriesPeriodEnd,
            dataTrend
          }
        ]
      };
    }
    case 'SET_COLUMN_SORTING': {
      const { sortColumn, sortDesc } = action.payload as ColumnSort;
      return {
        ...state,
        sortColumn,
        sortDesc
      };
    }
    case 'SET_ROWS_PER_PAGE': {
      const rowsPerPage = action.payload as RowsPerPageValues;

      return {
        ...state,
        rowsPerPage
      };
    }
    case 'SET_OPPRTUNITY_PREFERENCE': {
      const {
        hccWeight,
        exmWeight,
        exrWeight,
        psiWeight,
        psi02Weight,
        psi04Weight,
        psi07Weight,
        psi90Weight
      } = action.payload as OpportunityPreferences;
      return {
        ...state,
        hccWeight,
        exmWeight,
        exrWeight,
        psiWeight,
        psi02Weight,
        psi04Weight,
        psi07Weight,
        psi90Weight
      };
    }
    case 'CLEAR_TABLE_DIRTY': {
      return {
        ...state,
        tableDirty: false
      };
    }
    case 'SET_DEFAULT_DATES': {
      const defaultState = action.payload as MetricsNavigation;

      return {
        ...state,
        defaultPeriodStart: defaultState.defaultPeriodStart,
        defaultPeriodEnd: defaultState.defaultPeriodEnd,
        defaultComparisonPeriodStart: defaultState.defaultComparisonPeriodStart,
        defaultComparisonPeriodEnd: defaultState.defaultComparisonPeriodEnd,
        absoluteMinPeriod: defaultState.absoluteMinPeriod,
        interventionPeriod: defaultState.interventionPeriod
      };
    }
    case 'SET_CORE_NAV': {
      const stackNav = action.payload as MetricsNavigation;
      return {
        ...state,
        entity: stackNav.entity,
        metric: stackNav.metric,
        previousMetric: state.metric === 'condition' ? state.previousMetric : state.metric,
        display: stackNav.display,
        view: stackNav.view,
        condition: stackNav.condition,
        conditionName: stackNav.conditionName,
        dataTrend: stackNav.dataTrend,
        coreStackNav: [
          ...state.coreStackNav,
          {
            ...stackNav,
            periodStart: state.periodStart,
            periodEnd: state.periodEnd,
            comparisonPeriodStart: state.comparisonPeriodStart,
            comparisonPeriodEnd: state.comparisonPeriodEnd,
            timeSeriesAveragePeriodType: state.timeSeriesAveragePeriodType,
            timeSeriesPeriodStart: state.timeSeriesPeriodStart,
            timeSeriesPeriodEnd: state.timeSeriesPeriodEnd,
            dataTrend: state.dataTrend
          }
        ]
      };
    }
    case 'POP_CORE_NAV': {
      const stackNav = [...state.coreStackNav];
      stackNav.pop();

      if (stackNav.length) {
        const lastEntry = stackNav[stackNav.length - 1];
        return {
          ...state,
          entity: lastEntry.entity,
          metric: lastEntry.metric,
          display: lastEntry.display,
          view: lastEntry.view,
          condition: lastEntry.condition,
          conditionName: lastEntry.conditionName,
          periodStart: lastEntry.periodStart,
          periodEnd: lastEntry.periodEnd,
          comparisonPeriodStart: lastEntry.comparisonPeriodStart,
          comparisonPeriodEnd: lastEntry.comparisonPeriodEnd,
          timeSeriesAveragePeriodType: lastEntry.timeSeriesAveragePeriodType,
          timeSeriesPeriodStart: lastEntry.timeSeriesPeriodStart,
          timeSeriesPeriodEnd: lastEntry.timeSeriesPeriodEnd,
          dataTrend: lastEntry.dataTrend,
          coreStackNav: stackNav
        };
      }

      return state;
    }
    case 'SET_NAV_TO_TARGET_CONDITIONS': {
      return {
        ...state,
        navigateToTargetConditions: action.payload as boolean
      };
    }
    case 'SET_NAV_FROM_FOOTER': {
      return {
        ...state,
        navigatingFromFooter: action.payload as boolean
      };
    }
    case 'SET_NAV_FROM_COMPARISON': {
      return {
        ...state,
        navigatingFromComparison: action.payload as boolean
      };
    }
    case 'SET_DATA_TREND': {
      return {
        ...state,
        dataTrend: action.payload as DataTrend,
        graphDataPoints: {},
        tableStateData: { ...initialTableStateData }
      };
    }
    case 'SET_GRAPH_DATA_POINT': {
      return {
        ...state,
        graphDataPoints: action.payload as GraphDataPointsByMetric
      };
    }
    case 'SET_TABLE_INFO': {
      const { columnInfo, pageNumber, filters } = action.payload as TableInfoPayload;
      const tableStateData = state.tableStateData;
      tableStateData.tableInfo = { columnInfo, pageNumber, filters };
      return {
        ...state,
        tableStateData
      };
    }
    case 'SET_COMPARISON_INFO': {
      const { columnInfo, pageNumber, filters } = action.payload as TableInfoPayload;
      const tableStateData = state.tableStateData;
      tableStateData.comparisonInfo = { columnInfo, pageNumber, filters };
      return {
        ...state,
        tableStateData
      };
    }
    case 'SET_RESTORE_TABLE_INFO': {
      const restoreTableInfo = action.payload as boolean;
      const tableStateData: TableStateData = {
        ...state.tableStateData,
        restoreTableInfo
      };
      if (!restoreTableInfo) {
        tableStateData.tableInfo = null;
      }
      return {
        ...state,
        tableStateData
      };
    }
    case 'SET_RESTORE_COMPARISON_INFO': {
      const restoreComparisonInfo = action.payload as boolean;
      const tableStateData: TableStateData = {
        ...state.tableStateData,
        restoreComparisonInfo
      };
      if (!restoreComparisonInfo) {
        tableStateData.comparisonInfo = null;
      }
      return {
        ...state,
        tableStateData
      };
    }
    case 'CLEAR_TABLE_INFO': {
      return {
        ...state,
        tableStateData: {
          ...state.tableStateData,
          tableInfo: null
        }
      };
    }
    case 'CLEAR_COMPARISON_INFO': {
      return {
        ...state,
        tableStateData: {
          ...state.tableStateData,
          comparisonInfo: null
        }
      };
    }
    default: {
      return state;
    }
  }
};

export default reducer;
