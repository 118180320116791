import makeStyles from '@mui/styles/makeStyles';
import { useEffect } from 'react';
import ClinIntellLogo from '@clinintell/assets/images/logo.svg';

const useStyles = makeStyles(theme => ({
  widgetContainer: {
    [theme.breakpoints.down('md')]: {
      width: '90vw'
    }
  },
  logoContainer: {
    transform: 'translateX(-50%)',
    backgroundColor: theme.palette.blue.main,
    px: theme.spacing(2),
    py: theme.spacing(0.5),
    borderRadius: '5px',
    boxShadow: '0px 8px 6px -6px #ccc',
    position: 'absolute',
    top: '15px',
    left: '50%',
    display: 'block',
    zIndex: 1000001,
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  logo: {
    height: '3.2rem',
    width: '10rem',
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(4)
    }
  }
}));

const useAppendClinIntellLogo = (element: Element | null, auth0Lock: Auth0LockStatic | null): void => {
  const { logoContainer, logo } = useStyles();

  useEffect(() => {
    if (auth0Lock && element) {
      auth0Lock.on('show', () => {
        const logoContainerElement = document.createElement('div');
        logoContainerElement.className = logoContainer;

        const imageElement = document.createElement('img');
        imageElement.className = logo;
        imageElement.src = ClinIntellLogo;

        logoContainerElement.appendChild(imageElement);
        element.appendChild(logoContainerElement);
      });
    }
  }, [auth0Lock, element, logo, logoContainer]);
};

export default useAppendClinIntellLogo;
