/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useContext, useEffect, useState } from 'react';
import { Button, Box, Popover, Typography, useMediaQuery, useTheme } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import CircleLoadingIndicator from './CircleLoadingIndicator';
import CalendarIcon from '@clinintell/components/icons/CalendarIcon';
import 'react-datepicker/dist/react-datepicker.css';
import { Resources } from '@clinintell/utils/resources';
import { WindowSizeContext } from '@clinintell/modules/windowSizeContext';
import DateRangePickerContainer, { DateRangeProps } from './DateRangePickerContainer';
import Tooltip from './tooltip/Tooltip';
import { containerBorderRadius } from '@clinintell/theme/theme';

const DateRangePicker: React.FC<DateRangeProps> = (props: DateRangeProps) => {
  const CustomPopover = withStyles(theme => ({
    root: {
      zIndex: theme.zIndex.modal + 2,
      maxHeight: '100%',
      '& .MuiPopover-paper': {
        borderRadius: 10,
        [theme.breakpoints.down('md')]: {
          position: 'static',
          height: '100%',
          maxWidth: '100%',
          maxHeight: '100%'
        },
        [theme.breakpoints.down('sm')]: {
          borderRadius: 0
        },
        left: props.positionLeft ? props.positionLeft : null
      },
      '& .containerHeader': {
        backgroundColor: theme.palette.teal.main,
        color: theme.palette.common.white,
        padding: theme.spacing(2),
        '& h5': {
          color: theme.palette.common.white,
          fontWeight: theme.typography.fontWeightBold
        }
      },
      ...props.style
    }
  }))(Popover);
  const theme = useTheme();
  const { width } = useContext(WindowSizeContext);
  const isMobileView = useMediaQuery(theme.breakpoints.down('md'));
  const selectionMinDate = !!props.selectionMinDate ? new Date(props.selectionMinDate) : undefined;
  const selectionMaxDate = new Date(props.selectionMaxDate);
  const { isHistorical, isDisabled, isLoading } = props;
  const isDateRangeLoading = isLoading ?? false;

  const getDisplayFromDate = (min: Date | undefined, max: Date | undefined, displayFullText: boolean): string => {
    if (!min) return '';
    const minYear = min
      .getFullYear()
      .toString()
      .substr(-2);

    const minFullYear = min.getFullYear().toString();

    if (props.useSingleCalendar && props.useDaySelection) {
      return `${Resources.Months[min.getMonth()]} ${min.getDate()}, ${minFullYear}`;
    }

    const maxFullYear = max && max.getFullYear().toString();
    const maxYear =
      max &&
      max
        .getFullYear()
        .toString()
        .substr(-2);

    let displayTitle = Resources.Months[min.getMonth()] + ' ' + minYear;
    if (!props.useSingleCalendar) displayTitle += max ? ' - ' + Resources.Months[max.getMonth()] + ' ' + maxYear : '';

    if (displayFullText) {
      displayTitle = Resources.FullMonths[min.getMonth()] + ' ' + minFullYear;
      if (!props.useSingleCalendar) {
        displayTitle += max ? ' - ' + Resources.FullMonths[max.getMonth()] + ' ' + maxFullYear : '';
      }
    }

    if (isDisabled) {
      displayTitle = 'Mon YY - Mon YY';
    }

    return displayTitle;
  };

  const [displayDateRange, setDisplayDateRange] = useState(
    getDisplayFromDate(selectionMinDate, selectionMaxDate, false)
  );
  const [displayFullDateRange, setDisplayFullDateRange] = useState(
    getDisplayFromDate(selectionMinDate, selectionMaxDate, true)
  );
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const transformOrigin = props.transformOrigin ?? { vertical: 'top', horizontal: 'center' };
  const [tooltip, setTooltip] = useState('');
  const [isPreselectVisible] = useState(props.isPreselectVisible ? props.isPreselectVisible : true);

  useEffect(() => {
    setTooltip(isDisabled ? 'To enable, change the View option' : '');
  }, [isDisabled]);

  useEffect(() => {
    setDisplayDateRange(getDisplayFromDate(selectionMinDate, selectionMaxDate, false));
    setDisplayFullDateRange(getDisplayFromDate(selectionMinDate, selectionMaxDate, true));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectionMinDate, selectionMaxDate]);

  const handleDateRangeApply = (minDate: Date, maxDate?: Date): void => {
    setDisplayDateRange(getDisplayFromDate(minDate, maxDate, false));
    setDisplayFullDateRange(getDisplayFromDate(minDate, maxDate, true));
    props.handleDateRangeApply(minDate.toString(), maxDate?.toString());
    handlePopoverClose();
  };
  const handlePreselect = (duration: number): void => {
    props.handlePreselect(duration);
    handlePopoverClose();
  };
  const handlePrePostClick = (): void => {
    props.handlePrePostIntervention();
    handlePopoverClose();
  };
  const handlePopoverOpen = (e: React.MouseEvent<HTMLElement, MouseEvent>): void => {
    setAnchorEl(e.currentTarget);
  };
  const handlePopoverClose = (): void => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  let maxWidth: string | number = 'auto';
  if (props.useSingleCalendar) {
    maxWidth = props.useDaySelection ? 200 : 150;
  }

  return (
    <>
      <Tooltip content={tooltip}>
        <Box
          style={{
            display: 'flex',
            backgroundColor: theme.palette.common.white,
            minWidth: width < 540 ? 130 : 152,
            maxWidth,
            height: props.useSimpleLayout ? 30 : 38,
            borderRadius: containerBorderRadius
          }}
        >
          <Button
            style={{
              flexGrow: 1,
              borderWidth: props.useSimpleLayout ? 0 : 1,
              borderRadius: 10,
              borderColor: theme.palette.grey[300],
              minWidth: 120,
              paddingLeft: props.useSimpleLayout ? 7 : 15,
              color: theme.palette.grey[props.isDisabled ? 400 : 900]
            }}
            id={props.id}
            variant="outlined"
            className="dateRangePicker"
            endIcon={
              <CalendarIcon
                sx={{
                  width: 36,
                  height: 36,
                  color:
                    props.style && props.style.color
                      ? props.style.color
                      : theme.palette.grey[props.isDisabled ? 400 : 500]
                }}
              />
            }
            onClick={handlePopoverOpen}
            disabled={props.isDisabled}
            data-testid="btnToggleDatePicker"
            data-cy={props.id + 'DatePicker'}
          >
            <Box
              display="flex"
              justifyContent={props.useSimpleLayout || props.useSingleCalendar ? 'flex-start' : 'flex-end'}
              flexGrow={1}
            >
              {isDateRangeLoading ? (
                <CircleLoadingIndicator loadingIndicatorSize={18} />
              ) : props.useSimpleLayout ? (
                <Typography
                  variant="h4"
                  style={{
                    fontWeight: 'bold',
                    textTransform: 'none',
                    fontSize:
                      width < 515
                        ? '0.7rem'
                        : width < 540
                        ? '0.8rem'
                        : width < 570
                        ? '0.9rem'
                        : width < 695
                        ? '1rem'
                        : width < 750
                        ? '1.3rem'
                        : width < 785
                        ? '1.5rem'
                        : '1.625rem'
                  }}
                >
                  {displayDateRange}
                </Typography>
              ) : (
                <Box sx={{ typography: 'p2', fontWeight: 400, textTransform: 'none' }}>{displayDateRange}</Box>
              )}
            </Box>
          </Button>
        </Box>
      </Tooltip>
      <CustomPopover
        anchorEl={anchorEl}
        anchorReference="anchorEl"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={transformOrigin}
        open={open}
        onClose={handlePopoverClose}
        marginThreshold={0}
      >
        {isMobileView && (
          <Box className="containerHeader">
            <Typography variant="p2">{props.title}</Typography>
            <Typography variant="h5">{displayFullDateRange}</Typography>
          </Box>
        )}
        <DateRangePickerContainer
          onCancel={handlePopoverClose}
          onApply={handleDateRangeApply}
          onPreselect={handlePreselect}
          onPrePostClick={handlePrePostClick}
          isHistorical={isHistorical || false}
          isPreselectVisible={isPreselectVisible}
          {...props}
        />
      </CustomPopover>
    </>
  );
};

export default DateRangePicker;
