import { ChildMenuItem } from '@clinintell/modules/menuNavigation';
import { Box } from '@mui/material';
import React from 'react';
import { NavLink } from 'react-router-dom';

type ChildMenuNavigationLinkProps = {
  childMenuItem: ChildMenuItem;
  onClick: (childMenuItem: ChildMenuItem) => void;
};

const ChildMenuNavigationLink = React.forwardRef<HTMLElement, ChildMenuNavigationLinkProps>(
  ({ childMenuItem, onClick }, ref) => {
    const handleChildMenuLinkClick = () => {
      onClick(childMenuItem);
    };

    return (
      <NavLink
        onClick={handleChildMenuLinkClick}
        to={`${childMenuItem.parent.link}${childMenuItem.link}`}
        style={{ textDecoration: 'none', display: 'block' }}
      >
        <Box
          ref={ref}
          sx={{
            color: childMenuItem.isActive ? 'teal.main' : 'grey.500',
            ...(childMenuItem.isActive ? { fontWeight: 600 } : null),
            '&:hover': {
              cursor: 'pointer',
              color: 'teal.main'
            }
          }}
        >
          {childMenuItem.label}
        </Box>
      </NavLink>
    );
  }
);

export default ChildMenuNavigationLink;
