import React, { useEffect, useState } from 'react';
import { useGetAPICAll } from '@clinintell/utils/useGetAPICall';
import { Box, Typography } from '@mui/material';
import { Select, SelectOptionType } from '../../components/index';
import { createUrlWithQueryString, QueryStringParam } from '@clinintell/utils/querystring';

export type EntityReturnType = {
  id: number;
  hospitalName: string;
  physicianGroups: {
    id: number;
    physicianGroupName: string;
    trainingUsers: boolean;
  }[];
};

type Options = (SelectOptionType & { trainingUsers: boolean })[];

type Props = {
  labelPosition?: 'top' | 'side';
  title?: string;
  hospitalId?: number;
  defaultValue?: number;
  onGroupChangeFn: (value: number | null, display?: string) => void;
  onSelectLoaded?: (value: boolean) => void;
  includeAllGroups?: boolean;
  excludeOtherGroups?: boolean;
  excludeOtherMDs?: boolean;
  excludeHiddenProviders?: boolean;
  excludeHiddenSpecialtyGroups?: boolean;
  trainingOnly?: boolean;
  width?: number | string;
};

const SelectGroup: React.FC<Props> = ({
  labelPosition = 'top',
  title,
  hospitalId,
  defaultValue,
  onGroupChangeFn,
  onSelectLoaded,
  includeAllGroups = true,
  excludeOtherGroups = false,
  excludeOtherMDs = false,
  excludeHiddenProviders = false,
  excludeHiddenSpecialtyGroups = false,
  trainingOnly = false,
  width = '100%'
}) => {
  const [groupOptions, setGroupOptions] = useState<Options | []>([]);

  const params: QueryStringParam[] = [{ key: 'includeAllGroups', value: includeAllGroups ? '1' : '0' }];

  if (excludeOtherGroups) {
    params.push({ key: 'excludeOtherGroups', value: '1' });
  }

  if (excludeOtherMDs) {
    params.push({ key: 'excludeOtherMDs', value: '1' });
  }

  if (excludeHiddenProviders) {
    params.push({ key: 'excludeHiddenProviders', value: '1' });
  }

  if (excludeHiddenSpecialtyGroups) {
    params.push({ key: 'excludeHiddenSpecialtyGroups', value: '1' });
  }

  const endpoint = createUrlWithQueryString(`hospitals/${hospitalId}`, params);

  const { output: groupOutput, isLoading } = useGetAPICAll<EntityReturnType>({
    endpoint,
    isWaiting: hospitalId === undefined
  });

  if (groupOutput && groupOutput.error) {
    // if the hospitalId is invalid and groupOutput hits a 500
    groupOutput.data = {} as EntityReturnType;
  }

  useEffect(() => {
    if (!groupOutput || !groupOutput.data) {
      return;
    }
    if (groupOutput && groupOutput.data && groupOutput.data.physicianGroups) {
      let dropDownList: Options | [] = groupOutput.data.physicianGroups
        // Do we need 'All Groups'?
        .filter(group => group.physicianGroupName !== 'All Groups')
        .map(group => {
          return {
            value: group.id,
            label: group.physicianGroupName,
            trainingUsers: group.trainingUsers
          };
        })
        .sort((a, b) => {
          return a.label.localeCompare(b.label, 'en', { ignorePunctuation: true });
        });

      if (trainingOnly) {
        dropDownList = dropDownList.filter(group => group.trainingUsers);
      }

      setGroupOptions(dropDownList);
      if (dropDownList.length === 1) {
        onGroupChangeFn(dropDownList[0].value as number, dropDownList[0].label);
        return;
      }

      if (
        onSelectLoaded &&
        defaultValue &&
        dropDownList.length > 1 &&
        dropDownList.find(group => group.value === defaultValue)
      ) {
        onSelectLoaded(true);
      }
    }
  }, [groupOutput, onGroupChangeFn, isLoading, onSelectLoaded, defaultValue, trainingOnly]);

  return (
    <Box
      display="flex"
      flexDirection={labelPosition === 'top' ? 'column' : 'row'}
      alignItems={labelPosition === 'top' ? 'flex-start' : 'center'}
    >
      <Typography variant="p2">{title ?? 'Physician Group'}</Typography>
      <Box width="100%" style={labelPosition === 'top' ? { marginTop: 8 } : { marginLeft: 8 }}>
        <Select
          isDisabled={hospitalId === undefined || isLoading}
          isSearchable={true}
          options={groupOptions}
          value={Number(defaultValue)}
          onChange={(value, label): void => {
            onGroupChangeFn(Number(value), label);
          }}
          width={width}
        />
      </Box>
    </Box>
  );
};

export default React.memo(SelectGroup);
