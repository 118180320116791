// This must be the first line in src/index.js
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'abortcontroller-polyfill/dist/polyfill-patch-fetch';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import { Provider } from 'react-redux';
import UserReducer from '@clinintell/modules/user';
import userManagementReducer from '@clinintell/modules/userManagement';
import AuthenticationStatusReducer from '@clinintell/modules/authenticationStatus';
import AppConfigReducer from '@clinintell/modules/appConfig';
import MetricsNavigationReducer from '@clinintell/modules/metricsNavigation';
import MetricsDataReducer from '@clinintell/modules/metricsData';
import HospitalsReducer from '@clinintell/modules/hospitals';
import PhysicianGroupReducer from '@clinintell/modules/physicianGroups';
import ProviderReportsReducer from '@clinintell/modules/providerReports';
import ConditionsReducer from '@clinintell/modules/conditions';
import Auth0ConfigReducer from '@clinintell/modules/auth0Config';
import defaultDatesReducer from '@clinintell/modules/defaultDates';
import dashboardReducer from '@clinintell/modules/dashboard';
import TrainingReducer from '@clinintell/modules/training';
import TrainingAssignmentReducer from '@clinintell/modules/trainingAssignment';
import TableReducer from '@clinintell/modules/table';
import DocScoreGoalReducer from '@clinintell/modules/autoGenStrategy';
import CMIAnalysisReducer from '@clinintell/modules/cmiAnalysis';
import OrgTreeReducer from '@clinintell/modules/orgTree';
import CMIComparisonReducer from '@clinintell/modules/cmiComparison';
import MedicareComparisonReducer from '@clinintell/modules/medicareComparison';
import BreadcrumbNavigationReducer from '@clinintell/modules/breadcrumbNavigation';
import MenuNavigationReducer from '@clinintell/modules/menuNavigation';
import CMIAnalysisSelectionsReducer from '@clinintell/modules/cmiAnalysisSelections';
import createSagaMiddleware from 'redux-saga';
import { rootSaga } from './modules/store';

import '@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css';
import '@ag-grid-enterprise/all-modules/dist/styles/ag-theme-alpine.css';
import { LicenseManager } from 'ag-grid-enterprise';

LicenseManager.setLicenseKey(AG_GRID_LICENSE_KEY);

const sagaMiddleware = createSagaMiddleware();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

// combine reducers here
// DO NOT FORGET TO UPDATE src/tests/helpers.tsx
const rootReducer = combineReducers({
  user: UserReducer,
  userManagement: userManagementReducer,
  authenticationStatus: AuthenticationStatusReducer,
  appConfig: AppConfigReducer,
  metricsNavigation: MetricsNavigationReducer,
  metricsData: MetricsDataReducer,
  hospitals: HospitalsReducer,
  physicianGroups: PhysicianGroupReducer,
  providerReports: ProviderReportsReducer,
  auth0Config: Auth0ConfigReducer,
  defaultDates: defaultDatesReducer,
  conditions: ConditionsReducer,
  dashboardData: dashboardReducer,
  training: TrainingReducer,
  trainingAssignment: TrainingAssignmentReducer,
  tableState: TableReducer,
  cmiAnalysis: CMIAnalysisReducer,
  orgTree: OrgTreeReducer,
  cmiComparison: CMIComparisonReducer,
  hospitalDocScoreGoalDataset: DocScoreGoalReducer,
  medicareComparison: MedicareComparisonReducer,
  breadcrumbNavigation: BreadcrumbNavigationReducer,
  menuNavigation: MenuNavigationReducer,
  cmiAnalysisSelections: CMIAnalysisSelectionsReducer
});

export const store = createStore(rootReducer, composeEnhancers(applyMiddleware(sagaMiddleware)));

// Argument of type '() => Saga<any[]>' is not assignable to parameter of type 'Saga<any[]>'
// The above is fundamentally incorrect and I believe it's a type delcaration bug. The saga middleware requires a function as
// the argument, not the results of the function.
// eslint-disable-next-line
// @ts-ignore
sagaMiddleware.run(rootSaga);

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
