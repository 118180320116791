import { ChartDataSetType } from '@clinintell/containers/metricsTimeSeries/typings/metricChartTypes';
import { Metrics } from '@clinintell/modules/metricsNavigation';
import { Labels } from '@clinintell/utils/resources';

export type DatasetConfiguration = {
  id: ChartDataSetType;
  precision: number;
  usePercentage?: boolean;
  label?: string;
};

export type YAxesConfiguration = {
  label: string;
  precision: number;
  usePercentage?: boolean;
};

// Configures which chart dataset types apply to the chart and datatable, and which chart dataset the error band applies to (if any)
// Arrays are sorted in order of appearance (doesn't really matter for charts but oh well). Precision marks the precision of the values when you hover
// over a chart point and the data table values that are not integers
export interface Configuration {
  chartDataSets: DatasetConfiguration[];
  dataTableDataSets: DatasetConfiguration[];
  // Only 2 max y-axis configs
  yAxes: [YAxesConfiguration] | [YAxesConfiguration, YAxesConfiguration];
  chartDatasetsHiddenByDefault?: Set<ChartDataSetType>;
}

const getChartAndTableConfiguration = (metric: keyof typeof Metrics, forOCE = false): Configuration => {
  switch (metric) {
    case 'cmi':
      return {
        chartDataSets: [
          {
            id: 'Actual',
            precision: 3,
            label: 'Observed'
          },
          {
            id: 'ClinicallyExpected',
            precision: 3
          },
          {
            id: 'ComparisonEntity',
            precision: 3
          },
          {
            id: 'COVID',
            precision: 1,
            usePercentage: true,
            label: 'COVID'
          }
        ],
        dataTableDataSets: [
          {
            id: 'Discharges',
            precision: 0
          },
          {
            id: 'ClinicallyExpected',
            precision: 2
          },
          {
            id: 'Actual',
            precision: 2,
            label: 'Observed'
          },
          {
            id: 'Gap',
            precision: 2,
            label: 'CMI Gap'
          },
          {
            id: 'COVID',
            precision: 0,
            usePercentage: true,
            label: 'COVID'
          }
        ],
        yAxes: [
          {
            label: 'CMI',
            precision: 2
          },
          {
            label: 'Total Coded Rate, As % of Stays',
            precision: 0,
            usePercentage: true
          }
        ],
        chartDatasetsHiddenByDefault: new Set<ChartDataSetType>(['COVID'])
      };
    case 'severityCmi':
      return {
        chartDataSets: [
          {
            id: 'Actual',
            precision: 3
          },
          {
            id: 'COVID',
            precision: 1,
            usePercentage: true,
            label: 'COVID'
          }
        ],
        dataTableDataSets: [
          {
            id: 'Discharges',
            precision: 0
          },
          {
            id: 'Actual',
            precision: 2
          },
          {
            id: 'COVID',
            precision: 0,
            usePercentage: true,
            label: 'COVID'
          }
        ],
        yAxes: [
          {
            label: Labels.severityCmi.axisLabel,
            precision: 2
          },
          {
            label: 'Total Coded Rate, As % of Stays',
            precision: 0,
            usePercentage: true
          }
        ],
        chartDatasetsHiddenByDefault: new Set<ChartDataSetType>(['COVID'])
      };
    case 'docScore':
      return {
        chartDataSets: [
          {
            id: 'Actual',
            precision: 1,
            usePercentage: true,
            label: 'O/CE'
          },
          {
            id: 'ClinicallyExpected',
            precision: 1,
            usePercentage: true
          },
          {
            id: 'ComparisonEntity',
            precision: 1
          },
          {
            id: 'TargetDocScore',
            precision: 1,
            usePercentage: true
          },
          {
            id: 'COVID',
            precision: 1,
            usePercentage: true,
            label: 'COVID'
          }
        ],
        dataTableDataSets: [
          {
            id: 'Discharges',
            precision: 0
          },
          {
            id: 'ClinicallyExpected',
            precision: 0,
            usePercentage: true
          },
          {
            id: 'Actual',
            precision: 0,
            usePercentage: true,
            label: 'O/CE'
          },
          {
            id: 'COVID',
            precision: 0,
            usePercentage: true,
            label: 'COVID'
          }
        ],
        yAxes: [
          {
            label: Labels.docScore.axisLabel,
            precision: 0,
            usePercentage: true
          },
          {
            label: 'Total Coded Rate, As % of Stays',
            precision: 0,
            usePercentage: true
          }
        ],
        chartDatasetsHiddenByDefault: new Set<ChartDataSetType>(['COVID'])
      };
    case 'los':
      return {
        chartDataSets: [
          {
            id: 'Actual',
            precision: 3
          },
          {
            id: 'HCUPAvg',
            precision: 3
          },
          {
            id: 'CEHCUPAvg',
            precision: 3
          },
          {
            id: 'Gap',
            precision: 3
          }
        ],
        dataTableDataSets: [
          {
            id: 'Discharges',
            precision: 0
          },
          {
            id: 'Actual',
            precision: 2
          },
          {
            id: 'HCUPAvg',
            precision: 2
          },
          {
            id: 'CEHCUPAvg',
            precision: 2
          },
          {
            id: 'Gap',
            precision: 2,
            label: 'LOS Severity Gap'
          }
        ],
        yAxes: [
          {
            label: 'Length of Stay (days)',
            precision: 1
          },
          {
            label: 'LOS Severity Gap (days)',
            precision: 2
          }
        ],
        chartDatasetsHiddenByDefault: new Set<ChartDataSetType>(['Actual', 'Gap'])
      };
    case 'elixhauserMortality':
      return {
        chartDataSets: [
          {
            id: 'Actual',
            precision: 1,
            usePercentage: true,
            label: 'O/CE'
          },
          {
            id: 'ClinicallyExpected',
            usePercentage: true,
            precision: 1
          }
        ],
        dataTableDataSets: [
          {
            id: 'Discharges',
            precision: 0
          },
          {
            id: 'ClinicallyExpected',
            precision: 0,
            usePercentage: true
          },
          {
            id: 'Actual',
            precision: 0,
            usePercentage: true,
            label: 'O/CE'
          }
        ],
        yAxes: [
          {
            label: Labels.elixhauserMortality.axisLabel,
            precision: 0,
            usePercentage: true
          }
        ]
      };
    case 'elixhauserReadmission':
      return {
        chartDataSets: [
          {
            id: 'Actual',
            precision: 1,
            usePercentage: true,
            label: 'O/CE'
          },
          {
            id: 'ClinicallyExpected',
            usePercentage: true,
            precision: 1
          }
        ],
        dataTableDataSets: [
          {
            id: 'Discharges',
            precision: 0
          },
          {
            id: 'ClinicallyExpected',
            precision: 0,
            usePercentage: true
          },
          {
            id: 'Actual',
            precision: 0,
            usePercentage: true,
            label: 'O/CE'
          }
        ],
        yAxes: [
          {
            label: Labels.elixhauserReadmission.axisLabel,
            precision: 0,
            usePercentage: true
          }
        ]
      };
    case 'psi':
      return {
        chartDataSets: [
          {
            id: 'Actual',
            precision: 1,
            usePercentage: true,
            label: 'O/CE'
          },
          {
            id: 'ClinicallyExpected',
            usePercentage: true,
            precision: 1
          }
        ],
        dataTableDataSets: [
          {
            id: 'Discharges',
            precision: 0
          },
          {
            id: 'ClinicallyExpected',
            precision: 0,
            usePercentage: true
          },
          {
            id: 'Actual',
            precision: 0,
            usePercentage: true,
            label: 'O/CE'
          }
        ],
        yAxes: [
          {
            label: Labels.psi.axisLabel,
            precision: 0,
            usePercentage: true
          }
        ]
      };
    case 'raf':
      return {
        chartDataSets: [
          {
            id: 'Actual',
            precision: 1,
            usePercentage: true,
            label: 'O/CE'
          },
          {
            id: 'ClinicallyExpected',
            usePercentage: true,
            precision: 1
          }
        ],
        dataTableDataSets: [
          {
            id: 'Discharges',
            precision: 0
          },
          {
            id: 'ClinicallyExpected',
            precision: 0,
            usePercentage: true
          },
          {
            id: 'Actual',
            precision: 0,
            usePercentage: true,
            label: 'O/CE'
          }
        ],
        yAxes: [
          {
            label: Labels.raf.axisLabel,
            precision: 0,
            usePercentage: true
          }
        ]
      };
    case 'condition':
    case 'allConditions':
    case 'targetedConditions': {
      if (forOCE) {
        return {
          chartDataSets: [
            {
              id: 'OtoERatio',
              precision: 1,
              usePercentage: true
            },
            {
              id: 'OtoECE',
              precision: 1,
              usePercentage: true
            }
          ],
          dataTableDataSets: [
            {
              id: 'Discharges',
              precision: 0
            },
            {
              id: 'OtoECE',
              precision: 0,
              usePercentage: true
            },
            {
              id: 'OtoERatio',
              label: 'O/CE',
              precision: 0,
              usePercentage: true
            }
          ],
          yAxes: [
            {
              label: 'Secondary O/CE',
              precision: 0,
              usePercentage: true
            }
          ]
        };
      }

      return {
        chartDataSets: [
          {
            id: 'Secondary',
            precision: 1,
            usePercentage: true
          },
          {
            id: 'CESecondary',
            precision: 1,
            usePercentage: true
          },
          {
            id: 'Total',
            precision: 1,
            usePercentage: true
          },
          {
            id: 'SecondarySingle',
            precision: 1,
            usePercentage: true
          },
          {
            id: 'AvgImpactSingle',
            precision: 3
          },
          {
            id: 'SecondaryDual',
            precision: 1,
            usePercentage: true
          },
          {
            id: 'AvgImpactDual',
            precision: 3
          }
        ],
        dataTableDataSets: [
          {
            id: 'Discharges',
            precision: 0
          },
          {
            id: 'Total',
            precision: 0,
            usePercentage: true
          },
          {
            id: 'Secondary',
            precision: 0,
            usePercentage: true
          },
          {
            id: 'CESecondary',
            precision: 0,
            usePercentage: true
          },
          {
            id: 'Gap',
            precision: 0,
            usePercentage: true
          },
          {
            id: 'SecondarySingle',
            precision: 0,
            usePercentage: true
          },
          {
            id: 'AvgImpactSingle',
            precision: 2
          },
          {
            id: 'SecondaryDual',
            precision: 0,
            usePercentage: true
          },
          {
            id: 'AvgImpactDual',
            precision: 2
          }
        ],
        yAxes: [
          {
            label: 'Coded Rate, As % of Stays',
            precision: 0,
            usePercentage: true
          },
          {
            label: 'RWPV Per Patient Stay',
            precision: 2
          }
        ],
        chartDatasetsHiddenByDefault: new Set<ChartDataSetType>([
          'SecondarySingle',
          'SecondaryDual',
          'AvgImpactSingle',
          'AvgImpactDual',
          'Total'
        ])
      };
    }
    case 'hcupcmi': {
      return {
        chartDataSets: [
          {
            id: 'Actual',
            precision: 3,
            label: 'Observed'
          },
          {
            id: 'ClinicallyExpected',
            precision: 3
          },
          {
            id: 'ComparisonEntity',
            precision: 3
          }
        ],
        dataTableDataSets: [
          {
            id: 'Discharges',
            precision: 0
          },
          {
            id: 'ClinicallyExpected',
            precision: 2
          },
          {
            id: 'Actual',
            precision: 2,
            label: 'Observed'
          },
          {
            id: 'Gap',
            precision: 2,
            label: 'HCUP CMI Gap'
          }
        ],
        yAxes: [
          {
            label: 'HCUP CMI',
            precision: 2
          }
        ]
      };
    }
    default:
      return {
        chartDataSets: [
          {
            id: 'Secondary',
            precision: 2
          }
        ],
        dataTableDataSets: [
          {
            id: 'Discharges',
            precision: 0
          }
        ],
        yAxes: [
          {
            label: '',
            precision: 0
          }
        ]
      };
  }
};

export default getChartAndTableConfiguration;
