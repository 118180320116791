import { ApplicationAPI } from '@clinintell/utils/api';
import { formatDateForAPI } from '@clinintell/utils/formatting';
import { createUrlWithQueryString, QueryStringParam } from '@clinintell/utils/querystring';
import { useState } from 'react';

type UseExecutiveSummaryReportOutput = {
  isCreatingExecutiveReport: boolean;
  hasError: boolean;
  handleCreateExecutiveReport: (reportDate: Date, hospitalOrgId?: number, groupOrgId?: number) => Promise<void>;
};

const useExecutiveSummaryReport = (): UseExecutiveSummaryReportOutput => {
  const [isCreatingExecutiveReport, setIsCreatingExecutiveReport] = useState(false);
  const [hasError, setHasError] = useState(false);

  const handleCreateExecutiveReport = async (
    reportDate: Date,
    hospitalOrgId?: number,
    groupOrgId?: number
  ): Promise<void> => {
    if (!reportDate) {
      return;
    }

    setIsCreatingExecutiveReport(true);

    const endpoint = `reports/system/executivesummary`;
    const params: QueryStringParam[] = [
      {
        key: 'reportdate',
        value: formatDateForAPI(new Date(reportDate))
      }
    ];

    if (hospitalOrgId) params.push({ key: 'hospitalOrgId', value: `${hospitalOrgId}` });
    if (groupOrgId) params.push({ key: 'physicianGroupOrgId', value: `${groupOrgId}` });

    const createReport = ApplicationAPI.get({ endpoint: createUrlWithQueryString(endpoint, params) });

    const result = await Promise.resolve(createReport);
    if (!result.data) {
      setHasError(true);
      setIsCreatingExecutiveReport(false);
      return;
    }

    const blob = new Blob([result.data as ArrayBuffer], {
      type: 'application/pdf'
    });

    const filename = 'ExecutiveSummaryReport';
    if (window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, filename);
      return;
    }

    const link = document.createElement('a');
    link.download = filename;
    link.href = URL.createObjectURL(blob);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    setIsCreatingExecutiveReport(false);
  };

  return {
    isCreatingExecutiveReport,
    hasError,
    handleCreateExecutiveReport
  };
};

export default useExecutiveSummaryReport;
